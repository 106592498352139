import React from 'react'
import Header from '../Components/Header'
import SpecificBlogPage from '../Components/SpecificBlogPage'
import Footer from '../Components/Footer'

const SpecificBlog = () => {
    return (
        <div>
            {/* <Header navi={true}/> */}
            <SpecificBlogPage />
            {/* <Footer /> */}
        </div>
    )
}

export default SpecificBlog