import React, { useState, useEffect } from 'react'
import { GetTermsOfUse, MetaUserSpecificView } from '../Repository/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
const TermsOfUseContent = () => {
  const [cms, setCms] = useState({})
  const { title } = useSelector(state => state?.masterdata?.masterdata)
  const getTrainingcms = async () => {
    const response = await GetTermsOfUse()
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure)
    }
  }
  const [meta, setMeta] = useState()
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("terms-of-service")
    if (response && response.statusCode === 200) {
      setMeta(response?.data)
    }
  }
  useEffect(() => {
    getTrainingcms()
    GetMeta()
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      <div className='container h-top2 nunito mb-5'>
        <h2>{cms?.heading}</h2>
        <p>Last Updated:{moment(cms?.updatedAt).format("Do [day of]  MMMM YYYY")} </p>
        <div className='nunito'
          dangerouslySetInnerHTML={{ __html: cms?.content }}
        />
      </div>
    </>
  )
}

export default TermsOfUseContent