import React, { useEffect } from "react";
import CustomModal from "./CustomModal";
import Logo from '../../Assets/navlogo.webp'
import Mail from '../../Assets/images/submit.png'
const SubmittedModal = ({ open, setOpen }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])
    return (

        <div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content">
                    <div className="tnk-head">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="th-cnt">
                        <img src={Mail} alt="Thanks" />
                        <h1 className="font-bold grad-color">Thank You</h1>
                        <p>Your details have been successfully submitted.</p>
                        <button
                            type="button"
                            className="tnk-btn btn btn-primary"
                            onClick={() => {
                                setOpen(false);
                                window.location.reload()
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default SubmittedModal;
