import React, { useEffect, useRef, useState } from "react";
import logo from "../../Assets/navlogo.webp";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubmitOtp, VerifyOTPSend } from "../../Repository/Api";
import { errorMsg, successMsg } from "../Toastify";

const VerifyOTP = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const Ref = useRef(null);

  const [otpNum, setOtpNum] = useState("");
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const onClickReset = () => {
    handleSubmitResend();
    clearTimer(getDeadTime());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Payload = {
      emailId: state.auth.registerData,
      otp: otpNum,
      otpType: "forgotPassword",
    };
    const response = await SubmitOtp(Payload);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
      getTimeout();
    } else {
      errorMsg(response.message);
    }
  };

  const handleOtp = (num) => {
    setOtpNum(num);
  };
  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/ResetPassword"));
    }, 1000);
  };

  const handleSubmitResend = async () => {
    const Payload = {
      emailId: state.auth.registerData,
      otp: "forgotPassword",
    };
    const response = await VerifyOTPSend(Payload);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
    } else {
      errorMsg(response.message);
    }
  };

  return (
    <>
      <section className="loginbg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ms-auto mx-auto">
              <div className="loginmodel forgot-pass-layout">
                <div className="centertext">
                  <img src={logo} alt="Rescale Lab" />
                </div>
                <div className="mt-5">
                  <h4 className="fw-bold textcolor text-center">
                    Verify With OTP
                  </h4>
                </div>
                <div className="my-5">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="aligncenterone my-5">
                      <OtpInput
                        className="otpInput"
                        value={otpNum}
                        onChange={handleOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        renderSeparator={<span className="otpspace"></span>}
                        inputStyle={{
                          border: "1px solid #CFD3DB",
                          borderRadius: "8px",
                          width: "45px",
                          height: "45px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",
                        }}
                        focusStyle={{
                          border: "1px solid #3773C9",
                          outline: "none",
                        }}
                        separator={<span style={{ width: "8px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        name="otpValue"
                      />
                    </div>
                    <div className="inputspace">
                      <button className="loginbtn">Verify OTP</button>
                    </div>
                    <div className="displayflexspace">
                      <p
                        className="text-end forgettext"
                        onClick={() => {
                          navigate("/ForgetPassword");
                        }}
                      >
                        <small>Back</small>
                      </p>
                      <p
                        className="text-start forgettext"
                        onClick={onClickReset}
                      >
                        <small>Resend OTP in {timer}</small>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyOTP;
