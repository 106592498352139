import React, { useState } from "react";
import logo from "../../Assets/navlogo.webp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubmitPassword } from "../../Repository/Api";
import { errorMsg, successMsg } from "../Toastify";

const ResetPassword = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const [login, SetLogin] = useState([]);
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState();
  const [password1, setPassword1] = useState();
  const setData = (e, key) => {
    let TempData = login;
    TempData[key] = e;
    SetLogin(TempData);
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const viewPassword = () => {
    setPassword(!password);
  };
  const viewPassword1 = () => {
    setPassword1(!password1);
  };

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!login.password) {
      validdateData.password = "Password is Required";
      Status = false;
    } else if (login.password.localeCompare(login.repassword) !== 0) {
      validdateData.repassword = "Password and Re-Enter Password doesn't match";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Payload = {
      emailId: state.auth.registerData,
      password: login.password,
    };
    if (CheckValidation()) {
      const response = await SubmitPassword(Payload);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        getTimeout();
      } else {
        errorMsg(response.message);
      }
    }
  };

  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/adminlogin"));
    }, 1000);
  };

  return (
    <>
      <section className="loginbg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ms-auto mx-auto">
              <div className="loginmodel forgot-pass-layout">
                <div className="centertext">
                  <img src={logo} alt="Rescale Lab" />
                </div>
                <div className="my-5">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={password ? "text" : "password"}
                        className="logininput-field"
                        placeholder="New Password"
                        defaultValue={login.password}
                        onChange={(e) => {
                          setData(e.target.value, "password");
                        }}
                      />
                      <div className="psw-icon">
                        {password ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      {validation.password && (
                        <p className="validationerror">{validation.password}</p>
                      )}
                    </div>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={password1 ? "text" : "password"}
                        className="logininput-field"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          setData(e.target.value, "repassword");
                        }}
                      />
                      <div className="psw-icon">
                        {password1 ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword1()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword1()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      {validation.repassword && (
                        <p className="validationerror">
                          {validation.repassword}
                        </p>
                      )}
                    </div>
                    <div className="inputspace">
                      <button className="loginbtn">Update Password</button>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className=" forgettext"
                        onClick={() => {
                          navigate("/adminlogin");
                        }}
                      >
                        <small>Back to Login</small>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
