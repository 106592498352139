import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../Adminheader";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import {
  EmailValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";
import { AdminSpecificView, UpdateAdmin } from "../../../Repository/Api";

const AdminSpecificview = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSpecificlist();
  }, []);

  const [adminList, setAdminList] = useState({});
  const [validation, setValidation] = useState({});

  const setData = (e, key) => {
    setAdminList({ ...adminList, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getSpecificlist = async () => {
    const response = await AdminSpecificView(id, token);
    if (response && response.statusCode === 200) {
      setAdminList(response.data);
    } else {
      errorMsg(response.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.firstName = StringValidation(adminList?.firstName);
    validate.lastName = StringValidation(adminList?.lastName);
    validate.emailId = EmailValidation(adminList?.emailId);
    validate.phoneNumber = PhoneNumberValidation(adminList?.phoneNumber);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: adminList._id,
        firstName: adminList.firstName,
        lastName: adminList.lastName,
        // emailId: adminList.emailId,
        phoneNumber: adminList.phoneNumber,
        status: adminList.status,
      };
      const response = await UpdateAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setAdminList({});
        setTimeout(() => {
          setTimeout(navigate("/admin/Adminlist"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Adminlist");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Admin List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox mt-3">
              <form onSubmit={(e) => handleUpdate(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Admin Specific Details & Update
                  </p>
                  <div className="p-4">
                    <div className="row mt-3">
                      <div className="col-lg-6">
                        <label className="labeltext">First Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter First Name"
                            defaultValue={adminList?.firstName}
                            onChange={(e) => {
                              setData(e.target.value, "firstName");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.firstName?.message
                            ? `First Name ${validation?.firstName?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Last Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Last Name"
                            defaultValue={adminList?.lastName}
                            onChange={(e) => {
                              setData(e.target.value, "lastName");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.lastName?.message
                            ? `Last Name ${validation?.lastName?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Email ID *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinputemail"
                            placeholder="Enter Email ID"
                            defaultValue={adminList?.emailId}
                            disabled
                            onChange={(e) => {
                              setData(e.target.value, "emailId");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.emailId?.message
                            ? `Email ID ${validation?.emailId?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Phone Number *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Phone Number"
                            maxLength={10}
                            defaultValue={adminList?.phoneNumber}
                            onChange={(e) => {
                              setData(e.target.value, "phoneNumber");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.phoneNumber?.message
                            ? `Phone Number ${validation?.phoneNumber?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSpecificview;
