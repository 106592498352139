import { actionTypes } from "./action";

export const initState = {
  navbardata: [],
};

function reducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.NAVBAR_DATA:
      return {
        ...state,
        ...{ navbardata: action.payload },
      };
    default:
      return state;
  }
}
export default reducer;
