import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Capt from "../Assets/captcha.webp";
import { CreateContactData, verifyCaptchaToken } from "../Repository/Api";
import ThankYouModal from "./Modal/ThankyouModal";
import {
  GetUserSocialList,
  GetFaqList,
  GetContactusPage,
  MetaUserSpecificView,
} from "../Repository/Api";
import { cloudFrontUrl } from "../commonUtils/Utils";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Contactpage = () => {
  const captchaRef = useRef(null);
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const contact = contactCardData;
  const inputRef = useRef(null);
  const [thankYouModal, setThankYouModal] = useState(false);
  const [socialList, setSocialList] = useState([]);
  const [Content, setContactContent] = useState({});
  const [faq, setFaq] = useState([]);
  const [meta, setMeta] = useState();
  const [captchaToken, setCaptchaToken] = useState(null);
  const location = useLocation();

  const getSocialList = async () => {
    const response = await GetUserSocialList();
    if (response && response.statusCode === 200) {
      setSocialList(response?.data);
    } else {
      setSocialList([]);
    }
  };

  const getContactContent = async () => {
    const response = await GetContactusPage();
    if (response && response.statusCode === 200) {
      setContactContent(response?.data?.pageStructure);
    }
  };

  const FaqList = async () => {
    const response = await GetFaqList();
    if (response && response.statusCode === 200) {
      setFaq(response?.data);
    } else {
      setFaq([]);
    }
  };

  const GetMeta = async () => {
    const response = await MetaUserSpecificView("contact-us");
    if (response && response.statusCode === 200) {
      setMeta(response?.data);
    }
  };

  useEffect(() => {
    getSocialList();
    FaqList();
    getContactContent();
    GetMeta();
  }, []);

  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
    if (containerId === "in-fo" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [location]);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setData(token, "captchaToken");
  };

  const [info, setInfo] = useState({});
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setInfo({ ...info, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!info.firstName) {
      validdateData.firstName = "Firstname is Required";
      Status = false;
    }
    if (!info.lastName) {
      validdateData.lastName = "Lastname is Required";
      Status = false;
    }
    if (!info.emailId) {
      validdateData.emailId = "EmailId is Required";
      Status = false;
    }
    if (!info.country) {
      validdateData.country = "Country is Required";
      Status = false;
    }
    if (!info.message) {
      validdateData.message = "Message is Required";
      Status = false;
    }
    if (!info.companyName) {
      validdateData.companyName = "Company name is Required";
      Status = false;
    }
    if (!info.jobTitle) {
      validdateData.jobTitle = "Job Title is Required";
      Status = false;
    }
    if (!captchaToken) {
      validdateData.captchaToken = "Please verify the Captcha";
      Status = false;
    }

    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let inputvalue = {
      firstName: info.firstName,
      lastName: info.lastName,
      emailId: info.emailId,
      country: info.country,
      message: info.message,
      companyName: info.companyName,
      jobTitle: info.jobTitle,
    };

    let token = captchaRef.current.getValue();
    console.log(token, "dcdcdc");
    let formdata = new FormData();
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }

    if (CheckValidation()) {
      await CreateContactData(inputvalue);
      setThankYouModal(true);
      setInfo({});
      window.location.reload();
    } else {
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `,
          }}
        />
      </Helmet>
      <div className="nunito h-top2" id="contact">
        <ThankYouModal
          open={thankYouModal}
          setOpen={() => {
            setThankYouModal(false);
          }}
        />
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6">
              <p
                className="h1 grad-color"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                {Content?.heading}
              </p>
              <p
                className="mt-3 textarea"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                {Content.content}
              </p>
              {Content?.emaiIdShown && (
                <p className="mt-5" data-aos="zoom-in" data-aos-delay="300">
                  <img
                    src={`${cloudFrontUrl}${Content.emailIcon}`}
                    alt="EmailIcon"
                  />
                  <a href={`mailto:${Content.emailId}`} className="green ms-3">
                    {Content.emailId}
                  </a>
                </p>
              )}

              <div className="d-flex" data-aos="zoom-in" data-aos-delay="300">
                <div>
                  <img
                    src={`${cloudFrontUrl}${Content.locationIcon}`}
                    alt="LocationIcon"
                  />
                </div>
                <div className="ms-3 green">
                  {Content?.location?.map(
                    (location, index) =>
                      location.status === "active" && (
                        <p key={index} className="textarea">
                          {location.address}
                        </p>
                      )
                  )}
                </div>
              </div>
              {Content?.showCalendlyButton &&
              <div className="inputspace mt-4 text-center d-flex justify-content-center">
                <a
                  href={`${Content?.calendlyLink}`}
                  className="navbar-brand"
                  target="_blank"
                >
                  <button className="loginbtn d-flex align-items-center justify-content-center  w-auto px-4">
                    <span className="fs-6">
                      {" "}
                   {Content?.calendlyButtonContent}  {" "}
                    </span>
                    <span class="material-symbols-outlined">arrow_forward</span>
                  </button>
                </a>
              </div>}
              {Content.socialMediaShown && (
                <div
                  className="d-flex w-50 social-sml justify-content-between mt-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  {socialList.map((item, i) => {
                    return (
                      <div key={i}>
                        <a href={`${item.link}`} target="_blank">
                          <img
                            src={`${cloudFrontUrl}${item.icon}`}
                            alt="socialmedia"
                            className="social-icon-sml"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="300">
              <div className="row contact-k">
                <div className="my-3 col-md-6">
                  <label>First Name*</label>
                  <input
                    ref={inputRef}
                    id="in-fo"
                    type="text"
                    className="form-control"
                    name="firstName"
                    placeholder="Enter First Name"
                    onChange={(e) => setData(e.target.value, "firstName")}
                  />
                  {validation.firstName && (
                    <p className="error-text">First Name is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-6">
                  <label>Last Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    placeholder="Enter Last Name"
                    onChange={(e) => setData(e.target.value, "lastName")}
                  />
                  {validation.lastName && (
                    <p className="error-text">Last Name is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-6">
                  <label>Email*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="emailId"
                    placeholder="Enter Email"
                    onChange={(e) => setData(e.target.value, "emailId")}
                  />
                  {validation.emailId && (
                    <p className="error-text">Email is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-6">
                  <label>Company name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    placeholder="Enter Company"
                    onChange={(e) => setData(e.target.value, "companyName")}
                  />
                  {validation.companyName && (
                    <p className="error-text">company Name is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-6">
                  <label>Country/Region*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    placeholder="Enter Country/Region"
                    onChange={(e) => setData(e.target.value, "country")}
                  />
                  {validation.country && (
                    <p className="error-text">Country/Region is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-6">
                  <label>Job title*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="jobTitle"
                    placeholder="Enter Job Title"
                    onChange={(e) => setData(e.target.value, "jobTitle")}
                  />
                  {validation.jobTitle && (
                    <p className="error-text">Job Title is Required</p>
                  )}
                </div>
                <div className="my-3 col-md-12">
                  <label>Messages*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="message"
                    placeholder="Add Text"
                    rows={4}
                    onChange={(e) => setData(e.target.value, "message")}
                  />
                  {validation.message && (
                    <p className="error-text">Message is Required</p>
                  )}
                </div>
                <p className="sml textarea">{Content.contactUsFormContent}</p>
                <div className="d-flex justify-content-between">
                  {/* <div className="">
                    <img src={Capt} alt="catcha" />
                  </div> */}

                  <ReCAPTCHA
                    sitekey="6LdnxNopAAAAAEsgmhIuuugjMKeKqwWxvjOsOAy3"
                    ref={captchaRef}
                    onChange={handleCaptchaChange}
                  />
                  <div>
                    <button
                      type="submit"
                      className="btn-submit-ct"
                      // className= {!captchaToken?"btn-submit-diabled":"btn-submit-ct"}
                      onClick={handleSubmit}
                      // disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {validation.captchaToken && (
                  <p className="error-text">Please verify the Captcha</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {Content.faqShown && (
          <div className="FAQ" id="faq">
            <div className="container">
              <div className="row justify-content-center">
                <p className="h1 grad-color text-center">{Content.faqTitle}</p>
                <div className="col-lg-8">
                  <div className="accordion  my-5" id="accordionexample">
                    {faq
                      ? faq?.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="accordion-item ac-item-1"
                              data-aos="zoom-in"
                              data-aos-delay="300"
                            >
                              <h2 className="accordion-header" id="headingone">
                                <button
                                  className="accordion-button ac-btn-1 collapsed font-semibold"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  // data-bs-target="#collapseone"
                                  data-bs-target={`#collapse${i}`}
                                  aria-expanded="false"
                                  // aria-controls="collapseone"
                                  aria-controls={`collapse${i}`}
                                >
                                  {item?.question}
                                </button>
                              </h2>
                              <div
                                // id="collapseone"
                                id={`collapse${i}`}
                                className="accordion-collapse collapse"
                                // aria-labelledby="headingone"
                                aria-labelledby={`headingone${i}`}
                                data-bs-parent="#accordionexample"
                              >
                                <div className="accordion-body ac-body-1">
                                  <div
                                    className="faq-body"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.answer,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "NO Faq found"}
                    {/* <div className="accordion-item ac-item-1" data-aos="zoom-in" data-aos-delay="300">
                                    <h2 className="accordion-header" id="headingone">
                                        <button
                                            className="accordion-button ac-btn-1 collapsed font-semibold"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseone"
                                            aria-expanded="false"
                                            aria-controls="collapseone"
                                        >
                                            What services does RescaleLab provide to its clients?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseone"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingone"
                                        data-bs-parent="#accordionexample"
                                    >
                                        <div className="accordion-body ac-body-1">
                                            <p>Experiential learning is an engaged learning process whereby students “learn by doing” and by reflecting on the experience. Well-planned, supervised and assessed experiential learning programs can stimulate academic inquiry by promoting interdisciplinary learning, civic engagement, career development, cultural awareness, leadership, and other professional and intellectual skills.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item ac-item-1" data-aos="zoom-in" data-aos-delay="300">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button ac-btn-1 collapsed font-semibold"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsetwo"
                                            aria-expanded="false"
                                            aria-controls="collapsetwo"
                                        >
                                            What is involved in the client onboarding process at Rescalelab?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsetwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionexample"
                                    >
                                        <div className="accordion-body ac-body-1">
                                            <p>Experiential learning activities can include, but are not limited to, hands-on laboratory experiments, internships, practicums, field exercises, study abroad, undergraduate research and studio performances.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item ac-item-1" data-aos="zoom-in" data-aos-delay="300">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button ac-btn-1 collapsed font-semibold"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsethree"
                                            aria-expanded="false"
                                            aria-controls="collapsethree"
                                        >
                                            How do I become a partner with RescaleLab?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsethree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionexample"
                                    >
                                        <div className="accordion-body ac-body-1">
                                            <p>To become a partner with RescaleLab, please reach out to our partnership team at partnership@rescalelab.com. Our partnership team will guide you through the partnership process, which involves discussing your goals and how we can collaborate effectively.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item ac-item-1" data-aos="zoom-in" data-aos-delay="300">
                                    <h2 className="accordion-header" id="headingfour">
                                        <button
                                            className="accordion-button ac-btn-1 collapsed font-semibold"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefour"
                                            aria-expanded="false"
                                            aria-controls="collapsefour"
                                        >
                                            What benefits can I expect as a RescaleLab partner?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsefour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingfour"
                                        data-bs-parent="#accordionexample"
                                    >
                                        <div className="accordion-body ac-body-1">
                                            <p>As a RescaleLab partner, you can expect benefits such as access to our Generative AI-driven Mentat Training Programs and Advanced Measured Learning Platform, collaboration opportunities with our expert team, and the chance to co-develop innovative solutions in the field of Educational Technology. RescaleLab prioritises strong partnerships to drive mutual success.</p>
                                        </div>
                                    </div>
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {Content.contactUsCardsShown && (
          <div className="contact nunito">
            <div className="container d-md-block d-none">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <p className="h1 textarea"> {contact?.heading}</p>
                  <p className="textarea">{contact?.subHeading}</p>
                  {contact?.buttonShown && (
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className="btn-a1">
                        {contact?.buttonHeading}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div className="col-lg-6">
                  <img
                    src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {Content.contactUsCardsShown && (
          <div className="container mt-4 d-block d-md-none">
            <div
              data-aos="zoom-in"
              data-aos-delay="300"
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 text-center">{contact?.heading}</p>
              <p className="text"> {contact?.subHeading}</p>
              <img
                src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                className="w-100"
              />
              {contact?.buttonShown && (
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className="btn-a1">{contact?.buttonHeading}</button>
                </NavLink>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Contactpage;
