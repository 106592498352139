import React, { useState, useEffect } from "react";
import number1 from "../../Assets/number1.webp";
import number2 from "../../Assets/number2.webp";
import number3 from "../../Assets/number3.webp";
import number4 from "../../Assets/number4.webp";
import number5 from "../../Assets/number5.webp";
import number6 from "../../Assets/number6.webp";
import logo from "../../Assets/logo.webp";
import logo1 from "../../Assets/logo1.webp";
import cartoon from "../../Assets/cartoon.webp";
import dot from "../../Assets/dot.webp";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { cloudFrontUrl } from "../../commonUtils/Utils";
import { GetLicensingPage, MetaUserSpecificView } from "../../Repository/Api";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
const Licensingcontent = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const contact = contactCardData;
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const [cms, setCms] = useState({});
  const getcms = async () => {
    const response = await GetLicensingPage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };
  const [meta, setMeta] = useState();
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("licensing");
    if (response && response.statusCode === 200) {
      setMeta(response?.data);
    }
  };

  useEffect(() => {
    getcms();
    GetMeta();
  }, []);

  const ban = cms?.bannerIcons?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const empower = cms?.empoweringSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const feat = cms?.featuresSectionFeatures?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const expect = cms?.expectedSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const [imageError, setImageError] = useState(false);
  const handleImage = () => {
    setImageError(true);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header />
        </div>
      )}
      <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
        <div className="tech">
          <div className="container">
            <div className="row">
              <div
                className="back col-lg-6 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p className="h1">{cms?.bannerHeading}</p>
                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                {cms?.bannerButtonShown && (
                  <NavLink to={cms?.bannerButtonRedirectLink}>
                    <button
                      className="btn btn-outline-success me-5"
                      type="submit"
                    >
                      {cms?.bannerButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
              <div className="col-lg-6 col-md-6 d-flex justify-content-center girl-img">
                <img
                  src={`${cloudFrontUrl}${cms?.bannerImage}`}
                  alt="homeimg"
                  className="Girl2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[0]?.image}`}
                  alt="homeimg"
                  className={`l-img1 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[1]?.image}`}
                  alt="homeimg"
                  className={`l-img2 ${imageError && "d-none"}`}
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[2]?.image}`}
                  alt="homeimg"
                  className={`l-img3 ${imageError && "d-none"}`}
                  data-aos="flip-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[3]?.image}`}
                  alt="homeimg"
                  className={`l-img4 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[4]?.image}`}
                  alt="homeimg"
                  className={`l-img5 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[5]?.image}`}
                  alt="homeimg"
                  className={`l-img6 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </div>
        {cms?.empoweringSectionShown && (
          <div className="growth">
            <div className="container">
              <p className="subtitle" data-aos="fade-up" data-aos-delay="300">
                {cms?.empoweringSectionHeading}
              </p>
              <p className="title" data-aos="fade-up" data-aos-delay="300">
                {cms?.empoweringSectionSubHeading}
              </p>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number1} className="img6" alt="number1" />
                  </div>
                  <p className="para15 textarea">
                    {empower && empower[0]?.content}
                  </p>
                </div>
              </div>
              <br />
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number2} className="img6" alt="number2" />
                  </div>
                  <p className="para15 textarea">
                    {empower && empower[1]?.content}
                  </p>
                </div>
              </div>
              <br />
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number3} className="img6" alt="number3" />
                  </div>
                  <p className="para15 textarea">
                    {empower && empower[2]?.content}
                  </p>
                </div>
              </div>
              {cms?.empoweringSectionButtonShown && (
                <div className="bot" data-aos="zoom-in" data-aos-delay="300">
                  <NavLink to={cms?.empoweringSectionButtonRedirectLink}>
                    <button
                      className="btn btn-outline-success me-5"
                      type="submit"
                    >
                      {cms?.empoweringSectionButtonName}
                    </button>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        )}
        {cms?.featuresSectionShown && (
          <div className="magnet">
            <p className="subtitle" data-aos="fade-up" data-aos-delay="300">
              {cms?.featuresSectionHeading}
            </p>
            <p className="title" data-aos="fade-up" data-aos-delay="300">
              {cms?.featuresSectionSubHeading}
            </p>
            <div className="container ">
              <img src={logo} className="logo8" alt="logo" />
              <div className="container box">
                <div className="row g-1">
                  <div
                    className="col col-md-4"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="card">
                      <img
                        src={`${cloudFrontUrl}${feat && feat[0]?.icon}`}
                        className="img7"
                        alt="llicensingimg16"
                      />
                      <p className="title1">{feat && feat[0]?.heading}</p>
                      <p className="para18 textarea">
                        {feat && feat[0]?.content}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col col-md-4"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="card">
                      <img
                        src={`${cloudFrontUrl}${feat && feat[1]?.icon}`}
                        className="img7"
                        alt="llicensingimg17"
                      />
                      <p className="title1">{feat && feat[1]?.heading}</p>
                      <p className="para18 textarea">
                        {feat && feat[1]?.content}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col col-md-4"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="card">
                      <img
                        src={`${cloudFrontUrl}${feat && feat[2]?.icon}`}
                        className="img7"
                        alt="llicensingimg18"
                      />
                      <p className="title1">{feat && feat[2]?.heading}</p>
                      <p className="para18 textarea">
                        {feat && feat[2]?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={logo1} className="logo1" alt="logo" />
            {cms?.featuresSectionButtonShown && (
              <div className="bot" data-aos="zoom-in" data-aos-delay="300">
                <NavLink to={cms?.featuresSectionButtonRedirectPath}>
                  <button
                    className="btn btn-outline-success me-5"
                    type="submit"
                  >
                    {cms?.featuresSectionButtonName}
                  </button>
                </NavLink>
              </div>
            )}
          </div>
        )}
        {cms?.expectedSectionShown && (
          <div className="gun">
            <div className="container">
              <p className="subtitle" data-aos="zoom-in" data-aos-delay="300">
                {cms?.expectedSectionHeading}
              </p>
              <p className="title" data-aos="zoom-in" data-aos-delay="300">
                {cms?.expectedSectionSubHeading}
              </p>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number1} className="img6" alt="number1" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[0]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[0]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number2} className="img6" alt="number2" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[1]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[1]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number3} className="img6" alt="number3" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[2]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[2]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number4} className="img6" alt="number4" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[3]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[3]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number5} className="img6" alt="number5" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[4]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[4]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay="300">
                <div className="col">
                  <div className="num">
                    <img src={number6} className="img6" alt="number6" />
                  </div>
                  <div className="local">
                    <p className="title1">{expect && expect[5]?.heading}</p>
                    <p className="para19 textarea">
                      {expect && expect[5]?.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {cms?.expectedSectionButtonShown && (
              <div className="bot" data-aos="zoom-in" data-aos-delay="300">
                <NavLink to={cms?.expectedSectionButtonRedirectPath}>
                  <button
                    className="btn btn-outline-success me-5"
                    type="submit"
                  >
                    {cms?.expectedSectionButtonName}
                  </button>
                </NavLink>
              </div>
            )}
          </div>
        )}
        {cms?.partOfSectionShown && (
          <div className="cartoon">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-5"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img src={`${cloudFrontUrl}${cms?.LicenseImage}`} className="img9 w-100" alt="cartoon" />
                </div>
                <div
                  className="col-lg-6 col-md-7"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p className="subtitle">{cms?.partOfSectionHeading}</p>
                  <p className="title">{cms?.partOfSectionSubHeading}</p>
                  <p className="para20 textarea">{cms?.partOfSectionContent}</p>
                  {cms?.partOfSectionButtonShown && (
                    <NavLink to={cms?.partOfSectionButtonRedirectPath}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.partOfSectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.contactUsCardsShown && (
          <div className="contact nunito">
            <div className="container d-md-block d-none">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <p className="h1"> {contact?.heading}</p>
                  <p>{contact?.subHeading}</p>
                  {contact?.buttonShown && (
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className="btn-a1">
                        {contact?.buttonHeading}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div className="col-lg-6">
                  <img
                    src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.contactUsCardsShown && (
          <div className="container mt-4 d-block d-md-none">
            <div
              data-aos="zoom-in"
              data-aos-delay="300"
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 text-center">{contact?.heading}</p>
              <p className="text"> {contact?.subHeading}</p>
              <img
                src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                className="w-100"
              />
              {contact?.buttonShown && (
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className="btn-a1">{contact?.buttonHeading}</button>
                </NavLink>
              )}
            </div>
          </div>
        )}
        <div className="img" data-aos="zoom-in" data-aos-delay="300">
          <img src={dot} className="logo13" alt="dot" />
        </div>
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Licensingcontent;
