import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Vector1 from "../Assets/Vector1.webp";
import dot from "../Assets/dot.webp";
import img2 from "../Assets/big-user.webp";
import logo from "../Assets/logo.webp";
import logo1 from "../Assets/logo1.webp";
import { GetUniversitiespage, MetaUserSpecificView } from "../Repository/Api";
import { Helmet } from "react-helmet";
import { cloudFrontUrl } from "../commonUtils/Utils";
import Header from "./Header";
import Footer from "./Footer";
const Foruniversitiescontent = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const { testimonialdata } = useSelector((state) => state?.testimonialdata);
  const { partnerdata } = useSelector((state) => state?.partnerdata);
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const testimonial = testimonialdata;
  const contact = contactCardData;
  const [cms, setCms] = useState({});
  console.log(cms);
  const getTrainingcms = async () => {
    const response = await GetUniversitiespage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };
  const [meta, setMeta] = useState();
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("foruniversities");
    if (response && response.statusCode === 200) {
      setMeta(response?.data);
    }
  };
  useEffect(() => {
    getTrainingcms();
    GetMeta();
  }, []);

  const ban = cms?.bannerIcons?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  const challenge = cms?.learningSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const corporate = cms?.universitiesSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const [imageError, setImageError] = useState(false);
  const handleImage = () => {
    setImageError(true);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header />
        </div>
      )}
      <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
        <div className="corporation d-md-block d-none">
          <div className="container">
            <div className="row">
              <div
                className="back col-md-6 col-12"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p className="h1">{cms?.bannerHeading}</p>
                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                {cms?.bannerButtonShown && (
                  <NavLink to={cms?.bannerButtonRedirectLink}>
                    <button
                      className="btn btn-outline-success me-5"
                      type="submit"
                    >
                      {cms?.bannerButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center girl-img">
                <img
                  src={`${cloudFrontUrl}${cms?.bannerImage}`}
                  alt="homeimg"
                  className="Girl2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />

                <img
                  src={`${cloudFrontUrl}${ban && ban[0]?.image}`}
                  alt="homeimg"
                  className={`c-img1 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[1]?.image}`}
                  alt="homeimg"
                  className={`c-img2 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[2]?.image}`}
                  alt="homeimg"
                  className={`c-img3 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-5 d-block d-md-none">
          <div className="row ">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <p className="h1">{cms?.bannerHeading}</p>
              <p className="para1 textarea">{cms?.bannerSubHeading}</p>
              {cms?.bannerButtonShown && (
                <NavLink to={cms?.bannerButtonRedirectLink}>
                  <button
                    className="btn btn-outline-success me-5"
                    type="submit"
                  >
                    {cms?.bannerButtonName}
                  </button>
                </NavLink>
              )}
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <img
                src={`${cloudFrontUrl}${cms?.bannerImage}`}
                alt="girl"
                className="w-100"
              />
            </div>
          </div>
        </div>

        {cms?.learningSectionShown && (
          <div className="lab1 mb-5 pb-4">
            <p
              className="subtitle mt-5 "
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {cms?.learningSectionHeading}
            </p>
            <p
              className="title mb-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {cms?.learningSectionSubHeading}
            </p>
            <div className="container box mb-5">
              <div className="row">
                <div
                  className="col4 col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img src={logo} className="logo8" alt="logo" />
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${challenge && challenge[0]?.image
                        }`}
                      className="img28"
                      alt="corporationlogo1"
                    />
                    <p className="title1 ">
                      {challenge && challenge[0]?.heading}
                    </p>
                    <p className="para35">
                      {challenge && challenge[0]?.subHeading}
                    </p>
                  </div>
                </div>
                <div
                  className="col col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${challenge && challenge[1]?.image
                        }`}
                      className="img28"
                      alt="corporationlogo2"
                    />
                    <p className="title1">
                      {challenge && challenge[1]?.heading}
                    </p>
                    <p className="para35">
                      {challenge && challenge[1]?.subHeading}
                    </p>
                  </div>
                </div>
                <div
                  className="col7 col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${challenge && challenge[2]?.image
                        }`}
                      className="img28"
                      alt="corporationlogo3"
                    />
                    <p className="title1">
                      {challenge && challenge[2]?.heading}
                    </p>
                    <p className="para35">
                      {challenge && challenge[2]?.subHeading}
                    </p>
                  </div>
                  <img src={logo1} className="logo1" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.graphImageShown && (
          <div
            className="image mt-5 pt-5 mb-5 pb-5"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <img
              src={`${cloudFrontUrl}${cms?.graphImage}`}
              className="img29"
              alt="image71"
            />
          </div>
        )}
        {cms?.stepsSectionShown && (
          <div
            className="image1 mt-5 mb-5 pb-5"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <p className="subtitle mt-5 ">{cms?.stepsSectionHeading}</p>
            <p className="title">{cms?.stepsSectionSubHeading}</p>
            <div className="image3">
              <img
                src={`${cloudFrontUrl}${cms?.stepsSectionImage}`}
                className="img29"
                alt="image72"
              />
            </div>
          </div>
        )}
        <div className="design">
          <div className="container">
            {cms?.accountabilitySectionShown && (
              <div className="row mt-5 align-items-center">
                <div
                  className="col-lg-6 col-md-7 order-md-2 d-flex flex-column justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <p className="h1 pb-3">{cms?.accountabilitySectionHeading}</p>
                  <p className="para27 pb-3 textarea">
                    {cms?.accountabilitySectionContent}
                  </p>
                  {cms?.accountabilitySectionButtonShown && (
                    <NavLink to={cms?.accountabilitySectionButtonRedirectLink}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.accountabilitySectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className="col-lg-6 col-md-5 order-md-1 d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${cms?.accountabilitySectionImage}`}
                    className="img21"
                    alt="Group15"
                  />
                </div>
              </div>
            )}
            {cms?.decisionsSectionShown && (
              <div className="row mt-5 align-items-center">
                <div
                  className="col-lg-6 col-md-7 d-flex flex-column justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <p className="h6">
                    {cms?.decisionsSectionHeading.split(" ")[0]}
                  </p>
                  <p className="h1 pb-3">
                    {cms?.decisionsSectionHeading.split(" ")[1]}
                  </p>
                  <p className="para27 pb-3 textarea">
                    {cms?.decisionsSectionContent}
                  </p>
                  {cms?.decisionsSectionButtonShown && (
                    <NavLink to={cms?.decisionsSectionButtonRedirectLink}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.decisionsSectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className="col-lg-6 col-md-5 d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${cms?.decisionsSectionImage}`}
                    className="img21"
                    alt="Group16"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {cms?.universitiesSectionShown && (
          <div className="benefit mb-5">
            <p
              className="subtitle mt-5 "
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.universitiesSectionHeading}{" "}
            </p>
            <p
              className="title mb-5 pb-5"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.universitiesSectionSubHeading}
            </p>
            <div className="container box mb-5">
              <div className="row">
                <div
                  className="col4 col-lg-3 col-md-3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img src={logo} className="logo8" alt="logo" />
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${corporate && corporate[0]?.image
                        }`}
                      className="img30"
                      alt="img60"
                    />
                    <p className="para36">
                      {corporate && corporate[0]?.content}
                    </p>
                  </div>
                </div>
                <div
                  className="col col-lg-3 col-md-3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${corporate && corporate[1]?.image
                        }`}
                      className="img30"
                      alt="img61"
                    />
                    <p className="para36">
                      {corporate && corporate[1]?.content}
                    </p>
                  </div>
                </div>
                <div
                  className="col7 col-lg-3 col-md-3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${corporate && corporate[2]?.image
                        }`}
                      className="img30"
                      alt="img62"
                    />
                    <p className="para36">
                      {corporate && corporate[2]?.content}
                    </p>
                  </div>
                </div>
                <div
                  className="col7 col-lg-3 col-md-3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${corporate && corporate[3]?.image
                        }`}
                      className="img30"
                      alt="img63"
                    />
                    <p className="para36">
                      {corporate && corporate[3]?.content}
                    </p>
                  </div>
                  <img src={logo1} className="logo1" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.partnersSectionShown && (
          <div className="template">
            <div className="container">
              <p className="h3 text-center">{cms?.partnersSectionHeading}</p>
              <p className="h1">{cms?.partnersSectionSubHeading}</p>
              <div className="d-flex justify-content-center">
                <p className="para37 textarea w-75 text-center">{cms?.partnersSectionContent}</p>
              </div>
            </div>
            <div className="color py-3 mt-5">
              {testimonial && testimonial.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="1"
                  dots={false}
                  smartSpeed={2000}
                  nav={false}
                  margin={20}
                  autoplayTimeout={5000}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "2",
                    },
                    670: {
                      items: "3",
                    },
                    992: {
                      items: "4",
                    },

                    1200: {
                      items: "5",
                    },
                  }}
                >
                  {partnerdata?.map((item, i) => {
                    return (
                      <div key={i}>
                        <img
                          src={`${cloudFrontUrl}${item?.logo}`}
                          className="logo24"
                          alt={item?.name}
                        />
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
              <div className="shadow-a1"></div>
              <div className="shadow-a2"></div>
            </div>
          </div>
        )}
        <div className="bot mt-5 d-flex justify-content-center">
          {cms?.partnersSectionButtonShown && (
            <NavLink to={cms?.partnersSectionButtonRedirectLink}>
              <button className="btn btn-outline-success me-5" type="submit">
                {cms?.partnersSectionButtonName}
              </button>
            </NavLink>
          )}
        </div>
        {cms?.testimonialSectionShown && (
          <div className="trainer ">
            <div className="container mt-5">
              <div className="row">
                <div className="col col-md-3">
                  <p className="h6">
                    {cms?.testimonialSectionHeading.split(" ")[0]}
                  </p>
                  <p className="h4">
                    {cms?.testimonialSectionHeading.split(" ")[1]}
                  </p>
                </div>
                <div className="col1  col-md-9">
                  <div className="py-3 main-owl">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      autoplay={true}
                      items="1"
                      dots={false}
                      smartSpeed={2000}
                      nav={true}
                      margin={10}
                      // center={true}
                      autoplayTimeout={5000}
                      responsive={{
                        360: {
                          items: "1",
                        },
                        414: {
                          items: "1",
                        },
                        670: {
                          items: "1",
                        },
                        992: {
                          items: "2",
                        },

                        1200: {
                          items: "2",
                        },
                      }}
                    >
                      {testimonial?.map((item, i) => {
                        return (
                          <div key={i} style={{ height: "300px" }}>
                            <img
                              src={`${cloudFrontUrl}${item?.profileImage}`}
                              className="logo10"
                              alt="img2"
                              onError={(e) => {
                                e.target.src = img2;
                                e.target.onError = null;
                              }}
                            />
                            <div className="card">
                              <img
                                src={Vector1}
                                className="logo11"
                                alt="Vector1"
                              />
                              <p className="card-title">{item.name}</p>
                              <p className="card-text">
                                {item.title},{item.role}
                              </p>
                              <p className="para3">{item.content}</p>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={`${cloudFrontUrl}${cms?.testimonialSectionImage}`}
              alt="background image"
              className="dummy-bg"
            />
            <div className="dummy-bg1"></div>
          </div>
        )}
        <div className="img">
          <img src={dot} className="logo30" alt="dot" />
        </div>
        {cms?.contactUsCardsShown && (
          <div className="contact nunito">
            <div className="container d-md-block d-none">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <p className="h1"> {contact?.heading}</p>
                  <p>{contact?.subHeading}</p>
                  {contact?.buttonShown && (
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className="btn-a1">
                        {contact?.buttonHeading}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div className="col-lg-6">
                  <img
                    src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.contactUsCardsShown && (
          <div className="container mt-4 d-block d-md-none">
            <div
              data-aos="zoom-in"
              data-aos-delay="300"
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 text-center">{contact?.heading}</p>
              <p className="text"> {contact?.subHeading}</p>
              <img
                src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                className="w-100"
              />
              {contact?.buttonShown && (
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className="btn-a1">{contact?.buttonHeading}</button>
                </NavLink>
              )}
            </div>
          </div>
        )}
        <div className="img">
          <img src={dot} className="logo13" alt="dot" />
        </div>
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Foruniversitiescontent;
