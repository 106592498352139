import { actionTypes } from "./action";

export const initState = {
  contactCardData: {},
};

function reducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.CONTACT_CARD_DATA:
      return {
        ...state,
        ...{ contactCardData: action.payload },
      };
    default:
      return state;
  }
}
export default reducer;
