import React from 'react';
import Main from './Main';
import '../src/Css/Responsive.css'
import '../src/Css/Style.css'
import AOS from "aos";
import "aos/dist/aos.css";
import "../src/Css/Admin.css";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from 'react';
import { ToastContainer } from "react-toastify";
import { GetUserMasterData, GetUserTestimonials, GetContactcarddata, GetPartners } from './Repository/Api';
import { useDispatch, useSelector } from 'react-redux';
import { masterdata } from './Store/masterData/action';
import { testimonialdata } from './Store/testimonial/action';
import { contactCarddata } from './Store/contactCard/action';
import { partnerdata } from './Store/partner/action';
import { cloudFrontUrl } from './commonUtils/Utils';
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    GetData()
  }, []);

  const GetData = async () => {
    const response = await GetUserMasterData()
    if (response && response.statusCode === 200) {
      dispatch(masterdata(response?.data))
    }
  }

  const GetTestimonial = async () => {
    const response = await GetUserTestimonials()
    if (response && response.statusCode === 200) {
      dispatch(testimonialdata(response?.data))
    }
  }

  const GetContactSection = async () => {
    const response = await GetContactcarddata()
    if (response && response.statusCode === 200) {
      dispatch(contactCarddata(response?.data?.pageStructure))
    }
  }

  const getPartnerData = async () => {
    const response = await GetPartners()

    if (response && response.statusCode === 200) {
      dispatch(partnerdata(response?.data))
    }
  }
  useEffect(() => {
    GetTestimonial()
    GetContactSection()
    getPartnerData()
  }, [])

  const state = useSelector(state => state.masterdata)
  console.log(state.masterdata.favicon)
  useEffect(() => {
    const favicon = state.masterdata.favicon
    const updateFavicon = () => {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${cloudFrontUrl + favicon}`;
      document.getElementsByTagName('head')[0].appendChild(link);
    };
    document.title = state?.masterdata?.title
    updateFavicon();
  }, []);

  return (
    <div>
      <Main />
      <ToastContainer />
    </div>
  );
}

export default App;
