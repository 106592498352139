import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../Adminheader";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation, URLValidation } from "../../../Store/validate";
import {
  ImageUpload,
  SocialSpecificView,
  UpdateSocial,
} from "../../../Repository/Api";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const SocialmediaSpecificview = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSpecificlist();
  }, []);

  const [adminList, setAdminList] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const setValues = (e, key) => {
    setAdminList({ ...adminList, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getSpecificlist = async () => {
    const response = await SocialSpecificView(id, token);
    if (response && response.statusCode === 200) {
      setAdminList(response.data);
    } else {
      errorMsg(response.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.mediaType = NonEmptyValidation(adminList?.mediaType);
    validate.link = URLValidation(adminList?.link);
    validate.icon = NonEmptyValidation(adminList?.icon);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: adminList._id,
        mediaType: adminList?.mediaType,
        link: adminList?.link,
        icon: adminList?.icon,
        status: adminList.status,
      };
      const response = await UpdateSocial(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setAdminList({});
        setTimeout(() => {
          setTimeout(navigate("/admin/Socialmedialist"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setValues(response.data.path, key);
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target.files[0].name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
    // const response = await ImageUpload(formData);
    // if (response && response.statusCode === 200) {
    //   handleChange(response.data._id, key);
    //   successMsg("Image Uploaded Successfully");
    // } else {
    //   errorMsg(response.message);
    // }
  };
  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      console.log([...dummy?.formData]);
      if (
        dummy?.image === "icon"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 45 &&
        // dummy?.width === 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response.data.path, "icon");
          successMsg("Image Uploaded Successfully");
          console.log(adminList)
        } else {
          errorMsg(response.message);
        }
      } else {
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      ImageUploadin();
    }
  }, [data]);
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Socialmedialist");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Social Media List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox ">
              <form onSubmit={(e) => handleUpdate(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Social Media Specific Details & Update
                  </p>
                  <div className="p-4">
                    <div className="row mt-5">
                      <div className="col-lg-6">
                        <label className="labeltext">Media Type *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Media Type"
                            value={adminList?.mediaType}
                            onChange={(e) => {
                              setValues(e.target.value, "mediaType");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.mediaType?.message
                            ? `Media Type ${validation?.mediaType?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Link *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Link"
                            value={adminList?.link}
                            onChange={(e) => {
                              setValues(e.target.value, "link");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.link?.message
                            ? `Link ${validation?.link?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Icon *</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="socialmediaedit"
                            accept="image/jpeg,image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "icon")}
                          />
                          <label for="socialmediaedit" className="alt-btn">
                            Upload Image
                          </label>

                          {!adminList.icon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${adminList.icon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 20KB, 45px X 45px.
                        </small>
                        <small className="validationerror">
                          {validation?.icon?.message
                            ? `Icon ${validation?.icon?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Status</label>
                        <div>
                          <select
                            className="fieldinput"
                            value={adminList?.status}
                            onChange={(e) => {
                              setValues(e.target.value, "status");
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="active">Active</option>
                            <option value="inactive">InActive</option>
                          </select>
                        </div>
                      </div>
                      <div className="row my-5">
                        <div className="col-lg-3 centertext ms-auto mx-auto">
                          <button className="buttonmodel">Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialmediaSpecificview;
