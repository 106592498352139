import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { EmailValidation, NonEmptyValidation, PasswordValidation, StringValidation } from "../../../Store/validate";
import { ImageUpload, GetMasterData, UpdateMasterData } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const MasterdataCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const [createMasterdata, setcreateMasterdata] = useState({});
  console.log(createMasterdata)
  const [validation, setValidation] = useState({});

  const setData1 = (e, key) => {
    setcreateMasterdata({ ...createMasterdata, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return { formData: formData, image: image, size: size, height: height, width: width }
  }
  useEffect(() => {
    GetData()
  }, [])

  const GetData = async () => {
    const response = await GetMasterData(token)
    console.log(response)
    if (response && response?.statusCode === 200) {
      setcreateMasterdata(response?.data)
    }
  }


  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };


  const ImageUploadin = async () => {
    console.log(data)
    if (data?.length > 0) {
      const dummy = data[0]
      if (dummy?.image === "headerLogo"
        // && dummy?.size <= 100
        // && dummy?.height === 57 && dummy?.width <= 171
      ) {
        console.log([...dummy?.formData])
        const response = await ImageUpload(dummy.formData);
        if (response && response.statusCode === 200) {
          setData1(response.data.path, "headerLogo");
          successMsg("Image Uploaded Successfully");
        } else {
          errorMsg(response.message);
        }
      }
      else if (dummy?.image === "favicon"
        //  && dummy?.size <= 5
        // && dummy?.height === 103 && dummy?.width <= 99
      ) {
        console.log([...dummy?.formData])
        const response = await ImageUpload(dummy.formData);
        if (response && response.statusCode === 200) {
          setData1(response.data.path, "favicon");
          successMsg("Image Uploaded Successfully");
        } else {
          errorMsg(response.message);
        }
      } else {
        errorMsg("The image should fit the recommended file size.")
      }
    }
  }

  useEffect(() => {
    if (data) {
      ImageUploadin()
    }
  }, [data])


  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    validate.headerLogo = NonEmptyValidation(createMasterdata?.headerLogo);
    validate.footerlogo = NonEmptyValidation(createMasterdata?.footerlogo);
    validate.favicon = NonEmptyValidation(createMasterdata?.favicon);
    validate.emailId = EmailValidation(createMasterdata?.emailId);
    validate.password = NonEmptyValidation(createMasterdata?.password);
    validate.title = NonEmptyValidation(createMasterdata?.title);

    // validate.batchRedirectLink = NonEmptyValidation(createMasterdata?.batchRedirectLink);
    // validate.subscriptionRedirectLink = NonEmptyValidation(createMasterdata?.subscriptionRedirectLink);

    setValidation(validate);
    console.log(validate, "validate")
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: createMasterdata?._id,
        headerLogo: createMasterdata?.headerLogo,
        footerlogo: createMasterdata?.footerlogo,
        favicon: createMasterdata?.favicon,
        emailId: createMasterdata?.emailId,
        password: createMasterdata?.password,
        title: createMasterdata?.title,

        // batchRedirectLink: createMasterdata?.batchRedirectLink,
        // subscriptionRedirectLink: createMasterdata?.subscriptionRedirectLink,
      };
      const response = await UpdateMasterData(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  Master Data
                </h6>
              </div>
            </div>
            <div className="contentbox mt-3">
              <div className="mt-1">
                {/* <h5>Create Admin</h5> */}
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="labeltext">Header Logo *</label>
                    <div>
                      <input
                        type="file"
                        className="fieldinputfilereport d-none"
                        id="masterdataimg1"
                        accept="image/jpeg, image/png,image/webp"
                        onChange={(e) => UploadDocument(e, "headerLogo")}
                      />
                      <label
                        for="masterdataimg1"
                        className="alt-btn">
                        Upload Image
                      </label>

                      {!createMasterdata.headerLogo ? (
                        ""
                      ) : (
                        <span
                          className="namelink"
                          onClick={() => {
                            window.open(`${cloudFrontUrl}${createMasterdata.headerLogo}`);
                          }}
                          target="_blank"
                        >
                          View
                        </span>
                      )}
                    </div>
                    <small className="max">
                      Image upload: Max 100KB, Max 171px X 57px
                    </small>
                    <small className="validationerror">
                      {validation?.headerLogo?.message
                        ? `Header Logo ${validation?.headerLogo?.message}`
                        : ""}
                    </small>
                  </div>
                  {/* <div className="col-lg-6">
                    <label className="labeltext">Footer Logo *</label>
                    <div>
                      <input
                        type="file"
                        className="fieldinputfilereport d-none"
                        id="myfile"
                        onChange={(e) => UploadDocument(e, "footerlogo")}
                      />
                      {!createMasterdata.footerlogo ? (
                        ""
                      ) : (
                        <span
                          className="namelink"
                          onClick={() => {
                            window.open(`${cloudFrontUrl}${createMasterdata.footerlogo}`);
                          }}
                          target="_blank"
                        >
                          View
                        </span>
                      )}
                    </div>
                    <small className="validationerror">
                      {validation?.footerlogo?.message
                        ? `Footer Logo ${validation?.footerlogo?.message}`
                        : ""}
                    </small>
                  </div> */}
                  <div className="col-lg-4">
                    <label className="labeltext">Favicon *</label>
                    <div>
                      <input
                        type="file"
                        className="fieldinputfilereport d-none"
                        id="masterdataimg2"
                        accept="image/jpeg, image/png,image/webp"
                        onChange={(e) => UploadDocument(e, "favicon")}
                      />
                      <label
                        for="masterdataimg2"
                        className="alt-btn">
                        Upload Image
                      </label>

                      {!createMasterdata.favicon ? (
                        ""
                      ) : (
                        <span
                          className="namelink"
                          onClick={() => {
                            window.open(`${cloudFrontUrl}${createMasterdata.favicon}`);
                          }}
                          target="_blank"
                        >
                          View
                        </span>
                      )}
                    </div>
                    <small className="max">
                      Image upload: Max 5KB, Max 99px X 103px
                    </small>
                    <small className="validationerror">
                      {validation?.favicon?.message
                        ? `Favicon ${validation?.favicon?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-3">
                    <label className="labeltext">Title</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Please Enter Title"
                        value={createMasterdata?.title}
                        onChange={(e) => {
                          setData1(e.target.value, "title");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.title?.message
                        ? `Title ${validation?.title?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="labeltext">Email-Id* (Messages will be sent to the user through this email address)</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Please Enter Email-Id"
                        value={createMasterdata?.emailId}
                        onChange={(e) => {
                          setData1(e.target.value, "emailId");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.emailId?.message
                        ? `Email-Id ${validation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="labeltext">PassCode*</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Please Enter 16 digit passcode"
                        value={createMasterdata?.password}
                        onChange={(e) => {
                          setData1(e.target.value, "password");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.password?.message
                        ? `password ${validation?.password?.message}`
                        : ""}
                    </small>
                  </div>
                  {/* <div className="col-lg-12">
                    <label className="labeltext">Subscription Redirect Link*</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Please Enter Subscription Redirect Link"
                        value={createMasterdata?.subscriptionRedirectLink}
                        onChange={(e) => {
                          setData1(e.target.value, "subscriptionRedirectLink");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.subscriptionRedirectLink?.message
                        ? `Subscription Redirect Link ${validation?.subscriptionRedirectLink?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="labeltext">Batch Redirect Link*</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinputemail"
                        placeholder="Please Enter Batch Redirect Link"
                        value={createMasterdata?.batchRedirectLink}
                        onChange={(e) => {
                          setData1(e.target.value, "batchRedirectLink");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.batchRedirectLink?.message
                        ? `Batch Redirect Link ${validation?.batchRedirectLink?.message}`
                        : ""}
                    </small>
                  </div> */}
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterdataCreate;
