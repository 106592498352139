import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Vector1 from "../Assets/Vector1.webp";
import dot from "../Assets/dot.webp";
import img2 from "../Assets/big-user.webp";
import logo from "../Assets/logo.webp";
import logo1 from "../Assets/logo1.webp";
import {
  GetForTrainingProviderspage,
  MetaUserSpecificView,
} from "../Repository/Api";
import { cloudFrontUrl } from "../commonUtils/Utils";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
const Fortrainingproviderscontent = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const { testimonialdata } = useSelector((state) => state?.testimonialdata);
  const { partnerdata } = useSelector((state) => state?.partnerdata);
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const testimonial = testimonialdata;
  const contact = contactCardData;
  const [cms, setCms] = useState({});
  const getTrainingcms = async () => {
    const response = await GetForTrainingProviderspage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };
  const [meta, setMeta] = useState();
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("fortrainingproviders");
    if (response && response.statusCode === 200) {
      setMeta(response?.data);
    }
  };

  useEffect(() => {
    getTrainingcms();
    GetMeta();
  }, []);

  const ban = cms?.bannerIcons?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  console.log(ban);
  const learn = cms?.learningSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const train = cms?.trainersSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const [imageError, setImageError] = useState();
  console.log(imageError);
  const handleImage = (e) => {
    console.log("called");
    setImageError(true);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
                    }}
                />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header />
        </div>
      )}
      <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
        <div className="training">
          <div className="container">
            <div className="row">
              <div
                className="back col-md-6 col-12"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <p className="h1">{cms?.bannerHeading}</p>
                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                {cms?.bannerButtonShown && (
                  <NavLink to={cms?.bannerButtonRedirectLink}>
                    <button
                      className="btn btn-outline-success me-5"
                      type="submit"
                    >
                      {cms?.bannerButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center girl-img">
                <img
                  src={`${cloudFrontUrl}${cms?.bannerImage}`}
                  alt="homeimg"
                  className="Girl2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[0]?.image}`}
                  alt="homeimg"
                  className={`k-img1 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[1]?.image}`}
                  alt="homeimg"
                  className={`k-img2 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[2]?.image}`}
                  alt="homeimg"
                  className={`k-img3 ${imageError && "d-none"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='container pt-5 d-block d-md-none'>
                <div className='row'>
                    <div className='col-12' data-aos="zoom-in" data-aos-delay="300">
                        <p className="h1">For Trainers</p>
                        <p className="para1">Becoming a highly-effective Trainer is hard work. RescaleLab’s System prepares you for success by ensuring you have the infrastructure and tools to pass on the knowledge you’ve gained from your years of experience. Our platform functions supercharges your training services, so that you can deliver high-touch experiential training courses with purpose and ensure no student is left behind

                        </p>
                        <NavLink to="/contact-us?id=in-fo">
                            <button className="btn btn-outline-success me-5" type="submit">
                                Contact us to learn more!
                            </button>
                        </NavLink>
                    </div>
                    <div className='col-12' data-aos="zoom-in" data-aos-delay="300">
                        <img src={Tr} alt="girl" className='w-100' />
                    </div>
                </div>
            </div> */}
        {cms?.learningSectionShown && (
          <div className="subject mb-5 pb-4">
            <p
              className="subtitle mt-5 "
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.learningSectionHeading}
            </p>
            <p
              className="title mb-5 pb-5"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.learningSectionSubHeading}
            </p>
            <div className="container box mb-5">
              <div className="row">
                <div
                  className="col4 col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img src={logo} className="logo8" alt="logo" />
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${learn && learn[0]?.image}`}
                      className="img32"
                      alt="trainingimg2"
                    />
                    <p className="title1 ">{learn && learn[0]?.heading}</p>
                    <p className="para38 textarea">
                      {learn && learn[0]?.subHeading}
                    </p>
                  </div>
                </div>
                <div
                  className="col col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${learn && learn[1]?.image}`}
                      className="img32"
                      alt="trainingimg3"
                    />
                    <p className="title1">{learn && learn[1]?.heading}</p>
                    <p className="para38 textarea">
                      {learn && learn[1]?.subHeading}
                    </p>
                  </div>
                </div>
                <div
                  className="col7 col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${learn && learn[2]?.image}`}
                      className="img32"
                      alt="trainingimg4"
                    />
                    <p className="title1">{learn && learn[2]?.heading}</p>
                    <p className="para38 textarea">
                      {learn && learn[2]?.subHeading}
                    </p>
                  </div>
                  <img src={logo1} className="logo1" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.graphImageShown && (
          <div
            className="image5 mt-5 pt-5 mb-5 pb-5"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <img
              src={`${cloudFrontUrl}${cms?.graphImage}`}
              className="img29"
              alt="image71"
            />
          </div>
        )}
        {cms?.stepsSectionShown && (
          <div className="image7 mt-5 mb-5 pb-5">
            <p className="subtitle mt-5 ">{cms?.stepsSectionHeading}</p>
            <p className="title">{cms?.stepsSectionSubHeading}</p>
            <div className="image9" data-aos="zoom-in" data-aos-delay="300">
              <img
                src={`${cloudFrontUrl}${cms?.stepsSectionImage}`}
                className="img29"
                alt="image72"
              />
            </div>
          </div>
        )}
        <div className="touch">
          <div className="container">
            {cms?.masterTrainerSectionShown && (
              <div className="row mt-5 align-items-center">
                <div
                  className="col-lg-6 col-md-7 order-md-2 d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p className="h6">{cms?.masterTrainerSectionHeading}</p>
                  <p className="para39 pb-3 textarea">
                    {cms?.masterTrainerSectionContent}
                  </p>
                  {cms?.masterTrainerSectionButtonShown && (
                    <NavLink to={cms?.masterTrainerSectionButtonRedirectLink}>
                      <button className="btn btn-outline-success" type="submit">
                        {cms?.masterTrainerSectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className="col-lg-6 col-md-5 order-md-1 d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${cms?.masterTrainerSectionImage}`}
                    className="img30"
                    alt="trainingimg5"
                  />
                </div>
              </div>
            )}
            {cms?.featuresSectionShown && (
              <div className="row mt-5 align-items-center">
                <div
                  className="col-lg-6 col-md-7 d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p className="h6">{cms?.featuresSectionHeading}</p>
                  <p className="para39 pb-3 textarea">
                    {cms?.featuresSectionContent}
                  </p>
                  {cms?.featuresSectionButtonShown && (
                    <NavLink to={cms?.featuresSectionButtonRedirectLink}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.featuresSectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className="col-lg-6 col-md-5 d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${cms?.featuresSectionImage}`}
                    className="img30"
                    alt="trainingimg6"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {cms?.trainersSectionShown && (
          <div className="benefit2 mb-5 pb-5">
            <p
              className="subtitle mt-5 "
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.trainersSectionHeading}
            </p>
            <p className="title mb-3" data-aos="zoom-in" data-aos-delay="300">
              {cms?.trainersSectionSubHeading}
            </p>
            <div className="container box mb-5">
              <div className="row">
                <div
                  className="col4 col-md-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img src={logo} className="logo8" alt="logo" />
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${train && train[0]?.image}`}
                      className="img32"
                      alt="trainingimg7"
                    />
                    <p className="para36">{train && train[0]?.content}</p>
                  </div>
                </div>
                <div
                  className="col col-md-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${train && train[1]?.image}`}
                      className="img32"
                      alt="trainingimg8"
                    />
                    <p className="para36">{train && train[1]?.content}</p>
                  </div>
                </div>
                <div
                  className="col7 col-md-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${train && train[2]?.image}`}
                      className="img32"
                      alt="trainingimg9"
                    />
                    <p className="para36">{train && train[2]?.content}</p>
                  </div>
                </div>
                <div
                  className="col7 col-md-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="card p-5">
                    <img
                      src={`${cloudFrontUrl}${train && train[3]?.image}`}
                      className="img32"
                      alt="trainingimg10"
                    />
                    <p className="para36">{train && train[3]?.content}</p>
                  </div>
                  <img src={logo1} className="logo1" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.trainersSectionSubHeading && (
          <div className="template">
            <div className="container" data-aos="zoom-in" data-aos-delay="300">
              <p className="h3 text-center">{cms?.partnersSectionHeading}</p>
              <p className="h1">{cms?.partnersSectionSubHeading}</p>
              <div className="d-flex justify-content-center">
                <p className="para37 textarea w-75 text-center">{cms?.partnersSectionContent}</p>
              </div>
            </div>
            <div className="color py-3 mt-5">
              {testimonial && testimonial.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="1"
                  dots={false}
                  smartSpeed={2000}
                  nav={true}
                  margin={20}
                  // center={true}
                  autoplayTimeout={5000}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "2",
                    },
                    670: {
                      items: "3",
                    },
                    992: {
                      items: "4",
                    },

                    1200: {
                      items: "5",
                    },
                  }}
                >
                  {partnerdata?.map((item, i) => {
                    return (
                      <div key={i}>
                        <img
                          src={`${cloudFrontUrl}${item?.logo}`}
                          className="logo24"
                          alt={item?.name}
                        />
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
              <div className="shadow-a1"></div>
              <div className="shadow-a2"></div>
            </div>
            <div className="bot mt-5">
              {cms?.partnersSectionButtonShown && (
                <NavLink to={cms?.partnersSectionButtonRedirectLink}>
                  <button
                    className="btn btn-outline-success me-5"
                    type="submit"
                  >
                    {cms?.partnersSectionButtonName}
                  </button>
                </NavLink>
              )}
            </div>
          </div>
        )}
        {cms?.testimonialSectionShown && (
          <div className="trainer ">
            <div className="container mt-5">
              <div className="row">
                <div className="col col-md-3">
                  <p className="h6">{cms?.testimonialSectionHeading}</p>
                </div>
                <div className="col1  col-md-9">
                  <div className="py-3 main-owl">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      autoplay={true}
                      items="1"
                      dots={false}
                      smartSpeed={2000}
                      nav={true}
                      margin={10}
                      // center={true}
                      autoplayTimeout={5000}
                      responsive={{
                        360: {
                          items: "1",
                        },
                        414: {
                          items: "1",
                        },
                        670: {
                          items: "1",
                        },
                        992: {
                          items: "2",
                        },

                        1200: {
                          items: "2",
                        },
                      }}
                    >
                      {testimonial?.map((item, i) => {
                        return (
                          <div key={i} style={{ height: "300px" }}>
                            <img
                              src={`${cloudFrontUrl}${item?.profileImage}`}
                              className="logo10"
                              alt="img2"
                              onError={(e) => {
                                e.target.src = img2;
                                e.target.onError = null;
                              }}
                            />
                            <div className="card">
                              <img
                                src={Vector1}
                                className="logo11"
                                alt="Vector1"
                              />
                              <p className="card-title">{item.name}</p>
                              <p className="card-text">
                                {item.title},{item.role}
                              </p>
                              <p className="para3">{item.content}</p>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={`${cloudFrontUrl}${cms?.testimonialSectionImage}`}
              alt="background image"
              className="dummy-bg"
            />
            <div className="dummy-bg1"></div>
          </div>
        )}
        <div className="img">
          <img src={dot} className="logo30" alt="dot" />
        </div>
        {cms?.contactUsCardsShown && (
          <div className="contact nunito">
            <div className="container d-md-block d-none">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <p className="h1"> {contact?.heading}</p>
                  <p>{contact?.subHeading}</p>
                  {contact?.buttonShown && (
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className="btn-a1">
                        {contact?.buttonHeading}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div className="col-lg-6">
                  <img
                    src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.contactUsCardsShown && (
          <div className="container mt-4 d-block d-md-none">
            <div
              data-aos="zoom-in"
              data-aos-delay="300"
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 text-center">{contact?.heading}</p>
              <p className="text"> {contact?.subHeading}</p>
              <img
                src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                className="w-100"
              />
              {contact?.buttonShown && (
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className="btn-a1">{contact?.buttonHeading}</button>
                </NavLink>
              )}
            </div>
          </div>
        )}
        <div className="img">
          <img src={dot} className="logo13" alt="dot" />
        </div>
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Fortrainingproviderscontent;
