import React, { useState, useEffect } from 'react'
import logo from "../Assets/logo.webp";
import Header from './Header';
import Footer from './Footer';
import logo1 from "../Assets/logo1.webp";
import logo2 from "../Assets/logo2.png";
import laptop from "../Assets/laptop.webp";
import circle from '../Assets/circle.webp';
import Vector1 from '../Assets/Vector1.webp';
import img2 from '../Assets/big-user.webp';
import dot from '../Assets/dot.webp';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import { useRef } from 'react';
import { GetUserTestimonials, GetContactcarddata, GetHomePage, GetPartners, MetaUserSpecificView } from '../Repository/Api';
import { cloudFrontUrl } from '../commonUtils/Utils';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';

const Content = () => {
    const [play, setPlay] = useState(false)
    const [testimonial, settestimonial] = useState([])
    const [contact, setContact] = useState({})
    const vidRef = useRef(null)
    const handleClick = () => {
        vidRef.current.play()
        setPlay(true)
    }
    const { title } = useSelector(state => state?.masterdata?.masterdata)
    console.log(title)

    const GetTestimonial = async () => {
        const response = await GetUserTestimonials()
        if (response && response.statusCode === 200) {
            settestimonial(response?.data)
        }
    }

    const GetContactSection = async () => {
        const response = await GetContactcarddata()
        if (response && response.statusCode === 200) {
            setContact(response?.data?.pageStructure)
        }
    }

    const [cms, setCms] = useState({})
    console.log(cms)
    const getTrainingcms = async () => {
        const response = await GetHomePage()
        if (response && response.statusCode === 200) {
            setCms(response?.data?.pageStructure)
        }
    }

    const [Partner, setPartner] = useState([])
    const getPartnerData = async () => {
        const response = await GetPartners()
        if (response && response.statusCode === 200) {
            setPartner(response?.data)
        }
    }
    const [meta, setMeta] = useState()
    const GetMeta = async () => {
        const response = await MetaUserSpecificView("home")
        if (response && response.statusCode === 200) {
            setMeta(response?.data)
        }
    }

    useEffect(() => {
        document.body.classList.add("loading-indicator");
        getTrainingcms()
        getPartnerData()
        GetMeta()
    }, [])

    useEffect(() => {
        if (Object.keys(cms).length && Partner.length && Object.keys(meta).length) {
            document.body.classList.remove("loading-indicator");
        }
    }, [cms, meta, Partner])
    const ban = cms?.bannerIcons?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    const problem = cms?.problemsSectionContent?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    const address = cms?.addressingSectionContent?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    const choose = cms?.chooseUsSectionContent?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    const partnerLogo = Partner?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    useEffect(() => {
        GetTestimonial()
        GetContactSection()
    }, [])
    console.log(window.location.href)
    const [imageError, setImageError] = useState(false)
    const handleImage = () => {
        setImageError(true)
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${meta?.title} - ${title}`}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={meta?.description} />
                <meta name="keyword" content={meta?.keyword} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
                    }}
                />
            </Helmet>
            {cms?.headerShown &&
                <div>
                    <Header />
                </div>
            }
            <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
                <div className="technology ">
                    <div className="container">
                        <div className="row">
                            <div className="back col-lg-5 col-md-6 col-12" data-aos="fade-up" data-aos-delay="300">
                                <p className="h1 textarea" style={{ "white-space": "pre-line" }}>{cms?.bannerHeading}</p>
                                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                                {cms?.bannerButtonShown &&
                                    <NavLink to={cms?.bannerButtonRedirectLink}>
                                        <button className="btn btn-outline-success me-5" type="submit">
                                            {cms?.bannerButtonName}
                                        </button>
                                    </NavLink>
                                }
                            </div>
                            {ban &&
                                <div className="col-lg-7  col-md-6 col-12 d-flex justify-content-center girl-img"
                                >
                                    <img src={`${cloudFrontUrl}${cms?.bannerImage}`} alt='homeimg' className='Girl' data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000" />
                                    <img src={`${cloudFrontUrl}${ban && ban[0]?.image}`} alt='homeimg' className={`g-img1 ${imageError && "d-none"}`} data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" />
                                    <img src={`${cloudFrontUrl}${ban && ban[1]?.image}`} alt='homeimg' className={`g-img2 ${imageError && "d-none"}`} data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000" />
                                    <img src={`${cloudFrontUrl}${ban && ban[2]?.image}`} alt='homeimg' className={`g-img3 ${imageError && "d-none"}`} data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000" />
                                    <img src={`${cloudFrontUrl}${ban && ban[3]?.image}`} alt='homeimg' className={`g-img4 ${imageError && "d-none"}`} data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {cms?.featuresSectionShown &&
                    <div className='template container-md-fluid'>
                        <div className='container' data-aos="fade-up" data-aos-delay="300" >
                            <p className='h1 text-center'>{cms?.featuresSectionHeading}</p>
                            <p className='para4 textarea'>{cms?.featuresSectionSubHeading}</p>
                        </div>
                        <div className="color py-3 shadow-a">
                            <OwlCarousel
                                className="owl-theme"
                                loop
                                autoplay={true}
                                items="1"
                                dots={false}
                                smartSpeed={2000}
                                center={true}
                                margin={20}
                                autoplayTimeout={5000}
                                responsive={{
                                    360:
                                    {
                                        items: "1",
                                    },
                                    414:
                                    {
                                        items: "2",
                                    },
                                    670:
                                    {
                                        items: "3",
                                    },
                                    992:
                                    {
                                        items: "4",
                                    },

                                    1200:
                                    {
                                        items: "5",
                                    },
                                }}
                            >
                                {partnerLogo?.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <img src={`${cloudFrontUrl}${item?.logo}`} className='logo24' alt={item?.name} />
                                        </div>
                                    )
                                })}
                            </OwlCarousel>
                            <div className='shadow-a1'></div>
                            <div className='shadow-a2'></div>
                        </div>
                    </div>
                }
                {cms?.problemsSectionShown &&
                    <div className="trade">
                        <div className="container trade-con" >
                            <p className="h3" data-aos="fade-up" data-aos-delay="300">{cms?.problemsSectionHeading}</p>
                            <p className="h1" data-aos="fade-up" data-aos-delay="300">{cms?.problemsSectionSubHeading}</p>
                            <div className="row justify-content-between g-md-2" >
                                <div className="card col-md-4 col-12" data-aos="fade-up" data-aos-delay="300" >
                                    <div>
                                        <img src={`${cloudFrontUrl}${problem && problem[0]?.image}`} className="card-img-top img-k2 rounded-top" alt="group1" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text textarea">{problem && problem[0]?.content}</p>
                                    </div>
                                </div>
                                <div className="card col-md-4 col-12" data-aos="fade-up" data-aos-delay="300">
                                    <div>
                                        <img src={`${cloudFrontUrl}${problem && problem[1]?.image}`} className="card-img-top rounded-top" alt="group2" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text textarea">{problem && problem[1]?.content}</p>
                                    </div>
                                </div>
                                <div className="card col-md-4 col-12" data-aos="fade-up" data-aos-delay="300">
                                    <div>
                                        <img src={`${cloudFrontUrl}${problem && problem[2]?.image}`} className="card-img-top rounded-top" alt="group3" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text textarea">{problem && problem[2]?.content}</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center' data-aos="fade-up" data-aos-delay="300">
                                    {cms?.problemsButtonShown &&
                                        <NavLink to={cms?.problemsButtonRedirectPath}>
                                            <button className="btn btn-outline-success" type="submit">
                                                {cms?.problemsButtonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                            </div>
                            <img src={logo} className="logo" alt='logo' />
                            <img src={logo1} className="logo1" alt='logo' />
                        </div>
                    </div>
                }
                {cms?.addressingSectionShown &&
                    <div className="two">
                        <div className="container">
                            <p className="h3" data-aos="fade-up" data-aos-delay="300">{cms?.addressingSectionHeading}</p>
                            <p className="h1" data-aos="fade-up" data-aos-delay="300">{cms?.addressingSectionSubHeading}</p>
                            <div className='row'>
                                <div className='col-lg-6  col-md-7 d-flex flex-column align-self-center'
                                    data-aos="fade-up" data-aos-delay="300" data-aos-duration="300">
                                    <p className="h4">{address && address[0]?.heading}</p>
                                    <p className="para2 textarea">{address && address[0]?.content}</p>
                                </div>
                                <div className='col-lg-6 col-md-5 d-flex justify-content-center'
                                    data-aos="fade-up" data-aos-delay="300" >
                                    <img src={`${cloudFrontUrl}${address && address[0]?.image}`} className="logo2" alt='group11' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6  col-md-7 order-1 order-md-2 d-flex flex-column align-self-center'
                                    data-aos="fade-up" data-aos-delay="300"
                                >
                                    <p className="h4">{address && address[1]?.heading}</p>
                                    <p className="para2 textarea">{address && address[1]?.content}</p>
                                </div>
                                <div className='col-lg-6 col-md-5 order-2 order-md-1 d-flex justify-content-center'
                                    data-aos="fade-up" data-aos-delay="300"
                                >
                                    <img src={`${cloudFrontUrl}${address && address[1]?.image}`} className="logo2" alt='group11' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-7 d-flex flex-column align-self-center'
                                    data-aos="fade-up" data-aos-delay="300"
                                >
                                    <p className="h5">{address && address[2]?.heading}</p>
                                    <p className="para2 textarea">{address && address[2]?.content}</p>
                                </div>
                                <div className='col-lg-6 col-md-5 d-flex justify-content-center align-items-center '
                                    data-aos="fade-up" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${address && address[2]?.image}`} className="logo2" alt='group11' />
                                </div>
                            </div>
                            {cms?.addressingButtonShown &&
                                <div className='d-flex justify-content-center'
                                    data-aos="fade-up" data-aos-delay="300"
                                >
                                    <NavLink to={cms?.addressingButtonRedirectPath}>
                                        <button className="btn btn-outline-success" type="submit">
                                            {cms?.addressingButtonName}
                                        </button>
                                    </NavLink>
                                </div>
                            }
                        </div>
                    </div>
                }
                {cms?.aboutUsSectionShown &&
                    <div className="about">
                        <div className='lap-head'>
                            <p className="h4">{cms?.aboutUsSectionHeading}</p>
                            <p className="h1">{cms?.aboutUsSectionSubHeading}</p>
                        </div>
                        <div className="lap-center" >
                            <img src={logo2} className="logo5" alt='logo2' />
                            <div className='video'>
                                <div className='video2'>
                                    <img src={laptop} className="logo6" alt='laptop' />
                                    {/* <video width="320" height="260" controls poster={Play} data-aos="zoom-in" data-aos-delay="300" ref={vidRef} controlsList="nodownload" oncontextmenu="return false;">
                                    <source src={cms?.aboutUsSectionVideoURL} type="video/mp4" />
                                </video> */}
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={cms?.aboutUsSectionVideoURL}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                                {/* <i className={`fa-regular fa-circle-play ${play === true ? "d-none" : ""}`} onClick={handleClick} data-aos="zoom-in" data-aos-delay="300"></i> */}
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                }
                {cms?.chooseUsSectionShown &&
                    <div className="choose">
                        <div className="container chs-con">
                            <p className="h4" data-aos="fade-up" data-aos-delay="300">{cms?.chooseUsSectionHeading}</p>
                            <p className="h1" data-aos="fade-up" data-aos-delay="300">{cms?.chooseUsSectionSubHeading}</p>
                            <div className='container box'>
                                <div className='row'>
                                    <div className='col-md-4' data-aos="fade-up" data-aos-delay="300" >
                                        <img className='logo7' src={`${cloudFrontUrl}${choose && choose[0]?.image}`} alt='logo6' />
                                        <p className='h3'>{choose && choose[0]?.heading}</p>
                                        <p className='para3 textarea'>{choose && choose[0]?.content}</p>
                                    </div>
                                    <div className='col-md-4'
                                        data-aos="fade-up" data-aos-delay="300"
                                    >
                                        <img className='logo7' src={`${cloudFrontUrl}${choose && choose[1]?.image}`} alt='logo6' />
                                        <p className='h3'>{choose && choose[1]?.heading}</p>
                                        <p className='para3 textarea'>{choose && choose[1]?.content}</p>
                                    </div>
                                    <div className='col-md-4'
                                        data-aos="fade-up" data-aos-delay="300"
                                    >
                                        <img className='logo7' src={`${cloudFrontUrl}${choose && choose[2]?.image}`} alt='logo6' />
                                        <p className='h3'>{choose && choose[2]?.heading}</p>
                                        <p className='para3 textarea'>{choose && choose[2]?.content}</p>
                                    </div>
                                </div>
                            </div>
                            <img src={logo} className='logo8' alt='logo' />
                            <img src={circle} className='logo9' alt='circle' />
                        </div>
                    </div>}
                {cms?.testimonialSectionShown &&
                    <div className='trainer'>
                        <div className='testimonial-content'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col col-md-3'>
                                        <p className='h6'>{cms?.testimonialSectionHeading}</p>
                                    </div>
                                    <div className='col1  col-md-9'>
                                        <div className="py-3 main-owl">
                                            {testimonial && testimonial.length > 0 && (
                                                <OwlCarousel
                                                    className="owl-theme"
                                                    loop
                                                    autoplay={false}
                                                    items="1"
                                                    dots={false}
                                                    nav={true}
                                                    smartSpeed={2000}
                                                    margin={10}
                                                    autoplayTimeout={5000}
                                                    responsive={{
                                                        360:
                                                        {
                                                            items: "1",
                                                        },
                                                        414:
                                                        {
                                                            items: "1",
                                                        },
                                                        670:
                                                        {
                                                            items: "1",
                                                        },
                                                        992:
                                                        {
                                                            items: "2",
                                                        },

                                                        1200:
                                                        {
                                                            items: "2",
                                                        },
                                                    }}
                                                >
                                                    {testimonial?.map((item, i) => {
                                                        return (
                                                            <div key={i} style={{ height: "300px" }}>
                                                                <div>
                                                                    <img
                                                                        src={`${cloudFrontUrl}${item?.profileImage}`} className='logo10'
                                                                        alt='img2'
                                                                        onError={(e) => {
                                                                            e.target.src = img2
                                                                            e.target.onError = null
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='card'>
                                                                    <img src={Vector1} className='logo11' alt='Vector1' />
                                                                    <p className='card-title'>{item.name}</p>
                                                                    <p className='card-text'>{item.title},{item.role}</p>
                                                                    <p className='para3'>{item.content}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </OwlCarousel>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={`${cloudFrontUrl}${cms?.testimonialSectionBackgroundImage}`} alt="background image" className='dummy-bg' />
                            <div className='dummy-bg1'></div>
                        </div>
                    </div>
                }
                <div className='img d-md-block d-none'>
                    <img src={dot} className='logo30' alt='dot' />
                </div>
                {cms?.contactUsCardsShown &&
                    <div className='contact nunito'>
                        <div className='container d-md-block d-none'>
                            <div className='row'>
                                <div className='col-lg-6 d-flex flex-column justify-content-center'>
                                    <p className='h1'> {contact?.heading}</p>
                                    <p>{contact?.subHeading}</p>
                                    {contact?.buttonShown &&
                                        <NavLink to={contact?.buttonRedirectPath}>
                                            <button className='btn-a1'>{contact?.buttonHeading}</button>
                                        </NavLink>
                                    }
                                </div>
                                <div className='col-lg-6'>
                                    <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {cms?.contactUsCardsShown &&
                    <div className='container mt-4 d-block d-md-none'>
                        <div data-aos="zoom-in" data-aos-delay="300" className='d-flex flex-column align-items-center'>
                            <p className='h3 text-center'>{contact?.heading}</p>
                            <p className='text'> {contact?.subHeading}</p>
                            <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
                            {contact?.buttonShown &&
                                <NavLink to={contact?.buttonRedirectPath}>
                                    <button className='btn-a1'>{contact?.buttonHeading}</button>
                                </NavLink>
                            }
                        </div>
                    </div>
                }
                <div className='img'>
                    <img src={dot} className='logo13' alt='dot' />
                </div>
            </div>
            {cms?.footerShown &&
                <div>
                    <Footer />
                </div>
            }
        </>

    )
}
export default Content