import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Contactpage from '../Components/Contactpage'
import Footer from '../Components/Footer'
import { GetContactusPage } from '../Repository/Api'
const Contact = () => {
    const [Content, setContactContent] = useState({})

    const getContactContent = async () => {
        const response = await GetContactusPage()
        if (response && response.statusCode === 200) {
            setContactContent(response?.data?.pageStructure)
        }
    }

    useEffect(() => {
        getContactContent()
    }, [])
    return (
        <div>
            {Content?.headerShown &&
                <Header navi={true} resource={true} />
            }
            <Contactpage />
            {Content?.footerShown &&
                <Footer />
            }
        </div>
    )
}

export default Contact