import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg } from "../../Toastify";
import { GetMetaList } from "../../../Repository/Api";

const MetatagList = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    getMetalist();
  }, []);

  const [metalist, setMetalist] = useState([]);
  console.log(metalist);
  const getMetalist = async () => {
    const response = await GetMetaList(token);
    if (response && response.statusCode === 200) {
      setMetalist(response?.data);
    } else {
      errorMsg(response.message);
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Meta Title",
    },
    { name: "pageName", label: "pageName" },

    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }} // Change color to green
                onClick={() =>
                  navigate(`/admin/MetatagSpecificview/${tableMeta.rowData[2]}`)
                }
              >
                edit_square
              </span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Metatag List</h6>
              </div>
            </div>
            <div className="mt-5">
              <MUIDataTable
                title={"Metatag List"}
                data={metalist}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetatagList;
