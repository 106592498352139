import React, { createElement, useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { EmailValidation, NonEmptyValidation } from "../../../Store/validate";
import {
  GetAdminFooterdata,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const FooterManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createFooter, setcreateFooter] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  useEffect(() => {
    GetData();
  }, []);

  const UploadDocument = async (e, key) => {
    const imagekey = key;

    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;

    const reader = new FileReader();
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];

      if (dummy?.image === "emailIcon") {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateFooter({
            ...createFooter,
            pageStructure: {
              ...createFooter.pageStructure,
              emailIcon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (dummy?.image === "footerlogo") {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateFooter({
            ...createFooter,
            pageStructure: {
              ...createFooter.pageStructure,
              footerlogo: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (dummy?.image === "addressIcon") {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateFooter({
            ...createFooter,
            pageStructure: {
              ...createFooter.pageStructure,
              addressIcon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);
  const GetData = async () => {
    const response = await GetAdminFooterdata(token);
    if (response && response?.statusCode === 200) {
      setcreateFooter(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.emailIcon = NonEmptyValidation(
      createFooter?.pageStructure?.emailIcon
    );
    validate.emailId = EmailValidation(createFooter?.pageStructure?.emailId);
    validate.addressIcon = NonEmptyValidation(
      createFooter?.pageStructure?.addressIcon
    );
    validate.address = NonEmptyValidation(createFooter?.pageStructure?.address);
    validate.registeredAddressHeading = NonEmptyValidation(
      createFooter?.pageStructure?.registeredAddressHeading
    );
    validate.registeredAddress = NonEmptyValidation(
      createFooter?.pageStructure?.registeredAddress
    );
    validate.copyRightsContent = NonEmptyValidation(
      createFooter?.pageStructure?.copyRightsContent
    );
    validate.TermsOfServiceTitle = NonEmptyValidation(
      createFooter?.pageStructure?.TermsOfServiceTitle
    );
    validate.privacyPolicyTitle = NonEmptyValidation(
      createFooter?.pageStructure?.privacyPolicyTitle
    );
    validate.privacyPolicyRedirectPath = NonEmptyValidation(
      createFooter?.pageStructure?.privacyPolicyRedirectPath
    );
    validate.TermsOfServiceRedirectPath = NonEmptyValidation(
      createFooter?.pageStructure?.TermsOfServiceRedirectPath
    );
    validate.menu2Title = NonEmptyValidation(
      createFooter?.pageStructure?.menu2Title
    );

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        pageName: createFooter?.pageName,
        pageStructure: {
          ...createFooter?.pageStructure,
          TermsOfServiceRedirectPath:
            createFooter?.pageStructure?.TermsOfServiceRedirectPath,
          TermsOfServiceShown: createFooter?.pageStructure?.TermsOfServiceShown,
          address: createFooter?.pageStructure?.address,
          addressIcon: createFooter?.pageStructure?.addressIcon,
          copyRightsContent: createFooter?.pageStructure?.copyRightsContent,
          emailIcon: createFooter?.pageStructure?.emailIcon,
          footerlogo: createFooter?.pageStructure?.footerlogo,
          emailId: createFooter?.pageStructure?.emailId,
          menu2Title: createFooter?.pageStructure?.menu2Title,
          privacyPolicyRedirectPath:
            createFooter?.pageStructure?.privacyPolicyRedirectPath,
          privacyPolicyShown: createFooter?.pageStructure?.privacyPolicyShown,
          privacyPolicyTitle: createFooter?.pageStructure?.privacyPolicyTitle,
          registeredAddress: createFooter?.pageStructure?.registeredAddress,
          registeredAddressHeading:
            createFooter?.pageStructure?.registeredAddressHeading,
        },
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Footer management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createFooter?.lastUpdateBy}{", "}
                  {moment(createFooter?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>

            <div className="contentbox">
              <div className="mt-1">{/* <h5>Create Admin</h5> */}</div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Footer Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="labeltext">Footer Logo</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="footerlogo1"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "footerlogo")}
                          />
                          <label
                            for='footerlogo1'
                            className="alt-btn">
                            Upload Image
                          </label>
                          {!createFooter?.pageStructure?.footerlogo ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createFooter?.pageStructure?.footerlogo}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 100KB, Max 171px X 57px.
                        </small>
                        <small className="validationerror">
                          {validation?.image?.message
                            ? `Thumbnail ${validation?.image?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Email Icon</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="footerimg1"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "emailIcon")}
                          />
                          <label
                            for='footerimg1'
                            className="alt-btn">
                            Upload Image
                          </label>
                          {!createFooter?.pageStructure?.emailIcon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createFooter?.pageStructure?.emailIcon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 10KB, Max 25px X 25px.
                        </small>
                        <small className="validationerror">
                          {validation?.image?.message
                            ? `Thumbnail ${validation?.image?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Mail Id</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Mail Id"
                            value={createFooter?.pageStructure?.emailId}
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  emailId: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.emailId?.message
                            ? `emailId ${validation?.emailId?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Address Icon</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="footerimg2"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "addressIcon")}
                          />
                          <label
                            for="footerimg2"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createFooter?.pageStructure?.addressIcon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createFooter?.pageStructure?.addressIcon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 10KB, Max 25px X 25px.
                        </small>
                        <small className="validationerror">
                          {validation?.image?.message
                            ? `Thumbnail ${validation?.image?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Address</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Address"
                            value={createFooter?.pageStructure?.address}
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  address: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.address?.message
                            ? `Address ${validation?.address?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Registered Address Heading
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter  Registered Address Heading"
                            value={
                              createFooter?.pageStructure
                                ?.registeredAddressHeading
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  registeredAddressHeading: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.registeredAddressHeading?.message
                            ? `Registered Address Heading ${validation?.registeredAddressHeading?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Registered Address</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Registered Address"
                            value={
                              createFooter?.pageStructure?.registeredAddress
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  registeredAddress: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.registeredAddress?.message
                            ? `Registered Address ${validation?.registeredAddress?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Menu 2 Title</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Logo Redirect Path"
                            value={createFooter?.pageStructure?.menu2Title}
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  menu2Title: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.menu2Title?.message
                            ? `Menu2Title ${validation?.menu2Title?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Copyrights Content </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Logo Redirect Path"
                            value={
                              createFooter?.pageStructure?.copyRightsContent
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  copyRightsContent: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.copyRightsContent?.message
                            ? `Copy Rights Content ${validation?.copyRightsContent?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Terms and Conditions & Privacy Policy
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between">
                          <label className="labeltext">
                            Show Terms and Conditions
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createFooter?.pageStructure?.TermsOfServiceShown
                              }
                              onChange={(e) => {
                                setcreateFooter({
                                  ...createFooter,
                                  pageStructure: {
                                    ...createFooter.pageStructure,
                                    TermsOfServiceShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">
                          Terms of service Title
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Terms Of ServiceTitle"
                            value={
                              createFooter?.pageStructure?.TermsOfServiceTitle
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  TermsOfServiceTitle: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.TermsOfServiceTitle?.message
                            ? `Terms Of ServiceTitle ${validation?.TermsOfServiceTitle?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">
                          Terms of service Redirect Path
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Terms of service Redirect Path"
                            value={
                              createFooter?.pageStructure
                                ?.TermsOfServiceRedirectPath
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  TermsOfServiceRedirectPath: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.TermsOfServiceRedirectPath?.message
                            ? `Terms Of Service Redirect Path ${validation?.TermsOfServiceRedirectPath?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between">
                          <label className="labeltext">
                            Show Privacy Policy
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createFooter?.pageStructure?.privacyPolicyShown
                              }
                              onChange={(e) => {
                                setcreateFooter({
                                  ...createFooter,
                                  pageStructure: {
                                    ...createFooter.pageStructure,
                                    privacyPolicyShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">
                          Privacy Policy Title
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Privacy Policy Title"
                            value={
                              createFooter?.pageStructure?.privacyPolicyTitle
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  privacyPolicyTitle: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.privacyPolicyTitle?.message
                            ? `Privacy Policy Title ${validation?.privacyPolicyTitle?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">
                          Privacy Policy Redirect Path
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Privacy Policy Redirect Path"
                            value={
                              createFooter?.pageStructure
                                ?.privacyPolicyRedirectPath
                            }
                            onChange={(e) => {
                              setcreateFooter({
                                ...createFooter,
                                pageStructure: {
                                  ...createFooter.pageStructure,
                                  privacyPolicyRedirectPath: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.privacyPolicyRedirectPath?.message
                            ? `privacy Policy Redirect Path ${validation?.privacyPolicyRedirectPath?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterManagement;
