import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import {
  BlogSpecificView,
  ImageUpload,
  UpdateBlog,
  GetCategoryList,
  CreateCategory,
} from "../../../Repository/Api";
import {
  NonEmptyValidation,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";
import moment from "moment";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import MUIDataTable from "mui-datatables";
import CreatableSelect from "react-select/creatable";
import { cloudFrontUrl } from "../../../commonUtils/Utils";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const BlogSpecificview = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSpecificlist();
    GetCategory();
  }, []);

  const handleCreateCategory = async (val) => {
    const valid = NonEmptyValidation(val);
    if (valid.status) {
      const Payload = {
        name: val,
      };
      const response = await CreateCategory(Payload, token);
      if (response && response.statusCode === 200) {
        GetCategory();
      }
    }
  };

  const [category, setCategory] = useState([]);

  const GetCategory = async () => {
    const response = await GetCategoryList(token);
    if (response && response?.statusCode === 200) {
      let list = response?.data
        .filter((t) => t.status === "active")
        .map((e) => {
          return {
            value: e.name,
            label: e.name,
            _id: e._id,
          };
        });
      setCategory(list);
    }
  };

  const [blogList, setBlogList] = useState({});

  const [comments, setComments] = useState([]);
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  const [createcms, setcreatecms] = useState({});

  const [active, setActive] = useState(0);
  const links = ["Blog Info", "Comments"];
  const showSelected = (index) => {
    setActive(index);
  };

  const setValues = (e, key) => {
    setBlogList({ ...blogList, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getSpecificlist = async () => {
    const response = await BlogSpecificView(id, token);
    if (response && response.statusCode === 200) {
      setcreatecms(response.data);
      let dummy = response.data;

      let cmsDescription = EditorState.createEmpty();
      if (dummy.content) {
        let blocksFromHtml = htmlToDraft(dummy.content);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.content = cmsDescription;
      setBlogList(dummy);
      setComments(dummy?.comments);
    } else {
      errorMsg(response.message);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.title = StringValidation(blogList?.title);
    validate.author = StringValidation(blogList?.author);
    validate.publishedAt = NonEmptyValidation(blogList?.publishedAt);
    validate.minutes = NumberValidation(blogList?.minutes);
    validate.image = NonEmptyValidation(blogList?.image);
    validate.content = NonEmptyValidation(blogList?.content);
    validate.displayOrder = NumberValidation(blogList?.displayOrder);
    validate.buttonName = NonEmptyValidation(blogList?.buttonName);
    validate.link = NonEmptyValidation(blogList?.link);
    setValidation(validate);

    let isNotEmpty =
      blogList.content && blogList.content.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(blogList.content.getCurrentContent())
      );

      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          _id: blogList._id,
          title: blogList.title,
          author: blogList.author,
          publishedAt: blogList.publishedAt,
          categoryId: blogList.categoryId,
          minutes: blogList.minutes,
          image: blogList.image,
          content: html,
          status: blogList.status,
          displayOrder: blogList?.displayOrder,
          buttonShown: blogList?.buttonShown === true ? true : false,
          buttonName: blogList?.buttonName,
          link: blogList?.link,
        };
        const response = await UpdateBlog(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
          setTimeout(() => {
            setTimeout(navigate("/admin/Bloglist"));
          }, 1000);
        } else {
          errorMsg(response.message);
        }
      }
    }
  };
  const handleStatus = async (payload) => {
    const response = await UpdateBlog(payload, token);
    if (response && response.statusCode === 200) {
      getSpecificlist();
      successMsg("Status Updated Successfully");
    }
  };
  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setValues(response.data._id, key);
  //     successMsg(response.message);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };

  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.size <= 500
        // && dummy?.width === 325 && dummy?.height === 174
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response.data.path, "image");
          successMsg(response.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div>
      //         <div
      //           className="namelink"
      //           onClick={() =>
      //             navigate(`/BlogSpecificcommentview/${tableMeta.rowData[0]}`)
      //           }
      //         >
      //           {value}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    },

    { name: "emailId", label: "Email Id" },
    {
      name: "createdAt",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="textformat">
              {moment(value).format("DD MMM yyyy")}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <div className="textformat">{value}</div>;
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4] === "active" ? true : false;
          return (
            <div className="d-flex">
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }}
                onClick={() =>
                  navigate(`/BlogSpecificcommentview/${tableMeta.rowData[0]}`)
                }
              >
                edit_square
              </span>
              {/* <span className="ms-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={status}
                    onChange={async (e) => {
                      const status = e.target.checked === true ? "active" : "inActive"
                      const payload = {
                        _id: tableMeta.rowData[0],
                        status: status
                      }
                      handleStatus(payload)
                    }}
                  /><span className="slider round"></span>
                </label>
              </span> */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2
                    className="text-center fw-bold text-capitalize"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <span className="arrowicon">← </span> Blog List
                  </h2>
                  <button
                    className="buttonmodel buttonmodel2"
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </div>
                <p>
                  Last Updated by {createcms?.lastUpdateBy}
                  {", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>

            <div className="contentbox">
              <div className="row">
                <div className="col-lg-6 mx-auto ms-auto">
                  <ul className="searchtopflex">
                    {links.map((link, index) => {
                      return (
                        <li
                          onClick={() => showSelected(index)}
                          key={link}
                          className={
                            active === index
                              ? "agenttextactive"
                              : "agenttextinactive"
                          }
                        >
                          {link}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {active === 0 && (
                <form onSubmit={(e) => handleUpdate(e)}>
                  <div className=" mt-4 admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Blog Info
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="labeltext">Title *</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Title"
                              defaultValue={blogList?.title}
                              onChange={(e) => {
                                setValues(e.target.value, "title");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.title?.message
                              ? `Title ${validation?.title?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Author *</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Author Name"
                              defaultValue={blogList?.author}
                              onChange={(e) => {
                                setValues(e.target.value, "author");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.author?.message
                              ? `Author Name ${validation?.author?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Published At *</label>
                          <div>
                            <input
                              type="date"
                              className="fieldinput"
                              value={moment(blogList?.publishedAt).format(
                                "yyyy-MM-DD"
                              )}
                              onChange={(e) => {
                                setValues(e.target.value, "publishedAt");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.publishedAt?.message
                              ? `Date ${validation?.publishedAt?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Minutes *</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Minutes"
                              defaultValue={blogList?.minutes}
                              onChange={(e) => {
                                setValues(e.target.value, "minutes");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.minutes?.message
                              ? `Minutes ${validation?.minutes?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Category *</label>
                          <div>
                            <CreatableSelect
                              options={category}
                              isClearable
                              className="createinput"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  height: "49px",
                                }),
                                menu: (baseStyles, state) => ({
                                  ...baseStyles,
                                  zIndex: 999,
                                }),
                              }}
                              placeholder="Choose Category Name"
                              value={category?.find(
                                (temp) => temp?._id === blogList?.categoryId
                              )}
                              onChange={(e) => {
                                setValues(e?._id, "categoryId");
                              }}
                              onCreateOption={handleCreateCategory}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.category?.message
                              ? `Minutes ${validation?.category?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Thumbnail *</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="blogeditimg"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "image")}
                            />
                            <label for="blogeditimg" className="alt-btn">
                              Upload Image
                            </label>

                            {!blogList.image ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${blogList?.image}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 500KB,325px X 174px.
                          </small>
                          <small className="validationerror">
                            {validation?.image?.message
                              ? `Thumbnail ${validation?.image?.message}`
                              : ""}
                          </small>
                        </div>
                        {/* <div className="col-lg-6">
                      <label className="labeltext">Status</label>
                      <div>
                        <select
                          className="fieldinput"
                          value={blogList?.status}
                          onChange={(e) => {
                            setValues(e.target.value, "status");
                          }}
                        >
                          <option value="">Select Type</option>
                          <option value="active">Active</option>
                          <option value="inActive">InActive</option>
                        </select>
                      </div>
                    </div> */}
                        <div className="col-lg-12">
                          <label className="labeltext">Description *</label>
                          <div className="fieldinput blog-editor">
                            <Editor
                              editorState={blogList?.content}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) => {
                                setValues(e, "content");
                              }}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                  previewImage: true,
                                  uploadCallback: (file) => {
                                    return new Promise((resolve, reject) => {
                                      const maxSizeInBytes = 500 * 1024; // 100KB
                                      if (file.size > maxSizeInBytes) {
                                        errorMsg(
                                          "File size exceeds the limit (500KB)"
                                        );
                                        reject("");
                                        return;
                                      }
                                      const allowedTypes = [
                                        "image/webp",
                                        "image/png",
                                      ];
                                      if (!allowedTypes.includes(file.type)) {
                                        errorMsg(
                                          "Only webp or png files are allowed."
                                        );
                                        reject("");
                                        return;
                                      }
                                      const reader = new FileReader();
                                      reader.onloadend = () => {
                                        resolve({
                                          data: {
                                            url: reader.result,
                                          },
                                        });
                                      };
                                      reader.onerror = (reason) =>
                                        reject(reason);
                                      reader.readAsDataURL(file);
                                    });
                                  },
                                  alt: { present: true, mandatory: true },
                                },
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.content?.message
                              ? `Description ${validation?.content?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Button Name *</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Button Name"
                              defaultValue={blogList?.buttonName}
                              onChange={(e) => {
                                setValues(e.target.value, "buttonName");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.buttonName?.message
                              ? `ButtonName ${validation?.buttonName?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Button Redirect Link *
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Redirect Link"
                              defaultValue={blogList?.link}
                              onChange={(e) => {
                                setValues(e.target.value, "link");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.link?.message
                              ? `link ${validation?.link?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          <div className="d-flex justify-content-between bx-box w-90">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={blogList?.buttonShown}
                                onChange={(e) => {
                                  setValues(e.target.checked, "buttonShown");
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Display Order *</label>
                          <div>
                            <input
                              type="number"
                              className="fieldinput"
                              placeholder="Enter Display Order"
                              defaultValue={blogList?.displayOrder}
                              onChange={(e) => {
                                setValues(e.target.value, "displayOrder");
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.displayOrder?.message
                              ? `DisplayOrder ${validation?.displayOrder?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="row my-5">
                          {/* <div className="col-lg-3 centertext ms-auto mx-auto">
                            <button className="buttonmodel">Update</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {active === 1 && (
                <>
                  <div className="mt-5">
                    <MUIDataTable
                      title={"Blog Comments List"}
                      data={comments}
                      columns={columns}
                      options={options}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSpecificview;
