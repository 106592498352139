import React, { createElement, useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  EmptyValidation,
  LengthValidation,
  NonEmptyValidation,
} from "../../../Store/validate";
import {
  GetAdminHeaderdata,
  Updateheader,
  ImageUpload,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const HeaderManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createHeader, setcreateHeader] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  console.log(validation);
  useEffect(() => {
    GetData();
  }, []);
  const [lengthvalidate, setLengthvalidate] = useState([]);
  console.log(lengthvalidate);
  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setcreateHeader({
  //       ...createHeader,
  //       pageStructure: {
  //         ...createHeader.pageStructure,
  //         logo: response.data.path
  //       }
  //     })
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (dummy?.size <= 50 && dummy?.width === 171 && dummy?.height === 57) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateHeader({
            ...createHeader,
            pageStructure: {
              ...createHeader.pageStructure,
              logo: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);
  const GetData = async () => {
    const response = await GetAdminHeaderdata(token);
    if (response && response?.statusCode === 200) {
      setcreateHeader(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.logo = NonEmptyValidation(createHeader?.pageStructure?.logo);
    validate.logoRedirectPath = NonEmptyValidation(
      createHeader?.pageStructure?.logoRedirectPath
    );
    if (Array.isArray(createHeader?.pageStructure?.menus)) {
      validate.menus = createHeader?.pageStructure?.menus.map((d, i) => ({
        title: NonEmptyValidation(d?.title),
        redirectPath:
          d?.subMenu?.length === 0
            ? NonEmptyValidation(d?.redirectPath)
            : EmptyValidation(d?.redirectPath),
        order: NonEmptyValidation(d?.order),
        subMenu:
          d?.subMenu?.length > 0
            ? d?.subMenu?.map((h, j) => ({
              title: NonEmptyValidation(h?.title),
              redirectPath: NonEmptyValidation(h?.redirectPath),
            }))
            : EmptyValidation(d?.subMenu),
      }));
    }
    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate.menus.length; i++) {
        const menu = validate.menus[i];
        if (
          !(menu.title.status && menu.redirectPath.status && menu.order.status)
        ) {
          return false;
        }
        if (Array.isArray(menu.subMenu)) {
          for (let j = 0; j < menu.subMenu.length; j++) {
            const subMenuItem = menu.subMenu[j];
            if (
              !(subMenuItem.title.status && subMenuItem.redirectPath.status)
            ) {
              return false;
            }
          }
        } else {
          if (!menu.subMenu.status) {
            return false;
          }
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate);

    if (isTrue) {
      const Payload = {
        pageName: createHeader?.pageName,
        pageStructure: {
          ...createHeader?.pageStructure,
          logo: createHeader?.pageStructure?.logo,
          logoRedirectPath: createHeader?.pageStructure?.logoRedirectPath,
          menus: createHeader?.pageStructure?.menus,
        },
      };
      const response = await Updateheader(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Navbar management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createHeader?.lastUpdateBy}{", "}
                  {moment(createHeader?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>
            <div className="contentbox">
              <div className="mt-1">{/* <h5>Create Admin</h5> */}</div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Logo Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      {/* <div className="col-lg-6">
                        <label className="labeltext">Header Logo</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="myfile"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "image")}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                logo: NonEmptyValidation(
                                  e?.target?.files[0]?.name
                                ),
                              });
                            }}
                          />
                          {!createHeader?.pageStructure?.logo ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createHeader?.pageStructure?.logo}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 50KB,171px X 57px.
                        </small>
                        <small className="validationerror">
                          {validation?.logo?.message
                            ? `Thumbnail ${validation?.logo?.message}`
                            : ""}
                        </small>
                      </div> */}
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Logo Redirect Path *
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Logo Redirect Path"
                            value={
                              createHeader?.pageStructure?.logoRedirectPath
                            }
                            onChange={(e) => {
                              setcreateHeader({
                                ...createHeader,
                                pageStructure: {
                                  ...createHeader.pageStructure,
                                  logoRedirectPath: e.target.value,
                                },
                              });
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                logoRedirectPath: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.logoRedirectPath?.message
                            ? `Title ${validation?.logoRedirectPath?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                {createHeader?.pageStructure?.menus?.map((item, i) => {
                  return (
                    <div key={i}>
                      <div className=" admin-box-container px-0">
                        <p className="h4 admin-box-heading text-center ">
                          {item?.title}
                        </p>
                        <div className="p-4">
                          <div className="row">
                            <h4>{item?.title}</h4>
                            <div className="row">
                              <div className="col-lg-6">
                                <label className="labeltext">Title
                                  <span className="char-hint">{" "}(Maximum 20 characters)</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className="fieldinput"
                                    placeholder="Enter title"
                                    defaultValue={item?.title}
                                    maxLength={20}
                                    onChange={(e) => {
                                      let dummyname =
                                        createHeader?.pageStructure?.menus?.map(
                                          (nav) => {
                                            if (nav.title === item.title) {
                                              return {
                                                ...nav,
                                                title: e.target.value,
                                              };
                                            }
                                            return nav;
                                          }
                                        );
                                      if (e.target.value.length === 20) {
                                        let g =
                                          createHeader?.pageStructure?.menus?.findIndex(
                                            (obj) => obj.title === item.title
                                          );
                                        const k = LengthValidation(
                                          e.target.value,
                                          20
                                        );
                                        console.log(k);
                                        let a = [];
                                        a[i] = k;
                                        console.log(a);
                                        setLengthvalidate(a);
                                        setTimeout(() => {
                                          setLengthvalidate();
                                        }, 3000);
                                      }
                                      console.log(lengthvalidate);
                                      setcreateHeader({
                                        ...createHeader,
                                        pageStructure: {
                                          ...createHeader.pageStructure,
                                          menus: dummyname,
                                        },
                                      });
                                    }}
                                    onBlur={(e) => {
                                      let g =
                                        createHeader?.pageStructure?.menus?.findIndex(
                                          (obj) => obj.title === item.title
                                        );
                                      const k = NonEmptyValidation(
                                        e.target.value
                                      );
                                      validation?.menus?.length > 0 &&
                                        (validation.menus[g].title = k);
                                      setValidation({
                                        ...validation,
                                      });
                                    }}
                                  />
                                </div>
                                <small className="validationerror">
                                  {validation?.menus?.length > 0 &&
                                    validation?.menus[i]?.title?.message
                                    ? `Title ${validation?.menus[i]?.title?.message}`
                                    : lengthvalidate?.length > 0 &&
                                    lengthvalidate[i]?.message}
                                </small>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="d-flex justify-content-between">
                                  <label className="labeltext">
                                    Show In Header
                                  </label>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item?.shownInHeader}
                                      //forcheckbox
                                      onChange={(e) => {
                                        let dummyname =
                                          createHeader?.pageStructure?.menus?.map(
                                            (nav) => {
                                              if (nav.title === item.title) {
                                                return {
                                                  ...nav,
                                                  shownInHeader:
                                                    e.target.checked,
                                                };
                                              }
                                              return nav;
                                            }
                                          );
                                        setcreateHeader({
                                          ...createHeader,
                                          pageStructure: {
                                            ...createHeader.pageStructure,
                                            menus: dummyname,
                                          },
                                        });
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label className="labeltext">
                                  Redirect Path
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className="fieldinput"
                                    placeholder="Enter redirectPath"
                                    defaultValue={item?.redirectPath}
                                    // disabled
                                    onChange={(e) => {
                                      let dummyname =
                                        createHeader?.pageStructure?.menus?.map(
                                          (nav) => {
                                            if (nav.title === item.title) {
                                              return {
                                                ...nav,
                                                redirectPath: e.target.value,
                                              };
                                            }
                                            return nav;
                                          }
                                        );
                                      setcreateHeader({
                                        ...createHeader,
                                        pageStructure: {
                                          ...createHeader.pageStructure,
                                          menus: dummyname,
                                        },
                                      });
                                    }}
                                    onBlur={(e) => {
                                      let g =
                                        createHeader?.pageStructure?.menus?.findIndex(
                                          (obj) => obj.title === item.title
                                        );
                                      const k = NonEmptyValidation(
                                        e.target.value
                                      );

                                      validation?.menus?.length > 0 &&
                                        (validation.menus[g].redirectPath = k);
                                      setValidation({
                                        ...validation,
                                      });
                                    }}
                                  />
                                </div>
                                <small className="validationerror">
                                  {validation?.menus?.length > 0 &&
                                    validation?.menus[i]?.redirectPath?.message
                                    ? `Redirect Path ${validation?.menus[i]?.redirectPath?.message}`
                                    : ""}
                                </small>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="d-flex justify-content-between">
                                  {item?.title === "Login" ? <label className="labeltext">

                                  </label> : <label className="labeltext">
                                    Show In Footer
                                  </label>}

                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item?.shownInFooter}
                                      onChange={(e) => {
                                        let dummyname =
                                          createHeader?.pageStructure?.menus?.map(
                                            (nav) => {
                                              if (nav.title === item.title) {
                                                return {
                                                  ...nav,
                                                  shownInFooter:
                                                    e.target.checked,
                                                };
                                              }
                                              return nav;
                                            }
                                          );
                                        setcreateHeader({
                                          ...createHeader,
                                          pageStructure: {
                                            ...createHeader.pageStructure,
                                            menus: dummyname,
                                          },
                                        });
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label className="labeltext">Order</label>
                                <div>
                                  <input
                                    type="text"
                                    className="fieldinput"
                                    placeholder="Enter order"
                                    defaultValue={item?.order}
                                    onChange={(e) => {
                                      let dummyname =
                                        createHeader?.pageStructure?.menus?.map(
                                          (nav) => {
                                            if (nav.title === item.title) {
                                              return {
                                                ...nav,
                                                order: e.target.value,
                                              };
                                            }
                                            return nav;
                                          }
                                        );
                                      setcreateHeader({
                                        ...createHeader,
                                        pageStructure: {
                                          ...createHeader.pageStructure,
                                          menus: dummyname,
                                        },
                                      });
                                    }}
                                    onBlur={(e) => {
                                      let g =
                                        createHeader?.pageStructure?.menus?.findIndex(
                                          (obj) => obj.title === item.title
                                        );
                                      const k = NonEmptyValidation(
                                        e.target.value
                                      );

                                      validation?.menus?.length > 0 &&
                                        (validation.menus[g].order = k);
                                      setValidation({
                                        ...validation,
                                      });
                                    }}
                                  />
                                </div>
                                <small className="validationerror">
                                  {validation?.menus?.length > 0 &&
                                    validation?.menus[i]?.order?.message
                                    ? `Order ${validation?.menus[i]?.order?.message}`
                                    : ""}
                                </small>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="d-flex justify-content-between">
                                  <label className="labeltext">
                                    Show Button Style
                                  </label>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item?.button}
                                      onChange={(e) => {
                                        let dummyname =
                                          createHeader?.pageStructure?.menus?.map(
                                            (nav) => {
                                              if (nav.title === item.title) {
                                                return {
                                                  ...nav,
                                                  button: e.target.checked,
                                                };
                                              }
                                              return nav;
                                            }
                                          );
                                        setcreateHeader({
                                          ...createHeader,
                                          pageStructure: {
                                            ...createHeader.pageStructure,
                                            menus: dummyname,
                                          },
                                        });
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {item?.subMenu?.length > 0 ? (
                              <h4 >Dropdown Menu</h4>
                            ) : (
                              ""
                            )}
                            {item?.subMenu?.length > 0
                              ? item?.subMenu?.map((submenu, j) => {
                                return (
                                  <div className="container px-5 py-3" key={j}>
                                    <div className="row justify-content-center">
                                      <div className="w-100">
                                        <h5 className="feature-card-heading-admin">{submenu?.title}</h5>
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <label className="labeltext">
                                              Title
                                              <span className="char-hint">{" "}(Maximum 28 characters)</span>

                                            </label>
                                            <div>
                                              <input
                                                type="text"
                                                className="fieldinput"
                                                placeholder="Enter title"
                                                defaultValue={submenu?.title}
                                                maxLength={28}
                                                onChange={(e) => {
                                                  const a =
                                                    createHeader
                                                      ?.pageStructure?.menus;
                                                  createHeader?.pageStructure?.menus.map(
                                                    (main) => {
                                                      if (
                                                        main.title ===
                                                        item.title
                                                      ) {
                                                        let dummysubname =
                                                          main?.subMenu?.map(
                                                            (nav) => {
                                                              if (
                                                                nav.title ===
                                                                submenu.title
                                                              ) {
                                                                return {
                                                                  ...nav,
                                                                  title:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              }
                                                              return nav;
                                                            }
                                                          );
                                                        const x = {
                                                          ...main,
                                                          subMenu:
                                                            dummysubname,
                                                        };
                                                        const index =
                                                          a.findIndex(
                                                            (o) =>
                                                              o.title ===
                                                              item.title
                                                          );
                                                        if (index !== -1) {
                                                          a[index] = x;
                                                        }
                                                        setcreateHeader({
                                                          ...createHeader,
                                                          pageStructure: {
                                                            ...createHeader.pageStructure,
                                                            menus: a,
                                                          },
                                                        });
                                                      }
                                                    }
                                                  );
                                                  if (
                                                    e.target.value.length ===
                                                    28
                                                  ) {
                                                    const k =
                                                      LengthValidation(
                                                        e.target.value,
                                                        28
                                                      );
                                                    console.log(k);
                                                    let menu = [];
                                                    let submenu = [];
                                                    submenu[j] = k;
                                                    menu[i] = submenu;
                                                    setLengthvalidate(menu);
                                                    console.log(
                                                      lengthvalidate
                                                    );
                                                    setTimeout(() => {
                                                      setLengthvalidate();
                                                    }, 3000);
                                                  }
                                                }}
                                                onBlur={(e) => {
                                                  let g =
                                                    createHeader?.pageStructure?.menus?.findIndex(
                                                      (obj) =>
                                                        obj.title ===
                                                        item.title
                                                    );
                                                  const k =
                                                    NonEmptyValidation(
                                                      e.target.value
                                                    );
                                                  validation?.menus?.length >
                                                    0 &&
                                                    (validation.menus[
                                                      g
                                                    ].subMenu[j].title = k);
                                                  setValidation({
                                                    ...validation,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <small className="validationerror">
                                              {validation?.menus?.length >
                                                0 &&
                                                validation?.menus[i]?.subMenu
                                                  ?.length > 0 &&
                                                validation?.menus[i]?.subMenu[j]
                                                  ?.title?.message
                                                ? `Title ${validation?.menus[i]?.subMenu[j]?.title?.message}`
                                                : lengthvalidate?.length >
                                                  0 &&
                                                  lengthvalidate[i]?.length >
                                                  0
                                                  ? lengthvalidate[i][j]
                                                    ?.message
                                                  : ""}
                                            </small>
                                          </div>
                                          <div className="col-lg-6 align-self-center">
                                            <div className="d-flex justify-content-between">
                                              <label className="labeltext">
                                                Show In Header
                                              </label>
                                              <label className="switch">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    submenu?.shownInHeader
                                                  }
                                                  onChange={(e) => {
                                                    const a =
                                                      createHeader
                                                        ?.pageStructure
                                                        ?.menus;
                                                    createHeader?.pageStructure?.menus.map(
                                                      (main) => {
                                                        if (
                                                          main.title ===
                                                          item.title
                                                        ) {
                                                          let dummysubname =
                                                            main?.subMenu?.map(
                                                              (nav) => {
                                                                if (
                                                                  nav.title ===
                                                                  submenu.title
                                                                ) {
                                                                  return {
                                                                    ...nav,
                                                                    shownInHeader:
                                                                      e.target
                                                                        .checked,
                                                                  };
                                                                }
                                                                return nav;
                                                              }
                                                            );
                                                          const x = {
                                                            ...main,
                                                            subMenu:
                                                              dummysubname,
                                                          };
                                                          const index =
                                                            a.findIndex(
                                                              (o) =>
                                                                o.title ===
                                                                item.title
                                                            );
                                                          if (index !== -1) {
                                                            a[index] = x;
                                                          }
                                                          setcreateHeader({
                                                            ...createHeader,
                                                            pageStructure: {
                                                              ...createHeader.pageStructure,
                                                              menus: a,
                                                            },
                                                          });
                                                        }
                                                      }
                                                    );
                                                  }}
                                                />
                                                <span className="slider round"></span>
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <label className="labeltext">
                                              Redirect Path
                                            </label>
                                            <div>
                                              <input
                                                type="text"
                                                className="fieldinput"
                                                placeholder="Enter redirectPath"
                                                disabled
                                                defaultValue={
                                                  submenu?.redirectPath
                                                }
                                                onChange={(e) => {
                                                  const a =
                                                    createHeader
                                                      ?.pageStructure?.menus;
                                                  createHeader?.pageStructure?.menus.map(
                                                    (main) => {
                                                      if (
                                                        main.title ===
                                                        item.title
                                                      ) {
                                                        let dummysubname =
                                                          main?.subMenu?.map(
                                                            (nav) => {
                                                              if (
                                                                nav.title ===
                                                                submenu.title
                                                              ) {
                                                                return {
                                                                  ...nav,
                                                                  redirectPath:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              }
                                                              return nav;
                                                            }
                                                          );
                                                        const x = {
                                                          ...main,
                                                          subMenu:
                                                            dummysubname,
                                                        };
                                                        const index =
                                                          a.findIndex(
                                                            (o) =>
                                                              o.title ===
                                                              item.title
                                                          );
                                                        if (index !== -1) {
                                                          a[index] = x;
                                                        }
                                                        setcreateHeader({
                                                          ...createHeader,
                                                          pageStructure: {
                                                            ...createHeader.pageStructure,
                                                            menus: a,
                                                          },
                                                        });
                                                      }
                                                    }
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  let g =
                                                    createHeader?.pageStructure?.menus?.findIndex(
                                                      (obj) =>
                                                        obj.title ===
                                                        item.title
                                                    );
                                                  const k =
                                                    NonEmptyValidation(
                                                      e.target.value
                                                    );

                                                  validation?.menus?.length >
                                                    0 &&
                                                    (validation.menus[
                                                      g
                                                    ].subMenu[
                                                      j
                                                    ].redirectPath = k);
                                                  setValidation({
                                                    ...validation,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <small className="validationerror">
                                              {validation?.menus?.length >
                                                0 &&
                                                validation?.menus[i]?.subMenu
                                                  ?.length > 0 &&
                                                validation?.menus[i]?.subMenu[j]
                                                  ?.redirectPath?.message
                                                ? `Redirect Path ${validation?.menus[i]?.subMenu[j]?.redirectPath?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                          <div className="col-lg-6 align-self-center">
                                            <div className="d-flex justify-content-between">
                                              <label className="labeltext">
                                                Show In Footer
                                              </label>
                                              <label className="switch">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    submenu?.shownInFooter
                                                  }
                                                  onChange={(e) => {
                                                    const a =
                                                      createHeader
                                                        ?.pageStructure
                                                        ?.menus;
                                                    createHeader?.pageStructure?.menus.map(
                                                      (main) => {
                                                        if (
                                                          main.title ===
                                                          item.title
                                                        ) {
                                                          let dummysubname =
                                                            main?.subMenu?.map(
                                                              (nav) => {
                                                                if (
                                                                  nav.title ===
                                                                  submenu.title
                                                                ) {
                                                                  return {
                                                                    ...nav,
                                                                    shownInFooter:
                                                                      e.target
                                                                        .checked,
                                                                  };
                                                                }
                                                                return nav;
                                                              }
                                                            );
                                                          const x = {
                                                            ...main,
                                                            subMenu:
                                                              dummysubname,
                                                          };
                                                          const index =
                                                            a.findIndex(
                                                              (o) =>
                                                                o.title ===
                                                                item.title
                                                            );
                                                          if (index !== -1) {
                                                            a[index] = x;
                                                          }
                                                          setcreateHeader({
                                                            ...createHeader,
                                                            pageStructure: {
                                                              ...createHeader.pageStructure,
                                                              menus: a,
                                                            },
                                                          });
                                                        }
                                                      }
                                                    );
                                                  }}
                                                />
                                                <span className="slider round"></span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderManagement;
