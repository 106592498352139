import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Blogpage from '../Components/Blogpage'
import Footer from '../Components/Footer'
import { GetBlogPage } from '../Repository/Api'
const Blog = () => {
    const [cms, setcms] = useState()

    const GetBlogcms = async () => {
        const response = await GetBlogPage()
        setcms(response?.data?.pageStructure
        )
    }
    useEffect(() => {
        GetBlogcms()
    }, [])
    return (
        <div>
            {cms?.headerShown &&
                <Header navi={true} resource={true} />
            }
            <Blogpage />
            {cms?.footerShown &&
                <Footer />
            }
        </div>
    )
}

export default Blog