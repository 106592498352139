import React, { useEffect, useState } from "react";
import AdminHeader from "./Adminheader";
import { useSelector } from "react-redux";
import { GetDashboard } from "../../Repository/Api";

const AdminDashboard = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  

  const [dashboardCount, setDashboardCount] = useState({});

  const GetDashboardList = async () => {
    const response = await GetDashboard(token);
    if (response && response?.statusCode === 200) {
      setDashboardCount(response?.data);
    }
  };
 


  console.log(dashboardCount, "dashboardCount");
  useEffect(() => {
    GetDashboardList();
  
  }, []);

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="contentdashboard">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="text-center fw-bold text-capitalize">
                  Dashboard
                </h2>

                <h2 className="text-left"></h2>
                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Admin Metrics{" "}
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Total Admin Users</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalAdminUsers ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Total Active Admin </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalActiveAdminUsers ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-grow-early">
                      <div className="dashboard-card">
                        <h4>Total In-Active Admin </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalInActiveAdminUsers ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Blog Metrics{" "}
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Total Blogs</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalBlogs ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-grow-early">
                      <div className="dashboard-card">
                        <h4>Total In-Active Blogs </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalInActiveBlogs ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Total Active Blogs </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalActiveBlogs ?? 0}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Blog Comments Metrics
                </h4>
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="tab-eg-55">
                    <div className="pt-2 card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="widget-content">
                            <div className="widget-content-outer">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-right">
                                  <div className="text-muted opacity-6">
                                    <div className="col-md-2 col-xl-2">
                                      <h4
                                        className="fw-bold"
                                        // style={{ color: "green" }}
                                      >
                                        Total {""}{" "}
                                        {dashboardCount?.totalBlogComments}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 col-xl-6">
                                  <div className="widget-progress-wrapper mt-1">
                                    <div className="progress-bar-sm progress-bar-animated-alt progress">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={
                                          dashboardCount?.totalBlogComments ?? 0
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width: `${
                                            dashboardCount?.totalBlogComments ??
                                            0
                                          }%`,
                                          background:
                                            "linear-gradient(101.6deg, #77c48c 4.72%, #3d7851 93.31%)",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Other col-md-6 elements */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Pending </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalAdminUsers}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Approved</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalApprovedBlogComments}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-grow-early">
                      <div className="dashboard-card">
                        <h4>Reject </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalRejectedBlogComments}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Contact Us Form Metrics{" "}
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Total</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalContactUs}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Read </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalContactUsRead}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-grow-early">
                      <div className="dashboard-card">
                        <h4>Un-Read</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.totalContactUsUnRead}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Top Visited Metrics
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>
                          Total Visit - {""}
                          <span className="fw-bold" style={{ color: "green" }}>
                            {dashboardCount?.topPage}
                          </span>
                        </h4>
                        <h1 className="fw-bold">{dashboardCount?.totalVist}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Page Visiting Count
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Home </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.homePageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Licensing  </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.licensingPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4> Platform </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.platformPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Training Services </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.trainingServicesPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Corporation </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.corporationPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Universities </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.universitiesPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Training Providers </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.trainingProvidersPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Contact Us </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.contactUsPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Blogs Page </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.blogsPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Pricing</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.pricingPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>About Us </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.aboutUsPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Privacy Policy </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.privacyPolicyPageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Terms Of Service </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.termsOfServicePageVisitingCount}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h4
                  className="fw-bold"
                  style={{ color: "green", marginBottom: 25 }}
                >
                  Page Response Time
                </h4>
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-midnight-bloom">
                      <div className="dashboard-card">
                        <h4>Home </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.homePageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Licensing </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.licensingResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4> Platform </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.platformResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Training Services </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.trainingServicesPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Corporation </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.corporationPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Universities </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.universitiesPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Training Providers </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.trainingProvidersPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Contact Us </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.contactUsPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Blogs Page </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.blogsPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Pricing</h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.pricingPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>About Us </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.aboutUsPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Privacy Policy </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.privacyPolicyPageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 col-xl-4">
                    <div className="card mb-3 widget-content bg-arielle-smile">
                      <div className="dashboard-card">
                        <h4>Terms Of Service </h4>
                        <h1 className="fw-bold">
                          {dashboardCount?.termsOfServicePageResponseTime}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
