import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Licensingcontent from '../Components/Submenus/Licensingcontent'

const Licensing = () => {
  return (
    <div>
        {/* <Header/> */}
        <Licensingcontent/>
        {/* <Footer/> */}
    </div>
  )
}

export default Licensing