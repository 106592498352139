import React, { useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import { NumberValidation, NonEmptyValidation } from "../../../Store/validate";
import { CreateMetatag } from "../../../Repository/Api";
import { useSelector } from "react-redux";
const MetatagCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createData, setcreateData] = useState({});
  const [validation, setValidation] = useState({});

  const setData = (e, key) => {
    setcreateData({ ...createData, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.title = NonEmptyValidation(createData?.title);
    validate.keyword = NonEmptyValidation(createData?.keyword);
    validate.pageName = NonEmptyValidation(createData?.pageName);
    validate.description = NonEmptyValidation(createData?.description);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        title: createData.title,
        keyword: createData.keyword,
        pageName: createData.pageName,
        description: createData.description,
      };
      const response = await CreateMetatag(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/MetatagList"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  <span
                    className="arrowicon"
                    onClick={() => {
                      navigate("/admin/MetatagList");
                    }}
                  >
                    ← &nbsp;
                  </span>
                  Metatag List
                </h6>
              </div>
            </div>
            <div className="contentbox mt-5">
              <div className="mt-1">
                <h5>Create Meta Tag</h5>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="labeltext">
                      Title *
                      <span className="char-hint">
                        {" "}
                        (Maximum 60 characters)
                      </span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        maxLength={60}
                        placeholder="Enter Title"
                        defaultValue={createData?.title}
                        onChange={(e) => {
                          setData(e.target.value, "title");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.title?.message
                        ? `Title ${validation?.title?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">
                      Keyword *
                      <span className="char-hint">
                        {" "}
                        (Maximum 160 characters)
                      </span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        maxLength={160}
                        placeholder="Enter Keyword"
                        defaultValue={createData?.keyword}
                        onChange={(e) => {
                          setData(e.target.value, "keyword");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.keyword?.message
                        ? `Keyword ${validation?.keyword?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">PageName *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Page name"
                        defaultValue={createData?.pageName}
                        onChange={(e) => {
                          setData(e.target.value, "pageName");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.pageName?.message
                        ? `PageName ${validation?.pageName?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">
                      Description *
                      <span className="char-hint">
                        {" "}
                        (Maximum 160 characters)
                      </span>
                    </label>
                    <div>
                      <textarea
                        type="text"
                        className="fieldinput"
                        maxLength={160}
                        placeholder="Enter description"
                        defaultValue={createData?.description}
                        onChange={(e) => {
                          setData(e.target.value, "description");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.description?.message
                        ? `Description ${validation?.description?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel" type="submit">
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetatagCreate;
