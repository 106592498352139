import React, { useEffect, useState } from "react";
import { GetFooterdata, GetUserSocialList, GetHeaderdata } from "../Repository/Api";
import { cloudFrontUrl } from "../commonUtils/Utils";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const [socialList, setSocialList] = useState([]);
  const [footer, setFooter] = useState({});
  const [header, setHeader] = useState([]);
  console.log(footer)

  const getSocialList = async () => {
    const response = await GetUserSocialList();
    if (response && response.statusCode === 200) {
      setSocialList(response?.data);
    } else {
      setSocialList([]);
    }
  };

  const getFooter = async () => {
    const response = await GetFooterdata();
    setFooter(response?.data);
  };

  const HeaderData = async () => {
    const response = await GetHeaderdata();
    setHeader(response?.data);
  };

  useEffect(() => {
    getSocialList();
    getFooter();
    HeaderData();
  }, []);
  const info2 = header?.pageStructure?.menus;
  const info = footer?.pageStructure;
  console.log(info)
  console.log(info2)
  return (
    <div>
      <div id="hub">
        <div className="container">
          <div className="row  ">
            <div className="col col-md-6 mb-3 col-12">
              <a
                href="/"
                className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none"
              ></a>
              <p className="h3 ">

                <img
                  src={`${cloudFrontUrl}${info?.footerlogo}`}
                  className="logo17 mt-5"
                  alt="footerlogo"
                />
              </p>
              <a className="text-white" href={`${cloudFrontUrl}${info?.emailIcon}`}>
                <div className="mail py-4">
                  <img
                    src={`${cloudFrontUrl}${info?.emailIcon}`}
                    className="logo16"
                    alt="email"
                  />
                  <p className="control">{info?.emailId}</p>
                </div>
              </a>
              <div className="navi">
                <img
                  src={`${cloudFrontUrl}${info?.addressIcon}`}
                  className="logo16 me-3"
                  alt="navigation"
                />
                <p className="navigation">
                  {info?.address}
                  <br />
                  <br />
                  {info?.registeredAddressHeading}: {info?.registeredAddress}
                </p>
                <br />
              </div>
            </div>
            <div className="col col-lg-6 mt-md-5 pt-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row justify-content-end">
                    {info2?.length > 0 &&
                      <div className="col col-lg-4 ">
                        {info2?.length && info2[3].shownInFooter && (
                          <>
                            <h5>{info2[3]?.title}</h5>
                            <ul className="nav flex-column mt-3">
                              {info2[3].subMenu[0].shownInFooter && (
                                <li className="nav-item mb-2">
                                  <a
                                    href={`${info2[3]?.subMenu[0].redirectPath}`}
                                    className="nav-link p-0 text-body-secondary"
                                  >
                                    {info2[3]?.subMenu[0].title}
                                  </a>
                                </li>
                              )}
                              {info2[3].subMenu[1].shownInFooter && (
                                <li className="nav-item mb-2">
                                  <a
                                    href={`${info2[3]?.subMenu[1].redirectPath}`}
                                    className="nav-link p-0 text-body-secondary"
                                  >
                                    {info2[3]?.subMenu[1].title}
                                  </a>
                                </li>
                              )}
                              {info2[3].subMenu[2].shownInFooter && (
                                <li className="nav-item mb-2">
                                  <a
                                    href={`${info2[3]?.subMenu[2].redirectPath}`}
                                    className="nav-link p-0 text-body-secondary"
                                  >
                                    {info2[3]?.subMenu[2].title}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </div>}

                    <div className="col col-lg-4">
                      <h5>{info?.menu2Title}</h5>
                      <ul className="nav flex-column mt-3">
                        {info2 && info2[0]?.shownInFooter && (
                          <li className="nav-item mb-2">
                            <a
                              href={`${info2[0].redirectPath}`}
                              className="nav-link p-0 text-body-secondary"
                            >
                              {info2[0].title}
                            </a>
                          </li>
                        )}
                        {info2 && info2[1]?.shownInFooter && (
                          <li className="nav-item mb-2">
                            <a
                              href={`${info2[1].redirectPath}`}
                              className="nav-link p-0 text-body-secondary"
                            >
                              {info2[1].title}
                            </a>
                          </li>
                        )}
                        {info2 && info2[2]?.shownInFooter && (
                          <li className="nav-item mb-2">
                            <a
                              href={`${info2[2].redirectPath}`}
                              className="nav-link p-0 text-body-secondary"
                            >
                              {info2[2].title}
                            </a>
                          </li>
                        )}
                        {info2 && info2[5]?.shownInFooter && (
                          <li className="nav-item mb-2">
                            <a
                              href={`${info2[5].redirectPath}`}
                              className="nav-link p-0 text-body-secondary"
                            >
                              {info2[5].title}
                            </a>
                          </li>
                        )}
                        {info2 && info2[7]?.shownInFooter && (
                          <li className="nav-item mb-2">
                            <a
                              href={`${info2[7].redirectPath}`}
                              className="nav-link p-0 text-body-secondary"
                            >
                              {info2[7].title}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="col col-lg-4">
                      <h5>Resources</h5>
                      {info2 && info2[4].shownInFooter && (
                        <ul className="nav flex-column mt-3">
                          {info2[4].subMenu[0].shownInFooter && (
                            <li className="nav-item mb-2">
                              <a
                                href={`${info2[4]?.subMenu[0].redirectPath}`}
                                className="nav-link p-0 text-body-secondary"
                              >
                                {info2[4]?.subMenu[0].title}
                              </a>
                            </li>
                          )}
                          {info2[4].subMenu[1].shownInFooter && (
                            <li className="nav-item mb-2">
                              <a
                                href={`${info2[4]?.subMenu[1].redirectPath}`}
                                className="nav-link p-0 text-body-secondary"
                              >
                                {info2[4]?.subMenu[1].title}
                              </a>
                            </li>
                          )}
                          {info2[4].subMenu[2].shownInFooter && (
                            <li className="nav-item mb-2">
                              <a
                                href={`${info2[4]?.subMenu[2].redirectPath}`}
                                className="nav-link p-0 text-body-secondary"
                              >
                                {info2[4]?.subMenu[2].title}
                              </a>
                            </li>
                          )}
                          {info2[4].subMenu[3].shownInFooter && (
                            <li className="nav-item mb-2">
                              <a
                                href={`${info2[4]?.subMenu[3].redirectPath}`}
                                className="nav-link p-0 text-body-secondary"
                              >
                                {info2[4]?.subMenu[3].title}
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="social">
                  {socialList.map((item, i) => {
                    return (
                      <div key={i}>
                        <a href={`${item.link}`} target="_blank">
                          <img
                            src={`${cloudFrontUrl}${item.icon}`}
                            alt="socialmedia"
                            className="social-icon-sml"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="line" />
        <div className="container down pb-3">
          <footer className="last me-3">{info?.copyRightsContent}</footer>
          {info?.privacyPolicyShown ? (
            <NavLink to={info?.privacyPolicyRedirectPath}>
              <footer className="middle me-3">
                {info?.privacyPolicyTitle}
              </footer>
            </NavLink>
          ) : (
            ""
          )}
          {info?.TermsOfServiceShown ? (
            <NavLink to={info?.TermsOfServiceRedirectPath}>
              <footer className="one">{info?.TermsOfServiceTitle}</footer>
            </NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
