import React from "react";
import errorimage from "../Assets/404.png";

const Notfound = () => {
  return (
    <>
      <section className="adminbackground">
        <div className="container">
          <div className="centertext">
            <img src={errorimage} alt="404" className="notfoundimg" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Notfound;
