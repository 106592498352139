import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation, NumberValidation, LengthValidation } from "../../../Store/validate";
import {
  GetAdminSpecificBlogpage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
const SpecificBlogPageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  const [err, setErr] = useState({});

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };
  const [validation, setValidation] = useState({});
  useEffect(() => {
    GetData();
  }, []);

  const [image, setImage] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    //checking width and height of image
    const test = e.target?.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        setDimensions({ width: img.width, height: img.height });
        setImage(test);
      };
    };
    reader.readAsDataURL(test);
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const response = await ImageUpload(formData);
    if (response && response.statusCode === 200) {
      if (imagekey === "bannerImage") {
        setcreatecms({
          ...createcms,
          pageStructure: {
            ...createcms.pageStructure,
            bannerImage: response.data.path,
          },
        });
      }
      if (imagekey === "testimonialSectionBackgroundImage") {
        setcreatecms({
          ...createcms,
          pageStructure: {
            ...createcms.pageStructure,
            testimonialSectionBackgroundImage: response.data.path,
          },
        });
      }
      if (imagekey === "supcardimg1") {
        setcreatecms({
          ...createcms,
          pageStructure: {
            ...createcms.pageStructure,
            supportingInstitutionsSectionCard1Icon: response.data.path,
          },
        });
      }
      if (imagekey === "supcardimg2") {
        setcreatecms({
          ...createcms,
          pageStructure: {
            ...createcms.pageStructure,
            supportingInstitutionsSectionCard2Icon: response.data.path,
          },
        });
      }
      successMsg("Image Uploaded Successfully");
    } else {
      errorMsg(response.message);
    }
  };

  const GetData = async () => {
    const response = await GetAdminSpecificBlogpage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    // <=============================================================================================>

    validate.nextTimeSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.nextTimeSectionHeading
    );
    validate.nextTimeSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.nextTimeSectionContent
    );
    validate.viewAllButtonHeading = NonEmptyValidation(
      createcms?.pageStructure?.viewAllButtonHeading
    );
    validate.veiewAllButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.veiewAllButtonRedirectPath
    );
    validate.replySectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.replySectionHeading
    );
    validate.replySectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.replySectionSubHeading
    );
    validate.replySectionSaveContent = NonEmptyValidation(
      createcms?.pageStructure?.replySectionSaveContent
    );
    validate.replySectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.replySectionButtonName
    );
    validate.relatedBlogsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.relatedBlogsSectionHeading
    );

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.heading.status &&
            menu.subHeading.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.challengesSectionContent);
    const isTrue3 = areAllStatusTrue3(validate.corporationsSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "challengesSectionContent",
      "corporationsSectionContent",
    ];
    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);
    const final = isTrue && isTrue2 && isTrue3 && isTrue5;
    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
          <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                  Specific Blog Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
               
              </div>
            </div>
         
            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                {/* nextTimeSectionHeading */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    {createcms?.pageStructure?.nextTimeSectionHeading}
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Heading
                        <span className="char-hint">{" "}(Maximum 40 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Heading"
                            maxLength={40}
                            value={
                              createcms?.pageStructure?.nextTimeSectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  nextTimeSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                40,
                                setErr,
                                "nextTimeSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                nextTimeSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.nextTimeSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.nextTimeSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.nextTimeSectionHeading?.message
                              ? `Heading ${validation?.nextTimeSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      {/* nextTimeSectionShown */}
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Section</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.nextTimeSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    nextTimeSectionShown: e.target.checked,
                                  },
                                });

                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      {/* nextTimeSectionContent */}
                      <div className="col-lg-6">
                        <label className="labeltext">Content 
                        <span className="char-hint">{" "}(Maximum 160 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Content"
                            maxLength={160}
                            value={
                              createcms?.pageStructure?.nextTimeSectionContent
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  nextTimeSectionContent: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                160,
                                setErr,
                                "nextTimeSectionContent"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                nextTimeSectionContent: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.nextTimeSectionContent?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.nextTimeSectionContent?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.nextTimeSectionContent?.message
                              ? `Content ${validation?.nextTimeSectionContent?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* viewAllButtonHeading */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    {createcms?.pageStructure?.viewAllButtonHeading} Button
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Button Name
                        <span className="char-hint">{" "}(Maximum 20 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            maxLength={20}
                            placeholder="Enter Heading"
                            value={
                              createcms?.pageStructure?.viewAllButtonHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  viewAllButtonHeading: e.target.value,
                                },
                              });

                              handleInputLengthValidation(
                                e.target.value,
                                20,
                                setErr,
                                "viewAllButtonHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                viewAllButtonHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.viewAllButtonHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.viewAllButtonHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.viewAllButtonHeading?.message
                              ? `Button Name ${validation?.viewAllButtonHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      {/* veiewAllButtonRedirectPath */}
                      {/* <div className="col-lg-6">
                        <label className="labeltext">
                          Button Redirect Bath
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Redirect Path"
                            value={
                              createcms?.pageStructure
                                ?.veiewAllButtonRedirectPath
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  veiewAllButtonRedirectPath: e.target.value,
                                },
                              });
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                veiewAllButtonRedirectPath: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.veiewAllButtonRedirectPath?.message
                            ? `Button Redirect Path ${validation?.veiewAllButtonRedirectPath?.message}`
                            : ""}
                        </small>
                      </div> */}
                      {/* viewAllButtonShown */}
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Button</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.viewAllButtonShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    viewAllButtonShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Comments Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      {/* replySectionHeading */}
                      <div className="col-lg-6">
                        <label className="labeltext">Heading 
                        <span className="char-hint">{" "}(Maximum 30 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Redirect Path"
                            maxLength={30}
                            value={
                              createcms?.pageStructure?.replySectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  replySectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                30,
                                setErr,
                                "replySectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                replySectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.replySectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.replySectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.replySectionHeading?.message
                              ? `Heading ${validation?.replySectionHeading?.message}`
                              : ""}
                          </small>)}
                      </div>
                      {/* replySectionSubHeading */}
                      <div className="col-lg-6">
                        <label className="labeltext">SubHeading
                        <span className="char-hint">{" "}(Maximum 100 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Redirect Path"
                            maxLength={100}
                            value={
                              createcms?.pageStructure?.replySectionSubHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  replySectionSubHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                100,
                                setErr,
                                "replySectionSubHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                replySectionSubHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.replySectionSubHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.replySectionSubHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.replySectionSubHeading?.message
                              ? `Subeading ${validation?.replySectionSubHeading?.message}`
                              : ""}
                          </small>)}
                      </div>
                      {/* replySectionSaveContent */}
                      <div className="col-lg-6">
                        <label className="labeltext">Content
                        <span className="char-hint">{" "}(Maximum 120 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Redirect Path"
                            maxLength={120}
                            value={
                              createcms?.pageStructure?.replySectionSaveContent
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  replySectionSaveContent: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                120,
                                setErr,
                                "replySectionSaveContent"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                replySectionSaveContent: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.replySectionSaveContent?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.replySectionSaveContent?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.replySectionSaveContent?.message
                              ? `Content ${validation?.replySectionSaveContent?.message}`
                              : ""}
                          </small>)}
                      </div>
                      {/* replySectionButtonName */}
                      <div className="col-lg-6">
                        <label className="labeltext">Button Name
                        <span className="char-hint">{" "}(Maximum 20 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Name"
                            maxLength={20}
                            value={
                              createcms?.pageStructure?.replySectionButtonName
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  replySectionButtonName: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                20,
                                setErr,
                                "replySectionButtonName"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                replySectionButtonName: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.replySectionButtonName?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.replySectionButtonName?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.replySectionButtonName?.message
                              ? `Heading ${validation?.replySectionButtonName?.message}`
                              : ""}
                          </small>)}
                      </div>
                      {/* replySectionShown */}
                      {/* <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Section</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.replySectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    replySectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* relatedBlogsSectionHeading */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Related Blogs Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Heading
                        <span className="char-hint">{" "}(Maximum 25 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Button Redirect Path"
                            maxLength={25}
                            value={
                              createcms?.pageStructure
                                ?.relatedBlogsSectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  relatedBlogsSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                25,
                                setErr,
                                "relatedBlogsSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                relatedBlogsSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.relatedBlogsSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.relatedBlogsSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.relatedBlogsSectionHeading?.message
                              ? `Heading ${validation?.relatedBlogsSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Section</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.relatedBlogSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    relatedBlogSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
            Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      {/* <div className="col-lg-4 align-self-center">
                        {" "}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div> */}
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificBlogPageManagement;
