import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdminPlatformpage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const PlatformpageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms);
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  console.log(err);
  useEffect(() => {
    GetData();
  }, []);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(errid);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };
  console.log(err);
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage"
        // &&
        // dummy?.size <= 500
        // &&
        // dummy?.height === 525 &&
        // dummy?.width === 526
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons3"
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons4"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons5"
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height <= 133 &&
        // dummy?.width <= 133
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[5];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[5] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "serviceIcons0"
        // &&
        // dummy?.size <= 15
        // &&
        // dummy?.height === 94 &&
        // dummy?.width === 104
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.rescalelabSolutions;
          const others = createcms?.pageStructure?.rescalelabSolutions[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabSolutions: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "serviceIcons1"
        // &&
        // dummy?.size <= 15
        // &&
        // dummy?.height === 94 &&
        // dummy?.width === 104
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.rescalelabSolutions;
          const others = createcms?.pageStructure?.rescalelabSolutions[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabSolutions: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "serviceIcons2"
        // &&
        // dummy?.size <= 15
        // &&
        // dummy?.height === 94 &&
        // dummy?.width === 104
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.rescalelabSolutions;
          const others = createcms?.pageStructure?.rescalelabSolutions[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabSolutions: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "serviceIcons3"
        // &&
        // dummy?.size <= 15
        // &&
        // dummy?.height === 94 &&
        // dummy?.width === 104
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.rescalelabSolutions;
          const others = createcms?.pageStructure?.rescalelabSolutions[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabSolutions: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcon0"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 72 &&
        // dummy?.width === 72
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures;
          const others =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabfeaturesSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcon1"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 72 &&
        // dummy?.width === 72
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures;
          const others =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabfeaturesSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcon2"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 72 &&
        // dummy?.width === 72
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures;
          const others =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabfeaturesSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcon3"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 72 &&
        // dummy?.width === 72
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures;
          const others =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabfeaturesSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcon4"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 72 &&
        // dummy?.width === 72
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures;
          const others =
            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              rescalelabfeaturesSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featuresimage0"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionContent;
          const others = createcms?.pageStructure?.featuresSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featuresimage1"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionContent;
          const others = createcms?.pageStructure?.featuresSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featuresimage2"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionContent;
          const others = createcms?.pageStructure?.featuresSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featuresimage3"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionContent;
          const others = createcms?.pageStructure?.featuresSectionContent[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialSectionBackgroundImage"
        // &&
        // dummy?.size <= 500
        // &&
        // dummy?.width <= 700 &&
        // dummy?.height <= 600
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialSectionBackgroundImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "decisionsSectionImage"
        // &&
        // dummy?.size <= 100
        // &&
        // dummy?.width <= 570 &&
        // dummy?.height <= 388
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              decisionsSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "platformSectionImage"
        //  &&
        // dummy?.size <= 100
        // &&
        // dummy?.width <= 570 &&
        // dummy?.height <= 455
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              platformSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminPlatformpage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    if (Array.isArray(createcms?.pageStructure?.rescalelabSolutions)) {
      validate.rescalelabSolutions =
        createcms?.pageStructure?.rescalelabSolutions.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
          content: NonEmptyValidation(d?.content),
          buttonName: NonEmptyValidation(d?.buttonName),
          buttonRedirectPath: NonEmptyValidation(d?.buttonRedirectPath),
        }));
    }
    validate.decisionsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionHeading
    );
    validate.decisionsSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionSubHeading
    );
    validate.decisionsContent = NonEmptyValidation(
      createcms?.pageStructure?.decisionsContent
    );
    validate.decisionsButtonName = NonEmptyValidation(
      createcms?.pageStructure?.decisionsButtonName
    );
    validate.decisionsButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.decisionsButtonRedirectPath
    );
    validate.rescalelabfeaturesSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.rescalelabfeaturesSectionHeading
    );
    validate.rescalelabfeaturesSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.rescalelabfeaturesSectionSubHeading
    );
    if (
      Array.isArray(createcms?.pageStructure?.rescalelabfeaturesSectionFeatures)
    ) {
      validate.rescalelabfeaturesSectionFeatures =
        createcms?.pageStructure?.rescalelabfeaturesSectionFeatures.map(
          (d, i) => ({
            image: NonEmptyValidation(d?.image),
            displayOrder: NumberValidation(d?.displayOrder),
            content: NonEmptyValidation(d?.content),
            heading: NonEmptyValidation(d?.heading),
          })
        );
    }
    validate.platformSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.platformSectionHeading
    );
    validate.platformSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.platformSectionSubHeading
    );
    validate.platformSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.platformSectionContent
    );
    validate.platformSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.platformSectionButtonName
    );
    validate.platformSectionButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.platformSectionButtonRedirectPath
    );
    validate.featuresSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionHeading
    );
    if (Array.isArray(createcms?.pageStructure?.featuresSectionContent)) {
      validate.featuresSectionContent =
        createcms?.pageStructure?.featuresSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
          content: NonEmptyValidation(d?.content),
          heading: NonEmptyValidation(d?.heading),
        }));
    }
    validate.testimonialSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionHeading
    );
    validate.testimonialSectionBackgroundImage = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionBackgroundImage
    );

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.content.status &&
            menu.buttonName.status &&
            menu.buttonRedirectPath.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.heading?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue4(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.heading?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.rescalelabSolutions);
    const isTrue3 = areAllStatusTrue3(
      validate.rescalelabfeaturesSectionFeatures
    );
    const isTrue4 = areAllStatusTrue4(validate.featuresSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "rescalelabSolutions",
      "rescalelabfeaturesSectionFeatures",
      "featuresSectionContent",
    ];

    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);
    const final = isTrue && isTrue2 && isTrue3 && isTrue4 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    } else {
      errorMsg("Fill out all the input fields.");
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Platform Page Management
                  </h2>
                  <button
                    className="buttonmodel buttonmodel2"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                  </button>
                </div>
                <p>
                  Last Updated by {createcms?.lastUpdateBy}
                  {", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>

            <div className="contentbox">
              <div className="mt-1"></div>
              <form>
                <div className="">
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">
                            Banner Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 40 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Heading"
                              value={createcms?.pageStructure?.bannerHeading}
                              maxLength={40}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  40,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 260 characters)
                            </span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Sub Heading"
                              value={createcms?.pageStructure?.bannerSubHeading}
                              maxLength={260}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  260,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="platformbannerimg"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label for="platformbannerimg" className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 200KB, 526px X 525px.
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div>
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="labeltext">{`Banner Icon ${
                                        i + 1
                                      }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`platformbannericon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`platformbannericon${i}`}
                                          className="alt-btn"
                                        >
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 118px X
                                        133px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                        validation?.bannerIcons[i]?.image
                                          ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.bannerIcons &&
                                              validation?.bannerIcons[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                        validation?.bannerIcons[i]?.displayOrder
                                          ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Services Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div>
                          {createcms?.pageStructure?.rescalelabSolutions &&
                            createcms?.pageStructure?.rescalelabSolutions.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Image ${
                                        i + 1
                                      }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`platformserviceicon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `serviceIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`platformserviceicon${i}`}
                                          className="alt-btn"
                                        >
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, 104px X 94px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.rescalelabSolutions
                                          ?.length > 0 &&
                                        validation?.rescalelabSolutions[i]
                                          ?.image?.message
                                          ? `Banner Icon ${validation?.rescalelabSolutions[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 25 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter content"
                                          maxLength={25}
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              25,
                                              "content",
                                              "rescalelabSolutions",
                                              "rescalelabSolutionserr"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabSolutions &&
                                              validation?.rescalelabSolutions[i]
                                                ?.content;
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.rescalelabSolutions
                                              ?.length > 0 &&
                                              (validation.rescalelabSolutions[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.rescalelabSolutionserr
                                        ?.length > 0 &&
                                      err?.errorstate?.rescalelabSolutionserr[i]
                                        ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.rescalelabSolutionserr[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.rescalelabSolutions
                                            ?.length > 0 &&
                                          validation?.rescalelabSolutions[i]
                                            ?.content?.message
                                            ? `content ${validation?.rescalelabSolutions[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-4">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Button Name
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 15 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter button Name"
                                          value={item?.buttonName}
                                          maxLength={15}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              15,
                                              "buttonName",
                                              "rescalelabSolutions",
                                              "rescalelabSolutions2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabSolutions &&
                                              validation?.rescalelabSolutions[i]
                                                ?.buttonName;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.rescalelabSolutions
                                              ?.length > 0 &&
                                              (validation.rescalelabSolutions[
                                                i
                                              ].buttonName = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.rescalelabSolutions2
                                        ?.length > 0 &&
                                      err?.errorstate?.rescalelabSolutions2[i]
                                        ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.rescalelabSolutions2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.rescalelabSolutions
                                            ?.length > 0 &&
                                          validation?.rescalelabSolutions[i]
                                            ?.buttonName?.message
                                            ? `Button Name ${validation?.rescalelabSolutions[i]?.buttonName?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-4">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Button Redirect Path
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter button Redirect Path"
                                          value={item?.buttonRedirectPath}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.rescalelabSolutions;
                                            const updatedObj = {
                                              ...item,
                                              buttonRedirectPath:
                                                e.target.value,
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                rescalelabSolutions:
                                                  bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabSolutions &&
                                              validation?.rescalelabSolutions[i]
                                                ?.buttonRedirectPath;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.rescalelabSolutions
                                              ?.length > 0 &&
                                              (validation.rescalelabSolutions[
                                                i
                                              ].buttonRedirectPath = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.rescalelabSolutions
                                          ?.length > 0 &&
                                        validation?.rescalelabSolutions[i]
                                          ?.buttonRedirectPath?.message
                                          ? `Button Redirect path ${validation?.rescalelabSolutions[i]?.buttonRedirectPath?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-4">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.rescalelabSolutions;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                rescalelabSolutions:
                                                  bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabSolutions &&
                                              validation?.rescalelabSolutions[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.rescalelabSolutions
                                              ?.length > 0 &&
                                              (validation.rescalelabSolutions[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.rescalelabSolutions
                                          ?.length > 0 &&
                                        validation?.rescalelabSolutions[i]
                                          ?.displayOrder?.message
                                          ? `Display Order ${validation?.rescalelabSolutions[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Service Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.rescalelabSolutionsSection
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      rescalelabSolutionsSection:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.decisionsSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="platformdecisionimg1"
                              onChange={(e) =>
                                UploadDocument(e, "decisionsSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="platformdecisionimg1"
                              className="alt-btn"
                            >
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.decisionsSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.decisionsSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB,Max 570px X 388px.
                          </small>
                          <small className="validationerror">
                            {validation?.decisionsSectionImage?.message
                              ? `Image ${validation?.decisionsSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">
                            Section Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 20 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={20}
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure
                                  ?.decisionsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "decisionsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsSectionHeading?.message
                                ? `Heading ${validation?.decisionsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={25}
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.decisionsSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "decisionsSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsSectionSubHeading?.message
                                ? `sub Heading ${validation?.decisionsSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">
                            Content
                            <span className="char-hint">
                              {" "}
                              (Maximum 450 characters)
                            </span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Content"
                              maxLength={450}
                              value={createcms?.pageStructure?.decisionsContent}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  450,
                                  setErr,
                                  "decisionsContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsContent?.message
                                ? `Content ${validation?.decisionsContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">
                            Button Name
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure?.decisionsButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "decisionsButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsButtonName?.message
                                ? `Button name ${validation?.decisionsButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.decisionsButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsButtonRedirectPath: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.decisionsButtonRedirectPath?.message
                              ? `Button Redirect Link ${validation?.decisionsButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.decisionsButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      decisionsButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.decisionsSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.decisionsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      decisionsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.rescalelabfeaturesSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">
                            Section Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 20 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Section Heading"
                              maxLength={20}
                              value={
                                createcms?.pageStructure
                                  ?.rescalelabfeaturesSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    rescalelabfeaturesSectionHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "rescalelabfeaturesSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  rescalelabfeaturesSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.rescalelabfeaturesSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.rescalelabfeaturesSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.rescalelabfeaturesSectionHeading
                                ?.message
                                ? `Heading ${validation?.rescalelabfeaturesSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Section sub Heading"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.rescalelabfeaturesSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    rescalelabfeaturesSectionSubHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "rescalelabfeaturesSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  rescalelabfeaturesSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.rescalelabfeaturesSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.rescalelabfeaturesSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.rescalelabfeaturesSectionSubHeading
                                ?.message
                                ? `sub Heading ${validation?.rescalelabfeaturesSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure
                            ?.rescalelabfeaturesSectionFeatures &&
                            createcms?.pageStructure?.rescalelabfeaturesSectionFeatures.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${
                                        i + 1
                                      }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`platformfeatureicon2${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `featureIcon${i}`)
                                          }
                                        />
                                        <label
                                          for={`platformfeatureicon2${i}`}
                                          className="alt-btn"
                                        >
                                          Upload Image
                                        </label>

                                        {console.log(item, "item?.icon")}
                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 10KB, 72px X 72px.
                                      </small>
                                      <small className="validationerror">
                                        {validation
                                          ?.rescalelabfeaturesSectionFeatures
                                          ?.length > 0 &&
                                        validation
                                          ?.rescalelabfeaturesSectionFeatures[i]
                                          ?.icon?.message
                                          ? `Icon ${validation?.rescalelabfeaturesSectionFeatures[i]?.icon?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 35 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={35}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              35,
                                              "heading",
                                              "rescalelabfeaturesSectionFeatures",
                                              "rescalelabfeaturesSectionFeatures1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabfeaturesSectionFeatures &&
                                              validation
                                                ?.rescalelabfeaturesSectionFeatures[
                                                i
                                              ]?.heading;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.rescalelabfeaturesSectionFeatures
                                              ?.length > 0 &&
                                              (validation.rescalelabfeaturesSectionFeatures[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate
                                        ?.rescalelabfeaturesSectionFeatures1
                                        ?.length > 0 &&
                                      err?.errorstate
                                        ?.rescalelabfeaturesSectionFeatures1[i]
                                        ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.rescalelabfeaturesSectionFeatures1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation
                                            ?.rescalelabfeaturesSectionFeatures
                                            ?.length > 0 &&
                                          validation
                                            ?.rescalelabfeaturesSectionFeatures[
                                            i
                                          ]?.heading?.message
                                            ? `Content ${validation?.rescalelabfeaturesSectionFeatures[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 120 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          value={item?.content}
                                          maxLength={120}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              120,
                                              "content",
                                              "rescalelabfeaturesSectionFeatures",
                                              "rescalelabfeaturesSectionFeatures2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabfeaturesSectionFeatures &&
                                              validation
                                                ?.rescalelabfeaturesSectionFeatures[
                                                i
                                              ]?.content;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.rescalelabfeaturesSectionFeatures
                                              ?.length > 0 &&
                                              (validation.rescalelabfeaturesSectionFeatures[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate
                                        ?.rescalelabfeaturesSectionFeatures2
                                        ?.length > 0 &&
                                      err?.errorstate
                                        ?.rescalelabfeaturesSectionFeatures2[i]
                                        ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.rescalelabfeaturesSectionFeatures2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation
                                            ?.rescalelabfeaturesSectionFeatures
                                            ?.length > 0 &&
                                          validation
                                            ?.rescalelabfeaturesSectionFeatures[
                                            i
                                          ]?.content?.message
                                            ? `Content ${validation?.rescalelabfeaturesSectionFeatures[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const rescalelabfeaturesSectionFeatures =
                                              createcms?.pageStructure
                                                ?.rescalelabfeaturesSectionFeatures;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            rescalelabfeaturesSectionFeatures[
                                              i
                                            ] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                rescalelabfeaturesSectionFeatures:
                                                  rescalelabfeaturesSectionFeatures,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.rescalelabfeaturesSectionFeatures &&
                                              validation
                                                ?.rescalelabfeaturesSectionFeatures[
                                                i
                                              ]?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.rescalelabfeaturesSectionFeatures
                                              ?.length > 0 &&
                                              (validation.rescalelabfeaturesSectionFeatures[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation
                                          ?.rescalelabfeaturesSectionFeatures
                                          ?.length > 0 &&
                                        validation
                                          ?.rescalelabfeaturesSectionFeatures[i]
                                          ?.displayOrder?.message
                                          ? `Display Order ${validation?.rescalelabfeaturesSectionFeatures[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.rescalelabfeaturesSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      rescalelabfeaturesSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.platformSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="platformsectionimg9"
                              onChange={(e) =>
                                UploadDocument(e, "platformSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="platformsectionimg9"
                              className="alt-btn"
                            >
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.platformSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.platformSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB,Max 570px X 455px.
                          </small>
                          <small className="validationerror">
                            {validation?.platformSectionImage?.message
                              ? `Image ${validation?.platformSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">
                            Section Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 20 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Section Heading"
                              maxLength={20}
                              value={
                                createcms?.pageStructure?.platformSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    platformSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "platformSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  platformSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.platformSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.platformSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.platformSectionHeading?.message
                                ? `Heading ${validation?.platformSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">
                              {" "}
                              (Maximum 20 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Section sub Heading"
                              maxLength={20}
                              value={
                                createcms?.pageStructure
                                  ?.platformSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    platformSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "platformSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  platformSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.platformSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.platformSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.platformSectionSubHeading?.message
                                ? `sub Heading ${validation?.platformSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">
                            Content
                            <span className="char-hint">
                              {" "}
                              (Maximum 300 characters)
                            </span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Content"
                              maxLength={300}
                              value={
                                createcms?.pageStructure?.platformSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    platformSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "platformSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  platformSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.platformSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.platformSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.platformSectionContent?.message
                                ? `Content ${validation?.platformSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">
                            Button Name
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.platformSectionButtonName
                              }
                              maxLength={25}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    platformSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "platformSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  platformSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.platformSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.platformSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.platformSectionButtonName?.message
                                ? `Button name ${validation?.platformSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.platformSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    platformSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  platformSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.platformSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.platformSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.platformSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      platformSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.platformSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.platformSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      platformSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.featuresSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure?.featuresSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "featuresSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionHeading?.message
                                ? `Heading ${validation?.featuresSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.featuresSectionContent &&
                            createcms?.pageStructure?.featuresSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Image ${
                                        i + 1
                                      }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`platformfeatureicon1a${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `featuresimage${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`platformfeatureicon1a${i}`}
                                          className="alt-btn"
                                        >
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 10KB, 76px X 76px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.featuresSectionContent
                                          ?.length > 0 &&
                                        validation?.featuresSectionContent[i]
                                          ?.image?.message
                                          ? `Image ${validation?.featuresSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 40 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={40}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              40,
                                              "heading",
                                              "featuresSectionContent",
                                              "featuresSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionContent &&
                                              validation
                                                ?.featuresSectionContent[i]
                                                ?.heading;
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionContent
                                              ?.length > 0 &&
                                              (validation.featuresSectionContent[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionContent1
                                        ?.length > 0 &&
                                      err?.errorstate?.featuresSectionContent1[
                                        i
                                      ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionContent
                                            ?.length > 0 &&
                                          validation?.featuresSectionContent[i]
                                            ?.heading?.message
                                            ? `Content ${validation?.featuresSectionContent[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">
                                          {" "}
                                          (Maximum 90 characters)
                                        </span>
                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          value={item?.content}
                                          maxLength={90}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              90,
                                              "content",
                                              "featuresSectionContent",
                                              "featuresSectionContent2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionContent &&
                                              validation
                                                ?.featuresSectionContent[i]
                                                ?.content;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionContent
                                              ?.length > 0 &&
                                              (validation.featuresSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionContent2
                                        ?.length > 0 &&
                                      err?.errorstate?.featuresSectionContent2[
                                        i
                                      ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionContent2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionContent
                                            ?.length > 0 &&
                                          validation?.featuresSectionContent[i]
                                            ?.content?.message
                                            ? `Content ${validation?.featuresSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const featuresSectionContent =
                                              createcms?.pageStructure
                                                ?.featuresSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            featuresSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                featuresSectionContent:
                                                  featuresSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionContent &&
                                              validation
                                                ?.featuresSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionContent
                                              ?.length > 0 &&
                                              (validation.featuresSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.featuresSectionContent
                                          ?.length > 0 &&
                                        validation?.featuresSectionContent[i]
                                          ?.displayOrder?.message
                                          ? `Display Order ${validation?.featuresSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.featuresSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Testimonial Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Image */}
                        <label className="labeltext">
                          Testimonial Background Image
                        </label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="platformtestimonialimg"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(
                                e,
                                "testimonialSectionBackgroundImage"
                              )
                            }
                          />
                          <label
                            for="platformtestimonialimg"
                            className="alt-btn"
                          >
                            Upload Image
                          </label>

                          {!createcms?.pageStructure
                            ?.testimonialSectionBackgroundImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createcms?.pageStructure?.testimonialSectionBackgroundImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 500KB, Max 700px X 600px.
                        </small>
                        <small className="validationerror">
                          {validation?.bannerImage?.message
                            ? `Image ${validation?.testimonialSectionBackgroundImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Heading */}
                        <label className="labeltext">
                          Banner Heading
                          <span className="char-hint">
                            {" "}
                            (Maximum 35 characters)
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Testimonial Heading"
                            value={
                              createcms?.pageStructure
                                ?.testimonialSectionHeading
                            }
                            maxLength={35}
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  testimonialSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                35,
                                setErr,
                                "testimonialSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                testimonialSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.testimonialSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.testimonialSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.testimonialSectionHeading?.message
                              ? `Testimonial Heading ${validation?.testimonialSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Testimonial Section
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.testimonialSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    testimonialSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  {/* <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformpageManagement;
