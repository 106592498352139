import React, { useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import {
  EmailValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";
import { CreateAdmin } from "../../../Repository/Api";
import { useSelector } from "react-redux";

const AdminCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createAdmin, setCreateAdmin] = useState({});
  console.log(createAdmin)
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState();

  const setData = (e, key) => {
    setCreateAdmin({ ...createAdmin, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const viewPassword = () => {
    setPassword(!password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.firstName = StringValidation(createAdmin?.firstName);
    validate.lastName = StringValidation(createAdmin?.lastName);
    validate.emailId = EmailValidation(createAdmin?.emailId);
    validate.phoneNumber = PhoneNumberValidation(createAdmin?.phoneNumber);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        firstName: createAdmin?.firstName,
        lastName: createAdmin?.lastName,
        emailId: createAdmin?.emailId,
        phoneNumber: createAdmin?.phoneNumber,
        password: createAdmin?.password,
        userType: "admin",
      };
      const response = await CreateAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/Adminlist"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Adminlist");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Admin List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox mt-3">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create Admin
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">First Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter First Name"
                            defaultValue={createAdmin?.firstName}
                            onChange={(e) => {
                              setData(e.target.value, "firstName");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.firstName?.message
                            ? `First Name ${validation?.firstName?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Last Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Last Name"
                            defaultValue={createAdmin?.lastName}
                            onChange={(e) => {
                              setData(e.target.value, "lastName");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.lastName?.message
                            ? `Last Name ${validation?.lastName?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Email ID *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinputemail"
                            placeholder="Enter Email ID"
                            defaultValue={createAdmin?.emailId}
                            onChange={(e) => {
                              setData(e.target.value, "emailId");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.emailId?.message
                            ? `Email ID ${validation?.emailId?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Phone Number *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Phone Number"
                            maxLength={10}
                            defaultValue={createAdmin?.phoneNumber}
                            onChange={(e) => {
                              setData(e.target.value, "phoneNumber");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.phoneNumber?.message
                            ? `Phone Number ${validation?.phoneNumber?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Password</label>
                        <div className="pass-input">
                          <input
                            type={password ? "text" : "password"}
                            className="fieldinput"
                            placeholder="Enter Password"
                            defaultValue={createAdmin?.password}
                            onChange={(e) => {
                              setData(e.target.value, "password");
                            }}
                          />
                          <div className="psw-iconinput">
                            {password ? (
                              <span
                                className="material-symbols-rounded cursor align-middle"
                                onClick={() => viewPassword()}
                              >
                                visibility
                              </span>
                            ) : (
                              <span
                                className="material-symbols-rounded cursor align-middle"
                                onClick={() => viewPassword()}
                              >
                                visibility_off
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <small className="validationWarn2">
                      (The password must contain at least 1 capital letter, 1 lowercase letter, 1 number, and 1 special character.)
                      </small>
                      <small className="validationWarn">
                        Note : Password will be auto generated
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Create</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreate;
