import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import {
  CreatePartners,
  ImageUpload,
  PartnerSpecificView,
  UpdatePartner,
} from "../../../Repository/Api";
import { NonEmptyValidation, NumberValidation } from "../../../Store/validate";
import moment from "moment";
import { useSelector } from "react-redux";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const PartnersEdit = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  const [partner, setpartner] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  const setValues = (e, key) => {
    setpartner({ ...partner, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getSpecificPartner = async () => {
    const response = await PartnerSpecificView(id, token);
    if (response && response.statusCode === 200) {
      setpartner(response?.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};

    validate.name = NonEmptyValidation(partner?.name);
    validate.logo = NonEmptyValidation(partner?.logo);
    validate.displayOrder = NumberValidation(partner?.displayOrder);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: partner?._id,
        name: partner?.name,
        logo: partner?.logo,
        displayOrder: partner?.displayOrder,
      };
      const response = await UpdatePartner(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/partners"));
        }, 1000);
      } else {
        errorMsg(response?.error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getSpecificPartner();
  }, []);

  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setValues(response.data._id, key);
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e.target.files[0].name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.size <= 50
        //  && dummy?.height <= 84 && dummy?.width <= 300
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response?.data._id, "logo");
          successMsg(response?.message);
        }
        console.log(partner);
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  <span
                    className="arrowicon"
                    onClick={() => {
                      navigate("/admin/partners");
                    }}
                  >
                    ← &nbsp;
                  </span>
                  Partners List
                </h6>
              </div>
            </div>
            <div className="contentbox mt-5">
              <div className="mt-1">
                <h5>Create Partner</h5>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="labeltext">Name *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Blog Title"
                        defaultValue={partner?.name}
                        onChange={(e) => {
                          setValues(e.target.value, "name");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.name?.message
                        ? `Name ${validation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Logo *</label>
                    <div>
                      <input
                        type="file"
                        className="fieldinputfilereport d-none"
                        id="partnerseditimg"
                        accept="image/jpeg, image/png,image/webp"
                        onChange={(e) => UploadDocument(e, "logo")}
                      />
                      <label for="partnerseditimg" className="alt-btn">
                        Upload Image
                      </label>

                      {!partner.logo ? (
                        ""
                      ) : (
                        <span
                          className="namelink"
                          onClick={() => {
                            window.open(`${cloudFrontUrl}${partner.logo}`);
                          }}
                          target="_blank"
                        >
                          View
                        </span>
                      )}
                    </div>
                    <small className="max">
                      Image upload: Max 50KB,Max 300px X 84px.
                    </small>
                    <small className="validationerror">
                      {validation?.logo?.message
                        ? `logo ${validation?.logo?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Display Order</label>
                    <div>
                      <input
                        type="number"
                        className="fieldinput"
                        placeholder="Enter Display Order"
                        defaultValue={partner?.displayOrder}
                        onChange={(e) => {
                          setValues(e.target.value, "displayOrder");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersEdit;
