import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import { GetBlogList, DeleteBlog, UpdateBlog } from "../../../Repository/Api";
import CustomModal from "../../../Containers/CustomModal";
import moment from "moment";

const Bloglist = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  const [blogList, setBlogList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [Id, setDelId] = useState();

  const getBloglist = async () => {
    const response = await GetBlogList(token);
    if (response && response.statusCode === 200) {
      setBlogList(response?.data);
    } else {
      errorMsg(response.message);
    }
  };
  const DeleteIcon = async () => {
    const payload = {
      _id: Id,
    };
    const response = await DeleteBlog(token, payload);
    if (response && response.statusCode === 200) {
      setOpenModal(false);
      successMsg(response?.message);
      getBloglist();
    }
  };

  const handleStatus = async (payload) => {
    const response = await UpdateBlog(payload, token);
    if (response && response.statusCode === 200) {
      getBloglist();
      successMsg("Status Updated Successfully");
    } else {
      errorMsg(response?.message);
    }
  };
  useEffect(() => {
    getBloglist();
  }, [token]);
  const updateOrder = async (id, order) => {
    const payload = {
      _id: id,
      displayOrder: order,
    };
    const response = await UpdateBlog(payload, token);

    if (response && response.statusCode === 200) {
      getBloglist();
      successMsg(response?.message);
    }
  };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div>
      //         <div
      //           className="namelink"
      //           onClick={() =>
      //             navigate(`/BlogSpecificview/${tableMeta.rowData[0]}`)
      //           }
      //         >
      //           {value}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    },
    { name: "author", label: "Author" },
    {
      name: "publishedAt",
      label: "Published Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="textformat">
              {moment(value).format("DD MMM yyyy")}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <div className="textformat">{value}</div>;
        },
      },
    },
    {
      name: "displayOrder",
      label: "Display Order",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="textformat">
              <input
                className="order-input"
                type="number"
                defaultValue={value}
                onBlur={(e) =>
                  updateOrder(tableMeta.rowData[0], e.target.value)
                }
              />
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4] === "active" ? true : false;
          return (
            <div className="d-flex">
              <span
                className="material-symbols-outlined me-3"
                style={{ color: "red" }} // Change color to red
                onClick={() => {
                  setOpenModal(true);
                  setDelId(tableMeta.rowData[0]);
                }}
              >
                delete
              </span>
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }} // Change color to green
                onClick={() =>
                  navigate(`/admin/BlogSpecificview/${tableMeta.rowData[0]}`)
                }
              >
                edit_square
              </span>
              <span className="ms-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={status}
                    onChange={async (e) => {
                      const status =
                        e.target.checked === true ? "active" : "inactive";
                      const payload = {
                        _id: tableMeta.rowData[0],
                        status: status,
                      };
                      handleStatus(payload);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Blog List</h6>
              </div>
              <div className="col-lg-6">
                <div className="headerfloat">
                  <button
                    className="buttonmodel"
                    onClick={() => {
                      navigate("/admin/BlogCreate");
                    }}
                  >
                    Add Blog
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <MUIDataTable
                title={"Blog List"}
                data={blogList}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
        }}
      >
        <div className="delete-modal nunito">
          <span className="icon-container" onClick={() => setOpenModal(false)}>
            <i className="fa-solid fa-circle-xmark"></i>
          </span>
          <p className="h1 mb-4">Confirmation</p>
          <p className="h3">Are you sure you want to delete this?</p>
          <div className="mt-4 d-flex justify-content-around">
            <button
              className="btn-cancel h-auto me-3"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary h-auto" onClick={DeleteIcon}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Bloglist;
