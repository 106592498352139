import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { errorMsg, successMsg } from "../../Toastify";
import {
  CreateBlog,
  ImageUpload,
  CreateCategory,
  GetCategoryList,
  CreateCoupon,
} from "../../../Repository/Api";
import {
  NonEmptyValidation,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";
import moment from "moment";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
const CouponCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createBlog, setCreateBlog] = useState({});
  const [validation, setValidation] = useState({});

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const CustomButton = ({ label, onClick }) => (
    <div className="custom-button" onClick={onClick}>
      {label}
    </div>
  );
  const handleCustomButtonClick = () => {
    // Handle button click logic here
  };

  const setValues = (e, key) => {
    setCreateBlog({ ...createBlog, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleEditorChange = (state, key) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setValues(html, "description");
    }
  };

  const [startDate, setStartDate] = useState(createBlog?.startDate);
  const [endDate, setEndDate] = useState(createBlog?.endDate);

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    // Update the minimum date for the end date input field
    setEndDate(selectedStartDate);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};

    // validate.title = StringValidation(createBlog?.title);
    validate.code = NonEmptyValidation(createBlog?.code);
    // validate.startDate = NonEmptyValidation(startDate);
    // validate.endDate = NonEmptyValidation(endDate);

    validate.percentage = NumberValidation(createBlog?.percentage);

    // validate.description = NonEmptyValidation(createBlog?.description);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        // title: createBlog?.title,
        code: createBlog?.code,
        // startDate: startDate,
        // endDate: endDate,

        percentage: Number(createBlog?.percentage),

        // description: createBlog?.description,
      };
      const response = await CreateCoupon(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/CouponList"));
        }, 1000);
      } else {
        errorMsg(response?.error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/CouponList");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Coupon List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox ">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create Coupon
                  </p>
                  <div className="p-4">
                    <div className="row">
                      {/* <div className="col-lg-6">
                        <label className="labeltext">Title *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Coupon Title"
                            defaultValue={createBlog?.title}
                            onChange={(e) => {
                              setValues(e.target.value, "title");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.title?.message
                            ? `Title ${validation?.title?.message}`
                            : ""}
                        </small>
                      </div> */}
                      <div className="col-lg-6">
                        <label className="labeltext">Code *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Code Name"
                            defaultValue={createBlog?.code}
                            onChange={(e) => {
                              setValues(e.target.value, "code");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.code?.message
                            ? `Code ${validation?.code?.message}`
                            : ""}
                        </small>
                      </div>
                      <>
                        {/* <div className="col-lg-6">
                          <label className="labeltext">Start Date *</label>
                          <div>
                            <input
                              type="date"
                              className="fieldinput"
                              value={startDate}
                              min={new Date().toISOString().split("T")[0]}
                              onChange={handleStartDateChange}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.startDate?.message
                              ? `Date ${validation?.startDate?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">End Date *</label>
                          <div>
                            <input
                              type="date"
                              className="fieldinput"
                              value={endDate}
                              min={startDate} // Set minimum date based on selected start date
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.endDate?.message
                              ? `Date ${validation?.endDate?.message}`
                              : ""}
                          </small>
                        </div> */}
                      </>
                      <div className="col-lg-6">
                        <label className="labeltext">Percentage *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Percentage"
                            defaultValue={createBlog?.percentage}
                            onChange={(e) => {
                              setValues(e.target.value, "percentage");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.percentage?.message
                            ? `Percentage ${validation?.percentage?.message}`
                            : ""}
                        </small>
                      </div>

                      {/* <div className="col-lg-12">
                        <label className="labeltext">Description *</label>
                        <div className="fieldinput">
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) =>
                              handleEditorChange(e, "description")
                            }
                            toolbar={{
                              custom: (
                                <CustomButton
                                  label="Custom"
                                  onClick={handleCustomButtonClick}
                                />
                              ),
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                              image: {
                                previewImage: true,
                                uploadCallback: (file) => {
                                  return new Promise((resolve, reject) => {
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      resolve({
                                        data: {
                                          url: reader.result,
                                        },
                                      });
                                    };

                                    reader.onerror = (reason) => reject(reason);
                                    reader.readAsDataURL(file);
                                  });
                                },
                                alt: { present: true, mandatory: true },
                              },
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.description?.message
                            ? `Description ${validation?.description?.message}`
                            : ""}
                        </small>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel" type="submit">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponCreate;
