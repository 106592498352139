import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import {
  BlogSpecificCommentsView,
  UpdateBlogComments,
} from "../../../Repository/Api";
import moment from "moment";

const BlogSpecificcommentview = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSpecificlist();
  }, []);

  const [comments, setComments] = useState([]);
  const [validation, setValidation] = useState({});

  const setData = (e, key) => {
    setComments({ ...comments, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getSpecificlist = async () => {
    const response = await BlogSpecificCommentsView(id, token);
    if (response && response.statusCode === 200) {
      setComments(response.data);
    } else {
      errorMsg(response.message);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    const Payload = {
      _id: comments._id,
      status: comments.status,
    };
    const response = await UpdateBlogComments(Payload, token);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      errorMsg(response.message);
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  <span
                    className="arrowicon"
                    onClick={() => {
                      navigate("/Bloglist");
                    }}
                  >
                    ← &nbsp;
                  </span>
                  Blog List
                </h6>
              </div>
            </div>
            <div className="contentbox mt-5">
              <form onSubmit={(e) => handleUpdate(e)}>
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <label className="labeltext">Name</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        defaultValue={comments?.name}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Email Id</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        defaultValue={comments?.emailId}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Created At</label>
                    <div>
                      <input
                        type="date"
                        className="fieldinput"
                        value={moment(comments?.createdAt).format("yyyy-MM-DD")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Website</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        defaultValue={comments?.website}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Comments</label>
                    <div>
                      <textarea
                        type="date"
                        className="fieldinput"
                        defaultValue={comments?.comment}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Status</label>
                    <div>
                      <select
                        className="fieldinput"
                        value={comments?.status}
                        onChange={(e) => {
                          setData(e.target.value, "status");
                        }}
                      >
                        <option value="">Select Type</option>
                        <option value="active">Active</option>
                        <option value="inactive">InActive</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel">Update</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSpecificcommentview;
