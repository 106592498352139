import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { GetUserBlogList, GetBlogPage, MetaUserSpecificView } from '../Repository/Api'
import moment from "moment";
import { cloudFrontUrl } from '../commonUtils/Utils'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

const Blogpage = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata)
  const contact = contactCardData
  const [blogs1, setBlogs] = useState()
  const [cms, setcms] = useState()
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const blogs = blogs1?.filter((a) => a.status === "active")

  const GetBlogs = async () => {
    const response = await GetUserBlogList()
    setBlogs(response?.data)
  }
  const GetBlogcms = async () => {
    const response = await GetBlogPage()
    setcms(response?.data?.pageStructure
    )
  }
  const [meta, setMeta] = useState()
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("news-and-blogs")
    if (response && response.statusCode === 200) {
      setMeta(response?.data)
    }
  }
  useEffect(() => {
    GetBlogs()
    GetBlogcms()
    GetMeta()
  }, [])

  const removeHTMLTags = (input) =>
    input
      ?.replace(/<\/?[^>]+(>|$)/g, "")
      ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
      .trim();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      <div className={`${cms?.headerShown === true ? "h-top2" : "mt-5"}`}>
        <div className='container my-5 nunito'>
          <p className='h1 grad-color text-center'>{cms?.heading}</p>
          <div className='row gx-5 my-3 gy-5'>
            {cms?.blogsShown &&
              <>
                {blogs?.map((item, i) => {
                  return (
                    <div key={i} className='col-lg-4 col-md-6' data-aos="zoom-in" data-aos-delay="300">
                      <div className='blog-card'>
                        <img src={`${cloudFrontUrl}${item.image}`} alt='blogpost1' className='w-100' />
                        <p className='bullet-header-2 mt-4'>Lab News</p>
                        <p className='fw-700 green h6'>{item.title}</p>
                        {/* <p className='blog-cnt'>{item.content}</p> */}
                        <p className='blog-cnt'>
                          {removeHTMLTags(item.content).substring(0, 150)}
                          <span>
                            {removeHTMLTags(item.content).length > 100 ? "..." : ""}
                          </span>
                        </p>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <NavLink className='green' to={`/specificblog/${item._id}`}>
                              Read More
                            </NavLink>
                          </div>
                          <div>
                            <p className='sml'>
                              {moment(item.publishedAt).format("MMM DD YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            }
          </div>
        </div>
        {cms?.contactUsCardsShown &&
          <div className='contact nunito'>
            <div className='container d-md-block d-none'>
              <div className='row'>
                <div className='col-lg-6 d-flex flex-column justify-content-center'>
                  <p className='h1'> {contact?.heading}</p>
                  <p>{contact?.subHeading}</p>
                  {contact?.buttonShown &&
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className='btn-a1'>{contact?.buttonHeading}</button>
                    </NavLink>
                  }
                </div>
                <div className='col-lg-6'>
                  <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
                </div>
              </div>
            </div>
          </div>
        }
        {cms?.contactUsCardsShown &&
          <div className='container mt-4 d-block d-md-none'>
            <div data-aos="zoom-in" data-aos-delay="300" className='d-flex flex-column align-items-center'>
              <p className='h3 text-center'>{contact?.heading}</p>
              <p className='text'> {contact?.subHeading}</p>
              <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
              {contact?.buttonShown &&
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className='btn-a1'>{contact?.buttonHeading}</button>
                </NavLink>
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default Blogpage