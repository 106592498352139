import React, { useEffect, useState } from 'react'
import logo from '../Assets/logo.webp'
import logo1 from '../Assets/logo1.webp'
import Vector1 from '../Assets/Vector1.webp';
import img2 from '../Assets/img2.webp';
import dot from '../Assets/dot.webp';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { cloudFrontUrl } from '../commonUtils/Utils'
import { GetTrainingServicePage, MetaUserSpecificView } from '../Repository/Api'
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
const Trainingsevicescontent = () => {
    const { title } = useSelector(state => state?.masterdata?.masterdata)

    const { contactCardData } = useSelector((state) => state?.contactCarddata)
    const { testimonialdata } = useSelector((state) => state?.testimonialdata)
    const testimonial = testimonialdata
    const contact = contactCardData
    const [cms, setCms] = useState({})
    console.log(cms)
    const getTrainingcms = async () => {
        const response = await GetTrainingServicePage()
        if (response && response.statusCode === 200) {
            setCms(response?.data?.pageStructure)
        }
    }

    const [meta, setMeta] = useState()
    const GetMeta = async () => {
        const response = await MetaUserSpecificView("trainingservices")
        if (response && response.statusCode === 200) {
            setMeta(response?.data)
        }
    }

    useEffect(() => {
        getTrainingcms()
        GetMeta()
    }, [])

    const ban = cms?.bannerIcons?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })

    const eightFrs = cms?.innovationFeatures

    const features = cms?.featuresSectionFeatures

    const FiveFeatures = cms?.features?.sort((a, b) => {
        return a.displayOrder - b.displayOrder
    })
    const [imageError, setImageError] = useState(false)
    const handleImage = () => {
        setImageError(true)
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${meta?.title} - ${title}`}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={meta?.description} />
                <meta name="keyword" content={meta?.keyword} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
                    }}
                />
            </Helmet>
            {cms?.headerShown &&
                <div>
                    <Header />
                </div>
            }
            <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
                <div className="develop">
                    <div className="container">
                        <div className="row">
                            <div className="back col-lg-6 col-md-6 col-12" data-aos="fade-up" data-aos-delay="300">
                                <p className="h1">{cms?.bannerHeading}</p>
                                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                                {cms?.bannerButtonShown &&
                                    <NavLink to={cms?.bannerButtonRedirectLink}>
                                        <button className="btn btn-outline-success me-5" type="submit">
                                            {cms?.bannerButtonName}
                                        </button>
                                    </NavLink>}
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center girl-img"
                            >
                                <img src={`${cloudFrontUrl}${cms?.bannerImage}`} alt='homeimg' className='Girl2' data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000" />
                                <img src={`${cloudFrontUrl}${ban && ban[0]?.image}`} alt='homeimg' className={`t-img3 ${imageError && "d-none"}`} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000" />
                                <img src={`${cloudFrontUrl}${ban && ban[1]?.image}`} alt='homeimg' className={`t-img1 ${imageError && "d-none"}`} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000" />
                                <img src={`${cloudFrontUrl}${ban && ban[2]?.image}`} alt='homeimg' className={`t-img4 ${imageError && "d-none"}`} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000" />
                                <img src={`${cloudFrontUrl}${ban && ban[3]?.image}`} alt='homeimg' className={`t-img2 ${imageError && "d-none"}`} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000" />
                            </div>
                        </div>
                    </div>
                </div>
                {cms?.innovationSectionShown &&
                    <div className='innovation'>
                        <div className='container mb-5'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-5 order-2 order-md-1' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${cms?.innovationImage}`} className='img10 w-100' alt='trainingservicesimg2' />
                                </div>
                                <div className='col-lg-6 order-1 col-md-7 order-md-2' data-aos="fade-up" data-aos-delay="300">
                                    <p className='subtitle'>{cms?.innovationHeading}</p>
                                    <p className='title'>{cms?.innovationSubHeading}</p>
                                    <p className='para21 textarea'>{cms?.innovationContent}</p>
                                    {cms?.innovationButtonShown &&
                                        <NavLink to={cms?.innovationButtonRedirectLink}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {cms?.innovationButtonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className='lab'>
                    {cms?.innovationFeaturesShown &&
                        <div className='container'>
                            <div className='row gy-5'>
                                <div className='col col-md-3 ' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[0]?.image}`} className='img12' alt='demologo1' />
                                    <p className='title'>{eightFrs[0]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[1]?.image}`} className='img11' alt='demologo2' />
                                    <p className='title'>{eightFrs[1]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[2]?.image}`} className='img11' alt='demologo3' />
                                    <p className='title'>{eightFrs[2]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[3]?.image}`} className='img11' alt='demologo4' />
                                    <p className='title'>{eightFrs[3]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[4]?.image}`} className='img11' alt='demologo5' />
                                    <p className='title'>{eightFrs[4]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[5]?.image}`} className='img11' alt='demologo6' />
                                    <p className='title'>{eightFrs[5]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[6]?.image}`} className='img11' alt='demologo7' />
                                    <p className='title'>{eightFrs[6]?.content}</p>
                                </div>
                                <div className='col col-md-3' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${eightFrs[7]?.image}`} className='img12' alt='demologo8' />
                                    <p className='title'>{eightFrs[7]?.content}</p>
                                </div>
                            </div>
                        </div>
                    }
                    {cms?.contactUsSectionShown &&
                        <div className='con'>
                            <div className='container box' data-aos="fade-up" data-aos-delay="300">
                                <p className='para22 textarea'>{cms?.contactUsSectionHeadin}</p>
                                {cms?.contactUsSectionButtonShown &&
                                    <div className='bot'>
                                        <NavLink to={cms?.contactUsSectionButtonRedirectPath}>
                                            <button className="btn-a1 fw-700" type="submit">
                                                {cms?.contactUsSectionButtonName}
                                            </button>
                                        </NavLink>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                {cms?.supportingInstitutionsSectionShown &&
                    <div className='support nunito'>
                        <div className='container'>
                            <div className='row mt-5 pt-5 py-3 gx-3'>
                                <div className='col col-lg-4 col-md-12 ' data-aos="fade-up" data-aos-delay="300">
                                    <p className='h6' >
                                        {cms?.supportingInstitutionsSectionHeading.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {cms?.supportingInstitutionsSectionHeading.trim().split(' ')[1]}
                                    </p>
                                    <p className='para22 pb-3 textarea'>
                                        {cms?.supportingInstitutionsSectionContent}
                                    </p>
                                    {cms?.supportingInstitutionsSectionButtonShown &&
                                        <div className='btn-new'>
                                            <NavLink to={cms?.supportingInstitutionsSectionButtonRedirectPath}>
                                                <button className="btn btn-outline-success me-5" type="submit">
                                                    {cms?.supportingInstitutionsSectionButtonName}
                                                </button>
                                            </NavLink>
                                        </div>
                                    }
                                </div>
                                <div className='col1 col-lg-4 mt-5 col-md-6 d-flex justify-content-center' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={logo} className="logo" alt='logo' />
                                    <div className='card '>
                                        <img src={`${cloudFrontUrl}${cms?.supportingInstitutionsSectionCard1Icon}`} className='img16 m-4' alt='trainingservicesimg8' />
                                        <p className='para18 ps-4 pe-4 pb-4'>
                                            {cms?.supportingInstitutionsSectionCard1Content}
                                        </p>
                                    </div>
                                </div>
                                <div className='col2 col-lg-4 mt-5 col-md-6' data-aos="zoom-in" data-aos-delay="300">
                                    <div className='card '>
                                        <img src={`${cloudFrontUrl}${cms?.supportingInstitutionsSectionCard2Icon}`} className='img16 m-4' alt='trainingservicesimg9' />
                                        <p className='para18 ps-4 pe-4 pb-4'>
                                            {cms?.supportingInstitutionsSectionCard2Content}
                                        </p>
                                    </div>
                                    <img src={logo1} className="logo30" alt='logo1' />
                                </div>
                            </div>
                        </div>
                    </div>}
                {cms?.featuresSectionShown &&
                    <div className="tar">
                        <div className="container mt-5 pt-5">
                            <p className="title" data-aos="zoom-in" data-aos-delay="300">
                                {cms?.featuresSectionHeading}
                            </p>
                            <div className="row py-5" >
                                <div className="card col-md-6 m-3 border border-success" data-aos="fade-up" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${features[0]?.image}`} className="img20 m-4" alt="ic1" />
                                    <div className="card-body">
                                        <p className='card-text'>{features[0]?.heading}</p>
                                        <p className='para26 textarea'>{features[0]?.content}</p>
                                    </div>
                                </div>
                                <div className="card-one col-md-6 m-3 border border-success" data-aos="fade-up" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${features[1]?.image}`} className="img20 m-4" alt="ic2" />
                                    <div className="card-body">
                                        <p className='banner-text'>{features[1]?.heading}</p>
                                        <p className='para25 textarea'>{features[1]?.content}</p>
                                    </div>
                                </div>

                                <div className="card-one col-md-6 m-3 border border-success" data-aos="fade-up" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${features[2]?.image}`} className="img20 m-4" alt="ic3" />
                                    <div className="card-body">
                                        <p className='banner-text'>{features[2]?.heading}</p>
                                        <p className='para25 textarea'>{features[2]?.content}</p>
                                    </div>
                                </div>

                                <div className="card col-md-6 m-3 border border-success" data-aos="fade-up" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${features[3]?.image}`} className="img20 m-4" alt="ic4" />
                                    <div className="card-body">
                                        <p className='card-text'>{features[3]?.heading}</p>
                                        <p className='para26 textarea'>{features[3]?.content}</p>

                                    </div>
                                </div>
                                <div className="collapse" id="collapseExample" data-aos="zoom-in" data-aos-delay="300">
                                    <div className='row justify-content-start col-lapse'>
                                        <div className="card col-md-6 m-3 border border-success" >
                                            <img src={`${cloudFrontUrl}${features[4]?.image}`} className="img20 m-4" alt="ic1" />
                                            <div className="card-body">
                                                <p className='card-text'>{features[4]?.heading}</p>
                                                <p className='para26 textarea'>{features[4]?.content}</p>
                                            </div>
                                        </div>
                                        <div className="card-one col-md-6 m-3 border border-success" >
                                            <img src={`${cloudFrontUrl}${features[5]?.image}`} className="img20 m-4" alt="ic2" />
                                            <div className="card-body">
                                                <p className='banner-text'>{features[5]?.heading}</p>
                                                <p className='para25 textarea'>{features[5]?.content}</p>
                                            </div>
                                        </div>

                                        <div className="card-one col-md-6 m-3 border border-success" >
                                            <img src={`${cloudFrontUrl}${features[6]?.image}`} className="img20 m-4" alt="ic3" />
                                            <div className="card-body">
                                                <p className='banner-text'>{features[6]?.heading}</p>
                                                <p className='para25 textarea'>{features[6]?.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cms?.featuresSectionButtonShown &&
                            <div className='bot mt-2' data-aos="fade-up" data-aos-delay="300">
                                <button className="btn btn-outline-success" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{cms?.featuresSectionButtonName}
                                </button>
                            </div>
                        }
                    </div>
                }
                <div className='digital'>
                    {cms?.featuresShown &&
                        <div className='container'>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-lg-6 col-md-7 order-md-2 d-flex flex-column justify-content-center' data-aos="zoom-in" data-aos-delay="300">
                                    <p className='h6'>
                                        {FiveFeatures[0]?.heading?.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {FiveFeatures[0]?.heading?.trim().split(' ')[1]}
                                    </p>
                                    <p className='para27 pb-3 textarea'>
                                        {FiveFeatures[0]?.content}
                                    </p>
                                    {FiveFeatures[0]?.buttonShown &&
                                        <NavLink to={FiveFeatures[0]?.buttonRedirectPath}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {FiveFeatures[0]?.buttonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                                <div className='col-lg-6 col-md-5 d-flex justify-content-center order-md-1' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${FiveFeatures[0]?.image}`} className="img21" alt="digitalimg1" />
                                </div>
                            </div>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-lg-6 col-md-7 d-flex flex-column justify-content-center' data-aos="zoom-in" data-aos-delay="300">
                                    <p className='h6'>
                                        {FiveFeatures[1]?.heading?.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {FiveFeatures[1]?.heading?.trim().split(' ')[1]}
                                    </p>
                                    <p className='para27 pb-3 textarea'>
                                        {FiveFeatures[1]?.content}
                                    </p>
                                    {FiveFeatures[1]?.buttonShown &&
                                        <NavLink to={FiveFeatures[1]?.buttonRedirectPath}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {FiveFeatures[1]?.buttonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                                <div className='col-lg-6 col-md-5 d-flex justify-content-center' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${FiveFeatures[1]?.image}`} className="img21" alt="digitalimg2" />
                                </div>
                            </div>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-lg-6 col-md-7 order-md-2 d-flex justify-content-center flex-column' data-aos="zoom-in" data-aos-delay="300">
                                    <p className='h6'>
                                        {FiveFeatures[2]?.heading?.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {FiveFeatures[2]?.heading?.trim().split(' ')[1]}
                                    </p>
                                    <p className='para27 pb-3 textarea'>
                                        {FiveFeatures[2]?.content}
                                    </p>
                                    {FiveFeatures[2]?.buttonShown &&
                                        <NavLink to={FiveFeatures[2]?.buttonRedirectPath}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {FiveFeatures[2]?.buttonName}
                                            </button>
                                        </NavLink>}
                                </div>
                                <div className='col-lg-6 col-md-5 order-md-1 d-flex justify-content-center ' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${FiveFeatures[2]?.image}`} className="img21" alt="digitalimg3" />
                                </div>
                            </div>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-lg-6 col-md-7 mt-5 d-flex justify-content-center flex-column' data-aos="zoom-in" data-aos-delay="300">
                                    <p className='h6'>
                                        {FiveFeatures[3]?.heading?.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {FiveFeatures[3]?.heading?.trim().split(' ')[1]}
                                    </p>
                                    <p className='para27 pb-3 textarea'>
                                        {FiveFeatures[3]?.content}
                                    </p>
                                    {FiveFeatures[3]?.buttonShown &&
                                        <NavLink to={FiveFeatures[3]?.buttonRedirectPath}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {FiveFeatures[3]?.buttonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                                <div className='col-lg-6 col-md-5 d-flex justify-content-center ' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${FiveFeatures[3]?.image}`} className="img21" alt="digitalimg3" />
                                </div>
                            </div>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-lg-6 col-md-7 order-md-2 d-flex justify-content-center flex-column' data-aos="zoom-in" data-aos-delay="300">
                                    <p className='h6'>
                                        {FiveFeatures[4]?.heading?.trim().split(' ')[0]}
                                    </p>
                                    <p className='h1 pb-3'>
                                        {FiveFeatures[4]?.heading?.trim().split(' ')[1]}
                                    </p>
                                    <p className='para27 pb-3 textarea'>
                                        {FiveFeatures[4]?.content}
                                    </p>
                                    {FiveFeatures[4]?.buttonShown &&
                                        <NavLink to={FiveFeatures[4]?.buttonRedirectPath}>
                                            <button className="btn btn-outline-success me-5" type="submit">
                                                {FiveFeatures[4]?.buttonName}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                                <div className='col-lg-6 col-md-5 order-md-1 d-flex justify-content-center ' data-aos="zoom-in" data-aos-delay="300">
                                    <img src={`${cloudFrontUrl}${FiveFeatures[4]?.image}`} className="img21" alt="digitalimg4" />
                                </div>
                            </div>
                        </div>
                    }
                    {cms?.testimonialSectionShown &&
                        <div className='trainer'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col col-md-3'>
                                        <p className='h6'>Customer</p>
                                        <p className='h4'>Stories</p>
                                    </div>
                                    <div className='col1  col-md-9'>
                                        <div className="py-3 main-owl">
                                            {testimonial && testimonial.length > 0 && (
                                                <OwlCarousel
                                                    className="owl-theme"
                                                    loop
                                                    autoplay={true}
                                                    items="1"
                                                    dots={false}
                                                    smartSpeed={2000}
                                                    margin={10}
                                                    autoplayTimeout={5000}
                                                    responsive={{
                                                        360:
                                                        {
                                                            items: "1",
                                                        },
                                                        414:
                                                        {
                                                            items: "1",
                                                        },
                                                        670:
                                                        {
                                                            items: "1",
                                                        },
                                                        992:
                                                        {
                                                            items: "2",
                                                        },

                                                        1200:
                                                        {
                                                            items: "2",
                                                        },
                                                    }}
                                                >
                                                    {testimonial?.map((item, i) => {
                                                        return (
                                                            <div key={i} style={{ height: "300px" }}>
                                                                <img
                                                                    src={`${cloudFrontUrl}${item?.profileImage}`} className='logo10'
                                                                    alt='img2'
                                                                    onError={(e) => {
                                                                        e.target.src = img2
                                                                        e.target.onError = null
                                                                    }}
                                                                />
                                                                <div className='card'>
                                                                    <img src={Vector1} className='logo11' alt='Vector1' />
                                                                    <p className='card-title'>{item.name}</p>
                                                                    <p className='card-text'>{item.title},{item.role}</p>
                                                                    <p className='para3 textarea'>{item.content}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </OwlCarousel>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={`${cloudFrontUrl}${cms?.testimonialImage}`} alt="background image" className='dummy-bg' />
                            <div className='dummy-bg1'></div>
                        </div>
                    }
                    <div className='img'>
                        <img src={dot} className='logo30' alt='dot' />
                    </div>
                </div>
                {cms?.contactUsCardsShown &&
                    <div className=''>
                        <div className='contact nunito'>
                            <div className='container d-md-block d-none'>
                                <div className='row'>
                                    <div className='col-lg-6 d-flex flex-column justify-content-center'>
                                        <p className='h1'> {contact?.heading}</p>
                                        <p>{contact?.subHeading}</p>
                                        {contact?.buttonShown &&
                                            <NavLink to={contact?.buttonRedirectPath}>
                                                <button className='btn-a1'>{contact?.buttonHeading}</button>
                                            </NavLink>
                                        }
                                    </div>
                                    <div className='col-lg-6'>
                                        <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-4 d-block d-md-none'>
                            <div data-aos="zoom-in" data-aos-delay="300" className='d-flex flex-column align-items-center'>
                                <p className='h3 text-center'>{contact?.heading}</p>
                                <p className='text'> {contact?.subHeading}</p>
                                <img src={`${cloudFrontUrl}${contact?.backgroudImage}`} className='w-100' />
                                {contact?.buttonShown &&
                                    <NavLink to={contact?.buttonRedirectPath}>
                                        <button className='btn-a1'>{contact?.buttonHeading}</button>
                                    </NavLink>
                                }
                            </div>
                        </div>
                        <div className='img' data-aos="zoom-in" data-aos-delay="300">
                            <img src={dot} className='logo13' alt='dot' />
                        </div>
                    </div>}
            </div >
            {cms?.footerShown &&
                <div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Trainingsevicescontent