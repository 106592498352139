import React, { useState, useEffect } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import {
  LengthValidation,
  NonEmptyValidation,
  StringValidation,
} from "../../../Store/validate";
import { CreateTestimonial, ImageUpload } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const TestimonialCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createFaq, setcreateFaq] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  const setValues = (e, key) => {
    setcreateFaq({ ...createFaq, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setValues(response.data._id, key);
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };

  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.size <= 500
        // &&dummy?.height <= 117 &&
        // dummy?.width <= 117
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response.data.path, "profileImage");
          successMsg(response.message);
        }
        console.log(createFaq);
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(createFaq?.name);
    validate.title = NonEmptyValidation(createFaq?.title);
    validate.role = NonEmptyValidation(createFaq?.role);
    validate.profileImage = NonEmptyValidation(createFaq?.profileImage);
    validate.content = NonEmptyValidation(createFaq?.content);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: createFaq.name,
        title: createFaq.title,
        role: createFaq.role,
        profileImage: createFaq.profileImage,
        content: createFaq.content,
      };
      const response = await CreateTestimonial(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/Testimonial"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Testimonial");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Testimonial List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create Testimonial
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Name *
                          <span className="char-hint">
                            {" "}
                            (Maximum 30 characters)
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Name"
                            defaultValue={createFaq?.name}
                            maxLength={30}
                            onChange={(e) => {
                              setValues(e.target.value, "name");
                              if (e.target.value.length === 30) {
                                console.log(e.target.value);
                                setValidation({
                                  ...validation,
                                  name: LengthValidation(e.target.value, 30),
                                });

                                setTimeout(() => {
                                  setValidation({
                                    ...validation,
                                    name: false,
                                  });
                                }, 3000);
                              }
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.name?.message
                            ? `Name ${validation?.name?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Profile Image *</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="testimonialcreate"
                            onChange={(e) => UploadDocument(e, "profileImage")}
                          />
                          <label for="testimonialcreate" className="alt-btn">
                            Upload Image
                          </label>

                          {!createFaq.profileImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createFaq?.profileImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 100KB,117px X 117px.
                        </small>
                        <small className="validationerror">
                          {validation?.profileImage?.message
                            ? `Profile Image ${validation?.profileImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Title *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Title"
                            defaultValue={createFaq?.title}
                            onChange={(e) => {
                              setValues(e.target.value, "title");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.title?.message
                            ? `Title ${validation?.title?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Role *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Role"
                            defaultValue={createFaq?.role}
                            onChange={(e) => {
                              setValues(e.target.value, "role");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.role?.message
                            ? `Role ${validation?.role?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Content *</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Content"
                            defaultValue={createFaq?.content}
                            onChange={(e) => {
                              setValues(e.target.value, "content");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.content?.message
                            ? `Content ${validation?.content?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="row my-4">
                        <div className="col-lg-3 centertext ms-auto mx-auto">
                          <button className="buttonmodel" type="submit">
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCreate;
