import React from 'react'
import Header from '../Components/Header'
import PrivacyPolicyContent from '../Components/PrivacyPolicyContent'
import Footer from '../Components/Footer'

const Privacypolicy = () => {
    return (
        <div>
            <Header />
            <PrivacyPolicyContent />
            <Footer />
        </div>
    )
}

export default Privacypolicy