import React, { useState } from "react";
import logo from "../../Assets/navlogo.webp";
import { useNavigate } from "react-router-dom";
import { ChangeNewPassword } from "../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../Toastify";

const ChangePassword = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  const [login, SetLogin] = useState([]);
  const [validation, setValidation] = useState({});
  const [oldpassword, setOldPassword] = useState(false);
  const [newpassword, setNewPassword] = useState(false);

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const setData = (e, key) => {
    let TempData = login;
    TempData[key] = e;
    SetLogin(TempData);
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const viewPassword = () => {
    setOldPassword((prevPassword) => !prevPassword);
   
  };
  const viewPassword1 = () => {
  
    setNewPassword((prevPassword) => !prevPassword);

   
  };
  const viewPassword2 = () => {
   

    setConfirmPasswordVisible((prevVisible) => !prevVisible);
  };
  
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!login.oldPassword) {
      validdateData.oldPassword = "Old Password is Required";
      Status = false;
    }
    if (!login.newPassword) {
      validdateData.newPassword = "New Password is Required";
      Status = false;
    } 
    if (!login.repassword) {
      validdateData.repassword = "Confirm Password is Required";
      Status = false;
      
    } else if (login.newPassword.localeCompare(login.repassword) !== 0) {
      validdateData.repassword =
        "new Password and Re-Enter Password doesn't match";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const Payload = {
      oldPassword: login.oldPassword,
      newPassword: login.newPassword,
    };
    if (CheckValidation()) {
      const response = await ChangeNewPassword(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        getTimeout();
      } else {
        errorMsg(response.message);
      }
    }
  };

  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/adminlogin"));
    }, 1000);
  };

  return (
    <>
      <section className="loginbg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ms-auto mx-auto">
              <div className="loginmodel">
                <div className="centertext">
                  <img src={logo} alt="Rescale Lab" />
                </div>
                <div className="my-5">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={oldpassword ? "text" : "password"}
                        className="logininput-field"
                        placeholder="OLD PASSWORD"
                        defaultValue={login.oldPassword}
                        onChange={(e) => {
                          setData(e.target.value, "oldPassword");
                        }}
                      />
                      <div className="psw-icon">

                        {oldpassword ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      {validation.oldPassword && (
                        <p className="validationerror">
                          {validation.oldPassword}
                        </p>
                      )}
                    </div>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={newpassword ? "text" : "password"}
                        className="logininput-field"
                        placeholder="NEW PASSWORD"
                        defaultValue={login.newPassword}
                        onChange={(e) => {
                          setData(e.target.value, "newPassword");
                        }}
                      />
                      <div className="psw-icon">
                        {newpassword ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword1()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword1()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      {validation.newPassword && (
                        <p className="validationerror">
                          {validation.newPassword}
                        </p>
                      )}
                    </div>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="logininput-field"
                        placeholder="CONFIRM PASSWORD"
                        onChange={(e) => {
                          setData(e.target.value, "repassword");
                        }}
                      />
                      <div className="psw-icon">
                        {confirmPasswordVisible ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword2()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword2()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      {validation.repassword && (
                        <p className="validationerror">
                          {validation.repassword}
                        </p>
                      )}
                    </div>
                    <div className="inputspace">
                      <button className="loginbtn"> Change Password </button>
                    </div>
                    <p
                      className="text-end forgettext"
                      onClick={() => {
                        navigate("/admin/dashboard");
                      }}
                    >
                      <small>Back to Dashboard</small>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
