import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import Biguser from "../Assets/big-user.webp";
import Dummy from "../Assets/dummy.webp";
import {
  CreateBlogComment,
  GetSpecificBlogpage,
  GetUserBlogList,
  GetUserSpecificBlogList,
} from "../Repository/Api";
import SubmittedModal from "./Modal/SubmittedModal";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
const SpecificBlogPage = () => {
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const currentUrl = window.location.href;
  const commentSection = currentUrl.split("/#")[2];
  const { id } = useParams();
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const [blogDetails, setBlogDetails] = useState();
  const [data, setDataa] = useState();
  const [check, setCheck] = useState(false);
  const [comment, setComments] = useState([]);

  const inputRef = useRef(null);
  const handleCheckbox = () => {
    setCheck(!check);
  };

  const GetSpecificBlog = async () => {
    const response = await GetUserSpecificBlogList(id, token);
    if (response && response.statusCode === 200) {
      setBlogDetails(response?.data);
      setDataa(response?.data.content);
      setComments(response?.data.comments);
    } else {
    }
  };
  const [blogs, setBlogs] = useState([]);
  const activeComments = comment.filter((item) => item.status === "active");
  const GetBlogs = async () => {
    const response = await GetUserBlogList();
    if (response && response.statusCode === 200) {
      setBlogs(response?.data);
    }
  };
  const [cms, setCms] = useState({});
  console.log(cms);
  const getcms = async () => {
    const response = await GetSpecificBlogpage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };
  useEffect(() => {
    getcms();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetSpecificBlog();
    GetBlogs();
    if (commentSection === "blogcomment") {
      inputRef.current.focus();
    }
  }, []);
  const BlogID = (data) => data?._id === blogDetails?._id;
  const BlogIndex = blogs?.findIndex(BlogID);
  const prevpost = blogs[BlogIndex - 1];
  const nextpost = blogs[BlogIndex + 1];
  const [submitModal, setSubmitModal] = useState();
  const excludedIds = [id, prevpost?._id, nextpost?._id];
  const similar = blogs
    ?.filter((post) => !excludedIds.includes(post?._id))
    .slice(0, 3);

  const removeHTMLTags = (input) =>
    input
      ?.replace(/<\/?[^>]+(>|$)/g, "")
      ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
      .trim();

  const [info, setInfo] = useState({});
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setInfo({ ...info, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!info.comment) {
      validdateData.comment = "Comment is Required";
      Status = false;
    }
    if (!info.name) {
      validdateData.name = "Name is Required";
      Status = false;
    }
    if (!info.emailId) {
      validdateData.emailId = "Email is Required";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let inputvalue = {
      blogId: id,
      comment: info.comment,
      name: info.name,
      emailId: info.emailId,
      website: info.website,
    };
    let formdata = new FormData();
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }
    if (CheckValidation()) {
      const response = await CreateBlogComment(inputvalue);
      if (response && response.statusCode === 200) {
        setInfo({});
        setSubmitModal(true);
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${blogDetails?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header navi={true} />
        </div>
      )}
      <div className={`${cms?.headerShown === true ? "h-top" : "mt-5"} nunito`}>
        <SubmittedModal
          open={submitModal}
          setOpen={() => {
            setSubmitModal(false);
          }}
        />
        <div className="container">
          <div style={{ marginTop: cms?.headerShown ? 100 : 5 }}>
            <NavLink to={`/category/${blogDetails?.category}`}>
              <p
                className="bullet-header"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                {blogDetails?.category}
              </p>
            </NavLink>
          </div>

          <p
            className="h1 grad-color text-capitalize"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            {blogDetails?.title}
          </p>
          <div
            className="d-flex mb-4 author"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <Link to={`/author/${blogDetails?.author}`}>
              <div className="d-flex">
                {/* <img src={User} alt='usericon' className='' /> */}
                <span className="material-symbols-outlined">person</span>
                <p className="mb-0 ms-2 nunito">{blogDetails?.author}</p>
              </div>
            </Link>
            <div className="d-flex ms-4 blog-date">
              {/* <img src={Calender} alt='calendericon' /> */}
              <span className="material-symbols-outlined">calendar_month</span>
              <p className="mb-0 ms-2 nunito">
                {moment(blogDetails?.publishedAt).format("MMM DD YYYY")}
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: data }} />
          <div className="redirect-btn">
            {blogDetails?.buttonShown === true ? (
              <button
                className="btn-primary w-auto"
                onClick={() => {
                  let url = blogDetails?.link;

                  if (
                    url &&
                    !url.startsWith("http://") &&
                    !url.startsWith("https://")
                  ) {
                    url = "http://" + url;
                  }
                  window.open(url, "_blank");
                }}
              >
                {blogDetails?.buttonName}
              </button>
            ) : null}
          </div>
        </div>

        {/* <div className='labnews my-5'>
        <div className='container'>
          <p className='bullet-header' data-aos="zoom-in" data-aos-delay="300">Lab News</p>
          <p className='h1 grad-color' data-aos="zoom-in" data-aos-delay="300">LAB News May Edition</p>
          <div className='d-flex' data-aos="zoom-in" data-aos-delay="300">
            <div className='d-flex'>
              <img src={User} alt='usericon' className='' />
              <p className='mb-0 ms-2 nunito'>Scarlett Johns</p>
            </div>
            <div className='d-flex ms-4'>
              <img src={Calender} alt='calendericon' />
              <p className='mb-0 ms-2 nunito'>June 20, 2023</p>
            </div>
          </div>
          <p className='pt-5 pb-3 nunito' style={{ color: "#333333" }} data-aos="zoom-in" data-aos-delay="300">
            Hey there 👋! As we approach the month of June, we wanted to share a quick update with you on what we’ve been up to in the past few weeks. By the way, if you are interested in learning more about how RescaleLab can help transform your programs...
            <br />
            <br />
            Hey there 👋!
            <br />
            <br />
            As we approach the month of June, we wanted to share a quick update with you on what we’ve been up to in the past few weeks.
            <br />
            <br />
            By the way, if you are interested in learning more about how<span style={{ color: "#77C48C" }}> RescaleLab </span>can help transform your programs into highly effective trainings, book a demo with us today and discover the full range of features and tools we offer!
          </p>
          <button className='btn-primary w-auto'>Book a Demo</button>
        </div>
      </div>
      <div className='featured-news'>
        <div className='container'>
          <p className='h3 my-2 nunito fw-700' style={{ color: "#5B615C" }} data-aos="zoom-in" data-aos-delay="300">🔥 Featured News</p>
          <p className='h1 grad-color mt-4' data-aos="zoom-in" data-aos-delay="300">Here’s a snapshot of what we’ve been up to!</p>
          <img src={Meeting} alt="groupmeeting" className='w-100  my-5' data-aos="zoom-in" data-aos-delay="300" />
          <p className='h4 nunito fw-700' style={{ color: "#5B615C" }} data-aos="zoom-in" data-aos-delay="300">Workshop “Let’s talk Mental Health Wellness with Sandy”</p>
          <p style={{ color: "#5B615C" }} className='nunito' data-aos="zoom-in" data-aos-delay="300">
            Last May 18th, the workshop <i>“Let’s talk Mental Health Wellness with Sandy”</i> finally took place at CirCo Nam Ky Khoi Nghia with<span className='green'> Ms. Sandy Sinn </span>– a Youth Mental Health Educator and Specialist at the<span className='green'> Center For Positive Psychology & Wellbeing Vietnam</span>, Mr. Troy Yeo – Head of Venture from<span className='green'> RescaleLab</span> , and many participants from different ages and backgrounds.<br /><br />

            During the workshop, Ms. Sandy provided us with many valuable insights, helping the attendees understand mental health, recognize signs of common issues, and how to prioritize their own well-being. If you missed it, don’t worry. You can find out more<span className='green'> here </span>and stay tune for our upcoming events.
          </p>
        </div>
      </div>
      <div className='upcoming-events'>
        <div className='container'>
          <p className='h1 mt-4' data-aos="zoom-in" data-aos-delay="300">🔥<span className='grad-color2'> Upcoming Events</span></p>
          <img src={Incubator} alt="incubator-program" className='w-100 my-5' data-aos="zoom-in" data-aos-delay="300" />
          <p className='h4 nunito fw-700 gray' data-aos="zoom-in" data-aos-delay="300">Huawei Cloud Startup Program: INCUBATOR</p>
          <p className='nunito' data-aos="zoom-in" data-aos-delay="300">
            We are thrilled to inform you that the<i className='green'> Huawei Cloud Startup Incubator Program </i>(Spark Incubator) is now accepting applications for the June 2023 cohort. This 5-month program is tailored for early-stage startups in Singapore operating in the exciting fields of Web 3.0, Metaverse, AI-Generated Content, Enterprise SaaS and Fintech.
            <br />
            <br />
            The program offers a variety of benefits to aid in your success, including but not limited to:
            <br />
            <br />
            <ul>
              <li>Up to $60K in Huawei Cloud credits</li>
              <li> Office space, facilities & resources at IMDA PIXEL</li>
              <li> Mentorship from Huawei and our global network of experts and investors</li>
              <li> Technical deep dives with Huawei & access to Huawei Cloud services</li>
              <li>Up to $3M in potential investment post-program with VC partners</li>
            </ul>
            <br />
            <br />
            Don’t miss this opportunity to grow your startup with Huawei.<br />
            Submit your application by 2 June:<span className='green'> https://www.f6s.com/huawei-incubator-2/about</span><br />
            For more South East Asia founders collaboration and funding opportunities, join us:<span className='green'> https://t.me/rescalelabcommunity</span>
          </p>
        </div>
      </div>
      <div className='using-ai'>
        <div className='container'>
          <p className='h1 mt-5' data-aos="zoom-in" data-aos-delay="300">🔥<span className='grad-color2'> Using AI in construction, why not?</span></p>
          <img src={Use} alt="incubator-program" className='w-100 my-5' data-aos="zoom-in" data-aos-delay="300" />
          <p className='nunito' data-aos="zoom-in" data-aos-delay="300">
            It was captured in the networking session organized by RescaleLab to connect all the founders from ConcreteAI (<span className='green'>Issac Siow </span>and<span className='green'> Xi Yi Ooi</span>); Invigilo (<span className='green'>Vishnu Saran</span>) and Paul Y. Engineering (<span className='green'>Marn Lee</span>)
            <br />
            <br />
            <span className='green'>ConcreteAI </span>system visualizes real-time early-age development of job-site concrete curing process for shorter casting cycle time and fewer resource wastage.
            <br />
            <br />
            <span className='green'>Invigilo </span>is an AI video analytics solution provider for workplace safety. The AI solutions can be implemented across different industry verticals such as construction sites, shipyards, manufacturing plants and more.
            <br />
            <br />
            <span className='green'>Paul Y. Engineering </span>has been at the heart of some of the most challenging and impactful construction projects that have shaped the iconic skylines of Hong Kong and many other cities.
          </p>
        </div>
      </div>
      <div className='improve'>
        <div className='container'>
          <p className='h1 mt-5' data-aos="zoom-in" data-aos-delay="300">🚀<span className='grad-color2'>Improve Your Training Program Now!</span></p>
          <img src={Next} alt="next-level-program" className='w-100 my-5' data-aos="zoom-in" data-aos-delay="300" />
          <p className='nunito' data-aos="zoom-in" data-aos-delay="300">
            Have you ever considered to seek wise training management strategies to conquer the challenges and improve the quality of your training program?<br /><br />
            🔥 Congrats! Here is a chance specially for you if you are looking to:<br /><br />
            <ul>
              <li> Establish a stronger, more lucrative relationship with your clients</li>
              <li>Save cost and time of your training programs</li>
              <li> Hit your long-term training goals and keep all details under control</li>
            </ul><br />
            Let’s have a chat! We will have a comprehensive and specific understanding of the problems you need to solve and show you what tools you can use to work more effectively, helping you to better reach out to your goals!
          </p>
          <button className='btn-primary w-auto'>Let’s Chat!</button>
        </div>
      </div> */}
        {cms?.nextTimeSectionShown && (
          <>
            <div className="till-next my-5">
              <div className="container nunito">
                <div className="until-next">
                  <p className="h1 fw-bold text-center py-3">
                    {cms?.nextTimeSectionHeading}
                  </p>
                </div>

                <p
                  className="mt-5 textarea"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  {cms?.nextTimeSectionContent}
                  <br />
                  <br />
                  Sincerely,
                  <br />
                  <br />
                  RescaleLab Team
                  <hr />
                </p>
                <div
                  className="d-flex align-items-center"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img src={Biguser} alt="user" className="d-flex" />
                  <div className="px-5">
                    <p className="h1">
                      <span className="grad-color2">{blogDetails?.author}</span>
                    </p>
                    {cms?.viewAllButtonShown && (
                      <NavLink to={`/author/${blogDetails?.author}`}>
                        <button className="btn-primary w-auto">
                          {cms?.viewAllButtonHeading}
                        </button>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="reply nunito my-5">
          <div className="container">
            <p
              className="h4 gray fw-700"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              {cms?.replySectionHeading}
            </p>
            <p data-aos="zoom-in textarea" data-aos-delay="300">
              {cms?.replySectionSubHeading}
            </p>
            <div className="formk" data-aos="zoom-in" data-aos-delay="300">
              <div className="my-4">
                <label>Comment *</label>
                <textarea
                  id="blogcomment"
                  ref={inputRef}
                  type="text"
                  className="form-control"
                  name="comment"
                  rows={3}
                  onChange={(e) => setData(e.target.value, "comment")}
                />
                {validation.comment && (
                  <p className="error-text">{validation.comment}</p>
                )}
              </div>
              <div className="my-4">
                <label>Name *</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) => setData(e.target.value, "name")}
                />
                {validation.name && (
                  <p className="error-text">{validation.name}</p>
                )}
              </div>
              <div className="my-4">
                <label>Email *</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailId"
                  onChange={(e) => setData(e.target.value, "emailId")}
                />
                {validation.emailId && (
                  <p className="error-text">{validation.emailId}</p>
                )}
              </div>
              <div className="my-4">
                <label>Website</label>
                <input
                  type="text"
                  className="form-control"
                  name="Website"
                  onChange={(e) => setData(e.target.value, "website")}
                />
              </div>
              <div className="my-4">
                {/* <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={handleCheckbox}
                  id="flexCheckDefault"
                />
                <label className="ms-3" for="flexCheckDefault">
                  {cms?.replySectionSaveContent}
                </label> */}
                {/* <p className='error-text'>Please Accept the Terms and Conditions</p> */}
              </div>
              <button className="btn-primary w-auto" onClick={handleSubmit}>
                {cms?.replySectionButtonName}
              </button>
            </div>
          </div>
        </div>

        <div className="comments nunito">
          <div className="container">
            {activeComments.map((item, i) => {
              return (
                <div className="user-comment d-flex" key={i}>
                  <div>
                    <img src={Biguser} alt="profile" />
                  </div>
                  <div>
                    <p className="h5">{item.name}</p>
                    <p>
                      {moment(item?.createdAt).format("MMM DD,YYYY")} at{" "}
                      {moment(item?.createdAt).format("LT")}{" "}
                    </p>
                    <p>{item.comment}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="Pagination nunito">
          <div className="container">
            <div className="row my-5" data-aos="zoom-in" data-aos-delay="300">
              <div className="previous d-flex align-items-center col-lg-6">
                {!prevpost ? (
                  ""
                ) : (
                  <>
                    <div>
                      <img src={Dummy} alt="dummy" className="dummyimage" />
                    </div>
                    <div className="px-5 nextpost">
                      <p className="fw-700 h6 gray ">Previous Post</p>
                      <NavLink
                        className="prev-nav"
                        to={`/specificblog/${prevpost?._id}`}
                      >
                        <p className="h4">{prevpost?.title}</p>
                      </NavLink>
                    </div>
                  </>
                )}
              </div>
              <div className="next  d-flex align-items-center col-lg-6 justify-content-end">
                {!nextpost ? (
                  ""
                ) : (
                  <>
                    <div className="px-5 text-end nextpost">
                      <p className="fw-700 h6 gray">Next Post</p>
                      <NavLink
                        className="next-nav"
                        to={`/specificblog/${nextpost?._id}`}
                      >
                        <p className="h4">{nextpost?.title}</p>
                      </NavLink>
                    </div>
                    <div>
                      <img src={Dummy} alt="dummy" className="dummyimage" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {cms?.relatedBlogSectionShown && (
          <div className="similiar nunito">
            <div className="container">
              <p
                className="h4 gray fw-700"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                {cms?.relatedBlogsSectionHeading}
              </p>
              {/* <div className='row my-5' data-aos="zoom-in" data-aos-delay="300">
            <div className='col-lg-3 col-md-4'>
              <img src={Dummy} alt="dummy" className='w-100 sim-post' />
            </div>
            <div className='col-lg-4 col-md-6 d-flex flex-column align-self-center'>
              <div className=' w-75'>
                <div className='d-flex mb-3'>
                  <div className='d-flex'>
                    <img src={User} alt='usericon' className='' />
                    <p className='mb-0 ms-2 nunito sml'>Scarlett Johns</p>
                  </div>
                  <div className='d-flex ms-4'>
                    <img src={Calender} alt='calendericon' />
                    <p className='mb-0 ms-2 nunito sml'>June 20, 2023</p>
                  </div>
                </div>
                <div>
                  <p className='h5 grad-color mb-2'>LAB News May Edition</p>
                </div>
                <div>
                  <p className='mb-3'>Hi there 👋, As we approach the end of the month, we wanted to share a quick update with you on what we’ve been up to...</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className=''>
                    <i className="fa-regular fa-clock me-2 green"></i>
                    6 min
                  </div>
                  <div className=''>
                    <i className="fa-regular fa-message me-2 green"></i>
                    0
                  </div>
                  <div className='add-comment' onClick={commentHandler}>
                    <i className="fa-regular fa-comment me-2 green"></i>
                    Add Comment
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='row my-5' data-aos="zoom-in" data-aos-delay="300">
            <div className='col-lg-3 col-md-4'>
              <img src={Dummy} alt="dummy" className='w-100 sim-post' />
            </div>
            <div className='col-lg-4 col-md-6 d-flex flex-column align-self-center'>
              <div className=' '>
                <div className='d-flex mb-3'>
                  <div className='d-flex'>
                    <img src={User} alt='usericon' className='' />
                    <p className='mb-0 ms-2 nunito sml'>Scarlett Johns</p>
                  </div>
                  <div className='d-flex ms-4'>
                    <img src={Calender} alt='calendericon' />
                    <p className='mb-0 ms-2 nunito sml'>June 20, 2023</p>
                  </div>
                </div>
                <div>
                  <p className='h5 grad-color mb-2'>LAB News February Edition</p>
                </div>
                <div className='w-75'>
                  <p className='mb-3'>Hi there 👋, As we approach the end of the month, we wanted to share a quick update with you on what we’ve been up to...</p>
                </div>
                <div className='d-flex justify-content-between w-75'>
                  <div className=''>
                    <i className="fa-regular fa-clock me-2 green"></i>
                    6 min
                  </div>
                  <div className=''>
                    <i className="fa-regular fa-message me-2 green"></i>
                    0
                  </div>
                  <div className='add-comment' onClick={commentHandler}>
                    <i className="fa-regular fa-comment me-2 green"></i>
                    Add Comment
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='row my-5' data-aos="zoom-in" data-aos-delay="300">
            <div className='col-lg-3 col-md-4'>
              <img src={Dummy} alt="dummy" className='w-100 sim-post' />
            </div>
            <div className='col-lg-4 col-md-6 d-flex flex-column align-self-center'>
              <div className=' '>
                <div className='d-flex mb-3'>
                  <div className='d-flex'>
                    <img src={User} alt='usericon' className='' />
                    <p className='mb-0 ms-2 nunito sml'>Scarlett Johns</p>
                  </div>
                  <div className='d-flex ms-4'>
                    <img src={Calender} alt='calendericon' />
                    <p className='mb-0 ms-2 nunito sml'>June 20, 2023</p>
                  </div>
                </div>
                <div>
                  <p className='h5 grad-color mb-2'>Feb 2023 Tech Updates | Lab Bomb</p>
                </div>
                <div className='w-75'>
                  <p className='mb-3'>Hi there 👋, As we approach the end of the month, we wanted to share a quick update with you on what we’ve been up to...</p>
                </div>
                <div className='d-flex justify-content-between w-75'>
                  <div className=''>
                    <i className="fa-regular fa-clock me-2 green"></i>
                    6 min
                  </div>
                  <div className=''>
                    <i className="fa-regular fa-message me-2 green"></i>
                    0
                  </div>
                  <div className='add-comment' onClick={commentHandler}>
                    <i className="fa-regular fa-comment me-2 green"></i>
                    Add Comment
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              {similar?.map((item, i) => {
                return (
                  <div key={i}>
                    <div
                      className="row my-5"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="col-lg-3 col-md-4">
                        <img
                          src={Dummy}
                          alt="dummy"
                          className="w-100 sim-post"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 d-flex flex-column align-self-center">
                        <div className=" ">
                          <div className="d-flex mb-3">
                            <div className="d-flex align-items-end">
                              <span className="material-symbols-outlined green">
                                person
                              </span>
                              <NavLink
                                className="green text-capitalize"
                                to={`/author/${item?.author}`}
                              >
                                <p className="mb-0 ms-2 nunito sml">
                                  {item?.author}
                                </p>
                              </NavLink>
                            </div>
                            <div className="d-flex ms-4 align-items-end">
                              <span className="material-symbols-outlined green">
                                calendar_month
                              </span>
                              <p className="mb-0 ms-2 nunito sml green">
                                {moment(item?.publishedAt).format(
                                  "MMM DD,YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                          <div>
                            <NavLink
                              className="green"
                              to={`/specificblog/${item._id}`}
                            >
                              <p className="h5 grad-color mb-2">
                                {item?.title}
                              </p>
                            </NavLink>
                          </div>
                          <div className="w-75">
                            <p className="mb-3">
                              <p className="blog-cnt">
                                {removeHTMLTags(item?.content).substring(
                                  0,
                                  150
                                )}
                                <span>
                                  {removeHTMLTags(item?.content).length > 100
                                    ? "..."
                                    : ""}
                                </span>
                              </p>
                            </p>
                          </div>
                          <div className="d-flex justify-content-between w-75">
                            <div className="">
                              <i className="fa-regular fa-clock me-2 green"></i>
                              {item?.minutes}
                            </div>
                            <div className="">
                              <i className="fa-regular fa-message me-2 green"></i>
                              {item?.comments?.length}
                            </div>
                            <div className="add-comment">
                              <NavLink
                                className="green"
                                to={`/specificblog/${item._id}/#blogcomment`}
                              >
                                <i className="fa-regular fa-comment me-2 green"></i>
                                Add Comment
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SpecificBlogPage;
