import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AdminHeader from "./Adminheader";
import { errorMsg, successMsg } from "../Toastify";
import { GetInitials } from "../../Store/constants";
import { GetMyProfile, ImageUpload, UpdateAdmin } from "../../Repository/Api";
import { PhoneNumberValidation, StringValidation } from "../../Store/validate";
import { updateSpecificUserDetailsKeyinState } from "../../Store/auth/action";
import { cloudFrontUrl } from "../../commonUtils/Utils";
const Profile = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const dispatch = useDispatch();
  const ProfileRef = useRef();

  useEffect(() => {
    getProfile();
  }, []);

  const [profile, setProfile] = useState({});
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setProfile({ ...profile, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const getProfile = async () => {
    const response = await GetMyProfile(token);
    if (response && response.statusCode === 200) {
      setProfile(response?.data);
    } else {
      errorMsg(response.message);
    }
  };

  const UploadDocument = async (e, key) => {
    const file = e.target.files[0];

    const maxSizeInBytes = 500 * 1024;
    if (file.size > maxSizeInBytes) {
      errorMsg("File size exceeds the limit of 500KB");
      return;
    }

    let formData = new FormData();
    let str = file.name;
    formData.append("file", file);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "profile/");

    const response = await ImageUpload(formData);
    if (response && response.statusCode === 200) {
      setData(response.data?._id, key);

      const Payload = {
        _id: profile._id,
        profileImage: response.data?._id,
      };
      const response2 = await UpdateAdmin(Payload, token);
      if (response2 && response2.statusCode === 200) {
        successMsg(response2.message);
        getProfile();
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        errorMsg(response2.message);
      }
    } else {
      errorMsg(response.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.firstName = StringValidation(profile?.firstName);
    validate.lastName = StringValidation(profile?.lastName);
    validate.phoneNumber = PhoneNumberValidation(profile?.phoneNumber);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: profile._id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        phoneNumber: profile.phoneNumber,
        profileImage: profile.profileImage,
      };
      const response = await UpdateAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        getProfile();
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  const handleRemoveProfile = async () => {
    const payload = {
      _id: profile._id,
      profileImage: "",
    };
    const response = await UpdateAdmin(payload, token);
    if (response && response.statusCode === 200) {
      dispatch(updateSpecificUserDetailsKeyinState(response.data));
      getProfile();
      successMsg(response.message);
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } else {
      errorMsg(response.message);
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <section>
              <div className="my-5 container">
                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="contenttitle">My Profile</h6>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="row mt-4">
                <div className="col-lg-3 mt-5">
                  <div className="profilesection1-content mt-5">
                    <div className="position-relative mb-4 m-auto d-flex align-items-center justify-content-center">
                      <div className="progress-profile mb-2">
                        <span>
                          {!profile?.profileImage ? (
                            <div className="messageimagelettercircle">
                              {GetInitials(profile?.firstName)}
                            </div>
                          ) : null}
                          <img
                            className="userprofilecircle"
                            src={`${cloudFrontUrl}${profile?.profileImage}`}
                            alt=""
                            hidden={!profile?.profileImage}
                          />
                        </span>
                      </div>
                      {!profile?.profileImage ? (
                        <span
                          className="material-symbols-rounded addpic"
                          onClick={() => ProfileRef.current.click()}
                        >
                          add_a_photo
                          <input
                            autoComplete="off"
                            type="file"
                            name="myfile"
                            ref={ProfileRef}
                            hidden
                            onChange={(e) => UploadDocument(e, "profileImage")}
                            accept="image/jpeg, image/png,image/webp"
                          />
                        </span>
                      ) : (
                        <span
                          className="material-symbols-rounded removepic"
                          onClick={() => handleRemoveProfile()}
                        >
                          cancel
                        </span>
                      )}
                    </div>
                  </div>
                  <small className="">
                    Image Upload: Max 500kb Ratio 1:1
                  </small>
                </div>
                <div className="col-lg-9">
                  <form onSubmit={(e) => handleUpdate(e)}>
                    <div className="my-5 px-3">
                      <div className="row justify-content-between mt-2">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="labeltext">First Name</label>
                            <div>
                              <input
                                type="text"
                                className="fieldinput"
                                placeholder="Enter First Name"
                                autoComplete="off"
                                value={profile?.firstName}
                                onChange={(e) =>
                                  setData(e.target.value, "firstName")
                                }
                              />
                            </div>
                            <small className="validationerror">
                              {validation?.firstName?.message
                                ? `First Name ${validation?.firstName?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="labeltext">Last Name</label>
                            <div>
                              <input
                                type="text"
                                className="fieldinput"
                                placeholder="Enter Last Name"
                                autoComplete="off"
                                value={profile?.lastName}
                                onChange={(e) =>
                                  setData(e.target.value, "lastName")
                                }
                              />
                            </div>
                            <small className="validationerror">
                              {validation?.lastName?.message
                                ? `Last Name ${validation?.lastName?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="labeltext">Phone No</label>
                            <div>
                              <input
                                type="text"
                                className="fieldinput"
                                placeholder="Enter Phone Mobile"
                                autoComplete="off"
                                maxLength={10}
                                value={profile?.phoneNumber}
                                onChange={(e) =>
                                  setData(e.target.value, "phoneNumber")
                                }
                              />
                            </div>
                            <small className="validationerror">
                              {validation?.phoneNumber?.message
                                ? `Phone Number ${validation?.phoneNumber?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="labeltext">Email ID</label>
                            <input
                              type="text"
                              className="fieldinputemail"
                              placeholder="Enter Email Id"
                              autoComplete="off"
                              value={profile?.emailId}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 mx-auto ms-auto mt-5">
                          <div className="d-flex justify-content-center">
                            <button className="buttonmodel">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
