import React, { useState, useEffect } from 'react'
import dummy1 from '../Assets/dummy1.webp'
import User from "../Assets/usericon.webp"
import Calender from "../Assets/calender.webp"
import moment from 'moment'
import { useParams, NavLink } from 'react-router-dom'
import { GetCategoryBlogList } from '../Repository/Api'

const CategoryBlogs = () => {
    const { category } = useParams()

    const [blogs, setBlogs] = useState([])
    const GetBlogs = async () => {
        const response = await GetCategoryBlogList(category)
        setBlogs(response?.data)
    }
    useEffect(() => {
        GetBlogs()
    }, [])

    const removeHTMLTags = (input) => input?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
        .trim();

    return (
        <div>
            <div className='john'>
                <div className='container '>
                    <div className='row my-5 mt-5'>
                        {blogs ?
                            blogs?.map((data, i) => {
                                return (
                                    <div
                                        key={i}
                                        className='col-lg-4 mt-5 pt-5 d-flex flex-column align-self-center'
                                    // data-aos="flip-left"
                                    // data-aos-delay="300"
                                    >
                                        <img src={dummy1} alt="dummy" className='w-100' />
                                        <div className=' '>
                                            <div className='row d-flex mt-3'>
                                                <div className=' col col-lg-6 d-flex'>
                                                    <img src={User} alt='usericon' className='lg ' />
                                                    <NavLink className='green' to={`/specificblog/${data._id}`}>
                                                        <p className='nunito sml ps-2'>{data?.author}</p>
                                                    </NavLink>
                                                </div>
                                                <div className='col-lg-6 d-flex '>
                                                    <img src={Calender} alt='calendericon' className='lg ' />
                                                    <p className='nunito sml ps-2'>
                                                        {moment(data?.publishedAt).format("MMM DD YYYY")}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='h5 grad-color mb-2'>{data.title}</p>
                                            </div>
                                            <div className='para47'>
                                                {removeHTMLTags(data?.content).split(' ').slice(0, 23).join(' ')}
                                                <span>
                                                    {removeHTMLTags(data?.content).length > 100 ? "..." : ""}
                                                </span>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-lg-3'>
                                                    <i className="fa-regular fa-clock me-1 green"></i>
                                                    {data?.minutes} min
                                                </div>
                                                <div className='col-lg-3 '>
                                                    <i className="fa-regular fa-message me-2 green"></i>
                                                    {data?.comments?.length}
                                                </div>
                                                <div className='col-lg-6'>
                                                    <NavLink
                                                        className='green'
                                                        to={`/specificblog/${data._id}/#blogcomment`}>
                                                        <i className="fa-regular fa-comment me-2 green"></i>
                                                        Add Comment
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='container text-center'>
                                No Author Blogs Found
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryBlogs