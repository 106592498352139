import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation, NumberValidation, LengthValidation } from "../../../Store/validate";
import {
  GetAdminAboutuspage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const AboutUSPageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms)
  const [validation, setValidation] = useState({});
  const [err, setErr] = useState({});
  useEffect(() => {
    GetData();
  }, []);
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };

  const [image, setImage] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(errid);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };


  console.log(createcms);
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target.files[0].name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
  };
  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      console.log([...dummy?.formData]);
      if (
        dummy?.image === "foundersMessageSectionImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.width === 528 &&
        // dummy?.height === 525
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              foundersMessageSectionImage: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        }
      } else if (
        dummy?.image === "logoSectionImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.width === 290 &&
        // dummy?.height === 290
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              logoSectionImage: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        }
      }
      else if (
        dummy?.image === "Icons0"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.whyUsSectionContent;
          const others = createcms?.pageStructure?.whyUsSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              whyUsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "Icons1"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.whyUsSectionContent;
          const others = createcms?.pageStructure?.whyUsSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              whyUsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "profile0"
        //  &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "profile1"
        //  &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "profile2"
        //  &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "profile3"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }

      else if (
        dummy?.image === "profile4"
        //  &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else if (
        dummy?.image === "profile5"
        //  &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 248 &&
        // dummy?.width === 248
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.teamDrivingSectionEmployees;
          const others = createcms?.pageStructure?.teamDrivingSectionEmployees[5];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[5] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              teamDrivingSectionEmployees: bannericons,
            },
          });
          successMsg(response?.message);
        }
      }
      else {
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminAboutuspage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.logoSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.logoSectionHeading
    );
    validate.bannerVideoUrl = NonEmptyValidation(
      createcms?.pageStructure?.bannerVideoUrl
    );
    validate.logoSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.logoSectionHeading
    );
    validate.logoSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.logoSectionSubHeading
    );
    validate.logoSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.logoSectionButtonName
    );
    validate.logoSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.logoSectionButtonRedirectLink
    );
    validate.foundersMessageSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.foundersMessageSectionHeading
    );
    validate.foundersMessageSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.foundersMessageSectionSubHeading
    );
    validate.foundersMessageSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.foundersMessageSectionButtonName
    );
    validate.foundersMessageSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.foundersMessageSectionButtonRedirectLink
    );
    validate.whyUsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.whyUsSectionHeading
    );
    if (Array.isArray(createcms?.pageStructure?.whyUsSectionContent)) {
      validate.whyUsSectionContent =
        createcms?.pageStructure?.whyUsSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
          content: NonEmptyValidation(d?.content),
          heading: NonEmptyValidation(d?.heading),
        }));
    }
    validate.teamDrivingSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.teamDrivingSectionHeading
    );
    validate.teamDrivingSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.teamDrivingSectionSubHeading
    );
    validate.teamDrivingSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.teamDrivingSectionContent
    );
    // if (Array.isArray(createcms?.pageStructure?.teamDrivingSectionEmployees)) {
    //   validate.teamDrivingSectionEmployees =
    //     createcms?.pageStructure?.teamDrivingSectionEmployees.map((d, i) => ({
    //       image: NonEmptyValidation(d?.image),
    //       displayOrder: NumberValidation(d?.displayOrder),
    //       designation: NonEmptyValidation(d?.designation),
    //       name: NonEmptyValidation(d?.name),
    //     }));
    // }
    // <====================================================================================================>

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.displayOrder.status &&
            menu.heading.status &&
            menu.content.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.name.status &&
            menu.designation.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.whyUsSectionContent);
    const isTrue2 = areAllStatusTrue2(validate.teamDrivingSectionEmployees);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    // Keys to filter out
    const keysToFilter = ["whyUsSectionContent", "teamDrivingSectionEmployees"]; // Add more keys as needed

    // Filter out specified keys from the object
    const remain = filterObjectKeys(validate, keysToFilter);

    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    About US Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>

            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row gx-5">
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Heading"
                              maxLength={50}
                              value={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "heading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.heading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.heading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 578 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Sub Heading"
                              maxLength={578}
                              value={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  578,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 26 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={26}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });

                                handleInputLengthValidation(
                                  e.target.value,
                                  26,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">Banner Video URL</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Banner Video URL"
                              value={createcms?.pageStructure?.bannerVideoUrl}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerVideoUrl: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerVideoUrl: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerVideoUrl?.message
                              ? `Banner Banner Video URL ${validation?.bannerVideoUrl?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <label className="labeltext">Banner Image</label>
                    <div>
                      <input
                        type="file"
                        className="fieldinputfilereport"
                        id="myfile"
                        onChange={(e) => UploadDocument(e, "bannerImage")}
                        accept="image/jpeg, image/png,image/webp"
                      />
                      {!createcms?.pageStructure?.bannerImage ? (
                        ""
                      ) : (
                        <span
                          className="namelink"
                          onClick={() => {
                            window.open(`${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`);
                          }}
                          target="_blank"
                        >
                          View
                        </span>
                      )}
                    </div>
                    <small className="validationerror">
                      {validation?.bannerImage?.message
                        ? `Banner Image ${validation?.bannerImage?.message}`
                        : ""}
                    </small>
                  </div> */}
                  {/* Logo section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.logoSectionHeading} Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Logo Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="aboutimg1"
                              onChange={(e) => UploadDocument(e, "logoSectionImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="aboutimg1"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.logoSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.logoSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB, 290px X 290px.
                          </small>
                          <small className="validationerror">
                            {validation?.logoSectionImage?.message
                              ? `Banner Image ${validation?.logoSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* logoSection Heading */}
                          <label className="labeltext">
                            Section Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter logoSection Heading"
                              maxLength={30}
                              value={
                                createcms?.pageStructure?.logoSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    logoSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "logoSectionHeading"
                                );

                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  logoSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.logoSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.logoSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.logoSectionHeading?.message
                                ? `logoSection Heading ${validation?.logoSectionHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* logoSection Sub Heading */}
                          <label className="labeltext">
                            Section Content
                            <span className="char-hint">{" "}(Maximum 600 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter logoSection Content"
                              maxLength={600}
                              value={
                                createcms?.pageStructure?.logoSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    logoSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  600,
                                  setErr,
                                  "logoSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  logoSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.logoSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.logoSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.logoSectionSubHeading?.message
                                ? `logoSection Sub Heading ${validation?.logoSectionSubHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* logoSection Button Name */}
                          <label className="labeltext">
                            Section Button Name
                            <span className="char-hint">{" "}(Maximum 26 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={26}
                              placeholder="Enter logoSection Button Name"
                              value={
                                createcms?.pageStructure?.logoSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    logoSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  26,
                                  setErr,
                                  "heading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  logoSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.heading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.heading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.logoSectionButtonName?.message
                                ? `logoSection Button Name ${validation?.logoSectionButtonName?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* logoSection Button Redirect Link */}
                          <label className="labeltext">
                            Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter logoSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.logoSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    logoSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  logoSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.logoSectionButtonRedirectLink?.message
                              ? `logoSection Button Redirect Link ${validation?.logoSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show logoSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.logoSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      logoSectionButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show logoSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.logoSectionSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      logoSectionSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.foundersMessageSectionHeading}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">

                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Founder Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="aboutimg2"
                              onChange={(e) => UploadDocument(e, "foundersMessageSectionImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="aboutimg2"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.foundersMessageSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.foundersMessageSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB, 528px X 525px.
                          </small>
                          <small className="validationerror">
                            {validation?.foundersMessageSectionImage?.message
                              ? `Founder Image ${validation?.foundersMessageSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <label className="labeltext">
                            Section Heading
                            <span className="char-hint">{" "}(Maximum 40 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter foundersMessageSection Heading"
                              maxLength={40}
                              value={
                                createcms?.pageStructure
                                  ?.foundersMessageSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    foundersMessageSectionHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  40,
                                  setErr,
                                  "foundersMessageSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  foundersMessageSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.foundersMessageSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.foundersMessageSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.foundersMessageSectionHeading?.message
                                ? `foundersMessageSection Heading ${validation?.foundersMessageSectionHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* foundersMessageSection Sub Heading */}
                          <label className="labeltext">
                            Section content
                            <span className="char-hint">{" "}(Maximum 600 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              maxLength={600}
                              placeholder="Enter foundersMessageSection Sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.foundersMessageSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    foundersMessageSectionSubHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  600,
                                  setErr,
                                  "foundersMessageSectionSubHeading"
                                );

                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  foundersMessageSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.foundersMessageSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.foundersMessageSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.foundersMessageSectionSubHeading
                                ?.message
                                ? `foundersMessageSection Sub Heading ${validation?.foundersMessageSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* foundersMessageSection Button Name */}
                          <label className="labeltext">
                            Section Button Name
                            <span className="char-hint">{" "}(Maximum 26 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter foundersMessageSection Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.foundersMessageSectionButtonName
                              }
                              maxLength={26}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    foundersMessageSectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  26,
                                  setErr,
                                  "foundersMessageSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  foundersMessageSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.foundersMessageSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.foundersMessageSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.foundersMessageSectionButtonName
                                ?.message
                                ? `foundersMessageSection Button Name ${validation?.foundersMessageSectionButtonName?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* foundersMessageSection Button Redirect Link */}
                          <label className="labeltext">
                            Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter foundersMessageSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.foundersMessageSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    foundersMessageSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  foundersMessageSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation
                              ?.foundersMessageSectionButtonRedirectLink
                              ?.message
                              ? `foundersMessageSection Button Redirect Link ${validation?.foundersMessageSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show foundersMessageSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.foundersMessageSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      foundersMessageSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show foundersMessageSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.foundersMessageSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      foundersMessageSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.whyUsSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              maxLength={30}
                              value={
                                createcms?.pageStructure?.whyUsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    whyUsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "whyUsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  whyUsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.whyUsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.whyUsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.whyUsSectionHeading?.message
                                ? `Heading ${validation?.whyUsSectionHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.whyUsSectionContent &&
                            createcms?.pageStructure?.whyUsSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`aboutimg7${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `Icons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`aboutimg7${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, 76px X 76px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.whyUsSectionContent
                                          ?.length > 0 &&
                                          validation?.whyUsSectionContent[i]
                                            ?.image?.message
                                          ? `Banner Icon ${validation?.whyUsSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        heading
                                        <span className="char-hint">{" "}(Maximum 25 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter heading"
                                          maxLength={25}
                                          value={item?.heading}
                                          // onChange={(e) => {
                                          //   const whyUsSectionContent =
                                          //     createcms?.pageStructure
                                          //       ?.whyUsSectionContent;
                                          //   const updatedObj = {
                                          //     ...item,
                                          //     heading: e.target.value,
                                          //   };
                                          //   whyUsSectionContent[i] = updatedObj;
                                          //   setcreatecms({
                                          //     ...createcms,
                                          //     pageStructure: {
                                          //       ...createcms.pageStructure,
                                          //       whyUsSectionContent:
                                          //         whyUsSectionContent,
                                          //     },
                                          //   });
                                          // }}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              25,
                                              "heading",
                                              "whyUsSectionContent",
                                              "whyUsSectionContenterr"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.whyUsSectionContent &&
                                              validation?.whyUsSectionContent[i]
                                                ?.heading;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.whyUsSectionContent
                                              ?.length > 0 &&
                                              (validation.whyUsSectionContent[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.whyUsSectionContenterr
                                        ?.length > 0 &&
                                        err?.errorstate?.whyUsSectionContenterr[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.whyUsSectionContenterr[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.whyUsSectionContent
                                            ?.length > 0 &&
                                            validation?.whyUsSectionContent[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.whyUsSectionContent[i]?.heading?.message}`
                                            : ""}
                                        </small>)}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 300 characters)</span>

                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={300}
                                          defaultValue={item?.content}
                                          // onChange={(e) => {
                                          //   const whyUsSectionContent =
                                          //     createcms?.pageStructure
                                          //       ?.whyUsSectionContent;
                                          //   const updatedObj = {
                                          //     ...item,
                                          //     content: e.target.value,
                                          //   };
                                          //   whyUsSectionContent[i] = updatedObj;
                                          //   setcreatecms({
                                          //     ...createcms,
                                          //     pageStructure: {
                                          //       ...createcms.pageStructure,
                                          //       whyUsSectionContent:
                                          //         whyUsSectionContent,
                                          //     },
                                          //   });
                                          // }}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              300,
                                              "content",
                                              "whyUsSectionContent",
                                              "whyUsSectionContenterr2"
                                            )
                                          }

                                          onBlur={(e) => {
                                            const f =
                                              validation?.whyUsSectionContent &&
                                              validation?.whyUsSectionContent[i]
                                                ?.content;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.whyUsSectionContent
                                              ?.length > 0 &&
                                              (validation.whyUsSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.whyUsSectionContenterr2
                                        ?.length > 0 &&
                                        err?.errorstate?.whyUsSectionContenterr2[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.whyUsSectionContenterr2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.whyUsSectionContent
                                            ?.length > 0 &&
                                            validation?.whyUsSectionContent[i]
                                              ?.content?.message
                                            ? `Content ${validation?.whyUsSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const whyUsSectionContent =
                                              createcms?.pageStructure
                                                ?.whyUsSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            whyUsSectionContent[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                whyUsSectionContent:
                                                  whyUsSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.whyUsSectionContent &&
                                              validation?.whyUsSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.whyUsSectionContent
                                              ?.length > 0 &&
                                              (validation.whyUsSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.whyUsSectionContent
                                          ?.length > 0 &&
                                          validation?.whyUsSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.whyUsSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show foundersMessageSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.whyUsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      whyUsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.teamDrivingSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 40 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              maxLength={40}
                              value={
                                createcms?.pageStructure
                                  ?.teamDrivingSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    teamDrivingSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  40,
                                  setErr,
                                  "teamDrivingSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  teamDrivingSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.teamDrivingSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.teamDrivingSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.teamDrivingSectionHeading?.message
                                ? `Heading ${validation?.teamDrivingSectionHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Heading */}
                          <label className="labeltext">Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 60 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Sub Heading"
                              maxLength={60}
                              value={
                                createcms?.pageStructure
                                  ?.teamDrivingSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    teamDrivingSectionSubHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  60,
                                  setErr,
                                  "teamDrivingSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  teamDrivingSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.teamDrivingSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.teamDrivingSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.teamDrivingSectionSubHeading?.message
                                ? `Heading ${validation?.teamDrivingSectionSubHeading?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Heading */}
                          <label className="labeltext">Content
                            <span className="char-hint">{" "}(Maximum 400 characters)</span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              maxLength={400}
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure
                                  ?.teamDrivingSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    teamDrivingSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  400,
                                  setErr,
                                  "teamDrivingSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  teamDrivingSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.teamDrivingSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.teamDrivingSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.teamDrivingSectionContent?.message
                                ? `content ${validation?.teamDrivingSectionContent?.message}`
                                : ""}
                            </small>)}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure
                            ?.teamDrivingSectionEmployees &&
                            createcms?.pageStructure?.teamDrivingSectionEmployees.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Image ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`teamdriving${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `profile${i}`
                                            )
                                          }

                                        />
                                        <label
                                          for={`teamdriving${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>
                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 400KB, 248px X 248px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.teamDrivingSectionEmployees
                                          ?.length > 0 &&
                                          validation?.teamDrivingSectionEmployees[
                                            i
                                          ]?.image?.message
                                          ? `Image ${validation?.teamDrivingSectionEmployees[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="labeltext">Name
                                        <span className="char-hint">{" "}(Maximum 30 characters)</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter name"
                                          maxLength={30}
                                          value={item?.name}
                                          // onChange={(e) => {
                                          //   const teamDrivingSectionEmployees =
                                          //     createcms?.pageStructure
                                          //       ?.teamDrivingSectionEmployees;
                                          //   const updatedObj = {
                                          //     ...item,
                                          //     name: e.target.value,
                                          //   };
                                          //   teamDrivingSectionEmployees[i] =
                                          //     updatedObj;
                                          //   setcreatecms({
                                          //     ...createcms,
                                          //     pageStructure: {
                                          //       ...createcms.pageStructure,
                                          //       teamDrivingSectionEmployees:
                                          //         teamDrivingSectionEmployees,
                                          //     },
                                          //   });
                                          // }}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              30,
                                              "name",
                                              "teamDrivingSectionEmployees",
                                              "teamDrivingSectionEmployeeserr"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.teamDrivingSectionEmployees &&
                                              validation
                                                ?.teamDrivingSectionEmployees[i]
                                                ?.name;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.teamDrivingSectionEmployees
                                              ?.length > 0 &&
                                              (validation.teamDrivingSectionEmployees[
                                                i
                                              ].name = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.teamDrivingSectionEmployeeserr
                                        ?.length > 0 &&
                                        err?.errorstate?.teamDrivingSectionEmployeeserr[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.teamDrivingSectionEmployeeserr[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.teamDrivingSectionEmployees
                                            ?.length > 0 &&
                                            validation?.teamDrivingSectionEmployees[
                                              i
                                            ]?.name?.message
                                            ? `Content ${validation?.teamDrivingSectionEmployees[i]?.name?.message}`
                                            : ""}
                                        </small>)}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button designation */}
                                      <label className="labeltext">
                                        Designation
                                        <span className="char-hint">{" "}(Maximum 400 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter designation"
                                          maxLength={40}
                                          value={item?.designation}
                                          // onChange={(e) => {
                                          //   const teamDrivingSectionEmployees =
                                          //     createcms?.pageStructure
                                          //       ?.teamDrivingSectionEmployees;
                                          //   const updatedObj = {
                                          //     ...item,
                                          //     designation: e.target.value,
                                          //   };
                                          //   teamDrivingSectionEmployees[i] =
                                          //     updatedObj;
                                          //   setcreatecms({
                                          //     ...createcms,
                                          //     pageStructure: {
                                          //       ...createcms.pageStructure,
                                          //       teamDrivingSectionEmployees:
                                          //         teamDrivingSectionEmployees,
                                          //     },
                                          //   });
                                          // }}

                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              40,
                                              "designation",
                                              "teamDrivingSectionEmployees",
                                              "teamDrivingSectionEmployees3"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.teamDrivingSectionEmployees &&
                                              validation
                                                ?.teamDrivingSectionEmployees[i]
                                                ?.designation;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.teamDrivingSectionEmployees
                                              ?.length > 0 &&
                                              (validation.teamDrivingSectionEmployees[
                                                i
                                              ].designation = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.teamDrivingSectionEmployees3
                                        ?.length > 0 &&
                                        err?.errorstate?.teamDrivingSectionEmployees3[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.teamDrivingSectionEmployees3[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.teamDrivingSectionEmployees
                                            ?.length > 0 &&
                                            validation?.teamDrivingSectionEmployees[
                                              i
                                            ]?.designation?.message
                                            ? `Content ${validation?.teamDrivingSectionEmployees[i]?.designation?.message}`
                                            : ""}
                                        </small>)}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const teamDrivingSectionEmployees =
                                              createcms?.pageStructure
                                                ?.teamDrivingSectionEmployees;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            teamDrivingSectionEmployees[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                teamDrivingSectionEmployees:
                                                  teamDrivingSectionEmployees,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.teamDrivingSectionEmployees &&
                                              validation
                                                ?.teamDrivingSectionEmployees[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.teamDrivingSectionEmployees
                                              ?.length > 0 &&
                                              (validation.teamDrivingSectionEmployees[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.teamDrivingSectionEmployees
                                          ?.length > 0 &&
                                          validation?.teamDrivingSectionEmployees[
                                            i
                                          ]?.displayOrder?.message
                                          ? `Display Order ${validation?.teamDrivingSectionEmployees[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show foundersMessageSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.teamDrivingSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      teamDrivingSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-------------------------------------------------------------------------------------> */}
                  {/* <|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||> */}
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUSPageManagement;
