import React, { useState, useEffect } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation } from "../../../Store/validate";
import { UpdateFaq, GetAdminSpecificFaq } from "../../../Repository/Api";
import { useSelector } from "react-redux";
//import modules forEditor
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

//forEditor
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const FaqUpdate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  const [createFaq, setcreateFaq] = useState({});
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setcreateFaq({ ...createFaq, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  useEffect(() => {
    Getfaq();
  }, []);

  const Getfaq = async () => {
    const response = await GetAdminSpecificFaq(id, token);
    if (response && response?.statusCode === 200) {
      let dummy = response?.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.answer) {
        let blocksFromHtml = htmlToDraft(dummy.answer);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.answer = cmsDescription;
      setcreateFaq(dummy);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = {
      question: NonEmptyValidation(createFaq?.question),
      answer: NonEmptyValidation(
        createFaq?.answer?.getCurrentContent()?.getPlainText().trim()
      ),
    };

    setValidation(validate);
    let isNotEmpty =
      createFaq?.answer && createFaq?.answer.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(createFaq?.answer.getCurrentContent())
      );
      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          _id: id,
          question: createFaq.question,
          answer: html,
        };
        const response = await UpdateFaq(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg("Successfully FAQ Updated");
          setTimeout(() => {
            setTimeout(navigate("/admin/FaqList"));
          }, 1000);
        } else {
          errorMsg(response.message);
        }
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/FaqList");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">FAQ List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Update FAQ
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="labeltext">Question *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Question"
                            value={createFaq?.question}
                            onChange={(e) => {
                              setcreateFaq({
                                ...createFaq,
                                question: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.question?.message
                            ? `Question ${validation?.question?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Answer *</label>
                        {/* forEditor */}
                        <div className="fieldinput">
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={(e) => setData(e, "answer")}
                            editorState={createFaq?.answer}
                            toolbar={{
                              options: ["list"],
                              list: { options: ["unordered"] },
                            }}
                          />
                        </div>

                        <small className="validationerror">
                          {validation?.answer?.message
                            ? `Answer ${validation?.answer?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="row my-4">
                        <div className="col-lg-3 centertext ms-auto mx-auto">
                          <button className="buttonmodel" type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqUpdate;
