import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Arrow1 from "../../Assets/Arrow1.webp";
import Vector1 from "../../Assets/Vector1.webp";
import dot from "../../Assets/dot.webp";
import img2 from "../../Assets/big-user.webp";
import logo from "../../Assets/logo.webp";
import logo1 from "../../Assets/logo1.webp";
import platformvector from "../../Assets/platformvector.webp";
import { GetPlatformPage, MetaUserSpecificView } from "../../Repository/Api";
import { cloudFrontUrl } from "../../commonUtils/Utils";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
const Platform = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const { testimonialdata } = useSelector((state) => state?.testimonialdata);
  const contact = contactCardData;
  const testimonial = testimonialdata;
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  const [cms, setCms] = useState({});
  const getTrainingcms = async () => {
    const response = await GetPlatformPage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };

  const [meta, setMeta] = useState();
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("platform");
    if (response && response.statusCode === 200) {
      setMeta(response?.data);
    }
  };

  useEffect(() => {
    getTrainingcms();
    GetMeta();
  }, []);

  const ban = cms?.bannerIcons?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const solution = cms?.rescalelabSolutions?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  const feature = cms?.rescalelabfeaturesSectionFeatures?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const finalfeature = cms?.featuresSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const [imageError, setImageError] = useState(false);
  const handleImage = () => {
    setImageError(true);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
                    }}
                />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header />
        </div>
      )}
      <div className={`${cms?.headerShown === true ? "h-top" : "mt-0"} nunito`}>
        <div className="plat mount">
          <div className="container">
            <div className="row ">
              <div
                className="back col-md-5 col-12"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p className="h1">{cms?.bannerHeading}</p>
                <p className="para1 textarea">{cms?.bannerSubHeading}</p>
                {cms?.bannerButtonShown && (
                  <NavLink to={cms?.bannerButtonRedirectLink}>
                    <button
                      className="btn btn-outline-success me-5"
                      type="submit"
                    >
                      {cms?.bannerButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
              <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-center girl-img">
                <img
                  src={`${cloudFrontUrl}${cms?.bannerImage}`}
                  alt="homeimg"
                  className="Girl2 w-100"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />

                <img
                  src={`${cloudFrontUrl}${ban && ban[0]?.image}`}
                  alt="homeimg"
                  className={`' ${imageError ? "d-none" : "p-img1"}`}
                  data-aos="flip-left"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[1]?.image}`}
                  alt="homeimg"
                  className={`p-img2 ${imageError && "d-none"}`}
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[2]?.image}`}
                  alt="homeimg"
                  className={`p-img3 ${imageError && "d-none"}`}
                  data-aos="flip-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[3]?.image}`}
                  alt="homeimg"
                  className={`p-img4 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[4]?.image}`}
                  alt="homeimg"
                  className={`p-img5 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
                <img
                  src={`${cloudFrontUrl}${ban && ban[5]?.image}`}
                  alt="homeimg"
                  className={`p-img6 ${imageError && "d-none"}`}
                  data-aos="flip-down"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
          <div className="mount-white col-12"></div>
        </div>
        {cms?.rescalelabSolutionsSection && (
          <div className="list">
            <div className="container">
              <div className="row gy-4">
                <div
                  className="col-2 col-lg-2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${solution && solution[0]?.image}`}
                    className="img1"
                    alt="platformimg1"
                  />
                  <p className="title">{solution && solution[0]?.content}</p>
                  <Link
                    className="learn"
                    aria-current="page"
                    to={solution && solution[0]?.buttonRedirectPath}
                  >
                    {solution && solution[0]?.buttonName}
                    <img src={Arrow1} className="ms-2 arrow" alt="Arrow1" />
                  </Link>
                </div>

                <img
                  src={platformvector}
                  className="vector1 d-none d-md-block"
                  alt="platfromvector"
                />

                <div
                  className="col-2 col-lg-2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${solution && solution[1]?.image}`}
                    className="img1"
                    alt="platformimg2"
                  />
                  <p className="title">{solution && solution[1]?.content}</p>
                  <Link
                    className="learn"
                    aria-current="page"
                    to={solution && solution[1]?.buttonRedirectPath}
                  >
                    {solution && solution[1]?.buttonName}
                    <img src={Arrow1} className="ms-2 arrow" alt="Arrow1" />
                  </Link>
                </div>
                <img
                  src={platformvector}
                  className="vector1 d-none d-md-block"
                  alt="platfromvector"
                />
                <div
                  className="col-2 col-lg-2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${solution && solution[2]?.image}`}
                    className="img1"
                    alt="platformimg3"
                  />
                  <p className="title">{solution && solution[2]?.content}</p>
                  <Link
                    className="learn"
                    aria-current="page"
                    to={solution && solution[2]?.buttonRedirectPath}
                  >
                    {solution && solution[2]?.buttonName}
                    <img src={Arrow1} className="ms-2 arrow" alt="Arrow1" />
                  </Link>
                </div>
                <img
                  src={platformvector}
                  className="vector1 d-none d-md-block"
                  alt="platfromvector"
                />
                <div
                  className="col-2 col-lg-2"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${solution && solution[3]?.image}`}
                    className="img1"
                    alt="platformimg4"
                  />
                  <p className="title">{solution && solution[3]?.content}</p>
                  <Link
                    className="learn"
                    aria-current="page"
                    to={solution && solution[3]?.buttonRedirectPath}
                  >
                    {solution && solution[3]?.buttonName}
                    <img src={Arrow1} className="ms-2 arrow" alt="Arrow1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.decisionsSectionShown && (
          <div className="banner">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-8"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p className="subtitle">{cms?.decisionsSectionHeading}</p>
                  <p className="title">{cms?.decisionsSectionSubHeading}</p>
                  <p className="para10 textarea">{cms?.decisionsContent}</p>
                  {cms?.decisionsButtonShown && (
                    <NavLink to={cms?.decisionsButtonRedirectPath}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.decisionsButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className="col-lg-6 col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="decision-image">
                    <img
                      src={`${cloudFrontUrl}${cms?.decisionsSectionImage}`}
                      className="img2 w-100"
                      alt="platformimg5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.rescalelabfeaturesSectionShown && (
          <div className="features">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 col-lg-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <p className="subtitle">
                    {cms?.rescalelabfeaturesSectionHeading}
                  </p>
                  <p className="title">
                    {cms?.rescalelabfeaturesSectionSubHeading}
                  </p>
                </div>
                <div
                  className="col-6 col-lg-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${feature && feature[0]?.image}`}
                    className="img3"
                    alt="platformimg6"
                  />
                  <p className="banner-text">
                    {feature && feature[0]?.heading}
                  </p>
                  <p className="para11 textarea">
                    {feature && feature[0]?.content}
                  </p>
                </div>
                <div
                  className="col-6 col-lg-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${feature && feature[1]?.image}`}
                    className="img3"
                    alt="platformimg7"
                  />
                  <p className="banner-text">
                    {feature && feature[1]?.heading}
                  </p>
                  <p className="para11 textarea">
                    {feature && feature[1]?.content}
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${feature && feature[2]?.image}`}
                    className="img3"
                    alt="platformimg8"
                  />
                  <p className="banner-text">
                    {feature && feature[2]?.heading}
                  </p>
                  <p className="para11 textarea">
                    {feature && feature[2]?.content}
                  </p>
                </div>
                <div
                  className="col col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${feature && feature[3]?.image}`}
                    className="img3"
                    alt="platformimg9"
                  />
                  <p className="banner-text">
                    {feature && feature[3]?.heading}
                  </p>
                  <p className="para11 textarea">
                    {feature && feature[3]?.content}
                  </p>
                </div>
                <div
                  className="col-6 col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${feature && feature[4]?.image}`}
                    className="img3"
                    alt="platformimg10"
                  />
                  <p className="banner-text">
                    {feature && feature[4]?.heading}
                  </p>
                  <p className="para11 textarea">
                    {feature && feature[4]?.content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.platformSectionShown && (
          <div className="cloud">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="decision-image">
                    <img
                      src={`${cloudFrontUrl}${cms?.platformSectionImage}`}
                      className="img4 w-100"
                      alt="platformimg11"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-8"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <p className="subtitle">{cms?.platformSectionHeading}</p>
                  <p className="title">{cms?.platformSectionSubHeading}</p>
                  <p className="para11 textarea">
                    {cms?.platformSectionContent}
                  </p>
                  {cms?.platformSectionButtonShown && (
                    <NavLink to={cms?.platformSectionButtonRedirectPath}>
                      <button
                        className="btn btn-outline-success me-5"
                        type="submit"
                      >
                        {cms?.platformSectionButtonName}
                      </button>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.featuresSectionShown && (
          <div className="target">
            <div className="container">
              <p className="title" data-aos="fade-up" data-aos-delay="300">
                {cms?.featuresSectionHeading}
              </p>
              <div className="row g-2">
                <img src={logo} className="logo d-lg-block" alt="logo" />
                <div
                  className="card col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${finalfeature && finalfeature[0]?.image
                      }`}
                    className="img5"
                    alt="platformimg12"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {finalfeature && finalfeature[0]?.heading}
                    </p>
                    <p className="para12 textarea">
                      {finalfeature && finalfeature[0]?.content}
                    </p>
                  </div>
                </div>
                <div
                  className="card col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${finalfeature && finalfeature[1]?.image
                      }`}
                    className="img5"
                    alt="platformimg12"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {finalfeature && finalfeature[1]?.heading}
                    </p>
                    <p className="para12 textarea">
                      {finalfeature && finalfeature[1]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div
                  className="card col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${finalfeature && finalfeature[2]?.image
                      }`}
                    className="img5"
                    alt="platformimg12"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {finalfeature && finalfeature[2]?.heading}
                    </p>
                    <p className="para12 textarea">
                      {finalfeature && finalfeature[2]?.content}
                    </p>
                  </div>
                </div>
                <div
                  className="card col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src={`${cloudFrontUrl}${finalfeature && finalfeature[3]?.image
                      }`}
                    className="img5"
                    alt="platformimg12"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {finalfeature && finalfeature[3]?.heading}
                    </p>
                    <p className="para12 textarea">
                      {finalfeature && finalfeature[3]?.content}
                    </p>
                  </div>
                </div>
              </div>
              <img src={logo1} className="logo1" alt="logo" />
            </div>
          </div>
        )}
        {cms?.testimonialSectionShown &&
          <div className='trainer'>
            <div className='testimonial-content'>
              <div className='container'>
                <div className='row'>
                  <div className='col col-md-3'>
                    <p className='h6'>{cms?.testimonialSectionHeading}</p>
                  </div>
                  <div className='col1  col-md-9'>
                    <div className="py-3 main-owl">
                      {testimonial && testimonial.length > 0 && (
                        <OwlCarousel
                          className="owl-theme"
                          loop
                          autoplay={false}
                          items="1"
                          dots={false}
                          nav={true}
                          smartSpeed={2000}
                          margin={10}
                          autoplayTimeout={5000}
                          responsive={{
                            360:
                            {
                              items: "1",
                            },
                            414:
                            {
                              items: "1",
                            },
                            670:
                            {
                              items: "1",
                            },
                            992:
                            {
                              items: "2",
                            },

                            1200:
                            {
                              items: "2",
                            },
                          }}
                        >
                          {testimonial?.map((item, i) => {
                            return (
                              <div key={i} style={{ height: "300px" }}>
                                <div>
                                  <img
                                    src={`${cloudFrontUrl}${item?.profileImage}`} className='logo10'
                                    alt='img2'
                                    onError={(e) => {
                                      e.target.src = img2
                                      e.target.onError = null
                                    }}
                                  />
                                </div>
                                <div className='card'>
                                  <img src={Vector1} className='logo11' alt='Vector1' />
                                  <p className='card-title'>{item.name}</p>
                                  <p className='card-text'>{item.title},{item.role}</p>
                                  <p className='para3'>{item.content}</p>
                                </div>
                              </div>
                            )
                          })}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <img src={`${cloudFrontUrl}${cms?.testimonialSectionBackgroundImage}`} alt="background image" className='dummy-bg' />
              <div className='dummy-bg1'></div>
            </div>
          </div>
        }
        {/* {cms?.testimonialSectionShown && (
          <div className="trainer">
            <div className="container">
              <div className="row">
                <div className="col col-md-3">
                  <p className="h6">{cms?.testimonialSectionHeading}</p>
                </div>
                <div className="col1  col-md-9">
                  <div className="py-3 main-owl">
                    {testimonial && testimonial.length > 0 && (
                      <OwlCarousel
                        className="owl-theme"
                        loop
                        autoplay={true}
                        items="1"
                        dots={false}
                        smartSpeed={2000}
                        margin={10}
                        autoplayTimeout={5000}
                        responsive={{
                          360: {
                            items: "1",
                          },
                          414: {
                            items: "1",
                          },
                          670: {
                            items: "1",
                          },
                          992: {
                            items: "2",
                          },

                          1200: {
                            items: "2",
                          },
                        }}
                      >
                        {testimonial?.map((item, i) => {
                          return (
                            <div key={i} style={{ height: "300px" }}>
                              <img
                                src={`${cloudFrontUrl}${item?.profileImage}`}
                                className="logo10"
                                alt="img2"
                                onError={(e) => {
                                  e.target.src = img2;
                                  e.target.onError = null;
                                }}
                              />
                              <div className="card">
                                <img
                                  src={Vector1}
                                  className="logo11"
                                  alt="Vector1"
                                />
                                <p className="card-title">{item.name}</p>
                                <p className="card-text">
                                  {item.title},{item.role}
                                </p>
                                <p className="para3 textarea">{item.content}</p>
                              </div>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img
              src={`${cloudFrontUrl}${cms?.testimonialSectionBackgroundImage}`}
              alt="background image"
              className="dummy-bg"
            />
            <div className="dummy-bg1"></div>
          </div>
        )} */}
        <div className="img">
          <img src={dot} className="logo30" alt="dot" />
        </div>
        {cms?.contactUsCardsShown && (
          <div className="contact nunito">
            <div className="container d-md-block d-none">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <p className="h1"> {contact?.heading}</p>
                  <p>{contact?.subHeading}</p>
                  {contact?.buttonShown && (
                    <NavLink to={contact?.buttonRedirectPath}>
                      <button className="btn-a1">
                        {contact?.buttonHeading}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div className="col-lg-6">
                  <img
                    src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {cms?.contactUsCardsShown && (
          <div className="container mt-4 d-block d-md-none">
            <div
              data-aos="zoom-in"
              data-aos-delay="300"
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 text-center">{contact?.heading}</p>
              <p className="text"> {contact?.subHeading}</p>
              <img
                src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                className="w-100"
              />
              {contact?.buttonShown && (
                <NavLink to={contact?.buttonRedirectPath}>
                  <button className="btn-a1">{contact?.buttonHeading}</button>
                </NavLink>
              )}
            </div>
          </div>
        )}
        <div className="img" data-aos="fade-up" data-aos-delay="300">
          <img src={dot} className="logo13" alt="dot" />
        </div>
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Platform;
