import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { GetAdminSubscriptionList } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import CustomModal from "../../Modal/CustomModal";
import { DeleteSubscription, UpdateSubscription } from "../../../Repository/Api";
import { successMsg, errorMsg } from "../../Toastify";
const PricingList = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state)
  const token = state?.auth?.auth?.accessToken;
  const [subcription, setSubscription] = useState([])

  const [openModal, setOpenModal] = useState(false)
  const [Id, setDelId] = useState()

  useEffect(() => {
    GetSubscription()
  }, [])

  const GetSubscription = async () => {
    const response = await GetAdminSubscriptionList(token)
    if (response && response.statusCode === 200) {
      setSubscription(response?.data)
    }
  }

  const DeleteIcon = async () => {
    const payload = {
      _id: Id
    }
    const response = await DeleteSubscription(token, payload)
    if (response && response.statusCode === 200) {
      setOpenModal(false)
      successMsg(response?.message)
      GetSubscription()
    }

  }

  const handleStatus = async (payload) => {
    const response = await UpdateSubscription(payload, token)
    if (response && response.statusCode === 200) {
      GetSubscription()
      successMsg("Status Updated Successfully")
    } else {
      errorMsg(response?.message)
    }
  }

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "subscriptionsPlans",
      label: "Pricing Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div>
      //         <div
      //           className="namelink"
      //           onClick={() =>
      //             navigate(`/PricingSpecificview/${tableMeta.rowData[0]}`)
      //           }
      //         >
      //           {value}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    },
    {
      name: "cost",
      label: "Price"
    },
    {
      name: "batch",
      label: "Batch"
    },
    {
      name: "status",
      label: "Status"
    },
    {
      name: "licence",
      label: "License"
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4] === "active" ? true : false
          return (
            <div className="d-flex">
              <span className="material-symbols-outlined me-3" 
                 style={{ color: 'red' }} 
                 onClick={() => {
                setOpenModal(true)
                setDelId(tableMeta.rowData[0])
              }
              }>
                delete
              </span>
              <span className="material-symbols-outlined"
              style={{ color: 'green' }}
              
                onClick={() =>
                  navigate(`/PricingSpecificview/${tableMeta.rowData[0]}`)
                }
              >
                edit_square
              </span>
              <span className="ms-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={status}
                    onChange={async (e) => {
                      const status = e.target.checked === true ? "active" : "inactive"
                      const payload = {
                        _id: tableMeta.rowData[0],
                        status: status
                      }
                      handleStatus(payload)
                    }}
                  /><span className="slider round"></span>
                </label>
              </span>
            </div>
          )
        }
      }
    }
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Subscription List</h6>
              </div>
              <div className="col-lg-6">
                <div className="headerfloat">
                  <button
                    className="buttonmodel"
                    onClick={() => {
                      navigate("/PricingCreate");
                    }}
                  >
                    Add Subscription
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <MUIDataTable
                title={"Subscription List"}
                data={subcription}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
        }}
      >
        <div className="delete-modal nunito">
          <span  className="icon-container" onClick={() => setOpenModal(false)}>
            <i className="fa-solid fa-circle-xmark"></i>
          </span>
          <p className="h1 mb-4">Confirmation</p>
          <p className="h3">Are you sure you want to delete this?</p>
          <div className="mt-4 d-flex justify-content-around" >
            <button className="btn-cancel h-auto me-3" onClick={() => setOpenModal(false)}>Cancel</button>
            <button className="btn btn-primary h-auto" onClick={DeleteIcon}>Delete</button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default PricingList;
