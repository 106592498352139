import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Footer from '../Components/Footer';
import { GetPricingdata, Getusersidecoupon, MetaUserSpecificView } from '../Repository/Api';
import { cloudFrontUrl } from '../commonUtils/Utils';
import Header from './Header';
import { Helmet } from 'react-helmet';
import { errorMsg } from './Toastify';

const Pricingpage = () => {
    const { contactCardData } = useSelector((state) => state?.contactCarddata)
    const state = useSelector((state) => state);
    const token = state?.auth?.auth?.accessToken;
    const contact = contactCardData
    const { title } = useSelector(state => state?.masterdata?.masterdata)

    const [active, setActive] = useState(true)
    const [activebatch, setActivebatch] = useState(false)
    const [pricing, setPricing] = useState({})
    console.log(pricing)
    const [radio, setRadio] = useState("week")
    const [id, setId] = useState()
    const [total, setTotal] = useState({
        NumOfCohort: 0,
        numOfParticipants: 0,
        totalamount: 0,
        appliedCoupen: 0,
        finalamount: 0,
    })
    console.log(total.finalamount.toFixed(2))

    const [coupens, setcoupens] = useState([]);
    console.log(coupens)
    const getAdminlist = async () => {
        const response = await Getusersidecoupon(token);
        console.log(response, "oiuytryu")
        if (response && response.statusCode === 200) {
            setcoupens(response?.data);
            console.log(coupens, "coupens")
        } else {
            errorMsg(response.message);
        }
    };

    useEffect(() => {
        getAdminlist();
    }, [token]);
    const [coupen, setCoupen] = useState("")

    const [offer, setoffer] = useState(0)


    const [inputs, setInputs] = useState([{
        participants: "",
        week: "",
        amount: ""
    }])

    console.log(inputs)

    const [coupenError, setCoupenError] = useState("")
    const handlecheckout = () => {
        const x = coupen
        function isCoupunAvailable(x) {
            return coupens.some(a => a.code === x);
        }
        if (isCoupunAvailable(x)) {
            const percentage = coupens.find(a => a.code === x)?.percentage
            setoffer(percentage)
            setCoupenError("")
        } else {
            setCoupenError("*discount coupon is invalid.")
        }
    }

    const perweekconst = pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[0]?.price
    const permonthconst = pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[1]?.price
    const percohertconst = pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[2]?.price

    const cost = (activebatch === true ? percohertconst : (radio === "week" ? perweekconst : permonthconst))

    const handleInputChange = (e, index) => {
        // const cost = (radio === "week" ? 2.5 : 10)
        const { name, value } = e.target;
        const newValue = value === '' ? '' : Number(value);
        setInputs(prev => {
            const list = [...prev]
            list[index] = {
                ...list[index],
                [name]: newValue
                // [name]: parseInt(value || 0)
            }
            if (activebatch) {
                list[index].amount = list[index].week * cost

            } else {
                list[index].amount = list[index].participants * list[index].week * cost

            }
            return list
        })
    };
    const anyValueGreaterThanZero = inputs.some(obj => obj.amount > 0 || obj.participants > 0);
    useEffect(() => {
        const NumOfCohort = inputs?.filter((a) => a?.amount > 0)?.length
        const numOfParticipants = inputs?.reduce((total, item) => total + item.participants, 0);
        const totalamount = inputs?.reduce((total, item) => total + item.amount, 0)

        const appliedCoupen = (totalamount + (pricing?.extraFeeLabelName1 ? Number(pricing.extraFee1) : 0) + (pricing?.extraFeeLabelName2 ? Number(pricing.extraFee2) : 0)) * offer / 100
        const finalamount = totalamount - appliedCoupen + (anyValueGreaterThanZero && (pricing?.extraFeeLabelName1 ? Number(pricing.extraFee1) : 0) + (pricing?.extraFeeLabelName2 ? Number(pricing.extraFee2) : 0));
        console.log(pricing?.extraFeeLabelName1 && Number(pricing?.extraFee1))
        console.log(pricing?.extraFeeLabelName2 && Number(pricing?.extraFee2))
        setTotal({
            NumOfCohort: NumOfCohort,
            numOfParticipants: numOfParticipants,
            totalamount: totalamount,
            appliedCoupen: appliedCoupen,
            finalamount: finalamount
        })
    }, [inputs, offer])

    const handleDelete = (i) => {
        const array = inputs.filter((_, index) => index !== i);
        setInputs(array)
    }
    const handleAddClick = () => {
        setCoupen("")
        setInputs([...inputs, {
            participants: "",
            week: "",
            amount: ""
        }]);
    };
    const handleChange = (e) => {
        setRadio(e.target.value)
        setInputs([{
            participants: "",
            week: "",
            amount: ""
        }])
        setCoupen("")
        setoffer(0)
    }
    const handleclick = () => {
        setActive(true)
        setActivebatch(false)
        setoffer(0)
        setInputs([{
            participants: "",
            week: "",
            amount: ""
        }])
        setCoupen("")
    }
    const handleclickbatch = () => {
        setActive(false)
        setActivebatch(true)
        setoffer(0)
        setInputs([{
            participants: "",
            week: "",
            amount: ""
        }])
        setCoupen("")
    }
    // const refresh = () => {
    //     setInputs([{
    //         participants: "",
    //         week: "",
    //         amount: ""
    //     }])
    //     setCoupen("")
    // }
    const [meta, setMeta] = useState()
    const GetMeta = async () => {
        const response = await MetaUserSpecificView("pricing")
        if (response && response.statusCode === 200) {
            setMeta(response?.data)
        }
    }
    useEffect(() => {
        GetPricing()
        GetMeta()

        if (!pricing?.perUserSectionShown) {
            setActive(false)
            setActivebatch(true)
        }
        if (!pricing?.perCohortSectionShown) {
            setActivebatch(false)
            setActive(true)
        }

    }, [])

    const GetPricing = async () => {
        const response = await GetPricingdata()
        if (response && response.statusCode === 200) {
            setPricing(response?.data?.pageStructure)
        }
    }

    const getOrdinalSuffix = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${meta?.title} - ${title}`}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={meta?.description} />
                <meta name="keyword" content={meta?.keyword} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
                    }}
                />
            </Helmet>
            {pricing?.headerShown &&
                <div>
                    <Header />
                </div>
            }
            <div className={`${pricing?.headerShown === true ? "h-top2" : "mt-5"}  pb-5 nunito`}>
                <div className='container'>
                    <NavLink to={pricing?.bannerImageRedirectPath}>
                        <div
                            className='d-flex justify-content-center mb-5'
                        >
                            <img src={`${cloudFrontUrl}${pricing?.bannerImage}`} alt="bannerimage" className='pricingpagebanner' />
                        </div>
                    </NavLink>
                    <div className='d-flex justify-content-center'>
                        <div className='btn-grp'>
                            {pricing?.perUserSectionShown &&
                                <button
                                    onClick={handleclick}
                                    className={`${active ? "btn-price" : "btn-price-2"} ${pricing.perCohortSectionShown === false ? "" : "me-5"}`}
                                >
                                    {pricing?.perUserSectionTabName}
                                </button>
                            }
                            {pricing?.perCohortSectionShown &&
                                <button
                                    onClick={handleclickbatch}
                                    className={`${activebatch ? "btn-price" : "btn-price-2"}`}
                                >
                                    {pricing?.perCohortSectionTabName}
                                </button>
                            }
                        </div>
                    </div>
                </div>

                {active === true &&
                    <div>
                        <div className='container mt-5'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-8 d-flex justify-content-around pricing-user'>
                                    <label class={`radiolable ${radio === "week" && "radio-on"}`}>
                                        <input
                                            type="radio"
                                            checked={radio === "week"}
                                            value="week"
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span class="checkmark"></span>
                                        ${pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[0]?.price} - {pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[0]?.durationName}
                                    </label>
                                    <label class={`radiolable ${radio === "month" && "radio-on"}`}>
                                        <input
                                            type="radio"
                                            value="month"
                                            checked={radio === "month"}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span class="checkmark"></span>
                                        ${pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[1]?.price} - {pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[1]?.durationName}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='pricingdescription container nunito mt-4 d-flex justify-content-center'>
                            <p className=''>
                                {active === true ? pricing?.pricingperuserdescription : pricing?.pricingpercohortdescription}
                            </p>
                        </div>
                        <div className='container mt-5'>
                            <div className='row gx-5'>
                                <div className='col-lg-7'>
                                    <div className='cohort-list'>
                                        {inputs?.map((item, i) => {
                                            return (
                                                <div key={i} className='wcardsec-2 mb-3'>
                                                    <div className='wcardsec-1 d-flex justify-content-between'>
                                                        <div className='cohert'>
                                                            {getOrdinalSuffix(i + 1)} {pricing?.priceCalculationSectionBatchName}
                                                        </div>
                                                        <div className='ref-btn' >
                                                            {i !== 0 ?
                                                                <span
                                                                    class="material-symbols-outlined"
                                                                    onClick={() => handleDelete(i)}
                                                                >
                                                                    delete
                                                                </span> : ""
                                                                // <span
                                                                //     onClick={refresh}
                                                                //     class="material-symbols-outlined">
                                                                //     refresh
                                                                // </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='d-flex pricing-form mt-4'>
                                                        <div className='d-flex flex-column'>
                                                            <label>{pricing?.priceCalculationSectionParticipant}</label>
                                                            <input
                                                                type='number'
                                                                onKeyDown={(e) => {
                                                                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                min='0'
                                                                name="participants"
                                                                value={item?.participants}
                                                                onChange={(e) => handleInputChange(e, i)}

                                                            />
                                                        </div>
                                                        <div>
                                                            <span class="material-symbols">
                                                                X
                                                            </span>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <label>{radio === "week" ?
                                                                pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[0]?.duration
                                                                :
                                                                pricing?.perUserPriceDetails && pricing?.perUserPriceDetails[1]?.duration

                                                            }</label>
                                                            <input
                                                                type='number'
                                                                name="week"
                                                                min="0"
                                                                value={item?.week}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span class="material-symbols1">
                                                                =
                                                            </span>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <label>{pricing?.priceCalculationSectionAmount}</label>
                                                            <input
                                                                value={item && item.amount > 0 ? '$' + item.amount.toLocaleString() : ''}
                                                                disabled
                                                            />
                                                            {/* <p>{item?.amount}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className=''>
                                        <button className='cohert-btn' onClick={handleAddClick}>{pricing?.priceCalculationSectionAddNewButtonName}</button>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='total-price py-4'>
                                        <p className='h5 px-5'>Pricing Details</p>
                                        <table className='table px-5'>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionCohortName}</td>
                                                <td>- {total?.NumOfCohort}</td>
                                            </tr>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionParticipantsName}</td>
                                                <td>- {total?.numOfParticipants}</td>
                                            </tr>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionPricingName}</td>
                                                <td>- ${total?.totalamount.toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionAppliedCouponName}</td>
                                                <td> -${total?.appliedCoupen}</td>
                                            </tr>
                                            {
                                                pricing?.extraFeeLabelName1 &&
                                                <tr>
                                                    <td>{pricing?.extraFeeLabelName1}</td>
                                                    <td> -${pricing?.extraFee1}</td>
                                                </tr>
                                            }
                                            {
                                                pricing?.extraFeeLabelName2 &&
                                                <tr>
                                                    <td>{pricing?.extraFeeLabelName2}</td>
                                                    <td> -${pricing?.extraFee2}</td>
                                                </tr>
                                            }
                                        </table>
                                        <p className='total-green-bg px-5 h5'>
                                            <table className='table'>
                                                <tr>
                                                    <td className='total-gbg'>{pricing?.priceDetailsSectionTotalAmountName}</td>
                                                    <td>$ {total.finalamount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                </tr>
                                            </table>
                                        </p>
                                        <div className='d-flex flex-column px-5 apply-padding'>
                                            <div>
                                                <label>{pricing?.priceDetailsSectionCouponSectionHeading}</label>
                                            </div>
                                            <div className='coupen-input'>
                                                <input
                                                    type='text'
                                                    name='coupen'
                                                    value={coupen}
                                                    onChange={(e) => setCoupen(e.target.value)}
                                                    onBlur={(e) => {
                                                        if (e.target.value.length === 0) {
                                                            setoffer(0)
                                                        }
                                                    }}
                                                />
                                                <button onClick={handlecheckout}>
                                                    {pricing?.priceDetailsSectionCouponSectionButtonName}
                                                </button>
                                            </div>
                                            <p className='coupenError'> {coupenError ? coupenError : ""}</p>
                                        </div>
                                        <div className='px-5 mt-3 '>
                                            <NavLink to={pricing?.priceDetailsSectionButtonRedirectLing}>
                                                <button className='checkout-btn'>
                                                    {pricing?.priceDetailsSectionButtonName}
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activebatch === true &&
                    <div>
                        <div className='container mt-5'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-8 d-flex justify-content-around '>
                                    <label class={`radiolable "radio-on"}`}>
                                        <input
                                            type="radio"
                                            checked
                                        />
                                        <span class="checkmark"></span>
                                        ${pricing?.perUserPriceDetails[2]?.price}- {pricing?.perUserPriceDetails[2]?.durationName}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='pricingdescription container nunito mt-4 d-flex justify-content-center'>
                            <p className=''>
                                {active === true ? pricing?.pricingperuserdescription : pricing?.pricingpercohortdescription}
                            </p>
                        </div>
                        <div className='container mt-5'>
                            <div className='row gx-5'>
                                <div className='col-lg-7'>
                                    <div className='cohort-list'>
                                        {inputs?.map((item, i) => {
                                            return (
                                                <div key={i} className='wcardsec-2 mb-3'>
                                                    <div className='wcardsec-1 d-flex justify-content-between'>
                                                        <div className='cohert'>
                                                            {getOrdinalSuffix(i + 1)} {pricing?.priceCalculationSectionBatchName}
                                                        </div>
                                                        <div className='ref-btn' >
                                                            {i !== 0 ?
                                                                <span
                                                                    class="material-symbols-outlined"
                                                                    onClick={() => handleDelete(i)}
                                                                >
                                                                    delete
                                                                </span> : ""
                                                                // <span
                                                                //     onClick={refresh}
                                                                //     class="material-symbols-outlined">
                                                                //     refresh
                                                                // </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='d-flex pricing-form mt-4'>
                                                        <div className='d-flex flex-column'>
                                                            <label>{pricing?.perUserPriceDetails[2]?.duration}</label>
                                                            <input
                                                                type='number'
                                                                name="week"
                                                                min="0"
                                                                value={item?.week}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span class="material-symbols1">
                                                                =
                                                            </span>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <label>{pricing?.priceCalculationSectionAmount}</label>
                                                            <input
                                                                value={item && item.amount > 0 ? '$' + item.amount.toLocaleString() : ''}
                                                                disabled
                                                            />
                                                            {/* <p>{item?.amount}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className=''>
                                        <button className='cohert-btn' onClick={handleAddClick}>{pricing?.priceCalculationSectionAddNewButtonName}</button>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='total-price py-4'>
                                        <p className='h5 px-5'>Pricing Details</p>
                                        <table className='table px-5'>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionCohortName}</td>
                                                <td>- {total?.NumOfCohort}</td>
                                            </tr>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionTotalAmountName}</td>
                                                <td>- ${formatNumberWithCommas(total?.totalamount)}</td>
                                            </tr>
                                            <tr>
                                                <td>{pricing?.priceDetailsSectionAppliedCouponName}</td>
                                                <td> -${total?.appliedCoupen}</td>
                                            </tr>
                                            {
                                                pricing?.extraFeeLabelName1 &&
                                                <tr>
                                                    <td>{pricing?.extraFeeLabelName1}</td>
                                                    <td> -${pricing?.extraFee1}</td>
                                                </tr>
                                            }
                                            {
                                                pricing?.extraFeeLabelName2 &&
                                                <tr>
                                                    <td>{pricing?.extraFeeLabelName2}</td>
                                                    <td> -${pricing?.extraFee2}</td>
                                                </tr>
                                            }
                                        </table>
                                        <p className='total-green-bg px-5 h5'>
                                            <table className='table'>
                                                <tr>
                                                    <td className='total-gbg'>{pricing?.priceDetailsSectionTotalAmountName}</td>
                                                    <td>$ {total.finalamount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            </table>
                                        </p>
                                        <div className='d-flex flex-column px-5 apply-padding'>
                                            <div>
                                                <label>{pricing?.priceDetailsSectionCouponSectionHeading}</label>
                                            </div>
                                            <div className='coupen-input'>
                                                <input
                                                    type='text'
                                                    name='coupen'
                                                    value={coupen}
                                                    onChange={(e) => setCoupen(e.target.value)}
                                                />
                                                <button onClick={handlecheckout}>{pricing?.priceDetailsSectionCouponSectionButtonName}</button>
                                            </div>
                                        </div>
                                        <div className='px-5 mt-3 '>
                                            <NavLink to={pricing?.priceDetailsSectionButtonRedirectLing}>
                                                <button className='checkout-btn'>
                                                    {pricing?.priceDetailsSectionButtonName}
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='contact-us-pricing'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 d-flex align-items-center'>
                            <div>
                                <div className='contact-price-content'>
                                    <h3 className='mb-3'>{pricing?.contactUsCardHeading}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: pricing?.contactUsCardContent }} />
                                    {pricing?.contactUsCardButtonShown &&
                                        <NavLink to={pricing?.contactUsCardButtonRedirectPath}>
                                            <button className='btn-a1 mt-3'>
                                                {pricing?.contactUsCardButtonHeading}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img src={`${cloudFrontUrl}${pricing?.contactUsCardBackgroudImage}`} className='w-100' />
                        </div>
                    </div>
                </div>
            </div>
            {pricing?.footerShown &&
                <div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Pricingpage