import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = { isLoggedIn: true };
  return user && user.isLoggedIn;
};
const PrivateRoutes = () => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/adminlogin" />;
};

export default PrivateRoutes;
