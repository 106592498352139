import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdminTrainingServicespage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const TrainingServicespageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms);
  const [validation, setValidation] = useState({});

  useEffect(() => {
    GetData();
  }, []);

  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  console.log(err);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(key);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage"
        //  &&
        // dummy?.size <= 220
        // &&
        // dummy?.height === 484 &&
        // dummy?.width === 417
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 140 &&
        // dummy?.width <= 106
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 140 &&
        // dummy?.width <= 106
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 140 &&
        // dummy?.width <= 106
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons3"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 140 &&
        // dummy?.width <= 106
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "innovationImage"
        // &&
        // dummy?.size <= 120
        // &&
        // dummy?.height <= 384 &&
        // dummy?.width <= 558
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationImage: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons0"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons1"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons2"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons3"
        // &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons4"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons5"
        // &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[5];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[5] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons6"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[6];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[6] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureIcons7"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 45 &&
        // dummy?.width <= 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.innovationFeatures;
          const others = createcms?.pageStructure?.innovationFeatures[7];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[7] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              innovationFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "supcardimg1"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              supportingInstitutionsSectionCard1Icon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "supcardimg2"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              supportingInstitutionsSectionCard2Icon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features20"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features21"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features22"
        //  &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features23"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features24"
        // &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features25"
        // &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[5];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[5] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features26"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[6];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[6] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features27"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[7];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[7] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "features28"
        //  &&
        // dummy?.size <= 10
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[8];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[8] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "feature60"
        // &&
        // dummy?.size <= 200
        // &&
        // dummy?.height <= 550 &&
        // dummy?.width <= 660
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.features;
          const others = createcms?.pageStructure?.features[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              features: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "feature61"
        // &&
        // dummy?.size <= 200
        // &&
        // dummy?.height <= 550 &&
        // dummy?.width <= 660
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.features;
          const others = createcms?.pageStructure?.features[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              features: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "feature62"
        // &&
        // dummy?.size <= 200
        // &&
        // dummy?.height <= 550 &&
        // dummy?.width <= 660
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.features;
          const others = createcms?.pageStructure?.features[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              features: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "feature63"
        // &&
        // dummy?.size <= 200
        // &&
        // dummy?.height <= 550 &&
        // dummy?.width <= 660
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.features;
          const others = createcms?.pageStructure?.features[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              features: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "feature64"
        // &&
        // dummy?.size <= 200
        // &&
        // dummy?.height <= 550 &&
        // dummy?.width <= 660
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.features;
          const others = createcms?.pageStructure?.features[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              features: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialImage"
        // &&
        // dummy?.size <= 500
        // &&
        // dummy?.width <= 700 &&
        // dummy?.height <= 600
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminTrainingServicespage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.contactUsSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.contactUsSectionButtonName
    );
    validate.contactUsSectionButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.contactUsSectionButtonRedirectPath
    );
    validate.contactUsSectionHeadin = NonEmptyValidation(
      createcms?.pageStructure?.contactUsSectionHeadin
    );
    if (Array.isArray(createcms?.pageStructure?.features)) {
      validate.features = createcms?.pageStructure?.features.map((d, i) => ({
        buttonName: NonEmptyValidation(d?.buttonName),
        buttonRedirectPath: NonEmptyValidation(d?.buttonRedirectPath),
        content: NonEmptyValidation(d?.content),
        displayOrder: NumberValidation(d?.displayOrder),
        heading: NonEmptyValidation(d?.heading),
        image: NonEmptyValidation(d?.image),
      }));
    }
    validate.featuresSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionButtonName
    );
    if (Array.isArray(createcms?.pageStructure?.featuresSectionFeatures)) {
      validate.featuresSectionFeatures =
        createcms?.pageStructure?.featuresSectionFeatures.map((d, i) => ({
          content: NonEmptyValidation(d?.content),
          heading: NonEmptyValidation(d?.heading),
          image: NonEmptyValidation(d?.image),
        }));
    }
    validate.featuresSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionHeading
    );
    validate.innovationButtonName = NonEmptyValidation(
      createcms?.pageStructure?.innovationButtonName
    );
    validate.innovationButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.innovationButtonRedirectLink
    );
    validate.innovationContent = NonEmptyValidation(
      createcms?.pageStructure?.innovationContent
    );
    if (Array.isArray(createcms?.pageStructure?.innovationFeatures)) {
      validate.innovationFeatures =
        createcms?.pageStructure?.innovationFeatures.map((d, i) => ({
          content: NonEmptyValidation(d?.content),
          image: NonEmptyValidation(d?.image),
        }));
    }
    validate.innovationHeading = NonEmptyValidation(
      createcms?.pageStructure?.innovationHeading
    );
    validate.innovationImage = NonEmptyValidation(
      createcms?.pageStructure?.innovationImage
    );
    validate.innovationSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.innovationSubHeading
    );
    validate.supportingInstitutionsSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionButtonName
    );
    validate.supportingInstitutionsSectionButtonRedirectPath =
      NonEmptyValidation(
        createcms?.pageStructure
          ?.supportingInstitutionsSectionButtonRedirectPath
      );
    validate.supportingInstitutionsSectionCard1Content = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionCard1Content
    );
    validate.supportingInstitutionsSectionCard1Icon = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionCard1Icon
    );
    validate.supportingInstitutionsSectionCard2Content = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionCard2Content
    );
    validate.supportingInstitutionsSectionCard2Icon = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionCard2Icon
    );
    validate.supportingInstitutionsSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionContent
    );
    validate.supportingInstitutionsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionHeading
    );
    validate.supportingInstitutionsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.supportingInstitutionsSectionHeading
    );

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.buttonName.status &&
            menu.buttonRedirectPath.status &&
            menu.content.status &&
            menu.displayOrder.status &&
            menu.heading.status &&
            menu.image.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.heading?.status &&
            menu?.image?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue4(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.content.status && menu.image.status)) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.features);
    const isTrue3 = areAllStatusTrue3(validate.featuresSectionFeatures);
    const isTrue4 = areAllStatusTrue4(validate.innovationFeatures);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "features",
      "featuresSectionFeatures",
      "innovationFeatures",
    ];

    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue3 && isTrue4 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Training Services Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>

            <div className="contentbox ">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading

                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Banner Heading"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 200 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Banner Sub Heading"
                              maxLength={200}
                              value={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  200,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="trainingservicebanner"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                            />
                            <label
                              for="trainingservicebanner"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 220KB, 417px X 484px.
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div>
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`trainingservicebannericon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`trainingservicebannericon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 106px x
                                        140px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.image
                                            ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.bannerIcons &&
                                              validation?.bannerIcons[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.innovationHeading}`} Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Innovation Image */}
                          <label className="labeltext">
                            {" "}
                            {`${createcms?.pageStructure?.innovationHeading}`}{" "}
                            Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="trainigserviceinnavationimg"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) =>
                                UploadDocument(e, "innovationImage")
                              }
                            />
                            <label
                              for="trainigserviceinnavationimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.innovationImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.innovationImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 120KB, 558px X 384px
                          </small>
                          <small className="validationerror">
                            {validation?.innovationImage?.message
                              ? `Image ${validation?.innovationImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 15 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              maxLength={15}
                              value={
                                createcms?.pageStructure?.innovationHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    innovationHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  15,
                                  setErr,
                                  "innovationHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  innovationHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.innovationHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.innovationHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.innovationHeading?.message
                                ? `Heading ${validation?.innovationHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 15 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure?.innovationSubHeading
                              }
                              maxLength={15}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    innovationSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  15,
                                  setErr,
                                  "innovationSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  innovationSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.innovationSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.innovationSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.innovationSubHeading?.message
                                ? `sub Heading ${validation?.innovationSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* content */}
                          <label className="labeltext">Section Content
                            <span className="char-hint">{" "}(Maximum 430 characters)</span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={430}
                              value={
                                createcms?.pageStructure?.innovationContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    innovationContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  430,
                                  setErr,
                                  "innovationContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  innovationContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.innovationContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.innovationContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.innovationContent?.message
                                ? `Content ${validation?.innovationContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={25}
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure?.innovationButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    innovationButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "innovationButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  innovationButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.innovationButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.innovationButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.innovationButtonName?.message
                                ? `Button name ${validation?.innovationButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure
                                  ?.innovationButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    innovationButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  innovationButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.innovationButtonRedirectLink?.message
                              ? `Button Redirect Link ${validation?.innovationButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.innovationButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      innovationButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.innovationHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.innovationSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      innovationSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Features Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div>
                          {createcms?.pageStructure?.innovationFeatures &&
                            createcms?.pageStructure?.innovationFeatures.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`features image ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`trainingserviceinovation${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `featureIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`trainingserviceinovation${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 10KB, Max 45px X 45px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.innovationFeatures
                                          ?.length > 0 &&
                                          validation?.innovationFeatures[i]?.image
                                            ?.message
                                          ? `Image ${validation?.innovationFeatures[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Features Content
                                        <span className="char-hint">{" "}(Maximum 40 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          maxLength={40}
                                          placeholder="Enter Features Content"
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              40,
                                              "content",
                                              "innovationFeatures",
                                              "innovationFeatures1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.innovationFeatures &&
                                              validation?.innovationFeatures[i]
                                                ?.content;
                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.innovationFeatures
                                              ?.length > 0 &&
                                              (validation.innovationFeatures[
                                                i
                                              ].content = v);
                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.innovationFeatures1
                                        ?.length > 0 &&
                                        err?.errorstate?.innovationFeatures1[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.innovationFeatures1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.innovationFeatures
                                            ?.length > 0 &&
                                            validation?.innovationFeatures[i]
                                              ?.content?.message
                                            ? `Content ${validation?.innovationFeatures[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          <div className="col-lg-6 align-self-center">
                            {" "}
                            {/*Show  section */}
                            <div className="d-flex justify-content-between bx-box">
                              <label className="labeltext">
                                Show Features Section
                              </label>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    createcms?.pageStructure
                                      ?.innovationFeaturesShown
                                  }
                                  onChange={(e) => {
                                    setcreatecms({
                                      ...createcms,
                                      pageStructure: {
                                        ...createcms.pageStructure,
                                        innovationFeaturesShown:
                                          e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Contact Us Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Content
                            <span className="char-hint">{" "}(Maximum 250 characters)</span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={250}
                              value={
                                createcms?.pageStructure?.contactUsSectionHeadin
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsSectionHeadin: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  250,
                                  setErr,
                                  "contactUsSectionHeadin"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  contactUsSectionHeadin: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.contactUsSectionHeadin?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.contactUsSectionHeadin?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.contactUsSectionHeadin?.message
                                ? `Heading ${validation?.contactUsSectionHeadin?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Button Name</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.contactUsSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "contactUsSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  contactUsSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.contactUsSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.contactUsSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.contactUsSectionButtonName?.message
                                ? `Button Name ${validation?.contactUsSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure
                                  ?.contactUsSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  contactUsSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.contactUsSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.contactUsSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.contactUsSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      contactUsSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Contact Us Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.contactUsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      contactUsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {
                        createcms?.pageStructure
                          ?.supportingInstitutionsSectionHeading
                      }
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Section Heading</label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={30}
                              value={
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    supportingInstitutionsSectionHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "supportingInstitutionsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  supportingInstitutionsSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.supportingInstitutionsSectionHeading
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.supportingInstitutionsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.supportingInstitutionsSectionHeading
                                ?.message
                                ? `Heading ${validation?.supportingInstitutionsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Section Content</label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={300}
                              value={
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    supportingInstitutionsSectionContent:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "supportingInstitutionsSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  supportingInstitutionsSectionContent:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.supportingInstitutionsSectionContent
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.supportingInstitutionsSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.supportingInstitutionsSectionContent
                                ?.message
                                ? `Content ${validation?.supportingInstitutionsSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    supportingInstitutionsSectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "supportingInstitutionsSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  supportingInstitutionsSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.supportingInstitutionsSectionButtonName
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.supportingInstitutionsSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation
                                ?.supportingInstitutionsSectionButtonName
                                ?.message
                                ? `Button Name ${validation?.supportingInstitutionsSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Section Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    supportingInstitutionsSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  supportingInstitutionsSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation
                              ?.supportingInstitutionsSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.supportingInstitutionsSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.supportingInstitutionsSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      supportingInstitutionsSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <div className="col-lg-12">
                            {" "}
                            {/* Button link */}
                            <label className="labeltext">
                              {
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionHeading
                              }{" "}
                              card 1
                              <span className="char-hint">{" "}(Maximum 230 characters)</span>

                            </label>
                            <div>
                              <textarea
                                type="text"
                                className="fieldinput w-100"
                                placeholder="Enter Content"
                                maxLength={230}
                                value={
                                  createcms?.pageStructure
                                    ?.supportingInstitutionsSectionCard1Content
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      supportingInstitutionsSectionCard1Content:
                                        e.target.value,
                                    },
                                  });
                                  handleInputLengthValidation(
                                    e.target.value,
                                    230,
                                    setErr,
                                    "supportingInstitutionsSectionCard1Content"
                                  );
                                }}
                                onBlur={(e) => {
                                  setValidation({
                                    ...validation,
                                    supportingInstitutionsSectionCard1Content:
                                      NonEmptyValidation(e.target.value),
                                  });
                                }}
                              />
                            </div>
                            {err?.supportingInstitutionsSectionCard1Content
                              ?.message ? (
                              <small className="validationerror length-err">
                                <span class="material-symbols-outlined len-err-img">
                                  warning
                                </span>
                                {`${err?.supportingInstitutionsSectionCard1Content?.message}`}
                              </small>
                            ) : (
                              <small className="validationerror">
                                {validation
                                  ?.supportingInstitutionsSectionCard1Content
                                  ?.message
                                  ? `Content ${validation?.supportingInstitutionsSectionCard1Content?.message}`
                                  : ""}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">
                            {
                              createcms?.pageStructure
                                ?.supportingInstitutionsSectionHeading
                            }{" "}
                            Image 1
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="trainsubcardimg"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "supcardimg1")}
                            />
                            <label
                              for="trainsubcardimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.supportingInstitutionsSectionCard1Icon ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.supportingInstitutionsSectionCard1Icon}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 10KB, 76px X 76px.
                          </small>
                          <small className="validationerror">
                            {validation?.supportingInstitutionsSectionCard1Icon
                              ?.message
                              ? `Image ${validation?.supportingInstitutionsSectionCard1Icon?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            {
                              createcms?.pageStructure
                                ?.supportingInstitutionsSectionHeading
                            }{" "}
                            card 2
                            <span className="char-hint">{" "}(Maximum 230 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Content"
                              maxLength={230}
                              value={
                                createcms?.pageStructure
                                  ?.supportingInstitutionsSectionCard2Content
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    supportingInstitutionsSectionCard2Content:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  230,
                                  setErr,
                                  "supportingInstitutionsSectionCard2Content"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  supportingInstitutionsSectionCard2Content:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.supportingInstitutionsSectionCard2Content
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.supportingInstitutionsSectionCard2Content?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation
                                ?.supportingInstitutionsSectionCard2Content
                                ?.message
                                ? `Content ${validation?.supportingInstitutionsSectionCard2Content?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">
                            {
                              createcms?.pageStructure
                                ?.supportingInstitutionsSectionHeading
                            }{" "}
                            Image 2
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="trainingsupcardimg2"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "supcardimg2")}
                            />
                            <label
                              for="trainingsupcardimg2"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.supportingInstitutionsSectionCard2Icon ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.supportingInstitutionsSectionCard2Icon}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 10KB, 76px X 76px.
                          </small>
                          <small className="validationerror">
                            {validation?.supportingInstitutionsSectionCard2Icon
                              ?.message
                              ? `Image ${validation?.supportingInstitutionsSectionCard2Icon?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.supportingInstitutionsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      supportingInstitutionsSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.featuresSectionHeading}
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Heading
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Heading"
                              maxLength={25}
                              value={createcms?.pageStructure?.featuresSectionHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "featuresSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionHeading?.message
                                ? `Heading ${validation?.featuresSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div>
                          {createcms?.pageStructure?.featuresSectionFeatures &&
                            createcms?.pageStructure?.featuresSectionFeatures.map(
                              (item, i) => {
                                return (
                                  <div className="row feature-card-bottom">
                                    <h5 className="feature-card-heading-admin">
                                      Features Card {i + 1}
                                    </h5>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`features image ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`trainingfeature1icon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `features2${i}`)
                                          }
                                        />
                                        <label
                                          for={`trainingfeature1icon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 10KB, 76px X 76px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.featuresSectionFeatures
                                          ?.length > 0 &&
                                          validation?.featuresSectionFeatures[i]
                                            ?.image?.message
                                          ? `Image ${validation?.featuresSectionFeatures[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Features Heading
                                        <span className="char-hint">{" "}(Maximum 40 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Features Content"
                                          maxLength={40}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              40,
                                              "heading",
                                              "featuresSectionFeatures",
                                              "featuresSectionFeatures2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionFeatures &&
                                              validation
                                                ?.featuresSectionFeatures[i]
                                                ?.heading;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionFeatures
                                              ?.length > 0 &&
                                              (validation.featuresSectionFeatures[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionFeatures2
                                        ?.length > 0 &&
                                        err?.errorstate?.featuresSectionFeatures2[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionFeatures2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionFeatures
                                            ?.length > 0 &&
                                            validation?.featuresSectionFeatures[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.featuresSectionFeatures[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Features Content
                                        <span className="char-hint">{" "}(Maximum 430 characters)</span>

                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          maxLength={430}
                                          placeholder="Enter Features Content"
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              430,
                                              "content",
                                              "featuresSectionFeatures",
                                              "featuresSectionFeatures3"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionFeatures &&
                                              validation
                                                ?.featuresSectionFeatures[i]
                                                ?.content;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionFeatures
                                              ?.length > 0 &&
                                              (validation.featuresSectionFeatures[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionFeatures3
                                        ?.length > 0 &&
                                        err?.errorstate?.featuresSectionFeatures3[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionFeatures3[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionFeatures
                                            ?.length > 0 &&
                                            validation?.featuresSectionFeatures[i]
                                              ?.content?.message
                                            ? `Content ${validation?.featuresSectionFeatures[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "featuresSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionButtonName?.message
                                ? `Button Name ${validation?.featuresSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.featuresSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.featuresSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Features
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div>
                          {createcms?.pageStructure?.features &&
                            createcms?.pageStructure?.features.map(
                              (item, i) => {
                                return (
                                  <div className="row pb-3 feature-card-bottom">
                                    <p className="h5 feature-card-heading-admin">
                                      Features Sub Section {`${i + 1}`}
                                    </p>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`image ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`trainingserviceimg90${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `feature6${i}`)
                                          }
                                        />
                                        <label
                                          for={`trainingserviceimg90${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 200KB, max 660px X
                                        550px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.features?.length > 0 &&
                                          validation?.features[i]?.image?.message
                                          ? `Image ${validation?.features[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content        <span className="char-hint">{" "}(Maximum 30 characters)</span>
                                        Heading
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Features Content"
                                          maxLength={30}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              30,
                                              "heading",
                                              "features",
                                              "features7"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.features &&
                                              validation?.features[i]?.heading;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.features?.length > 0 &&
                                              (validation.features[i].heading =
                                                v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.features7?.length > 0 &&
                                        err?.errorstate?.features7[i]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.features7[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.features?.length > 0 &&
                                            validation?.features[i]?.heading
                                              ?.message
                                            ? `Heading ${validation?.features[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content        <span className="char-hint">{" "}(Maximum 600 characters)</span>
                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={600}
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              600,
                                              "content",
                                              "features",
                                              "features8"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.features &&
                                              validation?.features[i]?.content;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.features?.length > 0 &&
                                              (validation.features[i].content =
                                                v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.features8?.length > 0 &&
                                        err?.errorstate?.features8[i]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.features8[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.features?.length > 0 &&
                                            validation?.features[i]?.content
                                              ?.message
                                            ? `Content ${validation?.features[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>

                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        <span className="char-hint">{" "}(Maximum 25 characters)</span>
                                        Button Name
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          value={item?.buttonName}
                                          maxLength={25}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              25,
                                              "buttonName",
                                              "features",
                                              "features9"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.features &&
                                              validation?.features[i]
                                                ?.buttonName;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.features?.length > 0 &&
                                              (validation.features[
                                                i
                                              ].buttonName = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.features9?.length > 0 &&
                                        err?.errorstate?.features9[i]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.features9[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.features?.length > 0 &&
                                            validation?.features[i]?.buttonName
                                              ?.message
                                            ? `Button Name ${validation?.features[i]?.buttonName?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Button Redirect Path
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Button redirect path"
                                          value={item?.buttonRedirectPath}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.features;
                                            const updatedObj = {
                                              ...item,
                                              buttonRedirectPath:
                                                e.target.value,
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                features: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.features &&
                                              validation?.features[i]
                                                ?.buttonRedirectPath;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.features?.length > 0 &&
                                              (validation.features[
                                                i
                                              ].buttonRedirectPath = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.features?.length > 0 &&
                                          validation?.features[i]
                                            ?.buttonRedirectPath?.message
                                          ? `Button Redirect Path ${validation?.features[i]?.buttonRedirectPath?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.features;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                features: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.features &&
                                              validation?.features[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.features?.length > 0 &&
                                              (validation.features[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.features?.length > 0 &&
                                          validation?.features[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.features[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                      {" "}
                                      {/*Show  Button */}
                                      <div className="d-flex justify-content-between bx-box">
                                        <label className="labeltext">
                                          Show Button
                                        </label>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={item?.buttonShown}
                                            onChange={(e) => {
                                              const bannericons =
                                                createcms?.pageStructure
                                                  ?.features;
                                              const updatedObj = {
                                                ...item,
                                                buttonShown: e.target.checked,
                                              };
                                              bannericons[i] = updatedObj;
                                              setcreatecms({
                                                ...createcms,
                                                pageStructure: {
                                                  ...createcms.pageStructure,
                                                  features: bannericons,
                                                },
                                              });
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.featuresShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Testimonial Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Image */}
                        <label className="labeltext">
                          Testimonial Background Image
                        </label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="traintestimonialimg2a"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(e, "testimonialImage")
                            }
                          />
                          <label
                            for="traintestimonialimg2a"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createcms?.pageStructure?.testimonialImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createcms?.pageStructure?.testimonialImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 500KB,Max 700px X 600px.
                        </small>
                        <small className="validationerror">
                          {validation?.bannerImage?.message
                            ? `Image ${validation?.testimonialImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Heading */}
                        <label className="labeltext">Testimonial Heading
                          <span className="char-hint">{" "}(Maximum 35 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Testimonial Heading"
                            value={createcms?.pageStructure?.testimonialHeading}
                            maxLength={35}
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  testimonialHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                35,
                                setErr,
                                "testimonialHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                testimonialHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.testimonialHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.testimonialHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.testimonialHeading?.message
                              ? `Testimonial Heading ${validation?.testimonialHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Testimonial Section
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.testimonialSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    testimonialSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingServicespageManagement;
