import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import MUIDataTable from "mui-datatables";
import AdminHeader from "../Adminheader";
import { DeleteContact, GetContactUsList } from "../../../Repository/Api";
import CustomModal from "../../../Containers/CustomModal";
const Contactrequestlist = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    getContactList();
  }, []);

  const [contactList, setContactList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [Id, setDelId] = useState();

  const DeleteIcon = async () => {
    const payload = {
      _id: Id,
    };
    const response = await DeleteContact(token, payload);
    if (response && response?.statusCode === 200) {
      setOpenModal(false);
      successMsg(response?.message);
      getContactList();
    }
  };
  const getContactList = async () => {
    const response = await GetContactUsList(token);

    if (response && response.statusCode === 200) {
      setContactList(response?.data);
    } else {
      errorMsg(response.message);
    }
  };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "read",
      label: "Status",
      options: {
        display: false,
      },
    },

    {
      name: "firstName",
      label: "Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div
      //         className="namelink"
      //         onClick={() =>
      //           navigate(`/ContactrequestSpecificview/${tableMeta.rowData[0]}`)
      //         }
      //       >
      //         {value}
      //       </div>
      //     );
      //   },
      // },
    },
    { name: "emailId", label: "Email" },
    { name: "companyName", label: "Company Name" },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <span
                className="material-symbols-outlined me-3"
                style={{ color: "red" }}
                onClick={() => {
                  setOpenModal(true);
                  setDelId(tableMeta.rowData[0]);
                }}
              >
                delete
              </span>
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }} // Change color to green
                onClick={() =>
                  navigate(
                    `/admin/ContactrequestSpecificview/${tableMeta.rowData[0]}`
                  )
                }
              >
                Visibility
              </span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[1] === false) {
        return {
          style: { background: " #d6f1de", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Contact Us List</h6>
              </div>
            </div>
            <div className="mt-5">
              <MUIDataTable
                title={"Contact Request List"}
                data={contactList}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
        }}
      >
        <div className="delete-modal nunito">
          <span className="icon-container" onClick={() => setOpenModal(false)}>
            <i className="fa-solid fa-circle-xmark"></i>
          </span>
          <p className="h1 mb-4">Confirmation</p>
          <p className="h3">Are you sure you want to delete this?</p>
          <div className="mt-4 d-flex justify-content-around">
            <button
              className="btn-cancel h-auto me-3"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary h-auto" onClick={DeleteIcon}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Contactrequestlist;
