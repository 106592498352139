import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Trainingproviderscontent from '../Components/Trainingproviderscontent'


const Trainingproviders = () => {
  return (
    <div>
        {/* <Header/> */}
        <Trainingproviderscontent/>
        {/* <Footer/> */}
    </div>
  )
}

export default Trainingproviders