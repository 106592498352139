import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Forcorporationcontent from '../Components/Forcorporationcontent'

const Forcorporation = () => {
  return (
    <div>
      {/* <Header solution={true}/> */}
      <Forcorporationcontent />
      {/* <Footer /> */}
    </div>
  )
}

export default Forcorporation