import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import {
  GetMetaList,
  UpdateMetatag,
  MetaSpecificView,
} from "../../../Repository/Api";
import { LengthValidation, NonEmptyValidation } from "../../../Store/validate";

const MetatagSpecificview = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    getlist();
  }, []);

  const [metaList, setMetaList] = useState([]);
  console.log(metaList);
  const [validation, setValidation] = useState({});

  const setData = (e, key, len) => {
    setMetaList({ ...metaList, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
    console.log({ e, len });
    if (e.length === len) {
      setValidation({
        ...validation,
        [key]: LengthValidation(e, len),
      });
      setTimeout(() => {
        setValidation({
          ...validation,
          [key]: false,
        });
      }, 3000);
    }
  };

  const getlist = async () => {
    const response = await MetaSpecificView(id, token);
    if (response && response.statusCode === 200) {
      let dummy = response.data;
      setMetaList(dummy);
    } else {
      errorMsg(response.message);
    }
  };

  const hadleMapInputChange = (e, i, key) => {
    const updatedarray = {
      ...metaList[i],
      [key]: e.target.value,
    };
    metaList[i] = updatedarray;
    setMetaList({
      ...metaList,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.title = NonEmptyValidation(metaList?.title);
    validate.keyword = NonEmptyValidation(metaList?.keyword);
    validate.description = NonEmptyValidation(metaList?.description);

    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        title: metaList.title,
        keyword: metaList.keyword,
        description: metaList.description,
        pageName: metaList.pageName,
      };
      const response = await UpdateMetatag(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setMetaList({});
        setTimeout(() => {
          setTimeout(navigate("/admin/MetatagList"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="contentbox mt-2">
              <div className="mt-1">
                <h3 className="grad-color">Update Meta Tag</h3>
              </div>
              <form onSubmit={(e) => handleUpdate(e)}>
                <div className="row mt-5">
                  {/* {metaList.length > 0 && metaList?.map((metaList, i) => {
                    return ( */}
                  <div className="row">
                    <h2>{metaList.pageName}</h2>
                    <div className="col-lg-6">
                      <label className="labeltext">
                        Title *
                        <span className="char-hint">
                          {" "}
                          (Maximum 60 characters)
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          className="fieldinput"
                          placeholder="Enter Title"
                          maxLength={60}
                          defaultValue={metaList?.title}
                          // onChange={(e) => hadleMapInputChange(e, i, "title")}
                          onChange={(e) => {
                            setData(e?.target?.value, "title", 60);
                          }}
                        />
                      </div>
                      <small className="validationerror">
                        {validation?.title?.message
                          ? `Title ${validation?.title?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="col-lg-6">
                      <label className="labeltext">
                        Key Word *
                        <span className="char-hint">
                          {" "}
                          (Maximum 160 characters)
                        </span>
                      </label>
                      <div>
                        <textarea
                          type="text"
                          className="fieldinput"
                          placeholder="Enter Keyword"
                          maxLength={160}
                          defaultValue={metaList?.keyword}
                          // onChange={(e) => hadleMapInputChange(e, i, "keyword")}
                          onChange={(e) => {
                            setData(e?.target?.value, "keyword", 160);
                          }}
                        />
                      </div>
                      <small className="validationerror">
                        {validation?.keyword?.message
                          ? `Keyword ${validation?.keyword?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="col-lg-6">
                      <label className="labeltext">Page Name</label>
                      <div>
                        <input
                          type="text"
                          className="fieldinput"
                          placeholder="Enter Page Name"
                          defaultValue={metaList?.pageName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label className="labeltext">
                        Description
                        <span className="char-hint">
                          {" "}
                          (Maximum 160 characters)
                        </span>
                      </label>
                      <div>
                        <textarea
                          type="text"
                          className="fieldinput"
                          placeholder="Enter Description"
                          maxLength={160}
                          defaultValue={metaList?.description}
                          // onChange={(e) => hadleMapInputChange(e, i, "description")}
                          onChange={(e) => {
                            setData(e?.target?.value, "description", 160);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* )
                  })} */}
                  <div className="row my-5">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel">Update</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetatagSpecificview;
