import React, { useState, useEffect } from "react";
import aboutuslaptopimg from "../Assets/aboutuslaptopimg.webp";
import logo1 from "../Assets/logo1.webp";
import logo from "../Assets/logo.webp";
import dot from "../Assets/dot.webp";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { cloudFrontUrl } from "../commonUtils/Utils";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { GetAboutUsPage, MetaUserSpecificView } from "../Repository/Api";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
const Aboutuscontent = () => {
  const { contactCardData } = useSelector((state) => state?.contactCarddata);
  const contact = contactCardData;
  const [cms, setCms] = useState({});
  const { title } = useSelector(state => state?.masterdata?.masterdata)

  console.log(cms);
  const getTrainingcms = async () => {
    const response = await GetAboutUsPage();
    if (response && response.statusCode === 200) {
      setCms(response?.data?.pageStructure);
    }
  };

  const [meta, setMeta] = useState()
  const GetMeta = async () => {
    const response = await MetaUserSpecificView("aboutus")
    if (response && response.statusCode === 200) {
      setMeta(response?.data)
    }
  }
  useEffect(() => {
    getTrainingcms();
    GetMeta()
  }, []);

  const Arrayone = cms?.whyUsSectionContent?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });
  const ArrayTwo = cms?.teamDrivingSectionEmployees?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${meta?.title} - ${title}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={meta?.description} />
        <meta name="keyword" content={meta?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F0Q8Y7FKSZ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              `
                        window.dataLayer = window.dataLayer || [];   
                        function gtag(){dataLayer.push(arguments);}   
                        gtag('js', new Date());   
                        gtag('config', 'G-F0Q8Y7FKSZ');
                    `
          }}
        />
      </Helmet>
      {cms?.headerShown && (
        <div>
          <Header />
        </div>
      )}
      <div
        className={`about1 pb-5 mb-5 ${!cms?.headerShown ? "mt-0" : "mt-5"}`}
      >
        <div className="container pb-5 pt-5">
          <div className="row pt-5 ">
            <div
              className="col col-lg-6 align-self-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div>
                <p className="h1 mb-5">{cms?.bannerHeading}</p>
                <p className="para40 textarea">{cms?.bannerSubHeading}</p>
                {cms?.bannerButtonShown && (
                  <NavLink to={cms?.bannerButtonRedirectLink}>
                    <button className="btn btn-outline-success" type="button">
                      {cms?.bannerButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
            <div
              className="col col-lg-6 about-lap d-md-block d-none"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <img
                src={aboutuslaptopimg}
                className="aboutimg"
                alt="aboutuslaptopimg"
              />
              <iframe
                width="560"
                height="315"
                src={cms?.bannerVideoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-md-none d-block">
        <div className="row about-lap" data-aos="zoom-in" data-aos-delay="300">
          <img
            src={aboutuslaptopimg}
            className="aboutimg"
            alt="aboutuslaptopimg"
          />
          {/* <video
            width="320"
            height="260"
            controls
            poster={Play}
            className="video-abt"
          >
            <source src={cms?.bannerVideoUrl} type="video/mp4" />
          </video> */}
          <iframe
            width="320"
            height="260"
            src={cms?.bannerVideoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      {cms?.logoSectionSectionShown && (
        <div className="ourlogo pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div
                className="col col-lg-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <img
                  src={`${cloudFrontUrl}${cms?.logoSectionImage}`}
                  className="aboutimg"
                  alt="aboutuslogoimg"
                />
              </div>
              <div
                className="col col-lg-6"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <p className="h6">{cms?.logoSectionHeading?.split(" ")[0]}</p>
                <p className="h1 pb-3">
                  {cms?.logoSectionHeading?.split(" ")[1]}
                </p>
                <p className="para41 pb-3 textarea">
                  {cms?.logoSectionSubHeading}
                </p>
                {cms?.logoSectionButtonShown && (
                  <NavLink to={cms?.logoSectionButtonRedirectLink}>
                    <button className="btn btn-outline-success" type="button">
                      {cms?.logoSectionButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cms?.foundersMessageSectionShown && (
        <div className="message">
          <div className="container">
            <div className="row ">
              <div
                className="col1 col-lg-7"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p className="title">{cms?.foundersMessageSectionHeading}</p>
                <p className="para42 textarea">
                  {cms?.foundersMessageSectionSubHeading}
                </p>
                {cms?.foundersMessageSectionButtonShown && (
                  <NavLink to={cms?.foundersMessageSectionButtonRedirectLink}>
                    <button className="btn-a1" type="button">
                      {cms?.foundersMessageSectionButtonName}
                    </button>
                  </NavLink>
                )}
              </div>
              <div
                className="col col-lg-5"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="about-img-fouder">
                  <img
                    src={`${cloudFrontUrl}${cms?.foundersMessageSectionImage}`}
                    className="founderimg"
                    alt="founderimage1"
                  />
                  <div class="overlay">
                    <h4 className="text-white">Gustavo Liu</h4>
                    <p className="mb-0 fs-6 text-white">
                      Founder & CEO - RescaleLab
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cms?.whyUsSectionShown && (
        <div className="team mb-5 pb-5">
          <div className="container">
            <p className="title mt-5">{cms?.whyUsSectionHeading}</p>
            <div className="row">
              <div
                className="col1 col-lg-5"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <img src={logo} className="logo" alt="logo" />
                <div className="card ">
                  <img
                    src={`${cloudFrontUrl}${Arrayone && Arrayone[0]?.image}`}
                    className="img35 ms-2 mb-2"
                    alt="aboutusimg6"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {Arrayone && Arrayone[0]?.heading}
                    </p>
                    <p className="para43">{Arrayone && Arrayone[0]?.content}</p>
                  </div>
                </div>
              </div>
              <div
                className="col2 col-lg-5"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="card ">
                  <img
                    src={`${cloudFrontUrl}${Arrayone && Arrayone[1]?.image}`}
                    className="img35 ms-2 mb-2"
                    alt="aboutusimg7"
                  />
                  <div className="card-body">
                    <p className="banner-text">
                      {Arrayone && Arrayone[1]?.heading}
                    </p>
                    <p className="para43">{Arrayone && Arrayone[1]?.content}</p>
                  </div>
                </div>
                <img src={logo1} className="logo31" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      )}
      {cms?.teamDrivingSectionShown && (
        <div className="global">
          <div className="container trip">
            <p className="h6" data-aos="fade-up" data-aos-delay="300">
              {cms?.teamDrivingSectionHeading}
            </p>
            <p className="h1" data-aos="fade-up" data-aos-delay="300">
              {cms?.teamDrivingSectionSubHeading}
            </p>
            <p className="para44" data-aos="fade-up" data-aos-delay="300">
              {cms?.teamDrivingSectionContent}
            </p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              {ArrayTwo?.map((item, i) => (
                item?.name ?
                  <div
                    className="col-6 col-lg-4 pb-5"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="aboutusimg6">
                      <img
                        src={`${cloudFrontUrl}${item && item?.image}`}
                        className="img36"
                        alt="gustavoliu"
                      />
                    </div>
                    <div className="size">
                      <p className="h6">{item && item?.name}</p>
                      <p className="para45">
                        {item && item?.designation}
                      </p>
                    </div>
                  </div> : ""
              ))}
            </div>
          </div>
        </div>
      )}
      {cms?.contactUsCardsShown && (
        <div className="contact nunito">
          <div className="container d-md-block d-none">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <p className="h1"> {contact?.heading}</p>
                <p>{contact?.subHeading}</p>
                {contact?.buttonShown && (
                  <NavLink to={contact?.buttonRedirectPath}>
                    <button className="btn-a1">{contact?.buttonHeading}</button>
                  </NavLink>
                )}
              </div>
              <div className="col-lg-6">
                <img
                  src={`${cloudFrontUrl}${contact?.backgroudImage}`}
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {cms?.contactUsCardsShown && (
        <div className="container mt-4 d-block d-md-none">
          <div
            data-aos="zoom-in"
            data-aos-delay="300"
            className="d-flex flex-column align-items-center"
          >
            <p className="h3 text-center">{contact?.heading}</p>
            <p className="text"> {contact?.subHeading}</p>
            <img
              src={`${cloudFrontUrl}${contact?.backgroudImage}`}
              className="w-100"
            />
            {contact?.buttonShown && (
              <NavLink to={contact?.buttonRedirectPath}>
                <button className="btn-a1">{contact?.buttonHeading}</button>
              </NavLink>
            )}
          </div>
        </div>
      )}
      <div className="img">
        <img src={dot} className="logo13" alt="dot" />
      </div>
      {cms?.footerShown && (
        <div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Aboutuscontent;
