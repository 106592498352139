import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import Platform from "./Pages/Platform";
import Licensing from "./Pages/Licensing";
import Trainingservices from "../src/Pages/Trainingproviders";
import Forcorporation from "../src/Pages/Forcorporation";
import Foruniversities from "../src/Pages/Foruniversities";
import Fortrainingproviders from "../src/Pages/Fortrainingproviders";
import Aboutus from "./Pages/Aboutus";
import SpecificBlog from "./Pages/SpecificBlog";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import ScrolltoTop from "./Components/ScrollToTop";
import Scarlettjohns from "./Pages/Scarlettjohns";
import Pricing from "./Pages/Pricing";
import { useSelector } from "react-redux";
import PrivateRoutes from "./PrivateRoutes";
import Login from "./Components/Admin/Login";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import Adminlist from "./Components/Admin/AdminManagement/Adminlist";
import AdminCreate from "./Components/Admin/AdminManagement/AdminCreate";
import AdminSpecificview from "./Components/Admin/AdminManagement/AdminSpecificView";
import Bloglist from "./Components/Admin/BlogManagement/Bloglist";
import BlogCreate from "./Components/Admin/BlogManagement/BlogCreate";
import BlogSpecificview from "./Components/Admin/BlogManagement/BlogSpecificview";
import PricingList from "./Components/Admin/PricingManagement/Pricinglist";
import PricingCreate from "./Components/Admin/PricingManagement/PricingCreate";
import PricingSpecificview from "./Components/Admin/PricingManagement/PricingSpecificview";
import MetatagList from "./Components/Admin/MetatagManagement/Metataglist";
import MetatagSpecificview from "./Components/Admin/MetatagManagement/MetatagSpecificview";
import Profile from "./Components/Admin/Profile";
import ForgetPassword from "./Components/Admin/ForgetPassword";
import VerifyOTP from "./Components/Admin/VerifyOTP";
import ResetPassword from "./Components/Admin/ResetPassword";
import ChangePassword from "./Components/Admin/ChangePassword";
import BatchList from "./Components/Admin/BatchCredit/BatchCredit";
import BatchCreditCreate from "./Components/Admin/BatchCredit/BatchCreditCreate";
import Socialmedialist from "./Components/Admin/SocialMediaManagement/Socialmedialist";
import SocialmediaCreate from "./Components/Admin/SocialMediaManagement/SocialmediaCreate";
import SocialmediaSpecificview from "./Components/Admin/SocialMediaManagement/SocialmediaSpecificview";
import BlogSpecificcommentview from "./Components/Admin/BlogManagement/BlogSpecificcommentview";
import Contactrequestlist from "./Components/Admin/ContactManagement/Contactrequestlist";
import ContactrequestSpecificview from "./Components/Admin/ContactManagement/ContactrequestSpecificview";
import BatchEdit from "./Components/Admin/BatchCredit/BatchEdit";
import FaqList from "./Components/Admin/FAQManagement/FaqList";
import FaqCreate from "./Components/Admin/FAQManagement/FaqCreate";
import FaqUpdate from "./Components/Admin/FAQManagement/FaqUpdate";
import MasterdataCreate from "./Components/Admin/MasterdataManagement/MasterdataCreate";
import CategoryBlog from "./Pages/CategoryBlog";
import Testimonial from "./Components/Admin/TestimonialManagement/Testimonial";
import TestimonialCreate from "./Components/Admin/TestimonialManagement/TestimonialCreate";
import TestimonialUpdate from "./Components/Admin/TestimonialManagement/TestimonialUpdate";
import HeaderManagement from "./Components/Admin/HeaderManagement/HeaderManagement";
import FooterManagement from "./Components/Admin/FooterManagement/FooterManagement";
import ContactusCardmanagement from "./Components/Admin/ContactusCardmanagement/ContactusCardmanagement";
import MetatagCreate from "./Components/Admin/MetatagManagement/MetatagCreate";
import PricingLayout from "./Components/Admin/PricingLayout/PricingLayout";
import ContactuspageManagement from "./Components/Admin/ContactuspageManagement/ContactuspageManagement";
import BlogpageManagement from "./Components/Admin/BlogpageManagement/BlogpageManagement";
import TrainingServicespageManagement from "./Components/Admin/TrainingServicespageManagement/TrainingServicespageManagement";
import LicensingpageManagement from "./Components/Admin/LicensingpageManagement/LicensingpageManagement";
import PlatformpageManagement from "./Components/Admin/PlatformpageManagement/PlatformpageManagement";
import HomepageManagement from "./Components/Admin/HomepageManagement/HomepageManagement";
import AboutUSPageManagement from "./Components/Admin/AboutUSPageManagement/AboutUSPageManagement";
import ForCorporationPageManagement from "./Components/Admin/ForCorporationPageManagement/ForCorporationPageManagement";
import ForUniversityPageManagement from "./Components/Admin/ForUniversityPageManagement/ForUniversityPageManagement";
import ForTrainingProvidersPageManagement from "./Components/Admin/ForTrainingProvidersPageManagement/ForTrainingProvidersPageManagement";
import { GetMetaDetailsForUser } from "./Repository/Api";
import { Helmet } from "react-helmet";
import SpecificBlogPageManagement from "./Components/Admin/SpecificBlogPageManagement/SpecificBlogPageManagement";
import PartnersList from "./Components/Admin/PartnersSection/PartnersList";
import PartnersCreate from "./Components/Admin/PartnersSection/PartnersCreate";
import PartnersEdit from "./Components/Admin/PartnersSection/PartnersEdit";
import Privacypolicy from "./Pages/Privacypolicy";
import Termsofuse from "./Pages/Termsofuse";
import PrivacyPolicyManagement from "./Components/Admin/PrivacyPolicyManagement/PrivacyPolicyManagement";
import TermsOfServiceManagement from "./Components/Admin/TermsOfServiceManagement/TermsOfServiceManagement";
import CouponList from "./Components/Admin/CouponMangement/CouponList";
import CouponCreate from "./Components/Admin/CouponMangement/CouponCreate";
import CouponUpdate from "./Components/Admin/CouponMangement/CouponUpdate";
import Notfound from "./Components/Notfound";
import Sidebar from "./Components/Admin/Sidebar";
function Main() {
  const auth = useSelector((state) => state?.auth);
  const [metaData, setMetaData] = useState({});
  const { navbardata } = useSelector((state) => state?.navbardata);
  const nav = navbardata?.pageStructure?.menus;
  console.log(navbardata?.pageStructure?.menus[0].redirectPath);
  const initFunction = () => {
    let pathname = window.location.pathname;
    const response = GetMetaDetailsForUser(pathname.slice(1));
    response
      .then((res) => {
        setMetaData(res.data);
      })
      .catch(() => {
        setMetaData({});
      });
  };

  useEffect(() => {
    initFunction();
  }, []);
  const platformpath = nav?.length && nav[0]?.redirectPath;
  return (
    <>
      <Router>
        <ScrolltoTop />
        {auth?.isLoggedIn ? <Sidebar /> : null}

        <Routes>
          <Route path="*" element={<Notfound />} />
          <Route
            exact
            path="/adminlogin"
            element={
              !auth?.isLoggedIn ? (
                <Login />
              ) : (
                <Navigate replace to="/admin/dashboard" />
              )
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/licensing" element={<Licensing />} />
          <Route path="/trainingservices" element={<Trainingservices />} />
          <Route path="/forcorporation" element={<Forcorporation />} />
          <Route path="/foruniversities" element={<Foruniversities />} />
          <Route
            path="/fortrainingproviders"
            element={<Fortrainingproviders />}
          />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/specificblog/:id" element={<SpecificBlog />} />
          <Route path="/news-and-blogs" element={<Blog />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/author/:name" element={<Scarlettjohns />} />
          <Route path="/category/:category" element={<CategoryBlog />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/adminlogin" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/VerifyOTP" element={<VerifyOTP />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route component={<PrivateRoutes />}>
            <Route
              path="/admin/dashboard"
              element={
                auth?.isLoggedIn ? (
                  <AdminDashboard />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/Profile"
              element={
                auth?.isLoggedIn ? <Profile /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/Adminlist"
              element={
                auth?.isLoggedIn ? <Adminlist /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/AdminCreate"
              element={
                auth?.isLoggedIn ? <AdminCreate /> : <Navigate replace to="/" />
              }
            />

            <Route
              path="/admin/AdminSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <AdminSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/Bloglist"
              element={
                auth?.isLoggedIn ? <Bloglist /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/CouponList"
              element={
                auth?.isLoggedIn ? <CouponList /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/BlogCreate"
              element={
                auth?.isLoggedIn ? <BlogCreate /> : <Navigate replace to="/" />
              }
            />

            <Route
              path="/admin/CouponUpdate/:id"
              element={
                auth?.isLoggedIn ? (
                  <CouponUpdate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />

            <Route
              path="/admin/CouponCreate"
              element={
                auth?.isLoggedIn ? (
                  <CouponCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/BlogSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <BlogSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/BlogSpecificcommentview/:id"
              element={
                auth?.isLoggedIn ? (
                  <BlogSpecificcommentview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/PricingList"
              element={
                auth?.isLoggedIn ? <PricingList /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/PricingCreate"
              element={
                auth?.isLoggedIn ? (
                  <PricingCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path=".admin/PricingSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <PricingSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/MetatagList"
              element={
                auth?.isLoggedIn ? <MetatagList /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/metatag/create"
              element={
                auth?.isLoggedIn ? (
                  <MetatagCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/MetatagSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <MetatagSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/BatchList"
              element={
                auth?.isLoggedIn ? <BatchList /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/BatchCreditCreate"
              element={
                auth?.isLoggedIn ? (
                  <BatchCreditCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/Socialmedialist"
              element={
                auth?.isLoggedIn ? (
                  <Socialmedialist />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/SocialmediaSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <SocialmediaSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/SocialmediaCreate"
              element={
                auth?.isLoggedIn ? (
                  <SocialmediaCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/Contactrequestlist"
              element={
                auth?.isLoggedIn ? (
                  <Contactrequestlist />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/ContactrequestSpecificview/:id"
              element={
                auth?.isLoggedIn ? (
                  <ContactrequestSpecificview />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/BatchSpecificview/:id"
              element={
                auth?.isLoggedIn ? <BatchEdit /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/FaqList"
              element={
                auth?.isLoggedIn ? <FaqList /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/FaqCreate"
              element={
                auth?.isLoggedIn ? <FaqCreate /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/FaqSpecificview/:id"
              element={
                auth?.isLoggedIn ? <FaqUpdate /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/MasterdataCreate"
              element={
                auth?.isLoggedIn ? (
                  <MasterdataCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/Testimonial"
              element={
                auth?.isLoggedIn ? <Testimonial /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/admin/Testimonial/create"
              element={
                auth?.isLoggedIn ? (
                  <TestimonialCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/Testimonial/update/:id"
              element={
                auth?.isLoggedIn ? (
                  <TestimonialUpdate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/headerManagement"
              element={
                auth?.isLoggedIn ? (
                  <HeaderManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/footerManagement"
              element={
                auth?.isLoggedIn ? (
                  <FooterManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/ContactusCardManagement"
              element={
                auth?.isLoggedIn ? (
                  <ContactusCardmanagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/PricingPage"
              element={
                auth?.isLoggedIn ? (
                  <PricingLayout />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/contactusPage"
              element={
                auth?.isLoggedIn ? (
                  <ContactuspageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/news-and-blogs"
              element={
                auth?.isLoggedIn ? (
                  <BlogpageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/Training-services"
              element={
                auth?.isLoggedIn ? (
                  <TrainingServicespageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/licensing"
              element={
                auth?.isLoggedIn ? (
                  <LicensingpageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/platform"
              element={
                auth?.isLoggedIn ? (
                  <PlatformpageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/Home"
              element={
                auth?.isLoggedIn ? (
                  <HomepageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/AboutUs"
              element={
                auth?.isLoggedIn ? (
                  <AboutUSPageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/ForCorporation"
              element={
                auth?.isLoggedIn ? (
                  <ForCorporationPageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/ForUniversities"
              element={
                auth?.isLoggedIn ? (
                  <ForUniversityPageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/ForTrainingProviders"
              element={
                auth?.isLoggedIn ? (
                  <ForTrainingProvidersPageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/specific-blog"
              element={
                auth?.isLoggedIn ? (
                  <SpecificBlogPageManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/partners"
              element={
                auth?.isLoggedIn ? (
                  <PartnersList />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/create/partners"
              element={
                auth?.isLoggedIn ? (
                  <PartnersCreate />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/edit/partners/:id"
              element={
                auth?.isLoggedIn ? (
                  <PartnersEdit />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route path="/terms-of-service" element={<Termsofuse />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route
              path="/admin/page/privacy-policy"
              element={
                auth?.isLoggedIn ? (
                  <PrivacyPolicyManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            <Route
              path="/admin/page/terms-of-service"
              element={
                auth?.isLoggedIn ? (
                  <TermsOfServiceManagement />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default Main;
