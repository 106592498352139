import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdminlicensingpage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const LicensingpageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms)
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  useEffect(() => {
    GetData();
  }, []);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(key);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };

  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 575 &&
        // dummy?.width === 788
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "LicenseImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 575 &&
        // dummy?.width === 788
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              LicenseImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1"
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons3"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons4"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[4];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[4] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons5"
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 111 &&
        // dummy?.width <= 111
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[5];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[5] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureimage0"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 76 &&
        // dummy?.width <= 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[0];
          const updatedObj = {
            ...others,
            icon: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureimage1"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 76 &&
        // dummy?.width <= 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[1];
          const updatedObj = {
            ...others,
            icon: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featureimage2"
        // &&
        // dummy?.size <= 10
        // &&
        // dummy?.height <= 76 &&
        // dummy?.width <= 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.featuresSectionFeatures;
          const others = createcms?.pageStructure?.featuresSectionFeatures[2];
          const updatedObj = {
            ...others,
            icon: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionFeatures: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminlicensingpage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.empoweringSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.empoweringSectionHeading
    );
    validate.empoweringSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.empoweringSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.empoweringSectionContent)) {
      validate.empoweringSectionContent =
        createcms?.pageStructure?.empoweringSectionContent.map((d, i) => ({
          content: NonEmptyValidation(d?.content),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.empoweringSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.empoweringSectionButtonName
    );
    validate.empoweringSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.empoweringSectionButtonRedirectLink
    );
    validate.featuresSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionHeading
    );
    validate.featuresSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.featuresSectionFeatures)) {
      validate.featuresSectionFeatures =
        createcms?.pageStructure?.featuresSectionFeatures.map((d, i) => ({
          heading: NonEmptyValidation(d?.heading),
          content: NonEmptyValidation(d?.content),
          icon: NonEmptyValidation(d?.icon),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.featuresSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionButtonName
    );
    validate.featuresSectionButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionButtonRedirectPath
    );
    validate.expectedSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.expectedSectionHeading
    );
    validate.expectedSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.expectedSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.expectedSectionContent)) {
      validate.expectedSectionContent =
        createcms?.pageStructure?.expectedSectionContent.map((d, i) => ({
          heading: NonEmptyValidation(d?.heading),
          content: NonEmptyValidation(d?.content),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.expectedSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.expectedSectionButtonName
    );
    validate.expectedSectionButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.expectedSectionButtonRedirectPath
    );
    validate.partOfSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.partOfSectionHeading
    );
    validate.partOfSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.partOfSectionContent
    );
    validate.partOfSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.partOfSectionSubHeading
    );
    validate.partOfSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.partOfSectionButtonName
    );
    validate.partOfSectionButtonRedirectPath = NonEmptyValidation(
      createcms?.pageStructure?.partOfSectionButtonRedirectPath
    );

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.content.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.heading?.status &&
            menu?.icon?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue4(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.heading?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.empoweringSectionContent);
    const isTrue3 = areAllStatusTrue3(validate.featuresSectionFeatures);
    const isTrue4 = areAllStatusTrue4(validate.expectedSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    // Keys to filter out
    const keysToFilter = [
      "bannerIcons",
      "empoweringSectionContent",
      "featuresSectionFeatures",
      "expectedSectionContent",
    ]; // Add more keys as needed

    // Filter out specified keys from the object
    const remain = filterObjectKeys(validate, keysToFilter);

    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue3 && isTrue4 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">

            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Licensing Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>
            <div className="contentbox ">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row gx-5">
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 60 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Banner Heading"
                              maxLength={60}
                              value={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  60,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 400 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Banner Sub Heading"
                              maxLength={400}
                              value={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  400,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="licenceBannerimg"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                            />
                            <label
                              for="licenceBannerimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB,788px X 575px.
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div>
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`licencebannericon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`licencebannericon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 111px X
                                        111px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.image
                                            ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.bannerIcons &&
                                              validation?.bannerIcons[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.empoweringSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure
                                  ?.empoweringSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    empoweringSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "empoweringSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  empoweringSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.empoweringSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.empoweringSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.empoweringSectionHeading?.message
                                ? `Heading ${validation?.empoweringSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure
                                  ?.empoweringSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    empoweringSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "empoweringSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  empoweringSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.empoweringSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.empoweringSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.empoweringSectionSubHeading?.message
                                ? `sub Heading ${validation?.empoweringSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.empoweringSectionContent &&
                            createcms?.pageStructure?.empoweringSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 400 characters)</span>

                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={400}
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              400,
                                              "content",
                                              "empoweringSectionContent",
                                              "empoweringSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.empoweringSectionContent &&
                                              validation
                                                ?.empoweringSectionContent[i]
                                                ?.content;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.empoweringSectionContent
                                              ?.length > 0 &&
                                              (validation.empoweringSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate
                                        ?.empoweringSectionContent1?.length >
                                        0 &&
                                        err?.errorstate
                                          ?.empoweringSectionContent1[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.empoweringSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.empoweringSectionContent
                                            ?.length > 0 &&
                                            validation?.empoweringSectionContent[
                                              i
                                            ]?.content?.message
                                            ? `Content ${validation?.empoweringSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const empoweringSectionContent =
                                              createcms?.pageStructure
                                                ?.empoweringSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            empoweringSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                empoweringSectionContent:
                                                  empoweringSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.empoweringSectionContent &&
                                              validation
                                                ?.empoweringSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.empoweringSectionContent
                                              ?.length > 0 &&
                                              (validation.empoweringSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.empoweringSectionContent
                                          ?.length > 0 &&
                                          validation?.empoweringSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.empoweringSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.empoweringSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    empoweringSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "empoweringSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  empoweringSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.empoweringSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.empoweringSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.empoweringSectionButtonName?.message
                                ? `Button name ${validation?.empoweringSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.empoweringSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    empoweringSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  empoweringSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.empoweringSectionButtonRedirectLink
                              ?.message
                              ? `Button Redirect Link ${validation?.empoweringSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.empoweringSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      empoweringSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.empoweringSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.empoweringSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      empoweringSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  common section refer -- Features */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.featuresSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <p className="h2"></p>
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure?.featuresSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "featuresSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionHeading?.message
                                ? `Heading ${validation?.featuresSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionSubHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "featuresSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionSubHeading?.message
                                ? `sub Heading ${validation?.featuresSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.featuresSectionFeatures &&
                            createcms?.pageStructure?.featuresSectionFeatures.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`licencefeatureicon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `featureimage${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`licencefeatureicon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.icon ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.icon}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 10KB, 76px X 76px.
                                      </small>
                                      <small className="validationerror">
                                        {validation?.featuresSectionFeatures
                                          ?.length > 0 &&
                                          validation?.featuresSectionFeatures[i]
                                            ?.icon?.message
                                          ? `Icon ${validation?.featuresSectionFeatures[i]?.icon?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">{" "}(Maximum 50 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Heading"
                                          maxLength={50}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              50,
                                              "heading",
                                              "featuresSectionFeatures",
                                              "featuresSectionFeatures0"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionFeatures &&
                                              validation
                                                ?.featuresSectionFeatures[i]
                                                ?.heading;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionFeatures
                                              ?.length > 0 &&
                                              (validation.featuresSectionFeatures[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionFeatures0
                                        ?.length > 0 &&
                                        err?.errorstate?.featuresSectionFeatures0[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionFeatures0[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionFeatures
                                            ?.length > 0 &&
                                            validation?.featuresSectionFeatures[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.featuresSectionFeatures[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 400 characters)</span>

                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={400}
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              400,
                                              "content",
                                              "featuresSectionFeatures",
                                              "featuresSectionFeatures1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionFeatures &&
                                              validation
                                                ?.featuresSectionFeatures[i]
                                                ?.content;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionFeatures
                                              ?.length > 0 &&
                                              (validation.featuresSectionFeatures[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.featuresSectionFeatures1
                                        ?.length > 0 &&
                                        err?.errorstate?.featuresSectionFeatures1[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.featuresSectionFeatures1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.featuresSectionFeatures
                                            ?.length > 0 &&
                                            validation?.featuresSectionFeatures[i]
                                              ?.content?.message
                                            ? `Content ${validation?.featuresSectionFeatures[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const featuresSectionFeatures =
                                              createcms?.pageStructure
                                                ?.featuresSectionFeatures;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            featuresSectionFeatures[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                featuresSectionFeatures:
                                                  featuresSectionFeatures,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.featuresSectionFeatures &&
                                              validation
                                                ?.featuresSectionFeatures[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.featuresSectionFeatures
                                              ?.length > 0 &&
                                              (validation.featuresSectionFeatures[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.featuresSectionFeatures
                                          ?.length > 0 &&
                                          validation?.featuresSectionFeatures[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.featuresSectionFeatures[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Name"
                              maxLength={50}
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "featuresSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionButtonName?.message
                                ? `Button name ${validation?.featuresSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.featuresSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.featuresSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.featuresSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.featuresSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.featuresSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.expectedSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure?.expectedSectionHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    expectedSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "expectedSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  expectedSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.expectedSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.expectedSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.expectedSectionHeading?.message
                                ? `Heading ${validation?.expectedSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.expectedSectionSubHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    expectedSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "expectedSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  expectedSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.expectedSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.expectedSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.expectedSectionSubHeading?.message
                                ? `sub Heading ${validation?.expectedSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.expectedSectionContent &&
                            createcms?.pageStructure?.expectedSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">{" "}(Maximum 50 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Heading"
                                          maxLength={50}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              50,
                                              "heading",
                                              "expectedSectionContent",
                                              "expectedSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.expectedSectionContent &&
                                              validation
                                                ?.expectedSectionContent[i]
                                                ?.heading;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.expectedSectionContent
                                              ?.length > 0 &&
                                              (validation.expectedSectionContent[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.expectedSectionContent1
                                        ?.length > 0 &&
                                        err?.errorstate?.expectedSectionContent1[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.expectedSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.expectedSectionContent
                                            ?.length > 0 &&
                                            validation?.expectedSectionContent[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.expectedSectionContent[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const expectedSectionContent =
                                              createcms?.pageStructure
                                                ?.expectedSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            expectedSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                expectedSectionContent:
                                                  expectedSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.expectedSectionContent &&
                                              validation
                                                ?.expectedSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.expectedSectionContent
                                              ?.length > 0 &&
                                              (validation.expectedSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.expectedSectionContent
                                          ?.length > 0 &&
                                          validation?.expectedSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.expectedSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 300 characters)</span>

                                      </label>
                                      <div>
                                        <textarea
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          value={item?.content}
                                          maxLength={300}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              300,
                                              "content",
                                              "expectedSectionContent",
                                              "expectedSectionContent0"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.expectedSectionContent &&
                                              validation
                                                ?.expectedSectionContent[i]
                                                ?.content;

                                            const v = NonEmptyValidation(
                                              e.target.value
                                            );
                                            validation?.expectedSectionContent
                                              ?.length > 0 &&
                                              (validation.expectedSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.expectedSectionContent0
                                        ?.length > 0 &&
                                        err?.errorstate?.expectedSectionContent0[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.expectedSectionContent0[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.expectedSectionContent
                                            ?.length > 0 &&
                                            validation?.expectedSectionContent[i]
                                              ?.content?.message
                                            ? `Content ${validation?.expectedSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button Name */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.expectedSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    expectedSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "expectedSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  expectedSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.expectedSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.expectedSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.expectedSectionButtonName?.message
                                ? `Button name ${validation?.expectedSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Path
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.expectedSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    expectedSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  expectedSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.expectedSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.expectedSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.expectedSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      expectedSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.expectedSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.expectedSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      expectedSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* uptothis */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      License Community Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Section Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="LicenseImage"
                              accept="image/jpeg, image/png,image/webp"
                              onChange={(e) => UploadDocument(e, "LicenseImage")}
                            />
                            <label
                              for="LicenseImage"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.LicenseImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.LicenseImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB,400px X 400px.
                          </small>
                          <small className="validationerror">
                            {validation?.LicenseImage?.message
                              ? `Banner Image ${validation?.LicenseImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure?.partOfSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partOfSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partOfSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partOfSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partOfSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partOfSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partOfSectionHeading?.message
                                ? `Button Name ${validation?.partOfSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Sub Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure
                                  ?.partOfSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partOfSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partOfSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partOfSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partOfSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partOfSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partOfSectionSubHeading?.message
                                ? `Button Name ${validation?.partOfSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Content
                            <span className="char-hint">{" "}(Maximum 500 characters)</span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput w-100"
                              maxLength={500}
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure?.partOfSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partOfSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  500,
                                  setErr,
                                  "partOfSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partOfSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partOfSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partOfSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partOfSectionContent?.message
                                ? `Content ${validation?.partOfSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.partOfSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partOfSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partOfSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partOfSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partOfSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partOfSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partOfSectionButtonName?.message
                                ? `Button Name ${validation?.partOfSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Button link */}
                          <label className="labeltext">
                            Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Content"
                              value={
                                createcms?.pageStructure
                                  ?.partOfSectionButtonRedirectPath
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partOfSectionButtonRedirectPath:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partOfSectionButtonRedirectPath:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.partOfSectionButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.partOfSectionButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Button</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.partOfSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partOfSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.partOfSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partOfSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicensingpageManagement;
