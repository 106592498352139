import React, { useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import { NumberValidation, NonEmptyValidation } from "../../../Store/validate";
import { CreateBatch } from "../../../Repository/Api";
import { useSelector } from "react-redux";
const BatchCreditCreate = () => {
  const state = useSelector((state) => state)
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createAdmin, setCreateAdmin] = useState({});
  const [validation, setValidation] = useState({});

  const setData = (e, key) => {
    setCreateAdmin({ ...createAdmin, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.duration = NumberValidation(createAdmin?.duration);
    validate.durationType = NonEmptyValidation(createAdmin?.durationType);
    validate.price = NumberValidation(createAdmin?.price);
    validate.priceSymbol = NonEmptyValidation(createAdmin?.priceSymbol);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        duration: createAdmin.duration,
        durationType: createAdmin.durationType,
        price: createAdmin.price,
        priceSymbol: createAdmin.priceSymbol,
      };
      const response = await CreateBatch(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/BatchList"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  <span
                    className="arrowicon"
                    onClick={() => {
                      navigate("/BatchList");
                    }}
                  >
                    ← &nbsp;
                  </span>
                  Batch List
                </h6>
              </div>
            </div>
            <div className="contentbox mt-5">
              <div className="mt-1">
                <h5>Create Batch</h5>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="labeltext">Batch NO *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Batch No"
                        defaultValue={createAdmin?.duration}
                        onChange={(e) => {
                          setData(e.target.value, "duration");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.duration?.message
                        ? `Batch No ${validation?.duration?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Batch Period *</label>
                    <div>
                      <select
                        className="fieldinput"
                        placeholder="Enter Batch Period"
                        onChange={(e) => {
                          setData(e.target.value, "durationType");
                        }}
                      >
                        <option className="batchselect" value="">Please Select Batch Period</option>
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                      </select>
                    </div>
                    <small className="validationerror">
                      {validation?.durationType?.message
                        ? `Batch period ${validation?.durationType?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Price *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Price"
                        defaultValue={createAdmin?.price}
                        onChange={(e) => {
                          setData(e.target.value, "price");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.price?.message
                        ? `Price ${validation?.price?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Currency *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Price"
                        defaultValue={createAdmin?.priceSymbol}
                        onChange={(e) => {
                          setData(e.target.value, "priceSymbol");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.priceSymbol?.message
                        ? `Currency ${validation?.priceSymbol?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel" type="submit">Create</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchCreditCreate;
