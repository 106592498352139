import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { errorMsg, successMsg } from "../../Toastify";
import {
  CreateBlog,
  ImageUpload,
  CreateCategory,
  GetCategoryList,
} from "../../../Repository/Api";
import {
  NonEmptyValidation,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";
import moment from "moment";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const BlogCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createBlog, setCreateBlog] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const CustomButton = ({ label, onClick }) => (
    <div className="custom-button" onClick={onClick}>
      {label}
    </div>
  );
  const handleCustomButtonClick = () => {
    // Handle button click logic here
  };

  const setValues = (e, key) => {
    setCreateBlog({ ...createBlog, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleEditorChange = (state, key) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setValues(html, "content");
    }
  };

  const handleCreateCategory = async (val) => {
    const valid = NonEmptyValidation(val);
    if (valid.status) {
      const Payload = {
        name: val,
      };
      const response = await CreateCategory(Payload, token);
      if (response && response.statusCode === 200) {
        // Getdesignation(val);
        GetCategory();
      }
    }
  };

  const [category, setCategory] = useState({});

  const GetCategory = async () => {
    const response = await GetCategoryList(token);
    if (response && response?.statusCode === 200) {
      let list = response?.data
        .filter((t) => t.status === "active")
        .map((e) => {
          return {
            value: e.name,
            label: e.name,
            _id: e._id,
          };
        });
      setCategory(list);
    }
  };

  useEffect(() => {
    GetCategory();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};

    validate.title = StringValidation(createBlog?.title);
    validate.author = StringValidation(createBlog?.author);
    validate.publishedAt = NonEmptyValidation(createBlog?.publishedAt);
    validate.category = NonEmptyValidation(createBlog?.category);
    validate.minutes = NumberValidation(createBlog?.minutes);
    validate.image = NonEmptyValidation(createBlog?.image);
    validate.content = NonEmptyValidation(createBlog?.content);
    validate.displayOrder = NumberValidation(createBlog?.displayOrder);
    validate.buttonName = NonEmptyValidation(createBlog?.buttonName);
    validate.link = NonEmptyValidation(createBlog?.link);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        title: createBlog?.title,
        author: createBlog?.author,
        publishedAt: createBlog?.publishedAt,
        minutes: createBlog?.minutes,
        image: createBlog?.image,
        categoryId: createBlog?.category,
        content: createBlog?.content,
        displayOrder: createBlog?.displayOrder,
        buttonShown: createBlog?.buttonShown === true ? true : false,
        buttonName: createBlog?.buttonName,
        link: createBlog?.link,
      };
      const response = await CreateBlog(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/Bloglist"));
        }, 1000);
      } else {
        errorMsg(response?.error?.response?.data?.message);
      }
    }
  };

  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setValues(response.data._id, key);
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.size <= 500
        // && dummy?.width === 325 && dummy?.height === 174
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response.data.path, "image");
          successMsg(response.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Blog List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox ">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create Blog
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Title *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Blog Title"
                            defaultValue={createBlog?.title}
                            onChange={(e) => {
                              setValues(e.target.value, "title");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.title?.message
                            ? `Title ${validation?.title?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Author *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Author Name"
                            defaultValue={createBlog?.author}
                            onChange={(e) => {
                              setValues(e.target.value, "author");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.author?.message
                            ? `Author ${validation?.author?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Published Date *</label>
                        <div>
                          <input
                            type="date"
                            className="fieldinput"
                            defaultValue={createBlog?.publishedAt}
                            onChange={(e) => {
                              setValues(e.target.value, "publishedAt");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.publishedAt?.message
                            ? `Date ${validation?.publishedAt?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Minutes *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Minutes"
                            defaultValue={createBlog?.minutes}
                            onChange={(e) => {
                              setValues(e.target.value, "minutes");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.minutes?.message
                            ? `Minutes ${validation?.minutes?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Category *</label>
                        <div>
                          <CreatableSelect
                            options={category}
                            isClearable
                            className="createinput"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "49px",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                zIndex: 999,
                              }),
                            }}
                            placeholder="Choose Category Name"
                            // value={schedule.find(
                            //   (temp) => temp._id === employeeDetails?.designationId
                            // )}
                            // onInputChange={() => {
                            //   if (employeeValidation["designationId"])
                            //     delete employeeValidation["designationId"];
                            // }}
                            onChange={(e) => {
                              setValues(e._id, "category");
                            }}
                            onCreateOption={handleCreateCategory}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.category?.message
                            ? `Category ${validation?.category?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Thumbnail *</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="blogcretaeimg"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "image")}
                          />
                          <label for="blogcretaeimg" className="alt-btn">
                            Upload Image
                          </label>
                          {!createBlog?.image ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createBlog?.image}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 500KB,325px X 174px.
                        </small>
                        <small className="validationerror">
                          {validation?.image?.message
                            ? `Thumbnail ${validation?.image?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Description *</label>
                        <div className="fieldinput">
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) =>
                              handleEditorChange(e, "content")
                            }
                            toolbar={{
                              custom: (
                                <CustomButton
                                  label="Custom"
                                  onClick={handleCustomButtonClick}
                                />
                              ),
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                              image: {
                                previewImage: true,
                                uploadCallback: (file) => {
                                  return new Promise((resolve, reject) => {
                                    const maxSizeInBytes = 500 * 1024; // 100KB
                                    if (file.size > maxSizeInBytes) {
                                      errorMsg(
                                        "File size exceeds the limit (500KB)"
                                      );
                                      reject("");
                                      return;
                                    }
                                    const allowedTypes = [
                                      "image/webp",
                                      "image/png",
                                    ];
                                    if (!allowedTypes.includes(file.type)) {
                                      errorMsg(
                                        "Only webp or png files are allowed."
                                      );
                                      reject("");
                                      return;
                                    }
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      resolve({
                                        data: {
                                          url: reader.result,
                                        },
                                      });
                                    };

                                    reader.onerror = (reason) => reject(reason);
                                    reader.readAsDataURL(file);
                                  });
                                },
                                alt: { present: true, mandatory: true },
                              },
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.content?.message
                            ? `Description ${validation?.content?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Button Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Button Name"
                            defaultValue={createBlog?.buttonName}
                            onChange={(e) => {
                              setValues(e.target.value, "buttonName");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.buttonName?.message
                            ? `ButtonName ${validation?.buttonName?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Button Redirect Link *
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Redirect Link"
                            defaultValue={createBlog?.link}
                            onChange={(e) => {
                              setValues(e.target.value, "link");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.link?.message
                            ? `link ${validation?.link?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6 align-self-center">
                        <div className="d-flex justify-content-between bx-box w-90">
                          <label className="labeltext">Show Button</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createBlog?.buttonShown}
                              onChange={(e) => {
                                setValues(e.target.checked, "buttonShown");
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Display Order *</label>
                        <div>
                          <input
                            type="number"
                            className="fieldinput"
                            placeholder="Enter Display Order"
                            defaultValue={createBlog?.displayOrder}
                            onChange={(e) => {
                              setValues(e.target.value, "displayOrder");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.displayOrder?.message
                            ? `DisplayOrder ${validation?.displayOrder?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel" type="submit">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCreate;
