import { combineReducers } from "redux";
import auth from "./auth/reducer";
import device from "./device/reducer";
import masterdata from './masterData/reducer'
import contactCarddata from './contactCard/reducer'
import testimonialdata from './testimonial/reducer'
import partnerdata from './partner/reducer'
import navbardata from './navbar/reducer'
export default combineReducers({
  auth,
  device,
  masterdata,
  contactCarddata,
  testimonialdata,
  partnerdata,
  navbardata
});
