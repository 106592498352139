import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { errorMsg, successMsg } from "../../Toastify";
import { CreatesubscriptionContent, GetSpecificSubscription, UpdateSubscription } from "../../../Repository/Api";
import {
  NonEmptyValidation,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";
import { useSelector } from "react-redux";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}
const PricingSpecificview = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const state = useSelector((state) => state)
  const token = state?.auth?.auth?.accessToken;
  const [createPrice, setCreatePrice] = useState({});
  const [validation, setValidation] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    GetSubscription()
  }, [])

  const setData = (e, key) => {
    setCreatePrice({ ...createPrice, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setData(html, "description");
    }
  };

  const GetSubscription = async () => {
    const response = await GetSpecificSubscription(id, token)
    if (response && response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.description = cmsDescription;
      setCreatePrice(dummy);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.subscriptionsPlans = StringValidation(createPrice?.subscriptionsPlans);
    validate.cost = NumberValidation(createPrice?.cost);
    validate.batch = NonEmptyValidation(createPrice?.batch);
    validate.licence = NonEmptyValidation(createPrice?.licence);
    validate.description = NonEmptyValidation(createPrice?.description);
    setValidation(validate);

    let isNotEmpty = createPrice?.description && createPrice?.description.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(createPrice?.description.getCurrentContent())
      );

      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          _id: createPrice._id,
          subscriptionsPlans: createPrice?.subscriptionsPlans,
          cost: createPrice?.cost,
          batch: createPrice?.batch,
          licence: createPrice?.licence,
          description: html,
          subscriptionsLink: "www.insta.com"
        };
        const response = await UpdateSubscription(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
          setTimeout(() => {
            setTimeout(navigate("/Pricinglist"));
          }, 1000);
        } else {
          errorMsg(response.message);
        }
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/Pricinglist");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Subscription List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox">
            
              <form onSubmit={(e) => handleSubmit(e)}>
              <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                  Update Subscription
                  </p>
                  <div className="p-4">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="labeltext">Subscription Name *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Subscription Name"
                        value={createPrice?.subscriptionsPlans}
                        onChange={(e) => {
                          setData(e.target.value, "subscriptionsPlans");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.subscriptionsPlans?.message
                        ? `Subscription Name ${validation?.subscriptionsPlans?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">
                      Subscription Price *
                    </label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Price"
                        value={createPrice?.cost}
                        onChange={(e) => {
                          setData(e.target.value, "cost");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.cost?.message
                        ? `Price ${validation?.cost?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Batch *</label>
                    <div>
                      <input
                        type="text"
                        className="fieldinput"
                        placeholder="Enter Batch"
                        value={createPrice?.batch}
                        onChange={(e) => {
                          setData(e.target.value, "batch");
                        }}
                      />
                    </div>
                    <small className="validationerror">
                      {validation?.batch?.message
                        ? `Batch ${validation?.batch?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-6">
                    <label className="labeltext">Type *</label>
                    <div>
                      <select
                        className="fieldinput"
                        value={createPrice?.licence}
                        onChange={(e) => {
                          setData(e.target.value, "licence");
                        }}
                      >
                        <option value="">Select Type</option>
                        <option value="license">License</option>
                        <option value="nolicense">No License</option>
                      </select>
                    </div>
                    <small className="validationerror">
                      {validation?.licence?.message
                        ? `Type ${validation?.licence?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="col-lg-12">
                    <label className="labeltext">Pricing Description</label>
                    <div className="fieldinput">
                      <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        // editorState={editorState}
                        editorState={createPrice?.description}
                        onEditorStateChange={(e) => setData(e, "description")}
                        toolbar={{
                          options: ['list'],
                          list: { options: ['unordered'] },
                        }}
                      />
                    </div>

                    <small className="validationerror">
                      {validation?.description?.message
                        ? `Description ${validation?.description?.message}`
                        : ""}
                    </small>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-3 centertext ms-auto mx-auto">
                      <button className="buttonmodel">Update</button>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSpecificview;
