import React, { useEffect, useState } from "react";
import logo from "../../Assets/navlogo.webp";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { GetInitials } from "../../Store/constants";
import { useDispatch, useSelector } from "react-redux";
import { logOutSuccess } from "../../Store/auth/action";
import { GetHeaderdata, GetMyProfile } from "../../Repository/Api";
import { errorMsg } from "../Toastify";
import { cloudFrontUrl } from "../../commonUtils/Utils";
const AdminHeader = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [header, setHeader] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logOutSuccess());
  };
  const HeaderData = async () => {
    const response = await GetHeaderdata();
    setHeader(response?.data);
  };

  useEffect(() => {
    getProfile();
    HeaderData();
  }, []);
  

  const [profile, setProfile] = useState({});

  const getProfile = async () => {
    const response = await GetMyProfile(token);
    if (response && response.statusCode === 200) {
      setProfile(response?.data);
    } else {
      errorMsg(response.message);
    }
  };

  return (
    <>
      <div className="adminheader">
        <div className="">
          <div className="row">
            <div className="col-lg-6">
              <img
          src={`${cloudFrontUrl}${header?.pageStructure?.logo}`}
                alt="Rescale Lab"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="col-lg-6 my-auto">
              <div className="headerfloat">
                <div className="dropdown">
                  <div data-bs-toggle="dropdown" aria-expanded="false">
                    {!profile?.profileImage ? (
                      <div className="messageimageletternavbar">
                        {GetInitials(profile?.firstName)}
                      </div>
                    ) : null}
                    <img
                      className="userprofilenavbar"
                      src={`${cloudFrontUrl}${profile?.profileImage}`}
                      alt=""
                      hidden={!profile?.profileImage}
                    />
                  </div>
                  <ul className="dropdown-menu dropalign">
                    <li>
                      <Link className="dropdown-item" to="/Profile">
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ChangePassword">
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/adminlogin"
                        id="logout-hover"
                        onClick={logout}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Sidebar /> */}
    </>
  );
};

export default AdminHeader;
