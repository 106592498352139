import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg } from "../../Toastify";
import { ContactUsSpecificView } from "../../../Repository/Api";
import moment from "moment";

const ContactrequestSpecificview = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSpecificlist();
  }, []);

  const [contact, setContact] = useState([]);

  const getSpecificlist = async () => {
    const response = await ContactUsSpecificView(id, token);
    if (response && response.statusCode === 200) {
      setContact(response.data);
    } else {
      errorMsg(response.message);
    }
  };
  const name = contact.firstName + " " + contact.lastName;
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">
                  <span
                    className="arrowicon"
                    onClick={() => {
                      navigate("/admin/Contactrequestlist");
                    }}
                  >
                    ← &nbsp;
                  </span>
                  Contact Us List
                </h6>
              </div>
            </div>
            <div className="contentbox mt-5">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <label className="labeltext">Name</label>
                  <div>
                    <input
                      type="text"
                      className="fieldinput"
                      Value={name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Email Id</label>
                  <div>
                    <input
                      type="text"
                      className="fieldinput"
                      value={contact?.emailId}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Country</label>
                  <div>
                    <input
                      type="text"
                      className="fieldinput"
                      value={contact?.country}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Company Name</label>
                  <div>
                    <input
                      type="text"
                      className="fieldinput"
                      value={contact?.companyName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Job Title</label>
                  <div>
                    <input
                      type="text"
                      className="fieldinput"
                      value={contact?.jobTitle}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Created At</label>
                  <div>
                    <input
                      type="date"
                      className="fieldinput"
                      value={moment(contact?.createdAt).format("yyyy-MM-DD")}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="labeltext">Comments</label>
                  <div>
                    <textarea
                      type="date"
                      className="fieldinput"
                      value={contact?.message}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactrequestSpecificview;
