import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Toastify";
import {
  GetAdminPartners,
  DeleteBlog,
  DeletePartner,
} from "../../../Repository/Api";
import CustomModal from "../../../Containers/CustomModal";

const PartnersList = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    getpartners();
  }, []);

  const [partners, setpartners] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [Id, setDelId] = useState();

  const getpartners = async () => {
    const response = await GetAdminPartners(token);
    if (response && response.statusCode === 200) {
      setpartners(response?.data);
    } else {
      errorMsg(response.message);
    }
  };
  const DeleteIcon = async () => {
    const payload = {
      _id: Id,
    };
    const response = await DeletePartner(token, payload);
    if (response && response.statusCode === 200) {
      setOpenModal(false);
      successMsg(response?.message);
      getpartners();
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div>
      //         <div
      //           className="namelink"
      //           onClick={() =>
      //             navigate(`/edit/partners/${tableMeta.rowData[0]}`)
      //           }
      //         >
      //           {value}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    },
    {
      name: "displayOrder",
      label: "Display Order",
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4] === "active" ? true : false;
          return (
            <div className="d-flex">
              <span
                className="material-symbols-outlined me-3"
                style={{ color: "red" }}
                onClick={() => {
                  setOpenModal(true);
                  setDelId(tableMeta.rowData[0]);
                }}
              >
                delete
              </span>
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }}
                onClick={() =>
                  navigate(`/admin/edit/partners/${tableMeta.rowData[0]}`)
                }
              >
                edit_square
              </span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Partners List</h6>
              </div>
              <div className="col-lg-6">
                <div className="headerfloat">
                  <button
                    className="buttonmodel"
                    onClick={() => {
                      navigate("/admin/create/partners");
                    }}
                  >
                    Add Partner
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <MUIDataTable
                title={"Partners List"}
                data={partners}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
        }}
      >
        <div className="delete-modal nunito">
          <span className="icon-container" onClick={() => setOpenModal(false)}>
            <i className="fa-solid fa-circle-xmark"></i>
          </span>
          <p className="h1 mb-4">Confirmation</p>
          <p className="h3">Are you sure you want to delete this?</p>
          <div className="mt-4 d-flex justify-content-around">
            <button
              className="btn-cancel h-auto me-3"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary h-auto" onClick={DeleteIcon}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default PartnersList;
