import React, { useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation } from "../../../Store/validate";
import { CreateFaq } from "../../../Repository/Api";
import { useSelector } from "react-redux";
//import modules forEditor
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

const FaqCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createFaq, setcreateFaq] = useState({});
  const [validation, setValidation] = useState({});
  //forEditor
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const setData = (e, key) => {
    setcreateFaq({ ...createFaq, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  //forEditor
  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setData(html, "answer");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.question = NonEmptyValidation(createFaq?.question);
    //forEditor
    validate.answer = NonEmptyValidation(createFaq?.answer);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        question: createFaq.question,
        //forEditor
        answer: createFaq.answer,
      };
      const response = await CreateFaq(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg("Successfully FAQ Created");
        setTimeout(() => {
          setTimeout(navigate("/admin/FaqList"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/FaqList");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">FAQ List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create FAQ
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="labeltext">Question *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Question"
                            defaultValue={createFaq?.question}
                            onChange={(e) => {
                              setData(e.target.value, "question");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.question?.message
                            ? `Question ${validation?.question?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Answer *</label>
                        {/* forEditor */}
                        <div className="fieldinput">
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={editorState}
                            toolbar={{
                              options: ["list"],
                              list: { options: ["unordered"] },
                            }}
                            onEditorStateChange={(e) => handleEditorChange(e)}
                          />
                        </div>

                        <small className="validationerror">
                          {validation?.answer?.message
                            ? `Answer ${validation?.answer?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="row my-4">
                        <div className="col-lg-3 centertext ms-auto mx-auto">
                          <button className="buttonmodel" type="submit">
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqCreate;
