import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Foruniversitiescontent from '../Components/Foruniversitiescontent'

const Foruniversities = () => {
  return (
    <div>
        {/* <Header solution={true}/> */}
        <Foruniversitiescontent/>
        {/* <Footer/> */}
    </div>
  )
}

export default Foruniversities