import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdminUniversitiespage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const ForUniversitytPageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms);
  const [validation, setValidation] = useState({});
  useEffect(() => {
    GetData();
  }, []);
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  console.log(err);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(key);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage"
        //  &&
        // dummy?.size <= 400
        //  &&
        // dummy?.height === 448 &&
        // dummy?.width === 672
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons1" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons2"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "graphImage"
        //  &&
        // dummy?.size <= 200
        //  &&
        // dummy?.height === 354 &&
        // dummy?.width === 1024
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              graphImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "stepsSectionImage"
        //  &&
        // dummy?.size <= 100
        // &&
        // dummy?.height <= 330 &&
        // dummy?.width <= 1160
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              stepsSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "accountabilitySectionImage" 
        // &&
        // dummy?.size <= 100
        //  &&
        // dummy?.height <= 350 &&
        // dummy?.width <= 426
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              accountabilitySectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "decisionsSectionImage" 
        // &&
        // dummy?.size <= 300
        // &&
        // dummy?.height <= 400 &&
        // dummy?.width <= 420
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              decisionsSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons0"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.universitiesSectionContent;
          const others =
            createcms?.pageStructure?.universitiesSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              universitiesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons1" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.universitiesSectionContent;
          const others =
            createcms?.pageStructure?.universitiesSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              universitiesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.universitiesSectionContent;
          const others =
            createcms?.pageStructure?.universitiesSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              universitiesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons3" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.universitiesSectionContent;
          const others =
            createcms?.pageStructure?.universitiesSectionContent[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              universitiesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialSectionImage" 
        // &&
        // dummy?.size <= 500
        //  &&
        // dummy?.width === 700 &&
        // dummy?.height === 600
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminUniversitiespage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.learningSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.learningSectionHeading
    );
    validate.learningSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.learningSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.learningSectionContent)) {
      validate.learningSectionContent =
        createcms?.pageStructure?.learningSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          heading: NonEmptyValidation(d?.heading),
          subHeading: NonEmptyValidation(d?.subHeading),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.graphImage = NonEmptyValidation(
      createcms?.pageStructure?.graphImage
    );
    validate.stepsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionHeading
    );
    validate.stepsSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionSubHeading
    );
    validate.stepsSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionImage
    );
    validate.accountabilitySectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.accountabilitySectionHeading
    );
    validate.accountabilitySectionImage = NonEmptyValidation(
      createcms?.pageStructure?.accountabilitySectionImage
    );
    validate.accountabilitySectionContent = NonEmptyValidation(
      createcms?.pageStructure?.accountabilitySectionContent
    );
    validate.accountabilitySectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.accountabilitySectionButtonName
    );
    validate.accountabilitySectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.accountabilitySectionButtonRedirectLink
    );
    validate.decisionsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionHeading
    );
    validate.decisionsSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionImage
    );
    validate.decisionsSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionContent
    );
    validate.decisionsSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionButtonName
    );
    validate.decisionsSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.decisionsSectionButtonRedirectLink
    );
    validate.universitiesSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.universitiesSectionHeading
    );
    validate.universitiesSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.universitiesSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.universitiesSectionContent)) {
      validate.universitiesSectionContent =
        createcms?.pageStructure?.universitiesSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          content: NonEmptyValidation(d?.content),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.partnersSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionHeading
    );
    validate.partnersSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionSubHeading
    );
    validate.partnersSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionContent
    );
    validate.partnersSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonName
    );
    validate.partnersSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonRedirectLink
    );
    validate.testimonialSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionHeading
    );
    validate.testimonialSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionImage
    );

    // <=============================================================================================>

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.heading.status &&
            menu.subHeading.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.learningSectionContent);
    const isTrue3 = areAllStatusTrue3(validate.universitiesSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "learningSectionContent",
      "universitiesSectionContent",
    ];
    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue3 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">

            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    For Universities Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>
            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        {/* Banner section */}
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Heading"
                              maxLength={50}
                              defaultValue={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 390 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Sub Heading"
                              maxLength={390}
                              defaultValue={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  390,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              defaultValue={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="foruniversitybannerimg"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="foruniversitybannerimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB, 672px X 448px.
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-12">
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`forunivbannericons${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`forunivbannericons${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 270px X
                                        130px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.image
                                            ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          defaultValue={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.bannerIcons &&
                                              validation?.bannerIcons[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.learningSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              defaultValue={
                                createcms?.pageStructure?.learningSectionHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    learningSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "learningSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  learningSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.learningSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.learningSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.learningSectionHeading?.message
                                ? `Heading ${validation?.learningSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={50}
                              placeholder="Enter Section sub Heading"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.learningSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    learningSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "learningSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  learningSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.learningSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.learningSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.learningSectionSubHeading?.message
                                ? `sub Heading ${validation?.learningSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.learningSectionContent &&
                            createcms?.pageStructure?.learningSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`forunivchallengeicon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `ChallengeIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`forunivchallengeicon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 76px X 76px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.learningSectionContent
                                          ?.length > 0 &&
                                          validation?.learningSectionContent[i]
                                            ?.image?.message
                                          ? `Banner Icon ${validation?.learningSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">{" "}(Maximum 50 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={50}
                                          defaultValue={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              50,
                                              "heading",
                                              "learningSectionContent",
                                              "learningSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.learningSectionContent &&
                                              validation
                                                ?.learningSectionContent[i]
                                                ?.heading;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.learningSectionContent1
                                        ?.length > 0 &&
                                        err?.errorstate?.learningSectionContent1[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.learningSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.learningSectionContent
                                            ?.length > 0 &&
                                            validation?.learningSectionContent[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.learningSectionContent[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Sub Heading
                                        <span className="char-hint">{" "}(Maximum 100 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={100}
                                          defaultValue={item?.subHeading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              100,
                                              "subHeading",
                                              "learningSectionContent",
                                              "learningSectionContent2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.learningSectionContent &&
                                              validation
                                                ?.learningSectionContent[i]
                                                ?.subHeading;
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].subHeading = v);
                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.learningSectionContent2
                                        ?.length > 0 &&
                                        err?.errorstate?.learningSectionContent2[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.learningSectionContent2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.learningSectionContent
                                            ?.length > 0 &&
                                            validation?.learningSectionContent[i]
                                              ?.subHeading?.message
                                            ? `Content ${validation?.learningSectionContent[i]?.subHeading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          defaultValue={item?.displayOrder}
                                          onChange={(e) => {
                                            const learningSectionContent =
                                              createcms?.pageStructure
                                                ?.learningSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            learningSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                learningSectionContent:
                                                  learningSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.learningSectionContent &&
                                              validation
                                                ?.learningSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.learningSectionContent
                                          ?.length > 0 &&
                                          validation?.learningSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.learningSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.learningSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.learningSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      learningSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Graph Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Graph Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Graph Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forunivgraph"
                              onChange={(e) => UploadDocument(e, "graphImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forunivgraph"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.graphImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.graphImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 300KB, Max 1024px X 354px
                          </small>
                          <small className="validationerror">
                            {validation?.graphImage?.message
                              ? `Image ${validation?.graphImage?.message}`
                              : ""}
                          </small>
                        </div>

                        
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Graph</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.graphImageShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      graphImageShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 5 steps */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      5 Steps Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="foruniv5steps"
                              onChange={(e) =>
                                UploadDocument(e, "stepsSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="foruniv5steps"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.stepsSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.stepsSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 1160px X 330px
                          </small>
                          <small className="validationerror">
                            {validation?.stepsSectionImage?.message
                              ? `Image ${validation?.stepsSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 15 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={15}
                              placeholder="Enter Banner Heading"
                              defaultValue={
                                createcms?.pageStructure?.stepsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  15,
                                  setErr,
                                  "stepsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={20}
                              placeholder="Enter Banner Heading"
                              defaultValue={
                                createcms?.pageStructure?.stepsSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "stepsSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionSubHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.stepsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      stepsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* design section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.accountabilitySectionHeading}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/* accountabilitySection Image */}
                          <label className="labeltext">
                            Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forunivaccountimg1"
                              onChange={(e) =>
                                UploadDocument(e, "accountabilitySectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forunivaccountimg1"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.accountabilitySectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.accountabilitySectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 426px X 350px
                          </small>
                          <small className="validationerror">
                            {validation?.accountabilitySectionImage?.message
                              ? `accountabilitySection Image ${validation?.accountabilitySectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* accountabilitySection Heading */}
                          <label className="labeltext">
                            Heading
                            <span className="char-hint">{" "}(Maximum 40 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter accountabilitySection Heading"
                              maxLength={40}
                              defaultValue={
                                createcms?.pageStructure
                                  ?.accountabilitySectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    accountabilitySectionHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  40,
                                  setErr,
                                  "accountabilitySectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  accountabilitySectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.accountabilitySectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.accountabilitySectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.accountabilitySectionHeading?.message
                                ? `accountabilitySection Heading ${validation?.accountabilitySectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* accountabilitySection Sub Heading */}
                          <label className="labeltext">
                            Content
                            <span className="char-hint">{" "}(Maximum 450 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter accountabilitySection Sub Heading"
                              maxLength={450}
                              defaultValue={
                                createcms?.pageStructure
                                  ?.accountabilitySectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    accountabilitySectionContent:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  450,
                                  setErr,
                                  "accountabilitySectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  accountabilitySectionContent:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.accountabilitySectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.accountabilitySectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.accountabilitySectionContent?.message
                                ? `accountabilitySection Sub Heading ${validation?.accountabilitySectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* accountabilitySection Button Name */}
                          <label className="labeltext">
                            Button Name
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter accountabilitySection Button Name"
                              maxLength={50}
                              defaultValue={
                                createcms?.pageStructure
                                  ?.accountabilitySectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    accountabilitySectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "accountabilitySectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  accountabilitySectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.accountabilitySectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.accountabilitySectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.accountabilitySectionButtonName
                                ?.message
                                ? `accountabilitySection Button Name ${validation?.accountabilitySectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* accountabilitySection Button Redirect Link */}
                          <label className="labeltext">
                            Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter accountabilitySection Button Redirect Link"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.accountabilitySectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    accountabilitySectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  accountabilitySectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.accountabilitySectionButtonRedirectLink
                              ?.message
                              ? `accountabilitySection Button Redirect Link ${validation?.accountabilitySectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show accountabilitySection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.accountabilitySectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      accountabilitySectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                       
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show accountabilitySection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.accountabilitySectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      accountabilitySectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Key Features */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.decisionsSectionHeading}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/* decisionsSection Image */}
                          <label className="labeltext">
                            Desicion Section Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forunivdecisionimg"
                              onChange={(e) =>
                                UploadDocument(e, "decisionsSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forunivdecisionimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.decisionsSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.decisionsSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 300KB, Max 420px X 400px
                          </small>
                          <small className="validationerror">
                            {validation?.decisionsSectionImage?.message
                              ? `decisionsSection Image ${validation?.decisionsSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* decisionsSection Heading */}
                          <label className="labeltext">
                            Desicion Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={50}
                              placeholder="Enter decisionsSection Heading"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.decisionsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "decisionsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsSectionHeading?.message
                                ? `decisionsSection Heading ${validation?.decisionsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* decisionsSection Sub Heading */}
                          <label className="labeltext">
                            Desicion Section Content
                            <span className="char-hint">{" "}(Maximum 500 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter decisionsSection Sub Heading"
                              maxLength={500}
                              defaultValue={
                                createcms?.pageStructure
                                  ?.decisionsSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  500,
                                  setErr,
                                  "decisionsSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsSectionContent?.message
                                ? `decisionsSection Sub Heading ${validation?.decisionsSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* decisionsSection Button Name */}
                          <label className="labeltext">
                            Desicion Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter decisionsSection Button Name"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.decisionsSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "decisionsSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.decisionsSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.decisionsSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.decisionsSectionButtonName?.message
                                ? `decisionsSection Button Name ${validation?.decisionsSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* decisionsSection Button Redirect Link */}
                          <label className="labeltext">
                            Desicion Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter decisionsSection Button Redirect Link"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.decisionsSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    decisionsSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  decisionsSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.decisionsSectionButtonRedirectLink
                              ?.message
                              ? `decisionsSection Button Redirect Link ${validation?.decisionsSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show decisionsSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Desicion Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.decisionsSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      decisionsSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                     
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show decisionsSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Desicion Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.decisionsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      decisionsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.universitiesSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={20}
                              placeholder="Enter Section Heading"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.universitiesSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    universitiesSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "universitiesSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  universitiesSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.universitiesSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.universitiesSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.universitiesSectionHeading?.message
                                ? `Heading ${validation?.universitiesSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              maxLength={30}
                              defaultValue={
                                createcms?.pageStructure
                                  ?.universitiesSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    universitiesSectionSubHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "universitiesSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  universitiesSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.universitiesSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.universitiesSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.universitiesSectionSubHeading
                                ?.message
                                ? `sub Heading ${validation?.universitiesSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure
                            ?.universitiesSectionContent &&
                            createcms?.pageStructure?.universitiesSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`forunivimglast1${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `corporateIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`forunivimglast1${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, 76px X 76px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.universitiesSectionContent
                                          ?.length > 0 &&
                                          validation?.universitiesSectionContent[
                                            i
                                          ]?.image?.message
                                          ? `Banner Icon ${validation?.universitiesSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          defaultValue={item?.displayOrder}
                                          onChange={(e) => {
                                            const universitiesSectionContent =
                                              createcms?.pageStructure
                                                ?.universitiesSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            universitiesSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                universitiesSectionContent:
                                                  universitiesSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.universitiesSectionContent &&
                                              validation
                                                ?.universitiesSectionContent[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.universitiesSectionContent
                                              ?.length > 0 &&
                                              (validation.universitiesSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.universitiesSectionContent
                                          ?.length > 0 &&
                                          validation?.universitiesSectionContent[
                                            i
                                          ]?.displayOrder?.message
                                          ? `Display Order ${validation?.universitiesSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 50 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={50}
                                          defaultValue={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              50,
                                              "content",
                                              "universitiesSectionContent",
                                              "universitiesSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const f =
                                              validation?.universitiesSectionContent &&
                                              validation
                                                ?.universitiesSectionContent[i]
                                                ?.content;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation
                                              ?.universitiesSectionContent
                                              ?.length > 0 &&
                                              (validation.universitiesSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate
                                        ?.universitiesSectionContent1?.length >
                                        0 &&
                                        err?.errorstate
                                          ?.universitiesSectionContent1[i]
                                          ?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.universitiesSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation
                                            ?.universitiesSectionContent
                                            ?.length > 0 &&
                                            validation
                                              ?.universitiesSectionContent[i]
                                              ?.content?.message
                                            ? `Content ${validation?.universitiesSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                               
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.universitiesSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.universitiesSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      universitiesSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Partner Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.partnersSectionHeading}{" "}
                      {createcms?.pageStructure?.partnersSectionSubHeading}
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter partnersSection Heading"
                              maxLength={50}
                              defaultValue={
                                createcms?.pageStructure?.partnersSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partnersSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={50}
                              placeholder="Enter partnersSection Heading"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.partnersSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partnersSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionSubHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Sub Heading */}
                          <label className="labeltext">
                            Partner Section Content
                            <span className="char-hint">{" "}(Maximum 300 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              maxLength={300}
                              placeholder="Enter partnersSection Content"
                              defaultValue={
                                createcms?.pageStructure?.partnersSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "partnersSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionContent?.message
                                ? `partnersSection Sub Heading ${validation?.partnersSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Name */}
                          <label className="labeltext">
                            Partners Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter partnersSection Button Name"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partnersSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionButtonName?.message
                                ? `partnersSection Button Name ${validation?.partnersSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Redirect Link */}
                          <label className="labeltext">
                            Partners Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter partnersSection Button Redirect Link"
                              defaultValue={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.partnersSectionButtonRedirectLink
                              ?.message
                              ? `partnersSection Button Redirect Link ${validation?.partnersSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.partnersSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.partnersSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <=======================================================================================> */}
                  {/* <=======================================================================================> */}
                </div>
                {/* Testimonial section */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Testimonial Section
                  </p>
                  <div className="p-4">
                    <div className="row gx-5">
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Image */}
                        <label className="labeltext">
                          Testimonial Background Image
                        </label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="forunivtestimonial"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(e, "testimonialSectionImage")
                            }
                          />
                          <label
                            for="forunivtestimonial"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createcms?.pageStructure
                            ?.testimonialSectionImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createcms?.pageStructure?.testimonialSectionImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 500KB, 700px X 600px
                        </small>
                        <small className="validationerror">
                          {validation?.bannerImage?.message
                            ? `Image ${validation?.testimonialSectionImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Heading */}
                        <label className="labeltext">Testimonial Heading
                          <span className="char-hint">{" "}(Maximum 25 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Testimonial Heading"
                            maxLength={25}
                            defaultValue={
                              createcms?.pageStructure
                                ?.testimonialSectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  testimonialSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                25,
                                setErr,
                                "testimonialSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                testimonialSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.testimonialSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.testimonialSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.testimonialSectionHeading?.message
                              ? `Testimonial Heading ${validation?.testimonialSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Testimonial Section
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.testimonialSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    testimonialSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForUniversitytPageManagement;
