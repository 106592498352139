import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  DeleteAdmin,
  DeleteSocialIconList,
  GetAdminList,
  UpdateAdmin,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import CustomModal from "../../../Containers/CustomModal";

const Adminlist = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    getAdminlist();
  }, []);

  const [adminList, setAdminList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [Id, setDelId] = useState();

  const getAdminlist = async () => {
    const response = await GetAdminList(token);
    if (response && response.statusCode === 200) {
      setAdminList(response?.data?.userDetails);
    } else {
      errorMsg(response.message);
    }
  };

  const DeleteIcon = async () => {
    const payload = {
      _id: Id,
    };
    const response = await DeleteAdmin(token, payload);
    if (response && response.statusCode === 200) {
      setOpenModal(false);
      successMsg(response?.message);
      getAdminlist();
    }
  };

  const handleStatus = async (payload) => {
    const response = await UpdateAdmin(payload, token);
    if (response && response.statusCode === 200) {
      getAdminlist();
      successMsg("Status Updated Successfully");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "firstName",
      label: "Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div
      //         className="namelink"
      //         onClick={() =>
      //           navigate(`/AdminSpecificview/${tableMeta.rowData[0]}`)
      //         }
      //       >
      //         {value}
      //       </div>
      //     );
      //   },
      // },
    },
    { name: "emailId", label: "Email" },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <div className="textformat">{value}</div>;
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[3] === "active" ? true : false;
          return (
            <div className="d-flex">
              <span
                className="material-symbols-outlined me-3"
                style={{ color: "red" }}
                onClick={() => {
                  setOpenModal(true);
                  setDelId(tableMeta.rowData[0]);
                }}
              >
                delete
              </span>
              <span
                className="material-symbols-outlined"
                style={{ color: "green" }}
                onClick={() => {
                  navigate(`/admin/AdminSpecificview/${tableMeta.rowData[0]}`);
                }}
              >
                edit_square
              </span>
              <span className="ms-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={status}
                    onChange={async (e) => {
                      const status =
                        e.target.checked === true ? "active" : "inActive";
                      const payload = {
                        _id: tableMeta.rowData[0],
                        status: status,
                      };
                      handleStatus(payload);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </span>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="contenttitle">Admin List</h6>
              </div>
              <div className="col-lg-6">
                <div className="headerfloat">
                  <button
                    className="buttonmodel"
                    onClick={() => {
                      navigate("/admin/AdminCreate");
                    }}
                  >
                    Add Admin
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <MUIDataTable
                title={"Admin List"}
                data={adminList}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
        }}
      >
        <div className="delete-modal nunito">
          <span
            onClick={() => setOpenModal(false)}
            className="icon-container" // Add a class for styling
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </span>
          <p className="h1 mb-4">Confirmation</p>
          <p className="h3">Are you sure you want to delete this?</p>
          <div className="mt-4 d-flex justify-content-around">
            <button
              className="btn-cancel h-auto me-3"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary h-auto" onClick={DeleteIcon}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Adminlist;
