import React from 'react'
import Pricingpage from '../Components/Pricingpage'

const Pricing = () => {
  return (
    <div>
      <Pricingpage />
    </div>
  )
}

export default Pricing