import React, { useState, useEffect } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  URLValidation,
  LengthValidation,
} from "../../../Store/validate";
import { CreateSocial, ImageUpload } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const SocialmediaCreate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();

  const [createSocial, setCreateSocial] = useState({});
  const [validation, setValidation] = useState({});

  const handleChange = (e, key) => {
    setCreateSocial({ ...createSocial, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.mediaType = NonEmptyValidation(createSocial?.mediaType);
    validate.link = URLValidation(createSocial?.link);
    validate.icon = NonEmptyValidation(createSocial?.icon);

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        mediaType: createSocial?.mediaType,
        link: createSocial?.link,
        icon: createSocial?.icon,
      };
      const response = await CreateSocial(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/Socialmedialist"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target.files[0].name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
    // const response = await ImageUpload(formData);
    // if (response && response.statusCode === 200) {
    //   handleChange(response.data._id, key);
    //   successMsg("Image Uploaded Successfully");
    // } else {
    //   errorMsg(response.message);
    // }
  };
  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      console.log([...dummy?.formData]);
      if (
        dummy?.image === "icon"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 45 &&
        // dummy?.width === 45
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          handleChange(response.data.path, "icon");
          successMsg("Image Uploaded Successfully");
        } else {
          errorMsg(response.message);
        }
      } else {
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      ImageUploadin();
    }
  }, [data]);
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Socialmedialist");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4"> Social Media List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox ">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Create Social Media Links
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Media Type *
                          <span className="char-hint">
                            {" "}
                            (Maximum 15 characters)
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Media Type"
                            maxLength={15}
                            defaultValue={createSocial?.mediaType}
                            onChange={(e) => {
                              handleChange(e.target.value, "mediaType");
                              if (e.target.value.length === 15) {
                                setValidation({
                                  ...validation,
                                  mediaType: LengthValidation(
                                    e.target.value.length
                                  ),
                                });
                                setTimeout(() => {
                                  setValidation({
                                    ...validation,
                                    mediaType: "",
                                  });
                                }, 3000);
                              }
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.mediaType?.message
                            ? `Media Type ${validation?.mediaType?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Link *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Link"
                            defaultValue={createSocial?.link}
                            onChange={(e) => {
                              handleChange(e.target.value, "link");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.link?.message
                            ? `Link ${validation?.link?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Icon *</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="socialmediacreate"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "icon")}
                          />
                          <label for="socialmediacreate" className="alt-btn">
                            Upload Image
                          </label>

                          {!createSocial.icon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createSocial.icon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 20KB, 45px X 45px.
                        </small>
                        <small className="validationerror">
                          {validation?.icon?.message
                            ? `Icon ${validation?.icon?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Create</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialmediaCreate;
