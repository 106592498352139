import React from 'react'
import Platformcontent from '../Components/Submenus/Platformcontent'

const Platform = () => {
  return (
    <div>
      {/* <Header/> */}
      <Platformcontent />
      {/* <Footer/> */}
    </div>
  )
}

export default Platform