import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdminForTrainingProviderspage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const ForTrainingProvidersPageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms);
  const [validation, setValidation] = useState({});
  useEffect(() => {
    GetData();
  }, []);
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  console.log(err);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(key);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage"
        //  &&
        // dummy?.size <= 300
        // &&
        // dummy?.width === 536 &&
        // dummy?.height === 475
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2" 
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons1"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.learningSectionContent;
          const others = createcms?.pageStructure?.learningSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              learningSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "graphImage" 
        // &&
        // dummy?.size <= 300
        // &&
        // dummy?.height === 354 &&
        // dummy?.width === 1024
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              graphImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "stepsSectionImage" 
        // &&
        // dummy?.size <= 100
        // &&
        // dummy?.height <= 310 &&
        // dummy?.width <= 1160
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              stepsSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "masterTrainerSectionImage" 
        // &&
        // dummy?.size <= 100
        // &&
        // dummy?.height <= 400 &&
        // dummy?.width <= 426
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              masterTrainerSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "featuresSectionImage" 
        // &&
        // dummy?.size <= 150
        // &&
        // dummy?.height <= 400 &&
        // dummy?.width <= 450
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              featuresSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.trainersSectionContent;
          const others = createcms?.pageStructure?.trainersSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              trainersSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons1" 
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.trainersSectionContent;
          const others = createcms?.pageStructure?.trainersSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              trainersSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.trainersSectionContent;
          const others = createcms?.pageStructure?.trainersSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              trainersSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons3" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.trainersSectionContent;
          const others = createcms?.pageStructure?.trainersSectionContent[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              trainersSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialSectionImage" 
        // &&
        // dummy?.size <= 300
        // &&
        // dummy?.height <= 600 &&
        // dummy?.width <= 700
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialSectionImage" 
        // &&
        // dummy?.size <= 500
        // &&
        // dummy?.width === 700 &&
        // dummy?.height === 600
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminForTrainingProviderspage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.learningSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.learningSectionHeading
    );
    validate.learningSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.learningSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.learningSectionContent)) {
      validate.learningSectionContent =
        createcms?.pageStructure?.learningSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          heading: NonEmptyValidation(d?.heading),
          subHeading: NonEmptyValidation(d?.subHeading),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.graphImage = NonEmptyValidation(
      createcms?.pageStructure?.graphImage
    );
    validate.stepsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionHeading
    );
    validate.stepsSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionSubHeading
    );
    validate.stepsSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionImage
    );
    validate.masterTrainerSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.masterTrainerSectionHeading
    );
    validate.masterTrainerSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.masterTrainerSectionImage
    );
    validate.masterTrainerSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.masterTrainerSectionContent
    );
    validate.masterTrainerSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.masterTrainerSectionButtonName
    );
    validate.masterTrainerSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.masterTrainerSectionButtonRedirectLink
    );
    validate.featuresSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionHeading
    );
    validate.featuresSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionImage
    );
    validate.featuresSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionContent
    );
    validate.featuresSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionButtonName
    );
    validate.featuresSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.featuresSectionButtonRedirectLink
    );
    validate.trainersSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.trainersSectionHeading
    );
    validate.trainersSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.trainersSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.trainersSectionContent)) {
      validate.trainersSectionContent =
        createcms?.pageStructure?.trainersSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          content: NonEmptyValidation(d?.content),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.partnersSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionHeading
    );
    validate.partnersSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionSubHeading
    );
    validate.partnersSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionContent
    );
    validate.partnersSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonName
    );
    validate.partnersSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonRedirectLink
    );
    validate.testimonialSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionHeading
    );
    validate.testimonialSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionImage
    );

    // <=============================================================================================>

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.heading.status &&
            menu.subHeading.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.learningSectionContent);
    const isTrue3 = areAllStatusTrue3(validate.trainersSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "learningSectionContent",
      "trainersSectionContent",
    ];
    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue3 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
          <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                  For Training Providers Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
               
              </div>
            </div>
          
            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row gx-5">
                  {/* Banner section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Heading"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 500 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Sub Heading"
                              maxLength={500}
                              value={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  500,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="fortrainimg1"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="fortrainimg1"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 300KB, 536px X 475px
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div>
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`fortrainimg2${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`fortrainimg2${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 270px X
                                        130px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.image
                                            ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.learningSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure?.learningSectionHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    learningSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "learningSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  learningSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.learningSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.learningSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.learningSectionHeading?.message
                                ? `Heading ${validation?.learningSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={50}
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.learningSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    learningSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "learningSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  learningSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.learningSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.learningSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.learningSectionSubHeading?.message
                                ? `sub Heading ${validation?.learningSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.learningSectionContent &&
                            createcms?.pageStructure?.learningSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`fortrainingimg3${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `ChallengeIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`fortrainingimg3${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 76px X 76px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.learningSectionContent
                                          ?.length > 0 &&
                                          validation?.learningSectionContent[i]
                                            ?.image?.message
                                          ? `Banner Icon ${validation?.learningSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Heading
                                        <span className="char-hint">{" "}(Maximum 40 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={40}
                                          value={item?.heading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              40,
                                              "heading",
                                              "learningSectionContent",
                                              "learningSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].heading = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.learningSectionContent1
                                        ?.length > 0 &&
                                        err?.errorstate?.learningSectionContent1[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.learningSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.learningSectionContent
                                            ?.length > 0 &&
                                            validation?.learningSectionContent[i]
                                              ?.heading?.message
                                            ? `Content ${validation?.learningSectionContent[i]?.heading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                       Content
                                        <span className="char-hint">{" "}(Maximum 100 characters)</span>

                                      </label>
                                      <div>
                                        <textarea                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={100}
                                          value={item?.subHeading}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              100,
                                              "subHeading",
                                              "learningSectionContent",
                                              "learningSectionContent2"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].subHeading = v);
                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.learningSectionContent2
                                        ?.length > 0 &&
                                        err?.errorstate?.learningSectionContent2[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.learningSectionContent2[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.learningSectionContent
                                            ?.length > 0 &&
                                            validation?.learningSectionContent[i]
                                              ?.subHeading?.message
                                            ? `Content ${validation?.learningSectionContent[i]?.subHeading?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const learningSectionContent =
                                              createcms?.pageStructure
                                                ?.learningSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            learningSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                learningSectionContent:
                                                  learningSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.learningSectionContent
                                              ?.length > 0 &&
                                              (validation.learningSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.learningSectionContent
                                          ?.length > 0 &&
                                          validation?.learningSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.learningSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.learningSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.learningSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      learningSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Graph Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Graph Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Graph Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="fortraininggraph"
                              onChange={(e) => UploadDocument(e, "graphImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="fortraininggraph"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.graphImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.graphImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 300KB, Max 1024px X 354px
                          </small>
                          <small className="validationerror">
                            {validation?.graphImage?.message
                              ? `Image ${validation?.graphImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Graph</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.graphImageShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      graphImageShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 5 steps */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      5 Steps Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="fortrain5steps"
                              onChange={(e) =>
                                UploadDocument(e, "stepsSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for='fortrain5steps'
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.stepsSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.stepsSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 1160px X 310px
                          </small>
                          <small className="validationerror">
                            {validation?.stepsSectionImage?.message
                              ? `Image ${validation?.stepsSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 15 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={15}
                              placeholder="Enter Banner Heading"
                              value={
                                createcms?.pageStructure?.stepsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  15,
                                  setErr,
                                  "stepsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={20}
                              placeholder="Enter Banner Heading"
                              value={
                                createcms?.pageStructure?.stepsSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "stepsSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionSubHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.stepsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      stepsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* design section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.masterTrainerSectionHeading}
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/* masterTrainerSection Image */}
                          <label className="labeltext">
                            Master Trainer Section Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="fortrainimg7"
                              onChange={(e) =>
                                UploadDocument(e, "masterTrainerSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="fortrainimg7"
                              className="alt-btn">
                              Upload Image
                            </label>
                            {!createcms?.pageStructure
                              ?.masterTrainerSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.masterTrainerSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 426px X 400px
                          </small>
                          <small className="validationerror">
                            {validation?.masterTrainerSectionImage?.message
                              ? `masterTrainerSection Image ${validation?.masterTrainerSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* masterTrainerSection Heading */}
                          <label className="labeltext">
                            Master Trainer Section Heading
                            <span className="char-hint">{" "}(Maximum 40 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter masterTrainerSection Heading"
                              maxLength={40}
                              value={
                                createcms?.pageStructure
                                  ?.masterTrainerSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    masterTrainerSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  40,
                                  setErr,
                                  "masterTrainerSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  masterTrainerSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.masterTrainerSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.masterTrainerSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.masterTrainerSectionHeading?.message
                                ? `masterTrainerSection Heading ${validation?.masterTrainerSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* masterTrainerSection Sub Heading */}
                          <label className="labeltext">
                            Master Trainer Section Content
                            <span className="char-hint">{" "}(Maximum 400 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter masterTrainerSection Sub Heading"
                              maxLength={400}
                              value={
                                createcms?.pageStructure
                                  ?.masterTrainerSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    masterTrainerSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  400,
                                  setErr,
                                  "masterTrainerSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  masterTrainerSectionContent:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.masterTrainerSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.masterTrainerSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.masterTrainerSectionContent?.message
                                ? `masterTrainerSection Sub Heading ${validation?.masterTrainerSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* masterTrainerSection Button Name */}
                          <label className="labeltext">
                            Master Trainer Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter masterTrainerSection Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.masterTrainerSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    masterTrainerSectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "masterTrainerSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  masterTrainerSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.masterTrainerSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.masterTrainerSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.masterTrainerSectionButtonName
                                ?.message
                                ? `masterTrainerSection Button Name ${validation?.masterTrainerSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* masterTrainerSection Button Redirect Link */}
                          <label className="labeltext">
                            Master Trainer Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter masterTrainerSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.masterTrainerSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    masterTrainerSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  masterTrainerSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.masterTrainerSectionButtonRedirectLink
                              ?.message
                              ? `masterTrainerSection Button Redirect Link ${validation?.masterTrainerSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show masterTrainerSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Master Trainer Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.masterTrainerSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      masterTrainerSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                   
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show masterTrainerSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Master Trainer Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.masterTrainerSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      masterTrainerSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Key Features */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.featuresSectionHeading} Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* featuresSection Heading */}
                          <label className="labeltext">
                            High Touch Section Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={30}
                              placeholder="Enter featuresSection Heading"
                              value={
                                createcms?.pageStructure?.featuresSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "featuresSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionHeading?.message
                                ? `featuresSection Heading ${validation?.featuresSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* featuresSection Sub Heading */}
                          <label className="labeltext">
                            High Touch Section Content
                            <span className="char-hint">{" "}(Maximum 500 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter featuresSection Sub Heading"
                              maxLength={500}
                              value={
                                createcms?.pageStructure?.featuresSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  500,
                                  setErr,
                                  "featuresSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionContent?.message
                                ? `featuresSection Sub Heading ${validation?.featuresSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* featuresSection Button Name */}
                          <label className="labeltext">
                            High Touch Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter featuresSection Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "featuresSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.featuresSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.featuresSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.featuresSectionButtonName?.message
                                ? `featuresSection Button Name ${validation?.featuresSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* featuresSection Button Redirect Link */}
                          <label className="labeltext">
                            High Touch Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter featuresSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.featuresSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    featuresSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  featuresSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.featuresSectionButtonRedirectLink
                              ?.message
                              ? `featuresSection Button Redirect Link ${validation?.featuresSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show featuresSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show High Touch Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.featuresSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* featuresSection Image */}
                          <label className="labeltext">
                            High Touch Section Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="fortrainfeatureimg9"
                              onChange={(e) =>
                                UploadDocument(e, "featuresSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="fortrainfeatureimg9"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.featuresSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.featuresSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 450px X 400px
                          </small>
                          <small className="validationerror">
                            {validation?.featuresSectionImage?.message
                              ? `featuresSection Image ${validation?.featuresSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show featuresSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show High Touch Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.featuresSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      featuresSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.trainersSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={30}
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure?.trainersSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    trainersSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "trainersSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  trainersSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.trainersSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.trainersSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.trainersSectionHeading?.message
                                ? `Heading ${validation?.trainersSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              maxLength={30}
                              value={
                                createcms?.pageStructure
                                  ?.trainersSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    trainersSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "trainersSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  trainersSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.trainersSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.trainersSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.trainersSectionSubHeading?.message
                                ? `sub Heading ${validation?.trainersSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        <div>
                          {createcms?.pageStructure?.trainersSectionContent &&
                            createcms?.pageStructure?.trainersSectionContent.map(
                              (item, i) => {
                                return (
                                  <div className="row divider">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`fortraininngimg11${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(
                                              e,
                                              `corporateIcons${i}`
                                            )
                                          }
                                        />
                                        <label
                                          for={`fortraininngimg11${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, 76px X 76px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.trainersSectionContent
                                          ?.length > 0 &&
                                          validation?.trainersSectionContent[i]
                                            ?.image?.message
                                          ? `Banner Icon ${validation?.trainersSectionContent[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const trainersSectionContent =
                                              createcms?.pageStructure
                                                ?.trainersSectionContent;
                                            const updatedObj = {
                                              ...item,
                                              displayOrder: e.target.value,
                                            };
                                            trainersSectionContent[i] =
                                              updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                trainersSectionContent:
                                                  trainersSectionContent,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.trainersSectionContent
                                              ?.length > 0 &&
                                              (validation.trainersSectionContent[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.trainersSectionContent
                                          ?.length > 0 &&
                                          validation?.trainersSectionContent[i]
                                            ?.displayOrder?.message
                                          ? `Display Order ${validation?.trainersSectionContent[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Content
                                        <span className="char-hint">{" "}(Maximum 50 characters)</span>

                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Content"
                                          maxLength={50}
                                          value={item?.content}
                                          onChange={(e) =>
                                            mapInputOnchange(
                                              e,
                                              i,
                                              50,
                                              "content",
                                              "trainersSectionContent",
                                              "trainersSectionContent1"
                                            )
                                          }
                                          onBlur={(e) => {
                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.trainersSectionContent
                                              ?.length > 0 &&
                                              (validation.trainersSectionContent[
                                                i
                                              ].content = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      {err?.errorstate?.trainersSectionContent1
                                        ?.length > 0 &&
                                        err?.errorstate?.trainersSectionContent1[
                                          i
                                        ]?.message ? (
                                        <small className="validationerror length-err">
                                          <span class="material-symbols-outlined len-err-img">
                                            warning
                                          </span>
                                          {`${err?.errorstate?.trainersSectionContent1[i]?.message}`}
                                        </small>
                                      ) : (
                                        <small className="validationerror">
                                          {validation?.trainersSectionContent
                                            ?.length > 0 &&
                                            validation?.trainersSectionContent[i]
                                              ?.content?.message
                                            ? `Content ${validation?.trainersSectionContent[i]?.content?.message}`
                                            : ""}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.trainersSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.trainersSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      trainersSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Partner Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.partnersSectionHeading}{" "}
                      {createcms?.pageStructure?.partnersSectionSubHeading}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Heading
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter partnersSection Heading"
                              maxLength={25}
                              value={
                                createcms?.pageStructure?.partnersSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partnersSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={25}
                              placeholder="Enter partnersSection Heading"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partnersSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionSubHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Sub Heading */}
                          <label className="labeltext">
                            Partner Section Content
                            <span className="char-hint">{" "}(Maximum 380 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              maxLength={380}
                              placeholder="Enter partnersSection Content"
                              value={
                                createcms?.pageStructure?.partnersSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  380,
                                  setErr,
                                  "partnersSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionContent?.message
                                ? `partnersSection Sub Heading ${validation?.partnersSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Name */}
                          <label className="labeltext">
                            Partners Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter partnersSection Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partnersSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionButtonName?.message
                                ? `partnersSection Button Name ${validation?.partnersSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Redirect Link */}
                          <label className="labeltext">
                            Partners Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter partnersSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.partnersSectionButtonRedirectLink
                              ?.message
                              ? `partnersSection Button Redirect Link ${validation?.partnersSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.partnersSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.partnersSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <=======================================================================================> */}
                  {/* <=======================================================================================> */}
                </div>
                {/* Testimonial section */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Testimonial Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Image */}
                        <label className="labeltext">
                          Testimonial Background Image
                        </label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="testimonialfortrainers"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(e, "testimonialSectionImage")
                            }
                          />
                          <label
                            for="testimonialfortrainers"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createcms?.pageStructure
                            ?.testimonialSectionImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createcms?.pageStructure?.testimonialSectionImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 300KB, Max 700px X 600px.
                        </small>
                        <small className="validationerror">
                          {validation?.bannerImage?.message
                            ? `Image ${validation?.testimonialSectionImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Heading */}
                        <label className="labeltext">Testimonial Heading
                          <span className="char-hint">{" "}(Maximum 25 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Testimonial Heading"
                            maxLength={25}
                            value={
                              createcms?.pageStructure
                                ?.testimonialSectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  testimonialSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                25,
                                setErr,
                                "testimonialSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                testimonialSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.testimonialSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.testimonialSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.testimonialSectionHeading?.message
                              ? `Testimonial Heading ${validation?.testimonialSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Testimonial Section
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.testimonialSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    testimonialSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForTrainingProvidersPageManagement;
