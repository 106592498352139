import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import CategoryBlogs from '../Components/CategoryBlogs'

const CategoryBlog = () => {
  return (
    <div>
      <Header />
      <CategoryBlogs />
      <Footer />
    </div>
  )
}

export default CategoryBlog