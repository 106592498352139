import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Fortrainingproviderscontent from '../Components/Fortrainingprovidercontent'

const Fortrainingprovider = () => {
  return (
    <div>
        {/* <Header solution={true}/> */}
        <Fortrainingproviderscontent/>
        {/* <Footer/> */}
    </div>
  )
}

export default Fortrainingprovider
