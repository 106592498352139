import React, { createElement, useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { LengthValidation, NonEmptyValidation } from "../../../Store/validate";
import {
  Updateheader,
  ImageUpload,
  GetcontactUsCarddata,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const ContactusCardmanagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createData, setcreateData] = useState({});
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  useEffect(() => {
    GetData();
  }, []);

  // const UploadDocument = async (e, key) => {
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setcreateData({
  //       ...createData,
  //       pageStructure: {
  //         ...createData.pageStructure,
  //         backgroudImage: response.data.path
  //       }
  //     })
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const [err, setErr] = useState({});

  const GetData = async () => {
    const response = await GetcontactUsCarddata(token);
    if (
      response
      //  && response?.statusCode === 200
    ) {
      setcreateData(response?.data);
    }
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.backgroudImage = NonEmptyValidation(
      createData?.pageStructure?.backgroudImage
    );
    validate.subHeading = NonEmptyValidation(
      createData?.pageStructure?.subHeading
    );
    validate.heading = NonEmptyValidation(createData?.pageStructure?.heading);
    validate.buttonHeading = NonEmptyValidation(
      createData?.pageStructure?.buttonHeading
    );
    validate.buttonRedirectPath = NonEmptyValidation(
      createData?.pageStructure?.buttonRedirectPath
    );

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        pageName: createData?.pageName,
        pageStructure: {
          ...createData?.pageStructure,
          backgroudImage: createData?.pageStructure?.backgroudImage,
          subHeading: createData?.pageStructure?.subHeading,
          heading: createData?.pageStructure?.heading,
          buttonShown: createData?.pageStructure?.buttonShown,
          buttonHeading: createData?.pageStructure?.buttonHeading,
          buttonRedirectPath: createData?.pageStructure?.buttonRedirectPath,
        },
      };
      const response = await Updateheader(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (dummy?.size <= 500
        // && dummy?.width <= 649 && dummy?.height <= 466
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateData({
            ...createData,
            pageStructure: {
              ...createData.pageStructure,
              backgroudImage: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        } else {
          errorMsg(response.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">

            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Contact Us Section management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createData?.lastUpdateBy}{", "}
                  {moment(createData?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>
            <div className="contentbox ">
              <div className="mt-1">{/* <h5>Create Admin</h5> */}</div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    {createData?.pageStructure?.heading}
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Image</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="contactuscardimg"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "image")}
                          />
                          <label
                            for='contactuscardimg'
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createData?.pageStructure?.backgroudImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createData?.pageStructure?.backgroudImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 500KB,Max 649px X 466px.
                        </small>
                        <small className="validationerror">
                          {validation?.backgroudImage?.message
                            ? `Backgroud Image ${validation?.backgroudImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Heading *
                          <span className="char-hint">{" "}(Maximum 70 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Heading"
                            value={createData?.pageStructure?.heading}
                            maxLength={70}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  heading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                70,
                                setErr,
                                "heading"
                              );
                            }}
                          />
                        </div>
                        {err?.heading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.heading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.heading?.message
                              ? `Heading ${validation?.heading?.message}`
                              : ""}
                          </small>
                        )}

                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Subheading *
                          <span className="char-hint">{" "}(Maximum 120 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Subheading"
                            value={createData?.pageStructure?.subHeading}
                            maxLength={120}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  subHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                120,
                                setErr,
                                "subHeading"
                              );
                            }}
                          />
                        </div> {err?.subHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.subHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.subHeading?.message
                              ? `Subheading ${validation?.subHeading?.message}`
                              : ""}
                          </small>
                        )}

                      </div>
                      <div className="col-lg-5 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Button</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createData?.pageStructure?.buttonShown}
                              onChange={(e) => {
                                setcreateData({
                                  ...createData,
                                  pageStructure: {
                                    ...createData.pageStructure,
                                    buttonShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Button Name
                          <span className="char-hint">{" "}(Maximum 25 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Button Name"
                            value={createData?.pageStructure?.buttonHeading}
                            maxLength={25}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  buttonHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                25,
                                setErr,
                                "buttonHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                buttonHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.buttonHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.buttonHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.buttonHeading?.message
                              ? `Button Heading ${validation?.buttonHeading?.message}`
                              : ""}
                          </small>
                        )}

                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Button Redirect Path
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Redirect Path"
                            value={
                              createData?.pageStructure?.buttonRedirectPath
                            }
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  buttonRedirectPath: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.buttonRedirectPath?.message
                            ? `Button Redirect Path ${validation?.buttonRedirectPath?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactusCardmanagement;
