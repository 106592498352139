import axios from "axios";
import { url } from "./BaseUrl";
import { useDispatch } from "react-redux";

const APIBaseUrl = url + "/rescalelab/v1";
const TOKEN = localStorage.getItem("token");

const logoutUser = async () => {
  const USERID = localStorage.getItem("userId");
  // const res = await AdminLogOut({ _id: USERID });

  // if (res.statusCode === 200) {
    localStorage.clear();

    window.location.href = "/";
  // }
};

axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
axios.interceptors.request.use(
  function (config) {
    document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    console.log(response, "response");
    document.body.classList.remove("loading-indicator");
    return response;
  },

  function (error) {
    if (error?.response?.status === 401) {
      console.log("TOKEN Expired: " + error.response);
      logoutUser();
      return;
    }
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);
export const AdminLogOut = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/logout`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + TOKEN,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const ImageUpload = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/awsUpload/upload`, Payload, {
      headers: {
        Accept: "image/json",
        "Content-Type": "image/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Admin Login
export const AdminLoginSubmit = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/login`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Verify Otp
export const VerifyOTPSend = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/forgotPassword/otpSend`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Sumbit Otp for Forget Password
export const SubmitOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/forgotPassword/verifyOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Sumbit New Password
export const SubmitPassword = async (Payload) => {
  const response = await axios
    .put(`${APIBaseUrl}/user/forgotPassword/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Change New Password
export const ChangeNewPassword = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/user/changePassword`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Profile
export const GetMyProfile = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/profile/findMyProfile`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Admin List
export const GetAdminList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/profile/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Admin View
export const AdminSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/profile/findParticular?userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Admin
export const CreateAdmin = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Admin
export const UpdateAdmin = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/profile/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Meta Tag
export const GetMetaList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/metatag/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Meta View
export const MetaSpecificView = async (pageName, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/metatag/findParticular?pageName=${pageName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Meta
export const UpdateMetatag = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/metatag/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Blog List
export const GetBlogList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/blogs/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Blog View
export const BlogSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/blogs/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Blog Comments View
export const BlogSpecificCommentsView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/blogComments/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Blog
export const CreateBlog = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/blogs/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Blog
export const UpdateBlog = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/blogs/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Blog Comments
export const UpdateBlogComments = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/blogComments/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Social Media List
export const GetSocialList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/socialMedia/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Blog View
export const SocialSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/socialMedia/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Social Media
export const CreateSocial = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/socialMedia/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Social Media
export const UpdateSocial = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/socialMedia/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get Contact Us List
export const GetContactUsList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/user/findAllContactUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Specific Contact View
export const ContactUsSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/user/findParticularContactUs?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create ContactUs

// Get Social Media List User
export const GetUserSocialList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/socialMedia/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteSocialIconList = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/socialMedia/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
export const DeleteAdmin = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/user/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetUserBlogList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/blogs/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetUserSpecificBlogList = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/blogs/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateBlogComment = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/blogComments/submit`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateCategory = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/blogCategory/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetCategoryList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/blogCategory/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashboard = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/dashboardCount`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteBlog = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/blogs/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetUserSpecificBlogComments = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/blogComments/find?blogId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateBatch = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/batch/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetBatchList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/batch/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteBatch = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/batch/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetAdminSpecificBatch = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/batch/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateBatch = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/batch/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetUserBatchList = async () => {
  const response = await axios
    .get(`${APIBaseUrl}/batch/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatesubscriptionContent = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/subcription/createSubscription`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSubscriptionList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/user/subcription/findAllSubscription`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminSubscriptionList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/subcription/findAllSubscription`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteSubscription = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/user/subcription/deleteSubscription`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
export const GetSpecificSubscription = async (id, token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/admin/subcription/findParticularSubscription?_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateSubscription = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/user/subcription/editSubscription`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetFaqList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/Faq/findAllFaq`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminFaqList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/Faq/findAllFaq`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteFaq = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/Faq/deleteFaq`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreateFaq = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/Faq/createFaq`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateFaq = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/Faq/editFaq`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminSpecificFaq = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/Faq/findParticularFaq?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMasterData = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/masterData/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateMasterData = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/masterData/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAuthorBlogList = async (name) => {
  const response = await axios
    .get(`${APIBaseUrl}/blogs/find?author=${name}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetCategoryBlogList = async (category) => {
  const response = await axios
    .get(`${APIBaseUrl}/blogs/find?category=${category}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// export const DeleteContact = async (token, payload) => {
//
//   const response = await axios
//     .delete(`${APIBaseUrl}/user/deleteContactUs`, payload, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ error: error }));
//   return response;
// };

export const DeleteContact = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/deleteContactUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetUserMasterData = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterData/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminTestimonials = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/testimonial/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteTestimonial = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/testimonial/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreateTestimonial = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/testimonial/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const TestimonialSpecific = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/testimonial/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateTestimonial = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/testimonial/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetUserTestimonials = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/testimonial/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminHeaderdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=header`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const Updateheader = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/cms/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetHeaderdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=header`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminFooterdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=footer`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateFooter = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/cms/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetFooterdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=footer`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetcontactUsCarddata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=contactUsCard`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetContactcarddata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=contactUsCard`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateMetatag = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/metatag/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminPricingdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=pricing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPricingdata = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=pricing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminContactuspage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=contactUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetContactusPage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=contactUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminBlogpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=newsAndBlogs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetBlogPage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=newsAndBlogs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminTrainingServicespage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=trainingServices`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTrainingServicePage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=trainingServices`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminlicensingpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=licensing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetLicensingPage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=licensing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminPlatformpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=platform`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPlatformPage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=platform`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminHomepage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=home`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetHomePage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=home`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminAboutuspage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=aboutUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAboutUsPage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=aboutUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdmincorporationpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=forCorporation`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const Getcorporationpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=forCorporation`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminUniversitiespage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=forUniversities`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetUniversitiespage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=forUniversities`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminForTrainingProviderspage = async (token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/admin/cms/findParticular?pageName=forTrainingProviders`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetForTrainingProviderspage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=forTrainingProviders`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminSpecificBlogpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=specificBlogPage`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificBlogpage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=specificBlogPage`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminPartners = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/partners/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePartners = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/partners/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const PartnerSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/partners/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdatePartner = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/partners/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeletePartner = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/partners/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetAdminPrivacyPolicy = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=privacyPolicy`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminTermsOfUse = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/cms/findParticular?pageName=termsOfUse`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPrivacyPolicy = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=privacyPolicy`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTermsOfUse = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cms/find?pageName=termsOfUse`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPartners = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/partners/findAll`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMetaDetailsForUser = async (pageName) => {
  const response = await axios
    .get(`${APIBaseUrl}/metatag/find?pageName=${pageName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const MetaUserSpecificView = async (pageName, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/metatag/find?pageName=${pageName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//coupon
export const GetCouponList = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/coupon/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteCoupon = async (token, payload) => {
  const response = await axios
    .delete(`${APIBaseUrl}/admin/coupon/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const UpdateCoupon = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/admin/coupon/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateCoupon = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/coupon/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const CouponSpecificView = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/admin/coupon/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//user side coupon
export const Getusersidecoupon = async (token) => {
  console.log(`${APIBaseUrl}/coupon/findAll`);
  const response = await axios
    .get(`${APIBaseUrl}/coupon/findAll`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const verifyCaptchaToken = async (userResponseToken) => {
  const secret = "6Ldq980pAAAAABxqOlu6GyAqvvllDywSvgaumm5i";
  const data = new URLSearchParams();
  data.append('secret', secret);
  data.append('response', userResponseToken);

  try {
    const response = await axios.post(
      "https://www.google.com/recaptcha/api/siteverify",
      data 
    );

    return response.data; 
  } catch (error) {
    console.log(error, "Error in verifying reCAPTCHA token");
    return null; 
  }
};
export const CreateContactData = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/createContactUs`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
