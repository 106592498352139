import React, { useState } from "react";
import logo from "../../Assets/navlogo.webp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VerifyOTPSend } from "../../Repository/Api";
import { registerDetails } from "../../Store/auth/action";
import { errorMsg, successMsg } from "../Toastify";
import { EmailValidation } from "../../Store/validate";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, SetLogin] = useState([]);
  const [validation, setValidation] = useState({});

  const setData = (e, key) => {
    let TempData = login;
    TempData[key] = e;
    SetLogin(TempData);
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.emailId = EmailValidation(login?.emailId);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: login.emailId,
        otpType: "forgotPassword",
      };

      const response = await VerifyOTPSend(Payload);
      if (response && response.statusCode === 200) {
        dispatch(registerDetails(login.emailId));
        successMsg(response.message);
        getTimeout();
      } else {
        errorMsg(response.message);
      }
    }
  };
  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/VerifyOTP"));
    }, 1000);
  };
  return (
    <>
      <section className="loginbg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 ms-auto mx-auto">
              <div className="loginmodel forgot-pass-layout">
                <div className="centertext">
                  <img src={logo} alt="Rescale Lab" />
                </div>
                <div className="mt-5">
                  <h4 className="fw-bold textcolor">Forgot Password?</h4>
                  <p className="fw-bold textcolor">
                    <small>Welcome back Please Enter your Email</small>
                  </p>
                </div>
                <div className="my-5">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        mail
                      </span>
                      <input
                        type="text"
                        className="logininput-field"
                        placeholder="Enter Email-Id"
                        defaultValue={login.emailId}
                        onChange={(e) => {
                          setData(e.target.value, "emailId");
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "emailId",
                            EmailValidation(e.target.value)
                          );
                        }}
                      />
                      <small className="validationerror">
                        {validation?.emailId?.message
                          ? `Email Id ${validation?.emailId?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="inputspace">
                      <button className="loginbtn">Get OTP</button>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className=" forgettext"
                        onClick={() => {
                          navigate("/adminlogin");
                        }}
                      >
                        <small>Back To Login</small>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
