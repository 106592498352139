import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Aboutuscontent from '../Components/Aboutuscontent'

const Aboutus = () => {
  return (
    <div>
        {/* <Header/> */}
        <Aboutuscontent/>
        {/* <Footer/> */}
    </div>
  )
}

export default Aboutus