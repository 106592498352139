import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { useNavigate, useParams } from "react-router-dom";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation, StringValidation } from "../../../Store/validate";
import {
  ImageUpload,
  TestimonialSpecific,
  UpdateTestimonial,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const TestimonialUpdate = () => {
  const state = useSelector((state) => state);
  const token = state?.auth?.auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const [testimonial, settestimonial] = useState({});
  console.log(testimonial);
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, size, height, width) => {
    return { formData: formData, size: size, height: height, width: width };
  };
  const setValues = (e, key) => {
    settestimonial({ ...testimonial, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const Testimonial = async () => {
    const response = await TestimonialSpecific(id, token);
    if (response && response.statusCode === 200) {
      settestimonial(response?.data);
    }
  };

  useEffect(() => {
    Testimonial();
  }, []);

  const UploadDocument = async (e, key) => {
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([imageData(formData, imgsize, img.height, img.width)]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.size <= 500
        //  &&dummy?.height <= 117 &&
        // dummy?.width <= 117
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setValues(response.data.path, "profileImage");
          successMsg(response.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(testimonial?.name);
    validate.title = NonEmptyValidation(testimonial?.title);
    validate.role = NonEmptyValidation(testimonial?.role);
    validate.profileImage = NonEmptyValidation(testimonial?.profileImage);
    validate.content = NonEmptyValidation(testimonial?.content);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: testimonial._id,
        name: testimonial.name,
        title: testimonial.title,
        role: testimonial.role,
        profileImage: testimonial.profileImage,
        content: testimonial.content,
      };
      const response = await UpdateTestimonial(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setTimeout(() => {
          setTimeout(navigate("/admin/Testimonial"));
        }, 1000);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <button
                  className="btn btn-none"
                  onClick={() => {
                    navigate("/admin/Testimonial");
                  }}
                >
                  <h6 className="backArrowContent">
                    <span className="arrowicon">← </span>
                    <span className="fs-4">Testimonial List</span>
                  </h6>
                </button>
              </div>
            </div>
            <div className="contentbox">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Update Testimonial
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Name *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Name"
                            defaultValue={testimonial?.name}
                            onChange={(e) => {
                              setValues(e.target.value, "name");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.name?.message
                            ? `Name ${validation?.name?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Profile Image *</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="testimonialimgedit"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "profileImage")}
                          />
                          <label for="testimonialimgedit" className="alt-btn">
                            Upload Image
                          </label>

                          {!testimonial.profileImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${testimonial.profileImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          {" "}
                          Image upload: Max 100KB,117px X 117px. Image upload:
                          Max 100KB,100px X 100px.
                        </small>
                        <small className="validationerror">
                          {validation?.profileImage?.message
                            ? `Profile Image ${validation?.profileImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Title *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Title"
                            defaultValue={testimonial?.title}
                            onChange={(e) => {
                              setValues(e.target.value, "title");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.title?.message
                            ? `Title ${validation?.title?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Role *</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Role"
                            defaultValue={testimonial?.role}
                            onChange={(e) => {
                              setValues(e.target.value, "role");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.role?.message
                            ? `Role ${validation?.role?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Content *</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Content"
                            defaultValue={testimonial?.content}
                            onChange={(e) => {
                              setValues(e.target.value, "content");
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.content?.message
                            ? `Content ${validation?.content?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="row my-4">
                        <div className="col-lg-3 centertext ms-auto mx-auto">
                          <button className="buttonmodel" type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialUpdate;
