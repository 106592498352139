import React, { useEffect, useState } from "react";
import logo from "../../Assets/navlogo.webp";
import { useNavigate } from "react-router-dom";
import { EmailValidation, PasswordValidation } from "../../Store/validate";
import { errorMsg, successMsg } from "../Toastify";
import { loginSuccess } from "../../Store/auth/action";
import { useDispatch } from "react-redux";
import { AdminLoginSubmit, GetHeaderdata } from "../../Repository/Api";
import Loginimg from "../../Assets/images/loginimg.png";
import { cloudFrontUrl } from "../../commonUtils/Utils";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, SetLogin] = useState([]);
  const [header, setHeader] = useState([]);

  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const viewPassword = () => {
    setPassword(!password);
  };

  const setData = (e, key) => {
    SetLogin({ ...login, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const HeaderData = async () => {
    const response = await GetHeaderdata();
    setHeader(response?.data);
  };

  useEffect(() => {
   
    HeaderData();
  }, []);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let validate = {};
    validate.emailId = EmailValidation(login?.emailId);
    validate.password = PasswordValidation(login?.password);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: login.emailId,
        password: login.password,
      };

      const response = await AdminLoginSubmit(Payload);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setLoading(false);
        localStorage.setItem("userId", response?.data?._id);
        localStorage.setItem("token", response?.data?.accessToken);

        dispatch(loginSuccess(response.data, response?.data?.accessToken));
        setTimeout(() => {
          if (response?.data?.passwordNeedToChange === true) {
            navigate("../admin/dashboard", { replace: true });
          } else {
            navigate("/ChangePassword");
          }
        }, 1000);
      } else {
        errorMsg(response.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="loginbg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex col-lg-10">
              <div className="login-left">
                <img  src={`${cloudFrontUrl}${header?.pageStructure?.logo}`} alt="Rescale Lab" />
                <img src={Loginimg} alt="Rescale Lab" />
              </div>

              <div className="loginmodel">
                <div className="centertext">
                  {/* <img src={logo} alt="Rescale Lab" /> */}
                  <h4 className="adminlogintitle my-4">
                    Hello! let's get Started
                  </h4>
                </div>
                <div className="my-5">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        mail
                      </span>
                      <input
                        type="text"
                        className="logininput-field"
                        placeholder="Enter Email-Id"
                        defaultValue={login.emailId}
                        onChange={(e) => {
                          setData(e.target.value, "emailId");
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "emailId",
                            EmailValidation(e.target.value)
                          );
                        }}
                      />
                      <small className="validationerror">
                        {validation?.emailId?.message
                          ? `Email Id ${validation?.emailId?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="inputspace">
                      <span className="material-symbols-outlined loginicon">
                        key
                      </span>
                      <input
                        type={password ? "text" : "password"}
                        className="logininput-field"
                        placeholder="Password"
                        defaultValue={login.password}
                        onChange={(e) => {
                          setData(e.target.value, "password");
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "password",
                            PasswordValidation(e.target.value)
                          );
                        }}
                      />
                      <div className="psw-icon">
                        {password ? (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility
                          </span>
                        ) : (
                          <span
                            className="material-symbols-rounded cursor align-middle"
                            onClick={() => viewPassword()}
                          >
                            visibility_off
                          </span>
                        )}
                      </div>
                      <small className="validationerror">
                        {validation?.password?.message
                          ? `Password ${validation?.password?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="inputspace">
                      <button className="loginbtn">
                        {loading ? (
                          <i className="fa fa-circle-o-notch fa-spin"></i>
                        ) : (
                          ""
                        )}
                        LOGIN
                      </button>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className=" forgettext"
                        onClick={() => {
                          navigate("/ForgetPassword");
                        }}
                      >
                        <small>Forgot password ?</small>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
