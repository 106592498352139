import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloudFrontUrl } from '../commonUtils/Utils';
import { GetHeaderdata, GetContactusPage } from '../Repository/Api';
import { navbardata } from '../Store/navbar/action';
const Header = ({ navi, solution, resource }) => {
  const state = useSelector((state) => state)
  const navLogo = state?.masterdata?.masterdata?.headerLogo
  const [data, setData] = useState({})
  const [faq, setFaq] = useState(true)
  const dispatch = useDispatch()
  const HeaderData = async () => {
    const response = await GetHeaderdata()
    setData(response?.data)
    dispatch(navbardata(response?.data))
    const res2 = await GetContactusPage()
    const isFaq = res2?.data?.pageStructure?.faqShown
    setFaq(isFaq)
  }
console.log(data?.pageStructure?.logo,"data")
  const dummy = data?.pageStructure?.menus
  if (dummy && !faq) {
    const originData = dummy[4]
    const alteredData = dummy && dummy[4]?.subMenu.filter((f) => f?.title !== dummy[4]?.subMenu[2]?.title)
    const fialdata = dummy && {
      ...dummy[4],
      subMenu: alteredData
    }
    dummy[4] = (faq === false ? fialdata : originData)
  }
  useEffect(() => {
    HeaderData()
  }, [])
  const nav = dummy?.sort((a, b) => {
    return a.order - b.order
  }).filter((a) => a.shownInHeader === true)
  let select = {
    sol: solution,
    res: resource
  }
  let d1
  const trueKeys = Object.keys(select).filter(key => select[key] === true).toString();
  if (trueKeys === "sol") {
    d1 = data?.pageStructure?.menus[3]?.title
  } else if (trueKeys === "res") {
    d1 = data?.pageStructure?.menus[4]?.title
  }
  return (
    <div className='nunito'>
      <nav className={`navbar fixed-top navbar-expand-lg navbar-light nav2`}>
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={`${cloudFrontUrl}${data?.pageStructure?.logo}`} alt="header Logo" className='navbar-logo' />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link " to="/platform">Platform</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link " to="/licensing">Licensing</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link " to="/trainingservices">Training Services</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle ${solution ? "active" : ""}`} href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Solutions
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li>
                    <NavLink className="dropdown-item nav-link " to="/forcorporation" exact>For Corporation</NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item nav-link " to="/Foruniversities">For Universities</NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item nav-link" to="/Fortrainingproviders">For Training Providers</NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle ${resource ? "active" : ""}`} href="#dropdown-menu" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Resources
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <NavLink className="dropdown-item nav-link " to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact us</NavLink>
                  <NavLink className="dropdown-item nav-link " to="/news-and-blogs">News & Blogs</NavLink>
                  <NavLink className="dropdown-item nav-link " to="/contact-us?id=faq">FAQ</NavLink>
                </ul>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link " to="/pricing">Pricing</NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/adminlogin">
                  <button className="btn btn-outline-success" type="submit">Login</button>
                </NavLink>
              </li>
            </ul> */}
            <ul className="navbar-nav mb-2 mb-lg-0">
              {nav?.map((a, i) => {
                return (
                  <>
                    {!a?.subMenu.length > 0 ?
                      <li className={`nav-item  ${a?.subMenu.length > 0 ? "dropdown" : ""}`} key={i}>
                        <NavLink className={`nav-link ${a.button ? "btn btn-dyc" : ""}`} to={a.redirectPath}>{a.title}</NavLink>
                      </li>
                      :
                      <li className="nav-item dropdown" key={i}>
                        <a className={`nav-link dropdown-toggle ${d1 === a.title ? "active" : ""} `} href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          {a?.title}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                          {a.subMenu.map((b, i) => {
                            return (
                              <li key={i} className="dropdown-item nav-link">
                                <NavLink className="nav-link " to={b.redirectPath}>{b.title}</NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    }
                  </>
                )
              })}
            </ul>
          </div>
        </div >
      </nav >
    </div >
  )
}

export default Header