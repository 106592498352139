import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  LengthValidation,
  NonEmptyValidation,
  NumberValidation,
} from "../../../Store/validate";
import {
  GetAdminPricingdata,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}
const PricingLayout = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createPricing, setcreatePricing] = useState({});
  const [header, setheader] = useState({});


  const [validation, setValidation] = useState({});

  const [data, setData] = useState([]);
  const [err, setErr] = useState({});
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  useEffect(() => {
    GetData();
  }, []);

  // const UploadDocument = async (e, key) => {
  //   const imagekey = key
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     setcreatePricing({
  //       ...createPricing,
  //       pageStructure: {
  //         ...createPricing.pageStructure,
  //         bannerImage: response.data.path
  //       }
  //     })
  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };

  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      const dummy = data[0];
      if (
        dummy?.image === "bannerImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.width === 745 &&
        // dummy?.height === 127
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatePricing({
            ...createPricing,
            pageStructure: {
              ...createPricing.pageStructure,
              bannerImage: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        }
      } else if (
        dummy?.image === "contactUsCardBackgroudImage"
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.width === 734 &&
        // dummy?.height === 463
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatePricing({
            ...createPricing,
            pageStructure: {
              ...createPricing.pageStructure,
              contactUsCardBackgroudImage: response.data.path,
            },
          });
          successMsg("Image Uploaded Successfully");
        }
      } else {
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };
  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };
  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminPricingdata(token);
    console.log(response);
    if (response && response?.statusCode === 200) {
      const pageData = response?.data;
      setheader(response?.data)
      let dummy = response?.data?.pageStructure;
      let cmsDescription = EditorState.createEmpty();
      console.log(dummy);
      if (dummy.contactUsCardContent) {
        let blocksFromHtml = htmlToDraft(dummy.contactUsCardContent);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.contactUsCardContent = cmsDescription;
      setcreatePricing({
        ...createPricing,
        pageName: pageData.pageName,
        pageStructure: {
          ...dummy,
        },
      });
    }
  };

  const removeHTMLTags = (input) =>
    input
      ?.replace(/<\/?[^>]+(>|$)/g, "")
      ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
      .trim();

  // const GetData = async () => {
  //   const response = await GetAdminPricingdata(token)
  //   console.log(response)
  //   if (response && response?.statusCode === 200) {
  //     let dummy = response?.data?.pageStructure;
  //     let cmsDescription = EditorState.createEmpty();
  //     if (dummy?.contactUsCardContent) {
  //       let blocksFromHtml = htmlToDraft(dummy?.contactUsCardContent);
  //       let { contentBlocks, entityMap } = blocksFromHtml;
  //       let contentState = ContentState.createFromBlockArray(
  //         contentBlocks,
  //         entityMap
  //       );
  //       cmsDescription = EditorState.createWithContent(contentState);
  //     }
  //     dummy.contactUsCardContent = cmsDescription;

  //     // setcreatePricing(response?.data)
  //     setcreatePricing({
  //       ...createPricing,
  //       pageName: response?.data?.pageName,
  //       pageStructure: {
  //         ...dummy,
  //       },
  //     });
  //   }
  // }
  const mapInputOnchange = (e, i, length, key, array, errid) => {
    const data = createPricing?.pageStructure?.[array];

    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatePricing({
      ...createPricing,
      pageStructure: {
        ...createPricing.pageStructure,
        [array]: data,
      },
    });
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;

      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    let html1 = draftToHtml(
      convertToRaw(
        createPricing?.pageStructure?.contactUsCardContent.getCurrentContent()
      )
    );
    let plainText = removeHTMLTags(html1);

    validate.bannerImage = NonEmptyValidation(
      createPricing?.pageStructure?.bannerImage
    );
    validate.bannerImageRedirectPath = NonEmptyValidation(
      createPricing?.pageStructure?.bannerImageRedirectPath
    );
    if (Array.isArray(createPricing?.pageStructure?.perUserPriceDetails)) {
      validate.perUserPriceDetails =
        createPricing?.pageStructure?.perUserPriceDetails.map((d, i) => ({
          durationName: NonEmptyValidation(d?.durationName),
          duration: NonEmptyValidation(d?.duration),
          price: NumberValidation(d?.price),
        }));
    }
    validate.perUserSectionTabName = NonEmptyValidation(
      createPricing?.pageStructure?.perUserSectionTabName
    );
    validate.pricingpercohortdescription = NonEmptyValidation(
      createPricing?.pageStructure?.pricingpercohortdescription
    );
    validate.pricingperuserdescription = NonEmptyValidation(
      createPricing?.pageStructure?.pricingperuserdescription
    );
    validate.priceCalculationSectionBatchName = NonEmptyValidation(
      createPricing?.pageStructure?.priceCalculationSectionBatchName
    );
    validate.priceCalculationSectionParticipant = NonEmptyValidation(
      createPricing?.pageStructure?.priceCalculationSectionParticipant
    );
    validate.priceCalculationSectionAmount = NonEmptyValidation(
      createPricing?.pageStructure?.priceCalculationSectionAmount
    );
    validate.priceCalculationSectionAddNewButtonName = NonEmptyValidation(
      createPricing?.pageStructure?.priceCalculationSectionAddNewButtonName
    );
    validate.priceDetailsSectionCohortName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionCohortName
    );
    validate.priceDetailsSectionParticipantsName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionParticipantsName
    );
    validate.priceDetailsSectionPricingName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionPricingName
    );
    validate.priceDetailsSectionAppliedCouponName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionAppliedCouponName
    );
    validate.priceDetailsSectionTotalAmountName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionTotalAmountName
    );
    validate.priceDetailsSectionCouponSectionHeading = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionCouponSectionHeading
    );
    validate.priceDetailsSectionCouponSectionButtonName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionCouponSectionButtonName
    );
    validate.priceDetailsSectionButtonName = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionButtonName
    );
    validate.priceDetailsSectionButtonRedirectLing = NonEmptyValidation(
      createPricing?.pageStructure?.priceDetailsSectionButtonRedirectLing
    );
    validate.perCohortSectionTabName = NonEmptyValidation(
      createPricing?.pageStructure?.perCohortSectionTabName
    );
    validate.contactUsCardBackgroudImage = NonEmptyValidation(
      createPricing?.pageStructure?.contactUsCardBackgroudImage
    );
    validate.contactUsCardHeading = NonEmptyValidation(
      createPricing?.pageStructure?.contactUsCardHeading
    );
    validate.contactUsCardButtonHeading = NonEmptyValidation(
      createPricing?.pageStructure?.contactUsCardButtonHeading
    );
    validate.contactUsCardButtonRedirectPath = NonEmptyValidation(
      createPricing?.pageStructure?.contactUsCardButtonRedirectPath
    );
    validate.contactUsCardContent = NonEmptyValidation(plainText);
    console.log(validate);
    setValidation(validate);

    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.durationName.status &&
            menu.duration.status &&
            menu.price.status
          )
        ) {
          return false;
        }
      }
      return true;
    }

    const isTrue = areAllStatusTrue(validate.perUserPriceDetails);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = ["perUserPriceDetails"];
    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);
    const final = isTrue && isTrue5;
    console.log(final);
    let isNotEmpty =
      createPricing?.pageStructure?.contactUsCardContent &&
      createPricing?.pageStructure?.contactUsCardContent
        .getCurrentContent()
        .hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(
          createPricing?.pageStructure?.contactUsCardContent.getCurrentContent()
        )
      );
      if (final) {
        const Payload = {
          pageName: createPricing?.pageName,
          pageStructure: {
            ...createPricing?.pageStructure,
            contactUsCardContent: html,
          },
        };
        console.log(Payload);
        const response = await UpdateFooter(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
        } else {
          errorMsg(response.message);
        }
      } else {
        errorMsg("Please Check All Input Fields");
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Pricing Page management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {header?.lastUpdateBy}{", "}
                  {moment(header?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>


            <div className="contentbox">
              <div className="mt-1">{/* <h5>Create Admin</h5> */}</div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Banner Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Banner Image</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="pricinglayoutimg1"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "bannerImage")}
                          />
                          <label
                            for="pricinglayoutimg1"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createPricing?.pageStructure?.bannerImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createPricing?.pageStructure?.bannerImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="validationerror">
                          Image size should be 745 x 127 px
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Banner image Redirect Path
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Banner Image Redirect Path"
                            value={
                              createPricing?.pageStructure
                                ?.bannerImageRedirectPath
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  bannerImageRedirectPath: e.target.value,
                                },
                              });
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                bannerImageRedirectPath: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.bannerImageRedirectPath?.message
                            ? `Redirect Path ${validation?.bannerImageRedirectPath?.message}`
                            : ""}
                        </small>
                      </div>

                      <div className="col-lg-6">
                        <label className="labeltext">
                          Cohort Option 1
                          <span className="char-hint">
                            {" "}
                            (Maximum 12 characters)
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Tab Name 1"
                            maxLength={12}
                            value={
                              createPricing?.pageStructure
                                ?.perUserSectionTabName
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  perUserSectionTabName: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                12,
                                setErr,
                                "perUserSectionTabName"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                perUserSectionTabName: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.perUserSectionTabName?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.perUserSectionTabName?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.perUserSectionTabName?.message
                              ? `Redirect Path ${validation?.perUserSectionTabName?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Cohort Option 2{" "}
                          <span className="char-hint">
                            {" "}
                            (Maximum 12 characters)
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Tab Name 2"
                            maxLength={12}
                            value={
                              createPricing?.pageStructure
                                ?.perCohortSectionTabName
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  perCohortSectionTabName: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                12,
                                setErr,
                                "perCohortSectionTabName"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                perCohortSectionTabName: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.perCohortSectionTabName?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.perCohortSectionTabName?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.perCohortSectionTabName?.message
                              ? `Redirect Path ${validation?.perCohortSectionTabName?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Cohort Option 1 Description
                          <span className="char-hint">
                            {" "}
                            (Maximum 250 characters)
                          </span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Cohort Option 1 Description"
                            maxLength={250}
                            value={
                              createPricing?.pageStructure
                                ?.pricingperuserdescription
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  pricingperuserdescription: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                250,
                                setErr,
                                "pricingperuserdescription"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                pricingperuserdescription: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.pricingperuserdescription?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.pricingperuserdescription?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.pricingperuserdescription?.message
                              ? `Description ${validation?.pricingperuserdescription?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Cohort Option 2 Description
                          <span className="char-hint">
                            {" "}
                            (Maximum 250 characters)
                          </span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Cohort Option 2 Description"
                            maxLength={250}
                            value={
                              createPricing?.pageStructure
                                ?.pricingpercohortdescription
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  pricingpercohortdescription: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                250,
                                setErr,
                                "pricingpercohortdescription"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                pricingpercohortdescription: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.pricingpercohortdescription?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.pricingpercohortdescription?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.pricingpercohortdescription?.message
                              ? `Description ${validation?.pricingpercohortdescription?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="row divider">
                        {createPricing?.pageStructure?.perUserPriceDetails &&
                          createPricing?.pageStructure?.perUserPriceDetails.map(
                            (item, i) => {
                              return (
                                <div className="row">
                                  <div className="col-lg-4">
                                    {" "}
                                    <label className="labeltext">
                                      Duration Name (Cohort Option{" "}
                                      {`${i < 2 ? "1." : "2."} ${i < 2 ? i + 1 : 1
                                        }`}
                                      ){" "}
                                      <span className="char-hint">
                                        {" "}
                                        (Maximum 27 characters)
                                      </span>
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Duration Name"
                                        maxLength={27}
                                        value={item?.durationName}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            27,
                                            "durationName",
                                            "perUserPriceDetails",
                                            "perUserPriceDetails1"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.perUserPriceDetails &&
                                            validation?.perUserPriceDetails[i]
                                              ?.durationName;

                                          const v = NonEmptyValidation(
                                            e.target.value
                                          );
                                          validation?.perUserPriceDetails
                                            ?.length > 0 &&
                                            (validation.perUserPriceDetails[
                                              i
                                            ].durationName = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate?.perUserPriceDetails1
                                      ?.length > 0 &&
                                      err?.errorstate?.perUserPriceDetails1[i]
                                        ?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.perUserPriceDetails1[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.perUserPriceDetails?.length >
                                          0 &&
                                          validation?.perUserPriceDetails[i]
                                            ?.durationName?.message
                                          ? `Duration Name ${validation?.perUserPriceDetails[i]?.durationName?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    {" "}
                                    <label className="labeltext">
                                      Duration Label{" "}
                                      {`${i < 2 ? "1." : "2."} ${i < 2 ? i + 1 : 1
                                        }`}
                                      <br />{" "}
                                      <span className="char-hint">
                                        {" "}
                                        (Maximum 25 characters)
                                      </span>
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Duration"
                                        maxLength={25}
                                        value={item?.duration}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            25,
                                            "duration",
                                            "perUserPriceDetails",
                                            "perUserPriceDetails2"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.perUserPriceDetails &&
                                            validation?.perUserPriceDetails[i]
                                              ?.duration;

                                          const v = NonEmptyValidation(
                                            e.target.value
                                          );
                                          validation?.perUserPriceDetails
                                            ?.length > 0 &&
                                            (validation.perUserPriceDetails[
                                              i
                                            ].duration = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate?.perUserPriceDetails2
                                      ?.length > 0 &&
                                      err?.errorstate?.perUserPriceDetails2[i]
                                        ?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.perUserPriceDetails2[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.perUserPriceDetails?.length >
                                          0 &&
                                          validation?.perUserPriceDetails[i]
                                            ?.duration?.message
                                          ? `Duration ${validation?.perUserPriceDetails[i]?.duration?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    {" "}
                                    <label className="labeltext">
                                      Price
                                      <br />{" "}
                                      <span className="char-hint">
                                        {" "}
                                        (Maximum 5 characters)
                                      </span>
                                    </label>
                                    <div>
                                      <input
                                        type="number"
                                        className="fieldinput"
                                        placeholder="Enter Price"
                                        maxLength={5}
                                        value={item?.price}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            5,
                                            "price",
                                            "perUserPriceDetails",
                                            "perUserPriceDetails3"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.perUserPriceDetails &&
                                            validation?.perUserPriceDetails[i]
                                              ?.price;

                                          const v = NonEmptyValidation(
                                            e.target.value
                                          );
                                          validation?.perUserPriceDetails
                                            ?.length > 0 &&
                                            (validation.perUserPriceDetails[
                                              i
                                            ].price = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate?.perUserPriceDetails3
                                      ?.length > 0 &&
                                      err?.errorstate?.perUserPriceDetails3[i]
                                        ?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.perUserPriceDetails3[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.perUserPriceDetails?.length >
                                          0 &&
                                          validation?.perUserPriceDetails[i]?.price
                                            ?.message
                                          ? `Price ${validation?.perUserPriceDetails[i]?.price?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="row divider">
                        <p className="h3">Input Label Names</p>
                        <div className="col-lg-4">
                          <label className="labeltext">Batch Label Name
                            <span className="char-hint">{" "}(Maximum 12 characters)

                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Batch Label Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceCalculationSectionBatchName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceCalculationSectionBatchName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceCalculationSectionBatchName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceCalculationSectionBatchName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceCalculationSectionBatchName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceCalculationSectionBatchName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceCalculationSectionBatchName
                                ?.message
                                ? `Batch Label Name ${validation?.priceCalculationSectionBatchName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label className="labeltext">
                            Participants Label Name{" "}
                            <span className="char-hint">
                              {" "}
                              (Maximum 25 characters)
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Participants Label Name"
                              maxLength={25}
                              value={
                                createPricing?.pageStructure
                                  ?.priceCalculationSectionParticipant
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceCalculationSectionParticipant:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceCalculationSectionParticipant"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceCalculationSectionParticipant:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceCalculationSectionParticipant?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceCalculationSectionParticipant?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceCalculationSectionParticipant
                                ?.message
                                ? `Participants Label Name ${validation?.priceCalculationSectionParticipant?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label className="labeltext">Amount Label Name
                            <span className="char-hint">{" "}(Maximum 12 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Amount Label Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceCalculationSectionAmount
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceCalculationSectionAmount: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceCalculationSectionAmount"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceCalculationSectionAmount:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceCalculationSectionAmount?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceCalculationSectionAmount?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceCalculationSectionAmount?.message
                                ? `Amount Label Name ${validation?.priceCalculationSectionAmount?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label className="labeltext">Add New Button Name
                            <span className="char-hint">{" "}(Maximum 12 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Add New Button Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceCalculationSectionAddNewButtonName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceCalculationSectionAddNewButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceCalculationSectionAddNewButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceCalculationSectionAddNewButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceCalculationSectionAddNewButtonName
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceCalculationSectionAddNewButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceCalculationSectionAddNewButtonName
                                ?.message
                                ? `Add New Button Name ${validation?.priceCalculationSectionAddNewButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4 align-self-center ">
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Per User Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createPricing?.pageStructure
                                    ?.perUserSectionShown
                                }
                                onChange={(e) => {
                                  setcreatePricing({
                                    ...createPricing,
                                    pageStructure: {
                                      ...createPricing.pageStructure,
                                      perUserSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center ">
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Per Cohort Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createPricing?.pageStructure
                                    ?.perCohortSectionShown
                                }
                                onChange={(e) => {
                                  setcreatePricing({
                                    ...createPricing,
                                    pageStructure: {
                                      ...createPricing.pageStructure,
                                      perCohortSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row divider">
                        <p className="h3">Summary Section</p>
                        <div className="col-lg-6">
                          <label className="labeltext">cohert Label Name
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter cohert Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionCohortName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionCohortName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "priceDetailsSectionCohortName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionCohortName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionCohortName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionCohortName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionCohortName?.message
                                ? `Batch Label Name ${validation?.priceDetailsSectionCohortName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Participant Label Name
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Participant Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionParticipantsName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionParticipantsName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "priceDetailsSectionParticipantsName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionParticipantsName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionParticipantsName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionParticipantsName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionParticipantsName
                                ?.message
                                ? `Participant Label Name ${validation?.priceDetailsSectionParticipantsName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Pricing Label Name
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Pricing Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionPricingName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionPricingName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "priceDetailsSectionPricingName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionPricingName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionPricingName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionPricingName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionPricingName?.message
                                ? `Pricing Label Name ${validation?.priceDetailsSectionPricingName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Applied Coupon Label Name
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Applied Coupon Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionAppliedCouponName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionAppliedCouponName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "priceDetailsSectionAppliedCouponName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionAppliedCouponName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionAppliedCouponName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionAppliedCouponName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionAppliedCouponName
                                ?.message
                                ? `Applied Coupon Label Name ${validation?.priceDetailsSectionAppliedCouponName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Extra Fees Label Name 1
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter  Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.extraFeeLabelName1
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    extraFeeLabelName1:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "extraFeeLabelName1"
                                );
                              }}
                            // onBlur={(e) => {
                            //   setValidation({
                            //     ...validation,
                            //     extraFeeLabelName1:
                            //       NonEmptyValidation(e.target.value),
                            //   });
                            // }}
                            />
                          </div>
                          {err?.extraFeeLabelName1?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.extraFeeLabelName1?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.extraFeeLabelName1
                                ?.message
                                ? `Applied Coupon Label Name ${validation?.extraFeeLabelName1?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Extra Fees 1
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="number"
                              className="fieldinput"
                              placeholder="Enter Amount"
                              maxLength={20}
                              min={0}
                              value={
                                createPricing?.pageStructure
                                  ?.extraFee1
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    extraFee1:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "extraFee1"
                                );
                              }}
                            // onBlur={(e) => {
                            //   setValidation({
                            //     ...validation,
                            //     extraFeeLabelName1:
                            //       NonEmptyValidation(e.target.value),
                            //   });
                            // }}
                            />
                          </div>
                          {err?.extraFee1?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.extraFee1?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.extraFee1
                                ?.message
                                ? `Applied Coupon Label Name ${validation?.extraFee1?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Extra Fees Label Name 2
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter  Label Name"
                              maxLength={20}
                              value={
                                createPricing?.pageStructure
                                  ?.extraFeeLabelName2
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    extraFeeLabelName2:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "extraFeeLabelName2"
                                );
                              }}
                            />
                          </div>
                          {err?.extraFeeLabelName2?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.extraFeeLabelName2?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.extraFeeLabelName2
                                ?.message
                                ? `Applied Coupon Label Name ${validation?.extraFeeLabelName2?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Extra Fees 2
                            <span className="char-hint">{" "}(Maximum 20 characters)</span>
                          </label>
                          <div>
                            <input
                              type="number"
                              className="fieldinput"
                              placeholder="Enter Amount"
                              maxLength={20}
                              min={0}
                              value={
                                createPricing?.pageStructure
                                  ?.extraFee2
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    extraFee2:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  20,
                                  setErr,
                                  "extraFee2"
                                );
                              }}
                            // onBlur={(e) => {
                            //   setValidation({
                            //     ...validation,
                            //     extraFeeLabelName1:
                            //       NonEmptyValidation(e.target.value),
                            //   });
                            // }}
                            />
                          </div>
                          {err?.extraFee2?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.extraFee2?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.extraFee2
                                ?.message
                                ? `Applied Coupon Label Name ${validation?.extraFee2?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Total Amount Label Name
                            <span className="char-hint">{" "}(Maximum 12 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Total Amount Label Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionTotalAmountName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionTotalAmountName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceDetailsSectionTotalAmountName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionTotalAmountName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionTotalAmountName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionTotalAmountName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionTotalAmountName
                                ?.message
                                ? `Total Amount Label Name ${validation?.priceDetailsSectionTotalAmountName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">Coupon Label Name
                            <span className="char-hint">{" "}(Maximum 12 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Coupon Label Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionCouponSectionHeading
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionCouponSectionHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceDetailsSectionCouponSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionCouponSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionCouponSectionHeading
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionCouponSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionCouponSectionHeading
                                ?.message
                                ? `Coupon Label Name ${validation?.priceDetailsSectionCouponSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Apply Coupon Button Name
                            <span className="char-hint">{" "}(Maximum 12 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Apply Coupon Button Name"
                              maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionCouponSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionCouponSectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  12,
                                  setErr,
                                  "priceDetailsSectionCouponSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionCouponSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionCouponSectionButtonName
                            ?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionCouponSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation
                                ?.priceDetailsSectionCouponSectionButtonName
                                ?.message
                                ? `Apply Coupon Button Name ${validation?.priceDetailsSectionCouponSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Summary Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Summary Section Button Name"
                              maxLength={25}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "priceDetailsSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionButtonName?.message
                                ? `Summary Section Button Name ${validation?.priceDetailsSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="labeltext">
                            Summary Section Button Redirect Link
                            {/* <span className="char-hint">{" "}(Maximum 12 characters)</span> */}

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Summary Section Button Redirect Link"
                              // maxLength={12}
                              value={
                                createPricing?.pageStructure
                                  ?.priceDetailsSectionButtonRedirectLing
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    priceDetailsSectionButtonRedirectLing:
                                      e.target.value,
                                  },
                                });
                                // handleInputLengthValidation(
                                //   e.target.value,
                                //   12,
                                //   setErr,
                                //   "priceDetailsSectionButtonRedirectLing"
                                // );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  priceDetailsSectionButtonRedirectLing:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.priceDetailsSectionButtonRedirectLing?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.priceDetailsSectionButtonRedirectLing?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.priceDetailsSectionButtonRedirectLing
                                ?.message
                                ? `Summary Section Button Redirect Link${validation?.priceDetailsSectionButtonRedirectLing?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-lg-4 align-self-center">
                          <div className="d-flex justify-content-between  bx-box">
                            <label className="labeltext">
                              Show Contact Us Card
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createPricing?.pageStructure
                                    ?.contactUsCardsShown
                                }
                                onChange={(e) => {
                                  setcreatePricing({
                                    ...createPricing,
                                    pageStructure: {
                                      ...createPricing.pageStructure,
                                      contactUsCardsShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center ">
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Navbar</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createPricing?.pageStructure?.headerShown
                                }
                                onChange={(e) => {
                                  setcreatePricing({
                                    ...createPricing,
                                    pageStructure: {
                                      ...createPricing.pageStructure,
                                      headerShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center ">
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Footer</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createPricing?.pageStructure?.footerShown
                                }
                                onChange={(e) => {
                                  setcreatePricing({
                                    ...createPricing,
                                    pageStructure: {
                                      ...createPricing.pageStructure,
                                      footerShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Contact Us Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Image</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="pricinglayoutimg2"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(e, "contactUsCardBackgroudImage")
                            }
                          />
                          <label
                            for="pricinglayoutimg2"
                            className="alt-btn">
                            Upload Image
                          </label>
                          {!createPricing?.pageStructure
                            ?.contactUsCardBackgroudImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createPricing?.pageStructure?.contactUsCardBackgroudImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="validationerror">
                          Image size should be 734 x 463 px
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          Summary Section Button Redirect Link
                          {/* <span className="char-hint">{" "}(Maximum 12 characters)</span> */}

                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Summary Section Button Redirect Link"
                            // maxLength={12}
                            value={
                              createPricing?.pageStructure?.contactUsCardHeading
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  contactUsCardHeading: e.target.value,
                                },
                              });
                              // handleInputLengthValidation(
                              //   e.target.value,
                              //   12,
                              //   setErr,
                              //   "contactUsCardHeading"
                              // );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                contactUsCardHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.contactUsCardHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.contactUsCardHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.contactUsCardHeading?.message
                              ? `Summary Section Button Redirect Link${validation?.contactUsCardHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Content</label>
                        <div className="fieldinput">
                          <Editor
                            editorState={
                              createPricing?.pageStructure?.contactUsCardContent
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  contactUsCardContent: e,
                                },
                              });
                            }}
                            onBlur={() => {
                              let html1 = draftToHtml(
                                convertToRaw(
                                  createPricing?.pageStructure?.contactUsCardContent.getCurrentContent()
                                )
                              );
                              const plainText = removeHTMLTags(html1);
                              setValidation({
                                ...validation,
                                contactUsCardContent:
                                  NonEmptyValidation(plainText),
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.contactUsCardContent?.message
                            ? `Content ${validation?.contactUsCardContent?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext"> Button Name
                          <span className="char-hint">{" "}(Maximum 12 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Button Name"
                            maxLength={12}
                            value={
                              createPricing?.pageStructure
                                ?.contactUsCardButtonHeading
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  contactUsCardButtonHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                12,
                                setErr,
                                "contactUsCardButtonHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                contactUsCardButtonHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.contactUsCardButtonHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.contactUsCardButtonHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.contactUsCardButtonHeading?.message
                              ? `Enter Button Name ${validation?.contactUsCardButtonHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">
                          {" "}
                          Button Redirect Link
                          {/* <span className="char-hint">{" "}(Maximum 12 characters)</span> */}

                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Button Redirect Link"
                            // maxLength={12}
                            value={
                              createPricing?.pageStructure
                                ?.contactUsCardButtonRedirectPath
                            }
                            onChange={(e) => {
                              setcreatePricing({
                                ...createPricing,
                                pageStructure: {
                                  ...createPricing.pageStructure,
                                  contactUsCardButtonRedirectPath:
                                    e.target.value,
                                },
                              });
                              // handleInputLengthValidation(
                              //   e.target.value,
                              //   12,
                              //   setErr,
                              //   "contactUsCardButtonRedirectPath"
                              // );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                contactUsCardButtonRedirectPath:
                                  NonEmptyValidation(e.target.value),
                              });
                            }}
                          />
                        </div>
                        {err?.contactUsCardButtonRedirectPath?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.contactUsCardButtonRedirectPath?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.contactUsCardButtonRedirectPath
                              ?.message
                              ? `Button Redirect Link ${validation?.contactUsCardButtonRedirectPath?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 align-self-center ">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Button
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createPricing?.pageStructure
                                  ?.contactUsCardButtonShown
                              }
                              onChange={(e) => {
                                setcreatePricing({
                                  ...createPricing,
                                  pageStructure: {
                                    ...createPricing.pageStructure,
                                    contactUsCardButtonShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingLayout;
