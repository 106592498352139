import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { NonEmptyValidation, LengthValidation } from "../../../Store/validate";
import { GetAdminTermsOfUse, UpdateFooter } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import moment from "moment";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}
const TermsOfServiceManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  const [header, setHeader] = useState({});


  const [validation, setValidation] = useState({});
  const [err, setErr] = useState({});

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  const GetData = async () => {
    const response = await GetAdminTermsOfUse(token);
    console.log(response)
    if (response && response?.statusCode === 200) {
      const pageData = response?.data;
      setHeader(response?.data)
      let dummy = response?.data?.pageStructure;
      let cmsDescription = EditorState.createEmpty();
      console.log(dummy)
      if (dummy.content) {
        let blocksFromHtml = htmlToDraft(dummy.content);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.content = cmsDescription;
      setcreatecms({
        ...createcms,
        pageName: pageData.pageName,
        pageStructure: {
          ...dummy,
        },
      });
    }
  };

  const removeHTMLTags = (input) =>
    input
      ?.replace(/<\/?[^>]+(>|$)/g, "")
      ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
      .trim();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    let html1 = draftToHtml(
      convertToRaw(createcms?.pageStructure?.content.getCurrentContent())
    );
    let plainText = removeHTMLTags(html1);

    validate.heading = NonEmptyValidation(createcms?.pageStructure?.heading);
    validate.content = NonEmptyValidation(plainText);
    setValidation(validate);
    console.log(validate);
    let isNotEmpty =
      createcms?.pageStructure?.content &&
      createcms?.pageStructure?.content.getCurrentContent().hasText();
    console.log(isNotEmpty);
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(createcms?.pageStructure?.content.getCurrentContent())
      );
      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          pageName: createcms?.pageName,
          pageStructure: {
            ...createcms?.pageStructure,
            heading: createcms?.pageStructure?.heading,
            content: html,
          },
        };

        const response = await UpdateFooter(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
        } else {
          errorMsg(response.message);
        }
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Terms Of Use Page management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {header?.lastUpdateBy}{", "}
                  {moment(header?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>

            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    {createcms?.pageStructure?.heading}
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Heading
                          <span className="char-hint">{" "}(Maximum 40 characters)</span>
                        </label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Heading"
                            maxLength={40}
                            value={createcms?.pageStructure?.heading}
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  heading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                40,
                                setErr,
                                "heading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                heading: NonEmptyValidation(e.target.value),
                              });
                            }}
                          />
                        </div>
                        {err?.heading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.heading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.heading?.message
                              ? `Heading ${validation?.heading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Privacy Policy *</label>
                        <div className="fieldinput">
                          <Editor
                            editorState={createcms?.pageStructure?.content}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  content: e,
                                },
                              });
                            }}
                            onBlur={() => {
                              let html1 = draftToHtml(
                                convertToRaw(
                                  createcms?.pageStructure?.content.getCurrentContent()
                                )
                              );
                              const plainText = removeHTMLTags(html1);
                              setValidation({
                                ...validation,
                                content: NonEmptyValidation(plainText),
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.content?.message
                            ? `Content ${validation?.content?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfServiceManagement;
