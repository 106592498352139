// const EmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const NumberRegex = /^[0-9]/;
export const StringRegex = /^[A-Za-z ]+$/;
export const AlphaNumericRegex = /^[A-Za-z0-9 ]/;
const urlregex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{2,5})?\/?(\S*)$/;

export function PhoneNumberValidation(value) {
  if (value?.length === 0) {
    return { status: false, message: "is required" };
  } else if (value?.length !== 10) {
    return { status: false, message: "must be a 10 digits" };
  } else if (!/^[6789]\d{9}$/.test(value)) {
    return { status: false, message: "must start with 6, 7, 8, or 9" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }

  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}
export function NonEmptyValidation(value) {
  if (value?.length === 0) {
    return { status: false, message: "is required" };
  } else if (value === null) {
    return { status: false, message: "is required" };
  } else if (value === undefined) {
    return { status: false, message: "is required" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function EmptyValidation(value) {
  if (value?.length > 0) {
    return { status: false, message: "should be empty" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function PasswordValidation(value) {
  if (value?.length === 0) {
    return { status: false, message: "is required" };
  }
  const password = [
    /[a-z]/,
    /[A-Z]/,
    /[0-9]/,
    /[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]/,
  ].every((pattern) => pattern.test(value));
  return !password
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}
export function EmailValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  }
  return !EmailRegex.test(value)
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}
export function NumberValidation(value) {
  if (!value && value !== 0) {
    return { status: false, message: "is required" };
  } else if (typeof value === "string" && value.includes(" ")) {
    return { status: false, message: "cannot include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (!NumberRegex.test(value)) {
    return { status: false, message: "is only a number" };
  }

  return { status: true, message: "" };
}
export function NumberYearValidation(value) {
  if (!value && value !== 0) {
    return { status: false, message: "is required" };
  } else if (typeof value === "string" && value.includes(" ")) {
    return { status: false, message: "cannot include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (!/^\d{4}$/.test(value)) {
    return { status: false, message: "must be a 4-digit number" };
  }

  return { status: true, message: "" };
}
export function YearValidation(value) {
  const currentYear = new Date().getFullYear();

  if (!value || typeof value !== "string" || !/^\d{4}$/.test(value)) {
    return { status: false, message: "must be a 4-digit number" };
  }
  const parsedValue = parseInt(value, 10);
  if (isNaN(parsedValue) || parsedValue < 0) {
    return { status: false, message: "must be a positive number" };
  }
  if (parsedValue === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (parsedValue > currentYear) {
    return { status: false, message: "cannot be in the future" };
  }

  return { status: true, message: "" };
}
export function DurationValidation(value) {
  const regex = /^\d{2}-\d{2}$/;
  if (!value || !regex.test(value)) {
    return { status: false, message: "must be in the format YY-MM" };
  }
  const [years, months] = value.split("-").map(Number);
  if (years < 0 || months < 1 || months > 12) {
    return { status: false, message: "Months must be between 1 and 12" };
  }
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const inputYear = 2000 + years;
  if (
    inputYear > currentYear ||
    (inputYear === currentYear && months > currentMonth)
  ) {
    return { status: false, message: "Year cannot be in the future" };
  }
  return { status: true, message: "" };
}
export function StringValidation(value) {
  if (!value || !value?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  } else if (value === undefined) {
    return { status: false, message: "is required" };
  } else if (value === "") {
    return { status: false, message: "is required" };
  } else if (value === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}
export function AlphaNumericValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return AlphaNumericRegex.test(value)
    ? { status: true, message: "" }
    : { status: false, message: "is only Alphabets & Numbers" };
}
export function URLValidation(value) {
  const socialMediaRegex =
    // /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?[a-zA-Z0-9_./~-]*$/;
    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    // /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?[a-zA-Z0-9_./~-]*\??[a-zA-Z0-9_./~-]*=[a-zA-Z0-9_./~-]*/

  if (!value || !value.length) {
    return { status: false, message: "Cannot be empty." };
  } else if (!socialMediaRegex.test(value)) {
    return {
      status: false,
      message: "Is not a valid social media profile URL.",
    };
  }

  return {
    status: true,
    message: "",
  };
}

export function NonEmptyObjectValidation(value) {
  if (!value) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return { status: true, message: "" };
}
export function NonEmptyKeyValidation(value) {
  if (value === undefined) {
    return { status: false, message: "is required" };
  }
  return { status: true, message: "" };
}
export function PDFValidation(value) {
  return value === "pdf"
    ? { status: true, message: "" }
    : { status: false, message: "Accepts only pdf file" };
}

export function LengthValidation(value, length) {
  if (value?.length === length) {
    return { status: false, message: "Maximum Input Limit Reached" };
  } else {
    return { status: true, message: "" };
  }
}