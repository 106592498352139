import React, { useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import {
  NonEmptyValidation,
  NumberValidation,
  LengthValidation,
} from "../../../Store/validate";
import {
  GetAdmincorporationpage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const ForCorporationPageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createcms, setcreatecms] = useState({});
  console.log(createcms);
  const [validation, setValidation] = useState({});
  useEffect(() => {
    GetData();
  }, []);
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  const [err, setErr] = useState({});
  console.log(err);

  const mapInputOnchange = (e, i, length, key, array, errid) => {
    console.log(key);
    const data = createcms?.pageStructure?.[array];
    console.log(data);
    const updatedObj = {
      ...data[i],
      [key]: e.target.value,
    };
    data[i] = updatedObj;
    setcreatecms({
      ...createcms,
      pageStructure: {
        ...createcms.pageStructure,
        [array]: data,
      },
    });
    console.log(createcms);
    let error = [];
    let val = LengthValidation(e.target.value, length);
    error[i] = val;
    if (e.target.value.length === length) {
      let errorstate = {};
      errorstate[errid] = error;
      console.log(errorstate);
      setErr({
        errorstate,
      });
      setTimeout(() => {
        setErr();
      }, 3000);
    }
  };
  const UploadDocument = async (e, key) => {
    const imagekey = key;
    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    const reader = new FileReader();
    let str = e.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
        console.log(imagekey);
      };
    };
    reader.readAsDataURL(image);
  };

  const handleInputLengthValidation = (
    inputValue,
    maxLength,
    errSetter,
    errKey
  ) => {
    if (inputValue.length === maxLength) {
      errSetter((prevErr) => ({
        ...prevErr,
        [errKey]: LengthValidation(inputValue, maxLength),
      }));
      setTimeout(() => {
        errSetter(() => ({
          [errKey]: {},
        }));
      }, 3000);
    }
  };

  const ImageUploadin = async () => {
    console.log(data);
    if (data?.length > 0) {
      const dummy = data[0];
      console.log(dummy);
      if (
        dummy?.image === "bannerImage" 
        // &&
        // dummy?.size <= 400
        // &&
        // dummy?.height === 516 &&
        // dummy?.width === 444
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons1"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "bannerIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height <= 130 &&
        // dummy?.width <= 270
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.bannerIcons;
          const others = createcms?.pageStructure?.bannerIcons[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              bannerIcons: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons0" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.challengesSectionContent;
          const others = createcms?.pageStructure?.challengesSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              challengesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons1"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.challengesSectionContent;
          const others = createcms?.pageStructure?.challengesSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              challengesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "ChallengeIcons2" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons = createcms?.pageStructure?.challengesSectionContent;
          const others = createcms?.pageStructure?.challengesSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              challengesSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "graphImage"
        //  &&
        // dummy?.size <= 400
        //  &&
        // dummy?.height === 354 &&
        // dummy?.width === 1024
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              graphImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "stepsSectionImage"
        //  &&
        // dummy?.size <= 100
        // &&
        // dummy?.height <= 310 &&
        // dummy?.width <= 1160
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              stepsSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "designSectionImage"
        //  &&
        // dummy?.size <= 100
        // &&
        // dummy?.height <= 280 &&
        // dummy?.width <= 426
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              designSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "keyFeaturesSectionImage" 
        // &&
        // dummy?.size <= 150
        // &&
        // dummy?.height <= 340 &&
        // dummy?.width <= 420
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              keyFeaturesSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons0"
        //  &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.corporationsSectionContent;
          const others =
            createcms?.pageStructure?.corporationsSectionContent[0];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[0] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              corporationsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons1" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.corporationsSectionContent;
          const others =
            createcms?.pageStructure?.corporationsSectionContent[1];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[1] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              corporationsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons2" 
        // &&
        // dummy?.size <= 20
        //  &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.corporationsSectionContent;
          const others =
            createcms?.pageStructure?.corporationsSectionContent[2];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[2] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              corporationsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "corporateIcons3" 
        // &&
        // dummy?.size <= 20
        // &&
        // dummy?.height === 76 &&
        // dummy?.width === 76
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          const bannericons =
            createcms?.pageStructure?.corporationsSectionContent;
          const others =
            createcms?.pageStructure?.corporationsSectionContent[3];
          const updatedObj = {
            ...others,
            image: response.data.path,
          };
          console.log(others);
          bannericons[3] = updatedObj;
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              corporationsSectionContent: bannericons,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "testimonialSectionImage" 
        // &&
        // dummy?.size <= 500
        //  &&
        // dummy?.width <= 700 &&
        // dummy?.height <= 600
      ) {
        console.log([...dummy?.formData]);
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreatecms({
            ...createcms,
            pageStructure: {
              ...createcms.pageStructure,
              testimonialSectionImage: response.data.path,
            },
          });

          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdmincorporationpage(token);
    if (response && response?.statusCode === 200) {
      setcreatecms(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};

    validate.bannerImage = NonEmptyValidation(
      createcms?.pageStructure?.bannerImage
    );
    if (Array.isArray(createcms?.pageStructure?.bannerIcons)) {
      validate.bannerIcons = createcms?.pageStructure?.bannerIcons.map(
        (d, i) => ({
          image: NonEmptyValidation(d?.image),
          displayOrder: NumberValidation(d?.displayOrder),
        })
      );
    }
    validate.bannerHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerHeading
    );
    validate.bannerSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.bannerSubHeading
    );
    validate.bannerButtonName = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonName
    );
    validate.bannerButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.bannerButtonRedirectLink
    );
    validate.challengesSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.challengesSectionHeading
    );
    validate.challengesSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.challengesSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.challengesSectionContent)) {
      validate.challengesSectionContent =
        createcms?.pageStructure?.challengesSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          heading: NonEmptyValidation(d?.heading),
          subHeading: NonEmptyValidation(d?.subHeading),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.graphImage = NonEmptyValidation(
      createcms?.pageStructure?.graphImage
    );
    validate.stepsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionHeading
    );
    validate.stepsSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionSubHeading
    );
    validate.stepsSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.stepsSectionImage
    );
    validate.designSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.designSectionHeading
    );
    validate.designSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.designSectionImage
    );
    validate.designSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.designSectionContent
    );
    validate.designSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.designSectionButtonName
    );
    validate.designSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.designSectionButtonRedirectLink
    );
    validate.keyFeaturesSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.keyFeaturesSectionHeading
    );
    validate.keyFeaturesSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.keyFeaturesSectionImage
    );
    validate.keyFeaturesSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.keyFeaturesSectionContent
    );
    validate.keyFeaturesSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.keyFeaturesSectionButtonName
    );
    validate.keyFeaturesSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.keyFeaturesSectionButtonRedirectLink
    );
    validate.corporationsSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.corporationsSectionHeading
    );
    validate.corporationsSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.corporationsSectionSubHeading
    );
    if (Array.isArray(createcms?.pageStructure?.corporationsSectionContent)) {
      validate.corporationsSectionContent =
        createcms?.pageStructure?.corporationsSectionContent.map((d, i) => ({
          image: NonEmptyValidation(d?.image),
          content: NonEmptyValidation(d?.content),
          displayOrder: NumberValidation(d?.displayOrder),
        }));
    }
    validate.partnersSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionHeading
    );
    validate.partnersSectionSubHeading = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionSubHeading
    );
    validate.partnersSectionContent = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionContent
    );
    validate.partnersSectionButtonName = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonName
    );
    validate.partnersSectionButtonRedirectLink = NonEmptyValidation(
      createcms?.pageStructure?.partnersSectionButtonRedirectLink
    );
    validate.testimonialSectionHeading = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionHeading
    );
    validate.testimonialSectionImage = NonEmptyValidation(
      createcms?.pageStructure?.testimonialSectionImage
    );

    // <=============================================================================================>

    setValidation(validate);
    function areAllStatusTrue(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (!(menu.image.status && menu.displayOrder.status)) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue2(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu.image.status &&
            menu.heading.status &&
            menu.subHeading.status &&
            menu.displayOrder.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    function areAllStatusTrue3(validate) {
      for (let i = 0; i < validate?.length; i++) {
        const menu = validate[i];
        if (
          !(
            menu?.content?.status &&
            menu?.image?.status &&
            menu?.displayOrder?.status
          )
        ) {
          return false;
        }
      }
      return true;
    }
    const isTrue = areAllStatusTrue(validate.bannerIcons);
    const isTrue2 = areAllStatusTrue2(validate.challengesSectionContent);
    const isTrue3 = areAllStatusTrue3(validate.corporationsSectionContent);

    const filterObjectKeys = (obj, keysToFilter) => {
      return Object.keys(obj).reduce((filteredObj, key) => {
        if (!keysToFilter.includes(key)) {
          filteredObj[key] = obj[key];
        }
        return filteredObj;
      }, {});
    };

    const keysToFilter = [
      "bannerIcons",
      "challengesSectionContent",
      "corporationsSectionContent",
    ];
    const remain = filterObjectKeys(validate, keysToFilter);
    const isTrue5 = Object.values(remain).every((v) => v.status === true);

    const final = isTrue && isTrue2 && isTrue3 && isTrue5;

    if (final) {
      const Payload = {
        pageName: createcms?.pageName,
        pageStructure: createcms?.pageStructure,
      };
      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };
  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">

            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    For Corporations Page Management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
                <p>Last Updated by {" "}
                  {createcms?.lastUpdateBy}{", "}
                  {moment(createcms?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>
              </div>
            </div>
            <div className="contentbox">
              <div className="mt-1"></div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  {/* Banner section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Banner Section
                    </p>
                    <div className="p-4">
                      <div className="row gx-5">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Heading"
                              maxLength={50}
                              value={createcms?.pageStructure?.bannerHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "bannerHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerHeading?.message
                                ? `Banner Heading ${validation?.bannerHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Sub Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 500 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter Banner Sub Heading"
                              maxLength={500}
                              value={createcms?.pageStructure?.bannerSubHeading}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  500,
                                  setErr,
                                  "bannerSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerSubHeading?.message
                                ? `Banner Sub Heading ${validation?.bannerSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Name */}
                          <label className="labeltext">
                            Banner Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Name"
                              maxLength={25}
                              value={createcms?.pageStructure?.bannerButtonName}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "bannerButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.bannerButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.bannerButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.bannerButtonName?.message
                                ? `Banner Button Name ${validation?.bannerButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* Banner Button Redirect Link */}
                          <label className="labeltext">
                            Banner Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter Banner Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.bannerButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    bannerButtonRedirectLink: e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  bannerButtonRedirectLink: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.bannerButtonRedirectLink?.message
                              ? `Banner Button Redirect Link ${validation?.bannerButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Banner Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.bannerButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      bannerButtonShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Banner Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none d-none"
                              id="forcorporationimg1"
                              onChange={(e) => UploadDocument(e, "bannerImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for='forcorporationimg1'
                              className="alt-btn">
                              Upload Image
                            </label>
                            {!createcms?.pageStructure?.bannerImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.bannerImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB, 444px X 516px
                          </small>
                          <small className="validationerror">
                            {validation?.bannerImage?.message
                              ? `Banner Image ${validation?.bannerImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-12">
                          {createcms?.pageStructure?.bannerIcons &&
                            createcms?.pageStructure?.bannerIcons.map(
                              (item, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Image */}
                                      <label className="labeltext">{`Banner Icon ${i + 1
                                        }`}</label>
                                      <div>
                                        <input
                                          type="file"
                                          className="fieldinputfilereport d-none"
                                          id={`forcorporationbannericon${i}`}
                                          accept="image/jpeg, image/png,image/webp"
                                          onChange={async (e) =>
                                            UploadDocument(e, `bannerIcons${i}`)
                                          }
                                        />
                                        <label
                                          for={`forcorporationbannericon${i}`}
                                          className="alt-btn">
                                          Upload Image
                                        </label>

                                        {!item?.image ? (
                                          ""
                                        ) : (
                                          <span
                                            className="namelink"
                                            onClick={() => {
                                              window.open(
                                                `${cloudFrontUrl}${item?.image}`
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                      <small className="max">
                                        Image upload: Max 20KB, Max 270px X
                                        130px
                                      </small>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.image
                                            ?.message
                                          ? `Banner Icon ${validation?.bannerIcons[i]?.image?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="col-lg-6">
                                      {" "}
                                      {/* Banner Button Name */}
                                      <label className="labeltext">
                                        Banner Icon Display Order
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          className="fieldinput"
                                          placeholder="Enter Display Order"
                                          value={item?.displayOrder}
                                          onChange={(e) => {
                                            const bannericons =
                                              createcms?.pageStructure
                                                ?.bannerIcons;
                                            const updatedObj = {
                                              image: item.image,
                                              displayOrder: Number(
                                                e.target.value
                                              ),
                                            };
                                            bannericons[i] = updatedObj;
                                            setcreatecms({
                                              ...createcms,
                                              pageStructure: {
                                                ...createcms.pageStructure,
                                                bannerIcons: bannericons,
                                              },
                                            });
                                          }}
                                          onBlur={(e) => {
                                            const f =
                                              validation?.bannerIcons &&
                                              validation?.bannerIcons[i]
                                                ?.displayOrder;

                                            const v = NumberValidation(
                                              e.target.value
                                            );
                                            validation?.bannerIcons?.length >
                                              0 &&
                                              (validation.bannerIcons[
                                                i
                                              ].displayOrder = v);

                                            setValidation({
                                              ...validation,
                                            });
                                          }}
                                        />
                                      </div>
                                      <small className="validationerror">
                                        {validation?.bannerIcons?.length > 0 &&
                                          validation?.bannerIcons[i]?.displayOrder
                                            ?.message
                                          ? `Display Order ${validation?.bannerIcons[i]?.displayOrder?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.challengesSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure
                                  ?.challengesSectionHeading
                              }
                              maxLength={50}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    challengesSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "challengesSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  challengesSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.challengesSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.challengesSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.challengesSectionHeading?.message
                                ? `Heading ${validation?.challengesSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={50}
                              placeholder="Enter Section sub Heading"
                              value={
                                createcms?.pageStructure
                                  ?.challengesSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    challengesSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "challengesSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  challengesSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.challengesSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.challengesSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.challengesSectionSubHeading?.message
                                ? `sub Heading ${validation?.challengesSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        {createcms?.pageStructure?.challengesSectionContent &&
                          createcms?.pageStructure?.challengesSectionContent.map(
                            (item, i) => {
                              return (
                                <div className="row divider">
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Image */}
                                    <label className="labeltext">{`Banner Icon ${i + 1
                                      }`}</label>
                                    <div>
                                      <input
                                        type="file"
                                        className="fieldinputfilereport d-none"
                                        id={`forcorpporationchallengricon${i}`}
                                        accept="image/jpeg, image/png,image/webp"
                                        onChange={async (e) =>
                                          UploadDocument(
                                            e,
                                            `ChallengeIcons${i}`
                                          )
                                        }
                                      />
                                      <label
                                        for={`forcorpporationchallengricon${i}`}
                                        className="alt-btn">
                                        Upload Image
                                      </label>

                                      {!item?.image ? (
                                        ""
                                      ) : (
                                        <span
                                          className="namelink"
                                          onClick={() => {
                                            window.open(
                                              `${cloudFrontUrl}${item?.image}`
                                            );
                                          }}
                                          target="_blank"
                                        >
                                          View
                                        </span>
                                      )}
                                    </div>
                                    <small className="max">
                                      Image upload: Max 20KB, Max 76px X 76px
                                    </small>
                                    <small className="validationerror">
                                      {validation?.challengesSectionContent
                                        ?.length > 0 &&
                                        validation?.challengesSectionContent[i]
                                          ?.image?.message
                                        ? `Banner Icon ${validation?.challengesSectionContent[i]?.image?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Button Name */}
                                    <label className="labeltext">Heading
                                      <span className="char-hint">{" "}(Maximum 50 characters)</span>
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Content"
                                        maxLength={50}
                                        value={item?.heading}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            50,
                                            "heading",
                                            "challengesSectionContent",
                                            "challengesSectionContent1"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.challengesSectionContent &&
                                            validation
                                              ?.challengesSectionContent[i]
                                              ?.heading;

                                          const v = NumberValidation(
                                            e.target.value
                                          );
                                          validation?.challengesSectionContent
                                            ?.length > 0 &&
                                            (validation.challengesSectionContent[
                                              i
                                            ].heading = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate?.challengesSectionContent1
                                      ?.length > 0 &&
                                      err?.errorstate?.challengesSectionContent1[
                                        i
                                      ]?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.challengesSectionContent1[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.challengesSectionContent
                                          ?.length > 0 &&
                                          validation?.challengesSectionContent[i]
                                            ?.heading?.message
                                          ? `Content ${validation?.challengesSectionContent[i]?.heading?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Button Name */}
                                    <label className=" labeltext">
                                      Content
                                      <span className="char-hint">{" "}(Maximum 100 characters)</span>

                                    </label>
                                    <div>
                                      <textarea
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Content"
                                        maxLength={100}
                                        value={item?.subHeading}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            100,
                                            "subHeading",
                                            "challengesSectionContent",
                                            "challengesSectionContent2"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.challengesSectionContent &&
                                            validation
                                              ?.challengesSectionContent[i]
                                              ?.subHeading;
                                          const v = NumberValidation(
                                            e.target.value
                                          );
                                          validation?.challengesSectionContent
                                            ?.length > 0 &&
                                            (validation.challengesSectionContent[
                                              i
                                            ].subHeading = v);
                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate?.challengesSectionContent2
                                      ?.length > 0 &&
                                      err?.errorstate?.challengesSectionContent2[
                                        i
                                      ]?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.challengesSectionContent2[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.challengesSectionContent
                                          ?.length > 0 &&
                                          validation?.challengesSectionContent[i]
                                            ?.subHeading?.message
                                          ? `Content ${validation?.challengesSectionContent[i]?.subHeading?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Button Name */}
                                    <label className="labeltext">
                                      Display Order
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Display Order"
                                        value={item?.displayOrder}
                                        onChange={(e) => {
                                          const challengesSectionContent =
                                            createcms?.pageStructure
                                              ?.challengesSectionContent;
                                          const updatedObj = {
                                            ...item,
                                            displayOrder: e.target.value,
                                          };
                                          challengesSectionContent[i] =
                                            updatedObj;
                                          setcreatecms({
                                            ...createcms,
                                            pageStructure: {
                                              ...createcms.pageStructure,
                                              challengesSectionContent:
                                                challengesSectionContent,
                                            },
                                          });
                                        }}
                                        onBlur={(e) => {
                                          const f =
                                            validation?.challengesSectionContent &&
                                            validation
                                              ?.challengesSectionContent[i]
                                              ?.displayOrder;

                                          const v = NumberValidation(
                                            e.target.value
                                          );
                                          validation?.challengesSectionContent
                                            ?.length > 0 &&
                                            (validation.challengesSectionContent[
                                              i
                                            ].displayOrder = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    <small className="validationerror">
                                      {validation?.challengesSectionContent
                                        ?.length > 0 &&
                                        validation?.challengesSectionContent[i]
                                          ?.displayOrder?.message
                                        ? `Display Order ${validation?.challengesSectionContent[i]?.displayOrder?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.challengesSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.challengesSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      challengesSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Graph Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Graph Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Graph Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forcorporationgraph"
                              onChange={(e) => UploadDocument(e, "graphImage")}
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forcorporationgraph"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.graphImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.graphImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 400KB, Max 1024px X 354px
                          </small>
                          <small className="validationerror">
                            {validation?.graphImage?.message
                              ? `Image ${validation?.graphImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Graph</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.graphImageShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      graphImageShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 5 steps */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      5 Steps Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Image */}
                          <label className="labeltext">Image</label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forcorporation5step"
                              onChange={(e) =>
                                UploadDocument(e, "stepsSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forcorporation5step"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.stepsSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.stepsSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 1160px X 310px
                          </small>
                          <small className="validationerror">
                            {validation?.stepsSectionImage?.message
                              ? `Image ${validation?.stepsSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">Banner Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={50}
                              placeholder="Enter Banner Heading"
                              value={
                                createcms?.pageStructure?.stepsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "stepsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* Banner Heading */}
                          <label className="labeltext">
                            Banner Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={50}
                              placeholder="Enter Banner Heading"
                              value={
                                createcms?.pageStructure?.stepsSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    stepsSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "stepsSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  stepsSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.stepsSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.stepsSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.stepsSectionSubHeading?.message
                                ? `Banner Heading ${validation?.stepsSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Banner Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">Show Section</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.stepsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      stepsSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* design section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Design Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/*Design Section Image */}
                          <label className="labeltext">
                           Design Section Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forcorporationdesign"
                              onChange={(e) =>
                                UploadDocument(e, "designSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forcorporationdesign"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure?.designSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.designSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 426px X 280px
                          </small>
                          <small className="validationerror">
                            {validation?.designSectionImage?.message
                              ? `designSection Image ${validation?.designSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/*Design Section Heading */}
                          <label className="labeltext">
                           Design Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="EnterDesignn Section Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure?.designSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    designSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "designSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  designSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.designSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.designSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.designSectionHeading?.message
                                ? `designSection Heading ${validation?.designSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/*Design Section Sub Heading */}
                          <label className="labeltext">
                            Design Section Content
                            <span className="char-hint">{" "}(Maximum 400 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="EnterDesignn Section Sub Heading"
                              maxLength={400}
                              value={
                                createcms?.pageStructure?.designSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    designSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  400,
                                  setErr,
                                  "designSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  designSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.designSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.designSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.designSectionContent?.message
                                ? `designSection Sub Heading ${validation?.designSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/*Design Section Button Name */}
                          <label className="labeltext">
                           Design Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="EnterDesignn Section Button Name"
                              maxLength={25}
                              value={
                                createcms?.pageStructure
                                  ?.designSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    designSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "designSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  designSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.designSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.designSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.designSectionButtonName?.message
                                ? `designSection Button Name ${validation?.designSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/*Design Section Button Redirect Link */}
                          <label className="labeltext">
                           Design Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="EnterDesignn Section Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.designSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    designSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  designSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.designSectionButtonRedirectLink
                              ?.message
                              ? `designSection Button Redirect Link ${validation?.designSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Designn Section Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Design Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.designSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      designSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show Designn Section Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Design Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.designSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      designSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Key Features */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      Key Features Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                      <div className="col-lg-6">
                          {" "}
                          {/* keyFeaturesSection Image */}
                          <label className="labeltext">
                            key Features Section Image
                          </label>
                          <div>
                            <input
                              type="file"
                              className="fieldinputfilereport d-none"
                              id="forcorporationkeyimg"
                              onChange={(e) =>
                                UploadDocument(e, "keyFeaturesSectionImage")
                              }
                              accept="image/jpeg, image/png,image/webp"
                            />
                            <label
                              for="forcorporationkeyimg"
                              className="alt-btn">
                              Upload Image
                            </label>

                            {!createcms?.pageStructure
                              ?.keyFeaturesSectionImage ? (
                              ""
                            ) : (
                              <span
                                className="namelink"
                                onClick={() => {
                                  window.open(
                                    `${cloudFrontUrl}${createcms?.pageStructure?.keyFeaturesSectionImage}`
                                  );
                                }}
                                target="_blank"
                              >
                                View
                              </span>
                            )}
                          </div>
                          <small className="max">
                            Image upload: Max 100KB, Max 420px X 340px
                          </small>
                          <small className="validationerror">
                            {validation?.keyFeaturesSectionImage?.message
                              ? `keyFeaturesSection Image ${validation?.keyFeaturesSectionImage?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* keyFeaturesSection Heading */}
                          <label className="labeltext">
                            Key Features Heading
                            <span className="char-hint">{" "}(Maximum 30 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={30}
                              placeholder="Enter key Features Section Heading"
                              value={
                                createcms?.pageStructure
                                  ?.keyFeaturesSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    keyFeaturesSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  30,
                                  setErr,
                                  "keyFeaturesSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  keyFeaturesSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.keyFeaturesSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.keyFeaturesSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.keyFeaturesSectionHeading?.message
                                ? `key Features Section Heading ${validation?.keyFeaturesSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* keyFeaturesSection Sub Heading */}
                          <label className="labeltext">Content
                            <span className="char-hint">{" "}(Maximum 400 characters)</span>
                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              placeholder="Enter key Features Section Sub Heading"
                              maxLength={400}
                              value={
                                createcms?.pageStructure
                                  ?.keyFeaturesSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    keyFeaturesSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  400,
                                  setErr,
                                  "keyFeaturesSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  keyFeaturesSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.keyFeaturesSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.keyFeaturesSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.keyFeaturesSectionContent?.message
                                ? `key Features Section Sub Heading ${validation?.keyFeaturesSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* keyFeaturesSection Button Name */}
                          <label className="labeltext">
                            key Features Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter key Features Section Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.keyFeaturesSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    keyFeaturesSectionButtonName:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "keyFeaturesSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  keyFeaturesSectionButtonName:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.keyFeaturesSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.keyFeaturesSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.keyFeaturesSectionButtonName?.message
                                ? `key Features Section Button Name ${validation?.keyFeaturesSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* keyFeaturesSection Button Redirect Link */}
                          <label className="labeltext">
                            keyFeaturesSection Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter key Features Section Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.keyFeaturesSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    keyFeaturesSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  keyFeaturesSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.keyFeaturesSectionButtonRedirectLink
                              ?.message
                              ? `key Features Section Button Redirect Link ${validation?.keyFeaturesSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show keyFeaturesSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show key Features Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.keyFeaturesSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      keyFeaturesSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                    
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show keyFeaturesSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Key Features Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.keyFeaturesSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      keyFeaturesSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sectionShow,  heading,  subheading,  Array */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {`${createcms?.pageStructure?.corporationsSectionHeading}`}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/*  Heading */}
                          <label className="labeltext">Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              maxLength={50}
                              placeholder="Enter Section Heading"
                              value={
                                createcms?.pageStructure
                                  ?.corporationsSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    corporationsSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "corporationsSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  corporationsSectionHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.corporationsSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.corporationsSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.corporationsSectionHeading?.message
                                ? `Heading ${validation?.corporationsSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* sub Heading */}
                          <label className="labeltext">
                            Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput w-100"
                              placeholder="Enter Section sub Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure
                                  ?.corporationsSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    corporationsSectionSubHeading:
                                      e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "corporationsSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  corporationsSectionSubHeading:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          {err?.corporationsSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.corporationsSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.corporationsSectionSubHeading
                                ?.message
                                ? `sub Heading ${validation?.corporationsSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <p className="h5">List of Contents</p>
                        {createcms?.pageStructure?.corporationsSectionContent &&
                          createcms?.pageStructure?.corporationsSectionContent.map(
                            (item, i) => {
                              return (
                                <div className="row divider">
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Image */}
                                    <label className="labeltext">{`Banner Icon ${i + 1
                                      }`}</label>
                                    <div>
                                      <input
                                        type="file"
                                        className="fieldinputfilereport d-none"
                                        id={`forcorporationimg8${i}`}
                                        accept="image/jpeg, image/png,image/webp"
                                        onChange={async (e) =>
                                          UploadDocument(
                                            e,
                                            `corporateIcons${i}`
                                          )
                                        }
                                      />
                                      <label
                                        for={`forcorporationimg8${i}`}
                                        className="alt-btn">
                                        Upload Image
                                      </label>

                                      {!item?.image ? (
                                        ""
                                      ) : (
                                        <span
                                          className="namelink"
                                          onClick={() => {
                                            window.open(
                                              `${cloudFrontUrl}${item?.image}`
                                            );
                                          }}
                                          target="_blank"
                                        >
                                          View
                                        </span>
                                      )}
                                    </div>
                                    <small className="max">
                                      Image upload: Max 20KB, 76px X 76px
                                    </small>
                                    <small className="validationerror">
                                      {validation?.corporationsSectionContent
                                        ?.length > 0 &&
                                        validation?.corporationsSectionContent[i]
                                          ?.image?.message
                                        ? `Banner Icon ${validation?.corporationsSectionContent[i]?.image?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Button Name */}
                                    <label className="labeltext">
                                      Display Order
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Display Order"
                                        value={item?.displayOrder}
                                        onChange={(e) => {
                                          const corporationsSectionContent =
                                            createcms?.pageStructure
                                              ?.corporationsSectionContent;
                                          const updatedObj = {
                                            ...item,
                                            displayOrder: e.target.value,
                                          };
                                          corporationsSectionContent[i] =
                                            updatedObj;
                                          setcreatecms({
                                            ...createcms,
                                            pageStructure: {
                                              ...createcms.pageStructure,
                                              corporationsSectionContent:
                                                corporationsSectionContent,
                                            },
                                          });
                                        }}
                                        onBlur={(e) => {
                                          const f =
                                            validation?.corporationsSectionContent &&
                                            validation
                                              ?.corporationsSectionContent[i]
                                              ?.displayOrder;

                                          const v = NumberValidation(
                                            e.target.value
                                          );
                                          validation?.corporationsSectionContent
                                            ?.length > 0 &&
                                            (validation.corporationsSectionContent[
                                              i
                                            ].displayOrder = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    <small className="validationerror">
                                      {validation?.corporationsSectionContent
                                        ?.length > 0 &&
                                        validation?.corporationsSectionContent[i]
                                          ?.displayOrder?.message
                                        ? `Display Order ${validation?.corporationsSectionContent[i]?.displayOrder?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    {/* Banner Button Name */}
                                    <label className="labeltext">Content
                                      <span className="char-hint">{" "}(Maximum 50 characters)</span>
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="fieldinput"
                                        placeholder="Enter Content"
                                        maxLength={50}
                                        value={item?.content}
                                        onChange={(e) =>
                                          mapInputOnchange(
                                            e,
                                            i,
                                            50,
                                            "content",
                                            "corporationsSectionContent",
                                            "corporationsSectionContent1"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const f =
                                            validation?.corporationsSectionContent &&
                                            validation
                                              ?.corporationsSectionContent[i]
                                              ?.content;

                                          const v = NonEmptyValidation(
                                            e.target.value
                                          );
                                          validation?.corporationsSectionContent
                                            ?.length > 0 &&
                                            (validation.corporationsSectionContent[
                                              i
                                            ].content = v);

                                          setValidation({
                                            ...validation,
                                          });
                                        }}
                                      />
                                    </div>
                                    {err?.errorstate
                                      ?.corporationsSectionContent1?.length >
                                      0 &&
                                      err?.errorstate
                                        ?.corporationsSectionContent1[i]
                                        ?.message ? (
                                      <small className="validationerror length-err">
                                        <span class="material-symbols-outlined len-err-img">
                                          warning
                                        </span>
                                        {`${err?.errorstate?.corporationsSectionContent1[i]?.message}`}
                                      </small>
                                    ) : (
                                      <small className="validationerror">
                                        {validation?.corporationsSectionContent
                                          ?.length > 0 &&
                                          validation?.corporationsSectionContent[
                                            i
                                          ]?.content?.message
                                          ? `Content ${validation?.corporationsSectionContent[i]?.content?.message}`
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        <div className="col-lg-6 align-self-center">
                          {" "}
                          {/*Show  section */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show{" "}
                              {`${createcms?.pageStructure?.corporationsSectionHeading}`}{" "}
                              Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.corporationsSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      corporationsSectionShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Partner Section */}
                  <div className=" admin-box-container px-0">
                    <p className="h4 admin-box-heading text-center ">
                      {createcms?.pageStructure?.partnersSectionHeading}{" "}
                      {createcms?.pageStructure?.partnersSectionSubHeading}{" "}
                      Section
                    </p>
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              placeholder="Enter partnersSection Heading"
                              maxLength={50}
                              value={
                                createcms?.pageStructure?.partnersSectionHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partnersSectionHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Heading */}
                          <label className="labeltext">
                            Partner Section Sub Heading
                            <span className="char-hint">{" "}(Maximum 50 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput "
                              maxLength={50}
                              placeholder="Enter partnersSection Heading"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionSubHeading
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionSubHeading: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  50,
                                  setErr,
                                  "partnersSectionSubHeading"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionSubHeading: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionSubHeading?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionSubHeading?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionSubHeading?.message
                                ? `partnersSection Heading ${validation?.partnersSectionSubHeading?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          {/* partnersSection Sub Heading */}
                          <label className="labeltext">
                            Partner Section Content
                            <span className="char-hint">{" "}(Maximum 300 characters)</span>

                          </label>
                          <div>
                            <textarea
                              type="text"
                              className="fieldinput "
                              maxLength={300}
                              placeholder="Enter partnersSection Content"
                              value={
                                createcms?.pageStructure?.partnersSectionContent
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionContent: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  300,
                                  setErr,
                                  "partnersSectionContent"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionContent: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionContent?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionContent?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionContent?.message
                                ? `partnersSection Sub Heading ${validation?.partnersSectionContent?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Name */}
                          <label className="labeltext">
                            Partners Section Button Name
                            <span className="char-hint">{" "}(Maximum 25 characters)</span>

                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              maxLength={25}
                              placeholder="Enter partnersSection Button Name"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonName
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonName: e.target.value,
                                  },
                                });
                                handleInputLengthValidation(
                                  e.target.value,
                                  25,
                                  setErr,
                                  "partnersSectionButtonName"
                                );
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonName: NonEmptyValidation(
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </div>
                          {err?.partnersSectionButtonName?.message ? (
                            <small className="validationerror length-err">
                              <span class="material-symbols-outlined len-err-img">
                                warning
                              </span>
                              {`${err?.partnersSectionButtonName?.message}`}
                            </small>
                          ) : (
                            <small className="validationerror">
                              {validation?.partnersSectionButtonName?.message
                                ? `partnersSection Button Name ${validation?.partnersSectionButtonName?.message}`
                                : ""}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          {/* partnersSection Button Redirect Link */}
                          <label className="labeltext">
                            Partners Section Button Redirect Link
                          </label>
                          <div>
                            <input
                              type="text"
                              className="fieldinput"
                              placeholder="Enter partnersSection Button Redirect Link"
                              value={
                                createcms?.pageStructure
                                  ?.partnersSectionButtonRedirectLink
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    partnersSectionButtonRedirectLink:
                                      e.target.value,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                setValidation({
                                  ...validation,
                                  partnersSectionButtonRedirectLink:
                                    NonEmptyValidation(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <small className="validationerror">
                            {validation?.partnersSectionButtonRedirectLink
                              ?.message
                              ? `partnersSection Button Redirect Link ${validation?.partnersSectionButtonRedirectLink?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section Button
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure
                                    ?.partnersSectionButtonShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionButtonShown:
                                        e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          {" "}
                          {/*Show partnersSection Button */}
                          <div className="d-flex justify-content-between bx-box">
                            <label className="labeltext">
                              Show Partners Section
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  createcms?.pageStructure?.partnersSectionShown
                                }
                                onChange={(e) => {
                                  setcreatecms({
                                    ...createcms,
                                    pageStructure: {
                                      ...createcms.pageStructure,
                                      partnersSectionShown: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <=======================================================================================> */}
                  {/* <=======================================================================================> */}
                </div>
                {/* Testimonial section */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Testimonial Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <p className="h4"></p>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Image */}
                        <label className="labeltext">
                          Testimonial Background Image
                        </label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="forcorporationtestimonial"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) =>
                              UploadDocument(e, "testimonialSectionImage")
                            }
                          />
                          <label
                            for="forcorporationtestimonial"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createcms?.pageStructure
                            ?.testimonialSectionImage ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createcms?.pageStructure?.testimonialSectionImage}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 400KB, Max 700px X 600px.
                        </small>
                        <small className="validationerror">
                          {validation?.bannerImage?.message
                            ? `Image ${validation?.testimonialSectionImage?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        {/* Banner Heading */}
                        <label className="labeltext">Testimonial Heading
                          <span className="char-hint">{" "}(Maximum 50 characters)</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput "
                            placeholder="Enter Testimonial Heading"
                            maxLength={50}
                            value={
                              createcms?.pageStructure
                                ?.testimonialSectionHeading
                            }
                            onChange={(e) => {
                              setcreatecms({
                                ...createcms,
                                pageStructure: {
                                  ...createcms.pageStructure,
                                  testimonialSectionHeading: e.target.value,
                                },
                              });
                              handleInputLengthValidation(
                                e.target.value,
                                50,
                                setErr,
                                "testimonialSectionHeading"
                              );
                            }}
                            onBlur={(e) => {
                              setValidation({
                                ...validation,
                                testimonialSectionHeading: NonEmptyValidation(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        {err?.testimonialSectionHeading?.message ? (
                          <small className="validationerror length-err">
                            <span class="material-symbols-outlined len-err-img">
                              warning
                            </span>
                            {`${err?.testimonialSectionHeading?.message}`}
                          </small>
                        ) : (
                          <small className="validationerror">
                            {validation?.testimonialSectionHeading?.message
                              ? `Testimonial Heading ${validation?.testimonialSectionHeading?.message}`
                              : ""}
                          </small>
                        )}
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show Banner Button */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Testimonial Section
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure
                                  ?.testimonialSectionShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    testimonialSectionShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* header,footer,contactus */}
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">
                            Show Contact Us Card
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createcms?.pageStructure?.contactUsCardsShown
                              }
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    contactUsCardsShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Header</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.headerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    headerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        {" "}
                        {/*Show  section */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Footer</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createcms?.pageStructure?.footerShown}
                              onChange={(e) => {
                                setcreatecms({
                                  ...createcms,
                                  pageStructure: {
                                    ...createcms.pageStructure,
                                    footerShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForCorporationPageManagement;
