import React, { createElement, useEffect, useState } from "react";
import AdminHeader from "../Adminheader";
import { errorMsg, successMsg } from "../../Toastify";
import { EmailValidation, NonEmptyValidation } from "../../../Store/validate";
import {
  GetAdminContactuspage,
  ImageUpload,
  UpdateFooter,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloudFrontUrl } from "../../../commonUtils/Utils";
const ContactuspageManagement = () => {
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [createData, setcreateData] = useState({});
  console.log(createData);
  const [validation, setValidation] = useState({});
  const [data, setData] = useState([]);
  const imageData = (formData, image, size, height, width) => {
    return {
      formData: formData,
      image: image,
      size: size,
      height: height,
      width: width,
    };
  };
  useEffect(() => {
    GetData();
  }, []);

  // const UploadDocument = async (e, key) => {
  //   const imagekey = key
  //   let formData = new FormData();
  //   let str = e.target.files[0].name;
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
  //   formData.append("path", "document/");

  //   const response = await ImageUpload(formData);
  //   if (response && response.statusCode === 200) {
  //     if (imagekey === "emailIcon") {
  //       setcreateData({
  //         ...createData,
  //         pageStructure: {
  //           ...createData.pageStructure,
  //           emailIcon: response.data.path
  //         }
  //       })
  //     }
  //     if (imagekey === "locationIcon") {
  //       setcreateData({
  //         ...createData,
  //         pageStructure: {
  //           ...createData.pageStructure,
  //           locationIcon: response.data.path
  //         }
  //       })
  //     }

  //     successMsg("Image Uploaded Successfully");
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };

  const handleLocationChange = (index, value) => {
    const updatedLocations = [...createData.pageStructure.location];
    updatedLocations[index].address = value;
    setcreateData({
      ...createData,
      pageStructure: {
        ...createData.pageStructure,
        location: updatedLocations,
      },
    });
  };

  const handleLocationShownChange = (index, checked) => {
    const updatedLocations = [...createData.pageStructure.location];
    updatedLocations[index].status = checked ? "active" : "inactive";
    setcreateData({
      ...createData,
      pageStructure: {
        ...createData.pageStructure,
        location: updatedLocations,
      },
    });
  };
  const UploadDocument = async (e, key) => {
    const imagekey = key;

    let formData = new FormData();
    const image = e.target?.files[0];
    const imgsize = image?.size / 1024;
    let str = e?.target?.files[0]?.name;
    formData.append("file", e.target.files[0]);
    formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
    formData.append("path", "document/");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setData([
          imageData(formData, imagekey, imgsize, img.height, img.width),
        ]);
      };
    };
    reader.readAsDataURL(image);
  };

  const ImageUploadin = async () => {
    if (data?.length > 0) {
      console.log(data);
      const dummy = data[0];
      if (
        dummy?.image === "emailIcon"
        //  &&
        // dummy?.size <= 10
        // &&
        // dummy?.width <= 25 &&
        // dummy?.height <= 25
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateData({
            ...createData,
            pageStructure: {
              ...createData.pageStructure,
              emailIcon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else if (
        dummy?.image === "locationIcon" 
        // &&
        // dummy?.size <= 10
        //  &&
        // dummy?.width <= 25 &&
        // dummy?.height <= 25
      ) {
        const response = await ImageUpload(dummy?.formData);
        if (response && response.statusCode === 200) {
          setcreateData({
            ...createData,
            pageStructure: {
              ...createData.pageStructure,
              locationIcon: response.data.path,
            },
          });
          successMsg(response?.message);
        }
      } else {
        console.log("error");
        errorMsg("The image should fit the recommended file size.");
      }
    }
  };

  useEffect(() => {
    if (data) {
      ImageUploadin();
    }
  }, [data]);

  const GetData = async () => {
    const response = await GetAdminContactuspage(token);
    if (response && response?.statusCode === 200) {
      setcreateData(response?.data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.heading = NonEmptyValidation(createData?.pageStructure?.heading);
    validate.content = NonEmptyValidation(createData?.pageStructure?.content);
    validate.emailIcon = NonEmptyValidation(
      createData?.pageStructure?.emailIcon
    );
    validate.emailId = EmailValidation(createData?.pageStructure?.emailId);

    validate.locationIcon = NonEmptyValidation(
      createData?.pageStructure?.locationIcon
    );
    validate.calendlyButtonContent = NonEmptyValidation(
      createData?.pageStructure?.calendlyButtonContent
    );
    validate.calendlyLink = NonEmptyValidation(
      createData?.pageStructure?.calendlyLink
    );

    
    validate.location = NonEmptyValidation(createData?.pageStructure?.location);
    validate.faqTitle = NonEmptyValidation(createData?.pageStructure?.faqTitle);
    validate.contactUsFormContent = NonEmptyValidation(
      createData?.pageStructure?.contactUsFormContent
    );

    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        pageName: createData?.pageName,
        pageStructure: {
          ...createData?.pageStructure,
          heading: createData?.pageStructure?.heading,
          content: createData?.pageStructure?.content,
          emailIcon: createData?.pageStructure?.emailIcon,
          emailId: createData?.pageStructure?.emailId,
          emaiIdShown: createData?.pageStructure?.emaiIdShown,
          calendlyLink: createData?.pageStructure?.calendlyLink,
          calendlyButtonContent: createData?.pageStructure?.calendlyButtonContent,
          showCalendlyButton: createData?.pageStructure?.showCalendlyButton,


          
          locationIcon: createData?.pageStructure?.locationIcon,
          pageStructure: createData.pageStructure,
          location: createData.pageStructure?.location,
          // location: createData?.pageStructure?.location,
          // locationShown: createData?.pageStructure?.locationShown,
          headerShown: createData?.pageStructure?.headerShown,
          footerShown: createData?.pageStructure?.footerShown,
          socialMediaShown: createData?.pageStructure?.socialMediaShown,
          faqTitle: createData?.pageStructure?.faqTitle,
          faqShown: createData?.pageStructure?.faqShown,
          contactUsFormContent: createData?.pageStructure?.contactUsFormContent,
          contactUsCardsShown: createData?.pageStructure?.contactUsCardsShown,
        },
      };

      const response = await UpdateFooter(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
      } else {
        errorMsg(response.message);
      }
    }
  };

  return (
    <>
      <div className="adminbackground">
        <AdminHeader />
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="mt-2">
                  <h2 className="text-center fw-bold text-capitalize">
                    Contact Us Page management
                  </h2>
                  <button className="buttonmodel buttonmodel2" onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
               <p>Last Updated by {" "}
                  {createData?.lastUpdateBy}{", "}
                  {moment(createData?.updatedAt).format("DD-MMM-YYYY, HH:mm")}
                </p>

              </div>
            </div>

            <div className="contentbox">
              <div className="mt-1">{/* <h5>Create Admin</h5> */}</div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    {createData?.pageStructure?.heading}
                  </p>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="labeltext">Heading*</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Heading"
                            value={createData?.pageStructure?.heading}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  heading: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.heading?.message
                            ? `Heading ${validation?.heading?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-6">
                        <label className="labeltext">Content *</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput w-100"
                            placeholder="Enter Content"
                            value={createData?.pageStructure?.content}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  content: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.content?.message
                            ? `Content ${validation?.content?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Location Icon</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="contactuspageimg1"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "locationIcon")}
                          />
                          <label
                            for="contactuspageimg1"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createData?.pageStructure?.locationIcon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createData?.pageStructure?.locationIcon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 10KB, Max 25px X 25px.
                        </small>
                        <small className="validationerror">
                          {validation?.locationIcon?.message
                            ? `Thumbnail ${validation?.locationIcon?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">Location 1</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Location 1"
                            value={
                              createData?.pageStructure?.location[0]?.address
                            }
                            onChange={(e) =>
                              handleLocationChange(0, e.target.value)
                            }
                          />
                        </div>
                        {/* Checkbox for showing/hiding Location 1 */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Location 1</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createData?.pageStructure?.location[0]
                                  ?.status === "active"
                              }
                              onChange={(e) =>
                                handleLocationShownChange(0, e.target.checked)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">Location 2</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Location 1"
                            value={
                              createData?.pageStructure?.location[1]?.address
                            }
                            onChange={(e) =>
                              handleLocationChange(1, e.target.value)
                            }
                          />
                        </div>
                        {/* Checkbox for showing/hiding Location 1 */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Location 2</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createData?.pageStructure?.location[1]
                                  ?.status === "active"
                              }
                              onChange={(e) =>
                                handleLocationShownChange(1, e.target.checked)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">Location 3</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Location 1"
                            value={
                              createData?.pageStructure?.location[2]?.address
                            }
                            onChange={(e) =>
                              handleLocationChange(2, e.target.value)
                            }
                          />
                        </div>
                        {/* Checkbox for showing/hiding Location 1 */}
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Location 3</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createData?.pageStructure?.location[2]
                                  ?.status === "active"
                              }
                              onChange={(e) =>
                                handleLocationShownChange(2, e.target.checked)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">Email Icon</label>
                        <div>
                          <input
                            type="file"
                            className="fieldinputfilereport d-none"
                            id="contctuspageimg2"
                            accept="image/jpeg, image/png,image/webp"
                            onChange={(e) => UploadDocument(e, "emailIcon")}
                          />
                          <label
                            for="contctuspageimg2"
                            className="alt-btn">
                            Upload Image
                          </label>

                          {!createData?.pageStructure?.emailIcon ? (
                            ""
                          ) : (
                            <span
                              className="namelink"
                              onClick={() => {
                                window.open(
                                  `${cloudFrontUrl}${createData?.pageStructure?.emailIcon}`
                                );
                              }}
                              target="_blank"
                            >
                              View
                            </span>
                          )}
                        </div>
                        <small className="max">
                          Image upload: Max 10KB, Max 25px X 25px.
                        </small>
                        <small className="validationerror">
                          {validation?.image?.message
                            ? `Thumbnail ${validation?.image?.message}`
                            : ""}
                        </small>
                      </div>

                      <div className="col-lg-4">
                        <label className="labeltext">Email-Id*</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Email-Id"
                            value={createData?.pageStructure?.emailId}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  emailId: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.emailId?.message
                            ? `Email Id ${validation?.emailId?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Email ID</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createData?.pageStructure?.emaiIdShown}
                              onChange={(e) => {
                                setcreateData({
                                  ...createData,
                                  pageStructure: {
                                    ...createData.pageStructure,
                                    emaiIdShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    
                      <div className="col-lg-4">
                        <label className="labeltext">Calendly Button Content*</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Calendly Button Content"
                            value={createData?.pageStructure?.calendlyButtonContent}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  calendlyButtonContent: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.calendlyButtonContent?.message
                            ? `Calendly Button Content ${validation?.calendlyButtonContent?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">Calendly Link*</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter Calendly Link"
                            value={createData?.pageStructure?.calendlyLink}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  calendlyLink: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.calendlyLink?.message
                            ? `Calendly Link ${validation?.calendlyLink?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show Calendly Button</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createData?.pageStructure?.showCalendlyButton}
                              onChange={(e) => {
                                setcreateData({
                                  ...createData,
                                  pageStructure: {
                                    ...createData.pageStructure,
                                    showCalendlyButton: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between  bx-box">
                          <label className="labeltext">
                            Show SocialMedia Icons
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                createData?.pageStructure?.socialMediaShown
                              }
                              onChange={(e) => {
                                setcreateData({
                                  ...createData,
                                  pageStructure: {
                                    ...createData.pageStructure,
                                    socialMediaShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="labeltext">FAQ Title</label>
                        <div>
                          <input
                            type="text"
                            className="fieldinput"
                            placeholder="Enter FAQ Title"
                            value={createData?.pageStructure?.faqTitle}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  faqTitle: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.faqTitle?.message
                            ? `FAQ Title ${validation?.faqTitle?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="d-flex justify-content-between bx-box">
                          <label className="labeltext">Show FAQ</label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={createData?.pageStructure?.faqShown}
                              onChange={(e) => {
                                setcreateData({
                                  ...createData,
                                  pageStructure: {
                                    ...createData.pageStructure,
                                    faqShown: e.target.checked,
                                  },
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <label className="labeltext">Content for Form</label>
                        <div>
                          <textarea
                            type="text"
                            className="fieldinput w-100"
                            placeholder="Enter content for form"
                            value={
                              createData?.pageStructure?.contactUsFormContent
                            }
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  contactUsFormContent: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <small className="validationerror">
                          {validation?.contactUsFormContent?.message
                            ? `content for form ${validation?.contactUsFormContent?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" admin-box-container px-0">
                  <p className="h4 admin-box-heading text-center ">
                    Common Section
                  </p>
                  <div className="row p-4">
                    <div className="col-lg-4 align-self-center">
                      <div className="d-flex justify-content-between  bx-box">
                        <label className="labeltext">Show Header</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={createData?.pageStructure?.headerShown}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  headerShown: e.target.checked,
                                },
                              });
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 align-self-center">
                      <div className="d-flex justify-content-between  bx-box">
                        <label className="labeltext">Show Footer</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={createData?.pageStructure?.footerShown}
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  footerShown: e.target.checked,
                                },
                              });
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 align-self-center">
                      <div className="d-flex justify-content-between bx-box">
                        <label className="labeltext">
                          Show Contact us Section
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={
                              createData?.pageStructure?.contactUsCardsShown
                            }
                            onChange={(e) => {
                              setcreateData({
                                ...createData,
                                pageStructure: {
                                  ...createData.pageStructure,
                                  contactUsCardsShown: e.target.checked,
                                },
                              });
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <div className="row my-4">
                  <div className="col-lg-3 centertext ms-auto mx-auto">
                    <button className="buttonmodel">Update</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactuspageManagement;
