import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logOutSuccess } from "../../Store/auth/action";
import { GetAdminHeaderdata } from "../../Repository/Api";

const Sidebar = () => {
  const { pathname } = useLocation();
  console.log(pathname);
  const auth = useSelector((state) => state?.auth);
  const token = auth?.auth?.accessToken;
  const [navbar, setNavbar] = useState({});
  console.log(navbar)
  const GetData = async () => {
    const response = await GetAdminHeaderdata(token);
    if (response && response?.statusCode === 200) {
      setNavbar(response?.data?.pageStructure?.menus);
    }
  };
  useEffect(() => {
    GetData();
  }, [pathname]);
  console.log(navbar);

  return (
    <>
      {pathname.includes("/admin/") && (
        <div className="sidebar">
          <ul>
            <li className="marginspace">
              <Link to="/admin/dashboard">
                <div
                  className={
                    window.location.href.includes("dashboard")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      grid_view
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Dashboard</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/Home">
                <div
                  className={
                    window.location.href.includes("Home")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      home
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Home Page Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/platform">
                <div
                  className={
                    window.location.href.includes("platform")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      desktop_mac
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      {navbar?.length && navbar[0]?.title} {" "}
                    Page Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/licensing">
                <div
                  className={
                    window.location.href.includes("licensing")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      license
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      {navbar?.length && navbar[1]?.title} Page Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/Training-services">
                <div
                  className={
                    window.location.href.includes("Training-services")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      local_library
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      {navbar?.length && navbar[2]?.title} Page Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <div
                className={
                  window.location.href.includes("ForCorporation") ||
                    window.location.href.includes("ForUniversities") ||
                    window.location.href.includes("ForTrainingProviders")
                    ? "sidebarnavactive displayflex pointer align-items-center"
                    : "sidebarnavinactive displayflex pointer align-items-center"
                }
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span className="material-symbols-outlined sidebaricon">
                  payments
                </span>
                &nbsp;
                <h6 className="sidebartitle my-auto">
                  {navbar?.length && navbar[3]?.title} Page Management
                </h6>
                <span className="material-symbols-outlined">expand_more</span>
              </div>
              <div className="collapse" id="collapseExample">
                <ul className="card card-body">
                  <li className="marginspace">
                    <Link to="/admin/page/ForCorporation">
                      <div
                        className={
                          window.location.href.includes("ForCorporation")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            corporate_fare
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[3]?.subMenu[0]?.title}{" "}
                            Page Management
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="marginspace">
                    <Link to="/admin/page/ForUniversities">
                      <div
                        className={
                          window.location.href.includes("ForUniversities")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            school
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[3]?.subMenu[1]?.title}{" "}
                            Page Management
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="marginspace">
                    <Link to="/admin/page/ForTrainingProviders">
                      <div
                        className={
                          window.location.href.includes("ForTrainingProviders")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            diversity_2
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[3]?.subMenu[2]?.title}{" "}
                            Page Management
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="marginspace">
              <div
                className={
                  window.location.href.includes("Contactrequestlist") ||
                    window.location.href.includes("contactusPage") ||
                    window.location.href.includes("ContactrequestSpecificview")
                    ? "sidebarnavactive displayflex pointer align-items-center"
                    : "sidebarnavinactive displayflex pointer align-items-center"
                }
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span className="material-symbols-outlined sidebaricon">
                  payments
                </span>
                &nbsp;
                <h6 className="sidebartitle my-auto">
                  {navbar?.length && navbar[4]?.subMenu[0]?.title} Page
                  Management
                </h6>
                <span className="material-symbols-outlined">expand_more</span>
              </div>
              <div className="collapse" id="collapseExample1">
                <ul className="card card-body">
                  <li className="marginspace">
                    <Link to="/admin/contactusPage">
                      <div
                        className={
                          window.location.href.includes("contactusPage")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            contacts
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[4]?.subMenu[0]?.title}{" "}
                            Page CMS
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="marginspace">
                    <Link to="/admin/Contactrequestlist">
                      <div
                        className={
                          window.location.href.includes("Contactrequestlist") ||
                            window.location.href.includes(
                              "ContactrequestSpecificview"
                            )
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon ">
                            contact_mail
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[4]?.subMenu[0]?.title}{" "}
                            Forms
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="marginspace">
              <div
                className={
                  window.location.href.includes("news-and-blogs") ||
                    window.location.href.includes("Bloglist") ||
                    window.location.href.includes("BlogCreate") ||
                    window.location.href.includes("BlogSpecificview") ||
                    window.location.href.includes("specific-blog")
                    ? "sidebarnavactive displayflex pointer align-items-center"
                    : "sidebarnavinactive displayflex pointer align-items-center"
                }
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample2"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span className="material-symbols-outlined sidebaricon">
                  payments
                </span>
                &nbsp;
                <h6 className="sidebartitle my-auto">Blogs Management</h6>
                <span className="material-symbols-outlined">expand_more</span>
              </div>
              <div className="collapse" id="collapseExample2">
                <ul className="card card-body">
                  <li className="marginspace">
                    <Link to="/admin/page/news-and-blogs">
                      <div
                        className={
                          window.location.href.includes("news-and-blogs")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            newsmode
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            {navbar?.length && navbar[4]?.subMenu[1]?.title}{" "}
                            Page CMS
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="marginspace">
                    <Link to="/admin/Bloglist">
                      <div
                        className={
                          window.location.href.includes("Bloglist") ||
                            window.location.href.includes("BlogCreate") ||
                            window.location.href.includes("BlogSpecificview")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            topic
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">Blog List</h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="marginspace">
                    <Link to="/admin/page/specific-blog">
                      <div
                        className={
                          window.location.href.includes("specific-blog")
                            ? "sidebarnavactive"
                            : "sidebarnavinactive"
                        }
                      >
                        <div className="displayflex">
                          <span className="material-symbols-outlined sidebaricon">
                            post
                          </span>
                          &nbsp;
                          <h6 className="sidebartitle my-auto">
                            Specific Blog Page CMS
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="marginspace">
              <Link to="/admin/FaqList">
                <div
                  className={
                    window.location.href.includes("FaqList") ||
                      window.location.href.includes("FaqSpecificview") ||
                      window.location.href.includes("FaqCreate")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon ">
                      live_help
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      {navbar?.length && navbar[4]?.subMenu[2]?.title}{" "}
                      Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="my-3">
              <Link to="/admin/PricingPage">
                <div
                  className={
                    window.location.href.includes("PricingPage") ||
                      window.location.href.includes("PricingCreate")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      payments
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      {navbar?.length && navbar[5]?.title} Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/AboutUs">
                <div
                  className={
                    window.location.href.includes("AboutUs")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      groups
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                    {navbar?.length && navbar[4]?.subMenu[3]?.title}  Page Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
            <li className="marginspace">
              <Link to="/admin/Adminlist">
                <div
                  className={
                    window.location.href.includes("Adminlist") ||
                      window.location.href.includes("AdminCreate") ||
                      window.location.href.includes("AdminSpecificview")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      person_4
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Admin Management</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/MasterdataCreate">
                <div
                  className={
                    window.location.href.includes("MasterdataList") ||
                      window.location.href.includes("MasterdataCreate") ||
                      window.location.href.includes("MasterdataSpecificview")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      folder_supervised
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Master Data Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>

            {/* <li className="marginspace">
            <div
              className={
                window.location.href.includes("PricingPage") ||
                  window.location.href.includes("PricingCreate") ||
                  window.location.href.includes("BatchList") ||
                  window.location.href.includes("Pricinglist") ||
                  window.location.href.includes("BatchCreditCreate")
                  ? "sidebarnavactive displayflex pointer align-items-center"
                  : "sidebarnavinactive displayflex pointer align-items-center"
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample">
              <span className="material-symbols-outlined sidebaricon">
                payments
              </span>
              &nbsp;
              <h6 className="sidebartitle my-auto">
                Pricing Management
              </h6>
              <span className="material-symbols-outlined">
                expand_more
              </span>
            </div>
            <div className="collapse" id="collapseExample">
              <ul className="card card-body">
                <li className="my-2">
                  <Link to="/admin/PricingPage">
                    <div
                      className={
                        window.location.href.includes("PricingPage") ||
                          window.location.href.includes("PricingCreate")
                          ? "sidebarnavactive"
                          : "sidebarnavinactive"
                      }
                    >
                      <div className="displayflex">
                        <span className="material-symbols-outlined sidebaricon">
                          payments
                        </span>
                        &nbsp;
                        <h6 className="sidebartitle my-auto">
                          Pricing Management
                        </h6>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="my-3">
                  <Link to="/Pricinglist">
                    <div
                      className={
                        window.location.href.includes("Pricinglist") ||
                          window.location.href.includes("PricingCreate")
                          ? "sidebarnavactive"
                          : "sidebarnavinactive"
                      }
                    >
                      <div className="displayflex">
                        <span className="material-symbols-outlined sidebaricon">
                          request_quote
                        </span>
                        &nbsp;
                        <h6 className="sidebartitle my-auto">
                          Subscription Management
                        </h6>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="my-3">
                  <Link to="/BatchList">
                    <div
                      className={
                        window.location.href.includes("BatchList") ||
                          window.location.href.includes("BatchCreditCreate")
                          ? "sidebarnavactive"
                          : "sidebarnavinactive"
                      }
                    >
                      <div className="displayflex">
                        <span className="material-symbols-outlined sidebaricon ">
                          attach_money
                        </span>
                        &nbsp;
                        <h6 className="sidebartitle my-auto">Batch Management</h6>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </li> */}

            <li className="marginspace">
              <Link to="/admin/MetatagList">
                <div
                  className={
                    window.location.href.includes("MetatagList") ||
                      window.location.href.includes("MetatagSpecificview")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon ">
                      bookmarks
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Metatag Management</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/Socialmedialist">
                <div
                  className={
                    window.location.href.includes("Socialmedialist") ||
                      window.location.href.includes("SocialmediaCreate") ||
                      window.location.href.includes("SocialmediaSpecificview")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon ">
                      workspaces
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Social Media Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/CouponList">
                <div
                  className={
                    window.location.href.includes("CouponList") ||
                      window.location.href.includes("BlogCreate") ||
                      window.location.href.includes("BlogSpecificview")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      topic
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Coupon Management</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/Testimonial">
                <div
                  className={
                    window.location.href.includes("Testimonial") ||
                      window.location.href.includes("TestimonialSpecificview") ||
                      window.location.href.includes("TestimonialCreate")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      share_reviews
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Testimonial Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/headerManagement">
                <div
                  className={
                    window.location.href.includes("header")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      subheader
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Navbar Management</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/footerManagement">
                <div
                  className={
                    window.location.href.includes("footer")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      toast
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">Footer Management</h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/ContactusCardManagement">
                <div
                  className={
                    window.location.href.includes("ContactusCard")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span className="material-symbols-outlined sidebaricon">
                      phone_in_talk
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Contact Us Section Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>

            <li className="marginspace">
              <Link to="/admin/partners">
                <div
                  className={
                    window.location.href.includes("partners")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span class="material-symbols-outlined sidebaricon">
                      partner_exchange
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Partners Section Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/privacy-policy">
                <div
                  className={
                    window.location.href.includes("privacy-policy")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span class="material-symbols-outlined sidebaricon">
                      policy
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Privacy Policy Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
            <li className="marginspace">
              <Link to="/admin/page/terms-of-service">
                <div
                  className={
                    window.location.href.includes("terms-of-service")
                      ? "sidebarnavactive"
                      : "sidebarnavinactive"
                  }
                >
                  <div className="displayflex">
                    <span class="material-symbols-outlined sidebaricon">
                      list_alt
                    </span>
                    &nbsp;
                    <h6 className="sidebartitle my-auto">
                      Terms Of Use Management
                    </h6>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Sidebar;
