import { actionTypes } from "./action";

export const initState = {
  masterdata: {},
};

function reducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.MASTERDATA_SUCCESS:
      return {
        ...state,
        ...{ masterdata: action.payload },
      };
    default:
      return state;
  }
}
export default reducer;
